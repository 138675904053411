import moment from 'moment';

import { useGetTeamMembers } from '@/api';
import { useAccountInfo } from '@/hooks';
import { isArrayHasData } from '@/utils/helpers';

import { EMPLOYEE_STATUS } from '../../constants';

const useCheckHQEmployeeOnboardingStatus = () => {
  const { companyId } = useAccountInfo();
  const currentTime = moment();

  const { data: teamMembers } = useGetTeamMembers(companyId);

  const employeeTeamMembers = teamMembers?.filter(member => member.type === 'employee');
  const contractorTeamMembers = teamMembers?.filter(member => member.type === 'contractor');

  const completedEmployeeTeamMembers =
    employeeTeamMembers?.filter(
      member =>
        !member.incomplete ||
        member?.status === EMPLOYEE_STATUS.Deactivated ||
        (member.terminationDate && moment(member.terminationDate).isBefore(currentTime)),
    ) || [];

  const completedContractorTeamMembers =
    contractorTeamMembers?.filter(
      member => !member.incomplete || member?.status === EMPLOYEE_STATUS.Deactivated,
    ) || [];

  const isCompanyEmployeesOnly =
    isArrayHasData(completedContractorTeamMembers) && !isArrayHasData(contractorTeamMembers);

  const isCompanyContractorsOnly =
    !isArrayHasData(completedEmployeeTeamMembers) && isArrayHasData(contractorTeamMembers);

  // const isCompanyHasBothTypeOfEmployees =
  //   isArrayHasData(employeeTeamMembers) && isArrayHasData(contractorTeamMembers);

  const isEmployeesCompleted =
    isArrayHasData(employeeTeamMembers) && completedEmployeeTeamMembers?.length >= 1;

  const isEmployeesNeedsAttention =
    completedEmployeeTeamMembers?.length >= 1 &&
    employeeTeamMembers?.length !== completedEmployeeTeamMembers?.length;

  const isEmployeesBlocking = !isArrayHasData(completedEmployeeTeamMembers);

  const isContractorsCompleted =
    isArrayHasData(contractorTeamMembers) && completedContractorTeamMembers?.length >= 1;

  const isContractorsBlocking = !isArrayHasData(completedContractorTeamMembers);

  const isTeamMemberBlocking = isEmployeesBlocking && isContractorsBlocking;

  const isSomeTeamMemberIncomplete =
    isArrayHasData(teamMembers) && teamMembers?.some(member => member.incomplete);

  return {
    isEmployeesCompleted,
    isEmployeesNeedsAttention,
    isEmployeesBlocking,
    isContractorsCompleted,
    isContractorsBlocking,
    isCompanyEmployeesOnly,
    isCompanyContractorsOnly,
    isSomeTeamMemberIncomplete,
    isTeamMemberBlocking,
  };
};

export default useCheckHQEmployeeOnboardingStatus;
