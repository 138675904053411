import { useState } from 'react';
import { useCalendlyEventListener } from 'react-calendly';

export const useCustomCalendlyModal = () => {
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const [isCalendlyLoading, setIsCalendlyLoading] = useState(true);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useCalendlyEventListener({ onEventTypeViewed: () => setIsCalendlyLoading(false) });

  const handleCalendlyModal = () => {
    setIsCalendlyModalOpen(!isCalendlyModalOpen);
    setIsCalendlyLoading(true);
  };

  return {
    handleCalendlyModal,
    isCalendlyLoading,
    isCalendlyModalOpen,
    isPopoverOpen,
    setIsPopoverOpen,
  };
};
