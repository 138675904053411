import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';

import DialogComponent from '@/components/Dialog/DialogComponent';
import { UrgentModal } from '@/components/ui/alerts/UrgentModal/UrgentModal';

import ContactSupportButton from '../../ContactSupportButton';

type DeleteDeductionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoading?: boolean;
  isAssigned: boolean;
};

const DeleteDeductionModal: React.FC<DeleteDeductionModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  isLoading = false,
  isAssigned = false,
}) => {
  return (
    <DialogComponent
      open={isOpen}
      onCancel={onClose}
      width="w-[95vw] sm:w-[352px]"
      height="h-auto"
      modalClasses="rounded-[8px] relative"
    >
      <UrgentModal
        icon={
          isAssigned ? (
            <ExclamationTriangleIcon className="w-6  text-red-500" />
          ) : (
            <TrashIcon className="w-6  text-red-500" />
          )
        }
        title={
          isAssigned
            ? 'Deduction can’t be deleted'
            : 'Are you sure you want to delete this company benefits?'
        }
        subTitle={
          isAssigned ? (
            <p className="text-gray-400">
              This deduction is assigned to one more employees and can’t be deleted. Un-assign the
              employees and come back, or Need to make changes?{' '}
              <ContactSupportButton className="inline text-gray-400" /> for help.
            </p>
          ) : (
            ''
          )
        }
        isConfirmLoading={isLoading}
        onConfirm={!isAssigned ? onDelete : false}
        onClose={onClose}
        onCancel={onClose}
        closeButtonText={isAssigned ? 'Got it' : 'Cancel'}
        confirmButtonText="Delete"
        buttonWarperClasses="flex flex-row justify-between"
      />
    </DialogComponent>
  );
};

export default DeleteDeductionModal;
