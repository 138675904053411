/** @type {import('tailwindcss').Config} */

import tailwindcssForms from '@tailwindcss/forms';
import tailwindcssAriaComponents from 'tailwindcss-react-aria-components';
import { PluginAPI } from 'tailwindcss/types/config';

const customTailwindUtilities = ({ addUtilities }: PluginAPI) => {
  const utilities = {
    // Scrollbar hidden utility
    '.scrollbar-hidden': {
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },

    // Add custom utilities here
  };

  addUtilities(utilities);
};

const config = {
  darkMode: 'class',
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
    './node_modules/react-tailwindcss-datepicker/dist/index.esm.js',
  ],
  theme: {
    extend: {
      screens: {
        'menu-height': { raw: '(max-height: 920px)' },
        xs: '375px',
      },
      spacing: {
        '2x-small': 'var(--2x-small)',
        'x-small': 'var(--x-small)',
        small: 'var(--small)',
        medium: 'var(--medium)',
        large: 'var(--large)',
        'x-large': 'var(--x-large)',
        '2x-large': 'var(--2x-large)',
        '3x-large': 'var(--3x-large)',
        '4x-large': 'var(--4x-large)',
      },
      borderRadius: {
        '2x-small': '4px',
        'x-small': '8px',
        small: '12px',
        medium: '16px',
        large: '20px',
        'x-large': '24px',
        '2x-large': '32px',
        full: '1000px',
      },
      fontFamily: {
        'F37Bolton-Regular': ['F37Bolton-Regular', 'sans-serif'],
        'F37Bolton-Medium': ['F37Bolton-Medium', 'sans-serif'],
        'F37Bolton-Bold': ['F37Bolton-Bold', 'sans-serif'],
        'F37Bolton-RegularItalic': ['F37Bolton-RegularItalic', 'sans-serif'],
        'F37Bolton-MediumItalic': ['F37Bolton-MediumItalic', 'sans-serif'],
        'Yellowtail-Regular': ['Yellowtail-Regular', 'cursive'],
      },
      fontSize: {
        '2x-small': 'var(--font-2x-small)',
        'x-small': 'var(--font-x-small)',
        small: 'var(--font-small)',
        medium: 'var(--font-medium)',
        large: 'var(--font-large)',
        'x-large': 'var(--font-x-large)',
        '2x-large': 'var(--font-2x-large)',
        '3x-large': 'var(--font-3x-large)',
        '4x-large': 'var(--font-4x-large)',
        '5x-large': 'var(--font-5x-large)',
        '6x-large': 'var(--font-6x-large)',
      },
      animation: {
        'reverse-spin': 'reverse-spin 1.5s linear infinite',
      },
      keyframes: {
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
        },
      },
      boxShadow: {
        secondary: '3px 3px 0px 0px #C4C8CB',
      },
      borderColor: {
        DEFAULT: 'var(--gray-100)',
      },
      colors: {
        //Primitive colors
        'gray-50': 'var(--gray-50)',
        'gray-100': 'var(--gray-100)',
        'gray-200': 'var(--gray-200)',
        'gray-300': 'var(--gray-300)',
        'gray-400': 'var(--gray-400)',
        'gray-500': 'var(--gray-500)',
        'gray-600': 'var(--gray-600)',
        'gray-700': 'var(--gray-700)',
        'gray-800': 'var(--gray-800)',
        'gray-900': 'var(--gray-900)',
        'navy-50': 'var(--navy-50)',
        'navy-100': 'var(--navy-100)',
        'navy-200': 'var(--navy-200)',
        'navy-300': 'var(--navy-300)',
        'navy-400': 'var(--navy-400)',
        'navy-500': 'var(--navy-500)',
        'navy-600': 'var(--navy-600)',
        'navy-700': 'var(--navy-700)',
        'navy-800': 'var(--navy-800)',
        'navy-900': 'var(--navy-900)',
        'orange-50': 'var(--orange-50)',
        'orange-100': 'var(--orange-100)',
        'orange-200': 'var(--orange-200)',
        'orange-300': 'var(--orange-300)',
        'orange-400': 'var(--orange-400)',
        'orange-500': 'var(--orange-500)',
        'orange-600': 'var(--orange-600)',
        'orange-700': 'var(--orange-700)',
        'orange-800': 'var(--orange-800)',
        'orange-900': 'var(--orange-900)',
        'yellow-50': 'var(--yellow-50)',
        'yellow-100': 'var(--yellow-100)',
        'yellow-200': 'var(--yellow-200)',
        'yellow-300': 'var(--yellow-300)',
        'yellow-400': 'var(--yellow-400)',
        'yellow-500': 'var(--yellow-500)',
        'yellow-600': 'var(--yellow-600)',
        'yellow-700': 'var(--yellow-700)',
        'yellow-800': 'var(--yellow-800)',
        'yellow-900': 'var(--yellow-900)',
        'emerald-50': 'var(--emerald-50)',
        'emerald-100': 'var(--emerald-100)',
        'emerald-200': 'var(--emerald-200)',
        'emerald-300': 'var(--emerald-300)',
        'emerald-400': 'var(--emerald-400)',
        'emerald-500': 'var(--emerald-500)',
        'emerald-600': 'var(--emerald-600)',
        'emerald-700': 'var(--emerald-700)',
        'emerald-800': 'var(--emerald-800)',
        'emerald-900': 'var(--emerald-900)',
        'green-50': 'var(--green-50)',
        'green-100': 'var(--green-100)',
        'green-200': 'var(--green-200)',
        'green-300': 'var(--green-300)',
        'green-400': 'var(--green-400)',
        'green-500': 'var(--green-500)',
        'green-600': 'var(--green-600)',
        'green-700': 'var(--green-700)',
        'green-800': 'var(--green-800)',
        'green-900': 'var(--green-900)',
        'blue-50': 'var(--blue-50)',
        'blue-100': 'var(--blue-100)',
        'blue-200': 'var(--blue-200)',
        'blue-300': 'var(--blue-300)',
        'blue-400': 'var(--blue-400)',
        'blue-500': 'var(--blue-500)',
        'blue-600': 'var(--blue-600)',
        'blue-700': 'var(--blue-700)',
        'blue-800': 'var(--blue-800)',
        'blue-900': 'var(--blue-900)',
        'cyan-50': 'var(--cyan-50)',
        'cyan-100': 'var(--cyan-100)',
        'cyan-200': 'var(--cyan-200)',
        'cyan-300': 'var(--cyan-300)',
        'cyan-400': 'var(--cyan-400)',
        'cyan-500': 'var(--cyan-500)',
        'cyan-600': 'var(--cyan-600)',
        'cyan-700': 'var(--cyan-700)',
        'cyan-800': 'var(--cyan-800)',
        'cyan-900': 'var(--cyan-900)',
        'purple-50': 'var(--purple-50)',
        'purple-100': 'var(--purple-100)',
        'purple-200': 'var(--purple-200)',
        'purple-300': 'var(--purple-300)',
        'purple-400': 'var(--purple-400)',
        'purple-500': 'var(--purple-500)',
        'purple-600': 'var(--purple-600)',
        'purple-700': 'var(--purple-700)',
        'purple-800': 'var(--purple-800)',
        'purple-900': 'var(--purple-900)',
        'red-50': 'var(--red-50)',
        'red-100': 'var(--red-100)',
        'red-200': 'var(--red-200)',
        'red-300': 'var(--red-300)',
        'red-400': 'var(--red-400)',
        'red-500': 'var(--red-500)',
        'red-600': 'var(--red-600)',
        'red-700': 'var(--red-700)',
        'red-800': 'var(--red-800)',
        'red-900': 'var(--red-900)',
        gray: 'var(--gray)',

        //Color tokens
        'primary-emerald': 'var(--emerald-500)',
        'primary-emerald-hover': 'var(--emerald-600)',
        'primary-emerald-light-bg': 'var(--emerald-50)',
        'primary-emerald-light-border': 'var(--emerald-200)',
        'primary-emerald-active': 'var(--emerald-700)',
        'secondary-navy': 'var(--navy-500)',
        'secondary-navy-hover': 'var(--navy-600)',
        'secondary-navy-light-border': 'var(--navy-300)',
        'secondary-navy-light-bg': 'var(--navy-50)',
        'secondary-navy-active': 'var(--navy-700)',
        'tertiary-orange-orange': 'var(--orange-500)',
        'tertiary-orange-orange-hover': 'var(--orange-600)',
        'tertiary-orange-orange-light-border': 'var(--orange-300)',
        'tertiary-orange-orange-light-bg': 'var(--orange-50)',
        'tertiary-orange-orange-text': 'var(--orange-700)',
        'tertiary-orange-orange-active': 'var(--orange-700)',
        'tertiary-cyan-cyan': 'var(--cyan-500)',
        'tertiary-cyan-cyan-hover': 'var(--cyan-600)',
        'tertiary-cyan-cyan-light-border': 'var(--cyan-300)',
        'tertiary-cyan-cyan-light-bg': 'var(--cyan-50)',
        'tertiary-cyan-cyan-text': 'var(--cyan-700)',
        'tertiary-cyan-cyan-active': 'var(--cyan-700)',
        'tertiary-purple-purple': 'var(--purple-500)',
        'tertiary-purple-purple-hover': 'var(--purple-600)',
        'tertiary-purple-purple-light-border': 'var(--purple-300)',
        'tertiary-purple-purple-light-bg': 'var(--purple-50)',
        'tertiary-purple-purple-text': 'var(--purple-700)',
        'tertiary-purple-purple-active': 'var(--purple-600)',
        'semantic-success-green': 'var(--green-500)',
        'semantic-success-green-hover': 'var(--green-600)',
        'semantic-success-green-light-border': 'var(--green-300)',
        'semantic-success-green-light-bg': 'var(--green-50)',
        'semantic-success-green-text': 'var(--green-700)',
        'semantic-success-green-active': 'var(--green-700)',
        'semantic-accent-blue': 'var(--blue-500)',
        'semantic-accent-blue-hover': 'var(--blue-600)',
        'semantic-accent-blue-light-border': 'var(--blue-300)',
        'semantic-accent-blue-light-bg': 'var(--blue-50)',
        'semantic-accent-blue-text': 'var(--blue-700)',
        'semantic-accent-blue-active': 'var(--blue-700)',
        'semantic-warning-yellow': 'var(--yellow-500)',
        'semantic-warning-yellow-hover': 'var(--yellow-600)',
        'semantic-warning-yellow-light-border': 'var(--yellow-300)',
        'semantic-warning-yellow-light-bg': 'var(--yellow-50)',
        'semantic-warning-yellow-text': 'var(--yellow-700)',
        'semantic-warning-yellow-active': 'var(--yellow-700)',
        'semantic-danger-red': 'var(--red-500)',
        'semantic-danger-red-hover': 'var(--red-600)',
        'semantic-danger-red-light-border': 'var(--red-300)',
        'semantic-danger-red-light-bg': 'var(--red-50)',
        'semantic-danger-red-text': 'var(--red-700)',
        'semantic-danger-red-active': 'var(--red-700)',
        'content-heading': 'var(--gray-900)',
        'content-body-strong': 'var(--gray-500)',
        'content-body-medium': 'var(--gray-400)',
        'content-body-light': 'var(--gray-300)',
        'content-primary': 'var(--emerald-500)',
        'content-white': 'var(--white)',
        'content-body-disabled': 'var(--gray-200)',
        'surface-white': 'var(--white)',
        'surface-light-gray': 'var(--gray)',
        'surface-dark': 'var(--navy-500)',
        'surface-primary': 'var(--emerald-500)',
        'surface-dark-gray': 'var(--gray-50)',
        'surface-dark-gray-100': 'var(--gray-100)',
        'surface-dark-gray-200': 'var(--gray-200)',
        'border-light': 'var(--gray-50)',
        'border-medium': 'var(--gray-100)',
        'border-dark': 'var(--gray-200)',
        'border-strong': 'var(--gray-500)',
        'border-primary': 'var(--emerald-500)',
        'icon-white': 'var(--white)',
        'icon-primary': 'var(--emerald-500)',
        'icon-gray': 'var(--gray-400)',
        'icon-dark': 'var(--gray-600)',
        'icon-disabled': 'var(--gray-200)',
      },
    },
  },
  plugins: [tailwindcssForms, tailwindcssAriaComponents, customTailwindUtilities],
};

export default config;
