import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';
import Drawer from '@/components/ui/Drawer/Drawer';
import { DeductionClickType } from '@/modules/employee/forms';
import { ConfirmationModal } from '@/modules/payrollSchedule/components';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';

import EmployeeDeductionForm from './components/EmployeeDeductionForm/EmployeeDeductionForm';
import PayrollImpactNoticePopup from './components/PayrollImpactNoticePopup';
import UnassignDeductionModal from './components/UnassignDeductionModal/UnassignDeductionModal';
import useEmployeeDeductionDrawer from './useEmployeeDeductionDrawer';

type EmployeeDeductionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  data: DeductionTypes;
  isEdit?: boolean;
  employeeName?: string;
  employeeId: number;
  isEditedInPayrollSettings?: boolean;
  employeeSsn?: string | number | null;
  refetchEmployeeData?: VoidFunction;
  handleDeductionClick?: (data: DeductionClickType) => void;
  handleCompanyDeductionDrawer: () => void;
};

const EmployeeDeductionDrawer = ({
  isEdit,
  isOpen,
  data,
  onClose,
  employeeName,
  employeeId,
  isEditedInPayrollSettings,
  employeeSsn,
  refetchEmployeeData,
  handleDeductionClick,
  handleCompanyDeductionDrawer,
}: EmployeeDeductionDrawerProps) => {
  const {
    handleSubmit,
    isUnassignDeduction,
    handleUnassignDeductionModal,
    formRef,
    form,
    isSaveDeductionDisabled,
    handleUnassignEmployeeDeduction,
    handleEmployeeDeduction,
    isSavingDeductionLoading,
    isUnassignLoading,
    isNoticePopupOpen,
    setIsNoticePopupOpen,
    onNoticeCancel,
    isDirtyModalOpen,
    handleCloseDirtyModal,
    handleConfirmDirtyModal,
    handleDrawerClose,
    onError,
    isCheckHqUser,
    handleEditDeduction,
  } = useEmployeeDeductionDrawer({
    isEdit,
    data,
    employeeId,
    onClose,
    refetchEmployeeData,
    employeeSsn,
    handleCompanyDeductionDrawer,
  });

  return (
    <>
      {isDirtyModalOpen && (
        <ConfirmationModal
          isOpen={isDirtyModalOpen}
          handleConfirm={handleConfirmDirtyModal}
          handleClose={handleCloseDirtyModal}
        />
      )}
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        isBackIcon={isEditedInPayrollSettings}
        onBackAction={onClose}
        title={
          isEdit
            ? isEditedInPayrollSettings
              ? `Edit ${employeeName}'s deduction`
              : 'Edit deduction'
            : 'Add a deduction'
        }
        modelClasses="w-full max-w-[720px]"
        footer={
          <div
            className={twMerge(
              'flex items-center justify-end p-[24px]',
              isEdit ? 'justify-between' : '',
            )}
          >
            {isEdit && (
              <Button
                variant="text"
                onPress={handleUnassignDeductionModal}
                parentClasses="text-red-500"
              >
                Unassign
              </Button>
            )}

            <Button
              type="submit"
              variant="primary"
              parentClasses="w-auto px-6 py-3"
              children={isEdit ? 'Save changes' : `Assign to ${employeeName}`}
              onPress={handleSubmit}
              isDisabled={isSaveDeductionDisabled}
              isLoading={isSavingDeductionLoading}
              width="w-[150px] min-w-[150px]"
            />
          </div>
        }
      >
        <EmployeeDeductionForm
          isShowingEditDeduction={!isEditedInPayrollSettings}
          onDeductionSubmit={handleEmployeeDeduction}
          formRef={formRef}
          form={form}
          onDeleteDeduction={onClose}
          data={data}
          onError={onError}
          employeeSsn={employeeSsn}
          isEdit={isEdit}
          handleDeductionClick={handleDeductionClick}
          isCheckHqUser={isCheckHqUser}
          handleEditDeduction={handleEditDeduction}
          handleCompanyDeductionDrawer={handleCompanyDeductionDrawer}
        />
      </Drawer>
      {isUnassignDeduction && (
        <UnassignDeductionModal
          isOpen={isUnassignDeduction}
          onClose={handleUnassignDeductionModal}
          onDelete={() => handleUnassignEmployeeDeduction()}
          isLoading={isUnassignLoading}
          employeeName={employeeName || ''}
        />
      )}
      {isNoticePopupOpen && (
        <PayrollImpactNoticePopup
          isNoticePopupOpen={isNoticePopupOpen}
          setIsNoticePopupOpen={setIsNoticePopupOpen}
          onNoticeCancel={onNoticeCancel}
        />
      )}
    </>
  );
};
export default EmployeeDeductionDrawer;
