import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
const useSkipPayrollModel = () => {
  const schema = yup.object().shape({
    skipped_reason: yup.string().required('Skip reason is required'),
  });
  const form = useForm({
    defaultValues: {
      skipped_reason: '',
    },
    resolver: yupResolver(schema),
  });

  return {
    form,
  };
};

export default useSkipPayrollModel;
