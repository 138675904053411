import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useAccountInfo } from '@/hooks';
import * as Routes from '@/routes/routes';

import { usePatchCompanyTaxInformation } from './apis';
import { FormType } from './forms/types';
import { PayloadType, useEmployerTaxItemType } from './types';

const useEmployerTaxItem = ({ currentTaxItem, onSubmit }: useEmployerTaxItemType) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const shouldShowSupportLink = !currentPath?.includes(Routes.ACCOUNT_SETUP_TAXES);

  const { companyId } = useAccountInfo();

  const formRef = useRef();

  const { mutate, isPending: isSavingTaxInformation } = usePatchCompanyTaxInformation({
    companyId,
    mutationParams: {
      onSuccess: data => {
        onSubmit(data);
      },
    },
  });

  const submitHandler = (values: FormType) => {
    if (currentTaxItem) {
      const payload: PayloadType = {
        id: currentTaxItem.id,
        isExempt: values.isExempt,
      };

      if (!values.isExempt) {
        payload.fields = values.fields.map(({ name, value }) => ({ name, value }));
      }

      const formattedPayload = {
        taxItems: [payload],
      };

      mutate(formattedPayload);
    }
  };

  return {
    submitHandler,
    isSavingTaxInformation,
    formRef,
    shouldShowSupportLink,
  };
};

export default useEmployerTaxItem;
