import { useMutation, useQueryClient } from '@tanstack/react-query';

import { putCall } from '@/api/axios';
import { usePutBenefitType } from './types';
import { PUT_DEDUCTION } from './employeeApiRoutes';

const usePutDeduction = ({ companyId, employeeId }: usePutBenefitType) => {
  const queryClient = useQueryClient();

  const updateDeductions = (data: any) =>
    putCall(PUT_DEDUCTION({ companyId, employeeId, id: data.id }), data);

  return useMutation({
    mutationFn: updateDeductions,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDeductions'] });
    },
  });
};

export default usePutDeduction;
