import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FieldError } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

import { CurrentStepContext } from '@/App';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { Button } from '@/components/ui/designSystem/Button/Button';
import { ErrorPostWorkLocationType } from '@/modules/accountSetup/components/Company/forms/types';
import { extractEventName, keyUp } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import ContactSupportButton from '../ContactSupportButton';
import { useInputLocationSends } from './useInputLocationSends';
import { useSmartyAutocomplete } from './useSmartyAutoComplete';

export type stateLocation = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

export type addressFormValuesType = {
  streetAddress?: string | null;
  aptSteNumber?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
};

interface GooglePlacesAutocompleteProps {
  defaultValue?: string;
  errorMessage?: FieldError | undefined;
  onPlaceSelect: (stateLocation: stateLocation | string) => void;
  name?: string;
  addressError?: ErrorPostWorkLocationType;
  handleFetchCurrentFormValues?: () => addressFormValuesType;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  handleCloseSuggestedAddressPopup?: VoidFunction;
  isSuggestedAddressModelOpen?: boolean;
}

const InputLocationSends = forwardRef(
  (
    {
      onPlaceSelect,
      defaultValue,
      errorMessage,
      isReadOnly,
      isDisabled,
      addressError,
      handleFetchCurrentFormValues,
      handleCloseSuggestedAddressPopup,
      isSuggestedAddressModelOpen,
      ...rest
    }: GooglePlacesAutocompleteProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const location = useLocation();
    const [address, setAddress] = useState<string>(defaultValue ? defaultValue : '');
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const { stepperInformation } = useContext(CurrentStepContext);
    const { suggestions: placePredictions, loading: isPlacePredictionsLoading } =
      useSmartyAutocomplete(address);

    const { currentMsgAddress, suggestedMsgAddress, handleSetUpdatedAddress } =
      useInputLocationSends({
        addressError,
        handleFetchCurrentFormValues,
        onPlaceSelect,
        handleCloseSuggestedAddressPopup,
      });
    useEffect(() => {
      setError(errorMessage?.message);
    }, [errorMessage]);
    useEffect(() => {
      if (defaultValue) {
        setAddress(defaultValue);
      }
    }, [defaultValue]);

    const inputRef = useRef<HTMLInputElement>(null);

    // Use `useImperativeHandle` to expose the internal `inputRef` to the parent via `ref`
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleRef = (el: HTMLInputElement | null) => {
      inputRef.current = el;
      if (typeof ref === 'function') {
        ref(el); // Pass the ref to the parent component
      } else if (ref) {
        (ref as MutableRefObject<HTMLInputElement | null>).current = el;
      }
    };

    return (
      <div className="relative">
        <input
          ref={handleRef}
          name="input_location"
          className={`h-[44px] w-full rounded-lg border ${
            error
              ? 'border-red-500'
              : isDisabled
              ? 'border-gray-100 text-gray-100 placeholder:text-gray-100'
              : 'border-gray-300'
          } pl-[10px] focus:outline-none`}
          value={address}
          placeholder="Street address (Ex. 4594 UnionSt...)"
          autoComplete="off"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            trackEvent('inputChange', {
              name: 'location_input',
              currentPage: extractEventName(location.pathname),
              currentStep: stepperInformation.currentStep,
              previousStep: stepperInformation.previousStep,
              currentStepName: stepperInformation.currentStepName,
              previousStepName: stepperInformation.previousStepName,
            });
            setTimeout(() => {
              if (isFocused) {
                setIsFocused(false);
              }
            }, 200);
          }}
          onChange={evt => {
            setAddress(evt.target.value);
            onPlaceSelect(evt.target.value);
          }}
          readOnly={isReadOnly}
          disabled={isDisabled}
          {...rest}
        />
        {isFocused && !isPlacePredictionsLoading && (
          <ul className="absolute z-10 max-h-64 w-full overflow-y-auto rounded-md bg-white text-left shadow-md lg:w-full">
            {placePredictions.map((item, index) => (
              <li
                onKeyUp={keyUp}
                tabIndex={0}
                role="menuitem"
                key={index}
                className="cursor-pointer px-4 py-2 hover:bg-gray-100 "
                onClick={event => {
                  event.preventDefault();
                  setAddress(item.streetAddress);
                  onPlaceSelect(item);
                }}
              >
                <div className="font-F37Bolton-Medium  text-gray-900">{item.description}</div>
              </li>
            ))}
          </ul>
        )}
        {error && <p className="text-sm text-red-500">{error}</p>}
        {isSuggestedAddressModelOpen && addressError && (
          <DialogComponent
            open={isSuggestedAddressModelOpen}
            onCancel={handleCloseSuggestedAddressPopup}
            modalTitle={
              <div className="gap-small flex flex-col">
                <span className="text-content-heading font-F37Bolton-Bold">
                  Use suggested address?
                </span>
                <span className="text-content-body-medium font-F37Bolton-Regular">
                  We couldn’t verify your address. Here’s the closest suggested address based on
                  your entry.
                </span>
              </div>
            }
            height="100%"
            modalClasses="p-x-large  w-[400px]"
            isDismissable
            showCloseIcon
            children={
              <>
                <div className="gap-medium p-medium rounded-small bg-surface-light-gray my-x-large flex flex-col">
                  <div className="gap-x-small flex flex-col">
                    <span className="text-content-body-medium font-F37Bolton-Medium text-2x-small uppercase">
                      Your address
                    </span>
                    <span className="text-content-heading font-F37Bolton-Medium text-large">
                      {currentMsgAddress?.firstLine}
                    </span>
                    <span className="text-content-heading font-F37Bolton-Medium text-large">
                      {currentMsgAddress?.secondLine}
                    </span>
                  </div>
                  <div className="gap-x-small flex flex-col">
                    <span className="text-content-body-medium font-F37Bolton-Medium text-2x-small uppercase">
                      suggested address
                    </span>
                    <span className="text-content-heading font-F37Bolton-Medium text-large">
                      {suggestedMsgAddress?.firstLine}
                    </span>
                    <span className="text-content-heading font-F37Bolton-Medium text-large">
                      {suggestedMsgAddress?.secondLine}
                    </span>
                  </div>
                  <div>
                    <Button onPress={handleSetUpdatedAddress} variant="primary" className="w-full">
                      Use this address
                    </Button>
                  </div>
                </div>
                <div className="text-content-body-medium font-F37Bolton-Regular text-medium text-center">
                  If you believe your original address was correct, please{' '}
                  <ContactSupportButton className="inline lowercase underline" /> for assistance
                </div>
              </>
            }
          />
        )}
      </div>
    );
  },
);

InputLocationSends.displayName = 'InputLocationSends';

export default InputLocationSends;
