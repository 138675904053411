import { Elements } from '@stripe/react-stripe-js';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { Auth0ProviderWithNavigate } from './hooks/authHook';
import './index.css';
import { initializeAnalytics, initializeCobrowse } from './utils/analyticsInit';
import { stripeOptions, stripePromise } from './utils/stripeUtils';

// Initialize analytics and cobrowse
initializeAnalytics();
initializeCobrowse();

const AppContent = () => (
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <App />
      </Elements>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

// Conditional rendering for development mode
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  import.meta.env.DEV ? <AppContent /> : <AppContent />,
);
