import { useState } from 'react';

import { HandSpeaker } from '@/assets/icons';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { Button } from '@/components/ui/designSystem/Button/Button';

import { ExplanationModalComponent } from '../ExplanationModalComponent/ExplanationModalComponent';

const AccountUnderReviewBanner = () => {
  const [isExplanationOpen, setIsExplanationOpen] = useState<boolean>(false);
  return (
    <div className="py-medium px-2x-large gap-x-large flex flex-col justify-between bg-blue-600 sm:flex-row md:h-[60px] md:items-center">
      <div className="gap-medium flex items-center">
        <HandSpeaker stroke="white" />
        <div className="text-small text-white">
          Our team is currently verifying your account. We appreciate your patience as this process
          may take up to 3 business days.
        </div>
      </div>
      <Button
        variant="white"
        children="What does this mean?"
        onPress={() => setIsExplanationOpen(true)}
      />
      <DialogComponent
        open={isExplanationOpen}
        onCancel={() => setIsExplanationOpen(false)}
        width="lg:w-[540px] w-[335px]"
        height="h-auto"
        modalClasses="rounded-[8px] relative"
        isDismissable
      >
        <ExplanationModalComponent onClose={() => setIsExplanationOpen(false)} />
      </DialogComponent>
    </div>
  );
};

export default AccountUnderReviewBanner;
