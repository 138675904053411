import Button from '@/components/Button/Button';

import EmployerTaxItemForm from './forms/EmployerTaxItemForm';
import { EmployerTaxItemProps } from './types';
import useEmployerTaxItem from './useEmployerTaxItem';

const EmployerTaxItem = ({
  currentTaxItem,
  shouldShowAllFields,
  onSubmit,
  isLastStep,
  isFederalTaxItem,
  shouldShowBackBtn,
  handleBackBtnClick,
  handleIsDirty,
}: EmployerTaxItemProps) => {
  const { submitHandler, isSavingTaxInformation, formRef, shouldShowSupportLink } =
    useEmployerTaxItem({
      onSubmit,
      currentTaxItem,
    });

  return (
    <div className="flex flex-col gap-5">
      <EmployerTaxItemForm
        taxItem={currentTaxItem}
        shouldShowAllFields={shouldShowAllFields}
        onSubmit={submitHandler}
        isSavingTaxInformation={isSavingTaxInformation}
        isLastStep={isLastStep}
        isFederalTaxItem={isFederalTaxItem}
        shouldShowSupportLink={shouldShowSupportLink}
        hideSubmitButton={shouldShowBackBtn}
        ref={formRef}
        handleIsDirty={handleIsDirty}
      />
      {shouldShowBackBtn && (
        <div className="flex flex-wrap-reverse gap-2 sm:flex-nowrap">
          <Button
            variant="tertiary"
            width="w-full"
            parentClasses="grow"
            onPress={() => handleBackBtnClick()}
          >
            {'Go back'}
          </Button>
          <Button
            parentClasses="grow"
            variant="primary"
            type="submit"
            width="w-full"
            isLoading={isSavingTaxInformation}
            onPress={() => formRef.current?.submitForm()}
          >
            {isLastStep ? 'Save changes' : 'Next'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmployerTaxItem;
