import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRightEndOnRectangleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import useGetPayrollHistory from '@/modules/contractorPayment/apis/useGetPayrollHistory';
import useCheckHQCompanyOnboardingStatus from '@/modules/dashboard/components/checkhq/useCheckHQCompanyOnboardingStatus';
import { useGetPayrollHistory as useGetEmployeePayrollHistory } from '@/modules/runPayroll/apis';
import { RootState } from '@/redux/store';
import {
  checkAccountForSubscriptionReminder,
  impersonationLogoutHandler,
  isArrayHasData,
} from '@/utils/helpers';

import { ACCOUNT_BILLING, LOGOUT } from './constants';

const menuItemList = [
  {
    label: 'Accounts and billing',
    key: ACCOUNT_BILLING,
    startIcon: <Cog6ToothIcon className="size-[20px] text-gray-300" />,
    className: '',
  },
  {
    label: 'Logout',
    key: LOGOUT,
    startIcon: <ArrowRightEndOnRectangleIcon className="size-[20px] text-red-500" />,
    className: 'text-red-500',
  },
];

const useHeader = () => {
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const isPassedSignupStepper = location.pathname.includes('signup-stepper');
  const isBillingCenterOpen = searchParams.get('isBillingCenterOpen') === 'true';

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isOpenedLogoutModal, setIsOpenedLogoutModal] = useState<boolean>(false);
  const [isAnnouncementModalOpen, setIsAnnounceModalOpen] = useState(true);
  const [isOpenMyAccountModal, setIsOpenMyAccountModal] = useState(isBillingCenterOpen ?? false);
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const [isAnnouncementBannerShow, setIsAnnouncementBannerShow] = useState(true);

  const { logout, user } = useAuth0();
  const { isAuthenticatedAsImpersonate, isDemoAccount, account, companyId, isCheckHqUser } =
    useAccountInfo();
  const { billingInfo: subscriptionInfo } = useBillingInfo();

  const { firstName, lastName } = account || {};

  const demoInfo = useSelector((state: RootState) => state.demo?.demoInfo);

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('featureAnnouncementDismissed');
    if (hasSeenModal) {
      setIsAnnounceModalOpen(false);
    }
  }, []);

  useEffect(() => {
    const hasRemovedAnnouncementBanner = sessionStorage.getItem('announecementBanner');
    if (hasRemovedAnnouncementBanner) {
      setIsAnnouncementBannerShow(false);
    }
  }, []);

  // Fetch payroll history
  const { data: payrollHistoriesList } = useGetEmployeePayrollHistory({
    companyId,
  });
  const { data: contractorPayrollHistory } = useGetPayrollHistory({ companyId });

  const { isCompanyHasFailedPayrollDeposit, isCheckHQAccountUnderReview } =
    useCheckHQCompanyOnboardingStatus();

  const isCompanyHasFailedContractorPayment = useMemo(() => {
    if (isCheckHqUser) {
      return isCompanyHasFailedPayrollDeposit;
    }
    return isArrayHasData(contractorPayrollHistory)
      ? contractorPayrollHistory?.some(payroll => payroll.status === 6)
      : false;
  }, [contractorPayrollHistory, isCheckHqUser, isCompanyHasFailedPayrollDeposit]);

  const isPayrollPaymentFailed = useMemo(() => {
    if (isCheckHqUser) {
      return isCompanyHasFailedPayrollDeposit;
    }
    return isArrayHasData(payrollHistoriesList)
      ? payrollHistoriesList?.some(payroll => payroll.status === 6)
      : false;
  }, [isCheckHqUser, isCompanyHasFailedPayrollDeposit, payrollHistoriesList]);

  const {
    email,
    businessRole,
    numberOfEmployees,
    numberOfContractors,
    otherBusinessRole: otherRole,
  } = demoInfo || {};

  const currentPath = window.location.pathname;

  const handleLogout = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      impersonationLogoutHandler();
      logout({
        logoutParams: {
          returnTo: window.location.origin + '/login',
        },
      });
      window.fcWidget.user.clear().then(
        function () {
          console.log('FcWidget User cleared');
        },
        function () {
          console.error('FcWidget User Not cleared');
        },
      );
      window.FreshworksWidget('destroy');
    } catch (error) {
      alert(error);
    }
  };

  const bannerInfo = checkAccountForSubscriptionReminder({ subscriptionInfo, account });

  const handleCalendlyModal = () => {
    setIsCalendlyModalOpen(!isCalendlyModalOpen);
  };

  const handleHeaderMenuActions = (action: string | number) => {
    if (action === LOGOUT) {
      setIsOpenedLogoutModal(true);
    } else if (action === ACCOUNT_BILLING) setIsOpenMyAccountModal(true);
  };

  const handleAnnouncementModalOpen = () => {
    setIsAnnounceModalOpen(true);
  };

  const handleClose = () => {
    sessionStorage.setItem('featureAnnouncementDismissed', 'true');
    setIsAnnounceModalOpen(false);
  };

  const handleBannerClose = () => {
    sessionStorage.setItem('announecementBanner', 'true');
    setIsAnnouncementBannerShow(false);
  };

  return {
    bannerInfo,
    handleLogout,
    isOpenedLogoutModal,
    setIsOpenedLogoutModal,
    mobileMenuOpen,
    setMobileMenuOpen,
    user,
    currentPath,
    businessRole,
    email,
    numberOfContractors,
    numberOfEmployees,
    otherRole,
    handleCalendlyModal,
    handleHeaderMenuActions,
    menuItemList,
    isOpenMyAccountModal,
    setIsOpenMyAccountModal,
    isCalendlyModalOpen,
    firstName,
    lastName,
    isAuthenticatedAsImpersonate,
    isDemoAccount,
    isBillingCenterOpen,
    isPayrollPaymentFailed,
    isCheckHqUser,
    isCheckHQAccountUnderReview,
    isPassedSignupStepper,
    isAnnouncementModalOpen,
    handleAnnouncementModalOpen,
    handleClose,
    isAnnouncementBannerShow,
    handleBannerClose,
    isCompanyHasFailedContractorPayment,
  };
};

export default useHeader;
