import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Dialog, Heading, Modal, ModalOverlay } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';

import { DrawerType } from './types';

const DrawerModalOverlayId = 'drawerModalOverlay';

const Drawer: FC<DrawerType<any>> = ({
  children,
  open = false,
  onClose,
  title,
  footer,
  isBackIcon = false,
  titleClasses,
  modelClasses,
  onBackAction,
  isDismissable = true,
  ...rest
}) => {
  return (
    <ModalOverlay
      isOpen={open}
      id={DrawerModalOverlayId}
      className="fixed inset-0 z-50 bg-black bg-opacity-20 backdrop-blur-[2px] duration-300 ease-in-out"
      isDismissable={isDismissable}
      onOpenChange={() => {
        onClose && onClose();
      }}
      shouldCloseOnInteractOutside={(element: HTMLElement) => {
        /**
         * if the element is modal overlay element then return true otherwise false
         * if the returned value is true then only it will call the onOpenChange.
         * reason: when there is other modal on top of the drawer, event of the any element of that modal is discarded as the there is default stopPropagation is true in drawer modalOverlay element.
         *  */

        return element.id === DrawerModalOverlayId;
      }}
    >
      <Modal
        className={twMerge(
          'fixed bottom-0 right-0 top-0 w-full overflow-y-auto border-l border-gray-200 bg-white shadow-xl outline-none duration-300 ease-in-out lg:w-[800px]',
          modelClasses,
        )}
        {...rest}
      >
        <Dialog className="relative focus-visible:outline-none">
          <div className="flex h-screen flex-col">
            <div className="flex items-center justify-between px-6 py-5 focus-visible:outline-none">
              <div className="flex items-center">
                {isBackIcon && (
                  <ArrowLeftIcon
                    className="  mr-[16px] size-5 cursor-pointer"
                    onClick={onBackAction ? onBackAction : onClose}
                  />
                )}
                <Heading slot="title" className={`font-F37Bolton-Medium text-2xl ${titleClasses}`}>
                  {title}
                </Heading>
              </div>

              <Button variant="text" parentClasses="h-fit" onPress={onClose}>
                <XMarkIcon className="size-6" />
              </Button>
            </div>
            <hr />
            <div className="flex-1 px-6  pb-10">{children}</div>

            <div className="w-full bg-white">
              <hr />
              {!!footer && footer}
            </div>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export default Drawer;
