import moment from 'moment';
import { useEffect, useState } from 'react';

import { useBillingInfo } from '@/hooks';

import { PaymentDetailsType } from './types';
import { SUBSCRIPTION_REMINDER_STEPPER_IDS } from './utils/constants';

interface useSubscriptionReminderModalType {
  remainingDays?: string | number;
  isFreeTrailOver?: boolean;
}

const useSubscriptionReminderModal = ({
  remainingDays,
  isFreeTrailOver,
}: useSubscriptionReminderModalType) => {
  const [currentStep, setCurrentStep] = useState(
    SUBSCRIPTION_REMINDER_STEPPER_IDS.renderUpgradePlan,
  );
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsType | null>(null);
  const { billingInfo: subscriptionInfo, refetchBillingInfo } = useBillingInfo();

  const isSubscriptionCanceled =
    subscriptionInfo?.activeSubscriptionAgreement?.scheduledToBeCancelled;
  const confirmReminderHandler = () => {
    setCurrentStep(SUBSCRIPTION_REMINDER_STEPPER_IDS.renderUpgradePlan);
  };

  const upgradePlanTitle = (() => {
    if (isSubscriptionCanceled) {
      return 'Reactivate your subscription';
    }

    if (isFreeTrailOver) {
      return 'Your free trial is over!';
    }

    if (remainingDays) {
      return `Your free trial ends in ${remainingDays} days!`;
    }

    return 'Manage your subscription';
  })();

  const upgradePlanSubTitle = (() => {
    if (isFreeTrailOver || isSubscriptionCanceled) {
      return 'Select your plan to continue running payroll.';
    }

    if (remainingDays) {
      return `Ready to pick your plan with us?`;
    }

    return 'Take control of your plan. Upgrade, downgrade, or make changes to fit your needs.';
  })();

  useEffect(() => {
    const todayDate = moment().format('MM-DD-YY');
    sessionStorage.setItem(`subscriptionReminder-${todayDate}`, true);
  }, [remainingDays]);

  const paymentSuccessHandler = (payment: PaymentDetailsType) => {
    if (payment) {
      setPaymentDetails({
        amount: payment.amount,
        planName: payment.planName,
        discount: subscriptionInfo?.activeSubscriptionAgreement?.discountCodes,
      });
      setCurrentStep(SUBSCRIPTION_REMINDER_STEPPER_IDS.renderPaymentSuccess);
      refetchBillingInfo();
    }
  };

  return {
    currentStep,
    confirmReminderHandler,
    paymentSuccessHandler,
    paymentDetails,
    upgradePlanTitle,
    upgradePlanSubTitle,
  };
};

export default useSubscriptionReminderModal;
