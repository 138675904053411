import { useAuth0 } from '@auth0/auth0-react';
import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { IconBuildingBank, IconShieldLock } from '@tabler/icons-react';
import { useEffect, useMemo } from 'react';
import { useCalendlyEventListener } from 'react-calendly';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { VERTICAL_STEPPER_SIDEBAR_STATUS } from '@/components/ui/VerticalStepperSidebar/constants';
import { VerticalStepperSidebarStepType } from '@/components/ui/VerticalStepperSidebar/types';
import useVerticalStepperSidebar from '@/components/ui/VerticalStepperSidebar/useVerticalStepperSidebar';
import { useAccountInfo, useBillingInfo } from '@/hooks';
import useCheckHQCompanyOnboardingStatus from '@/modules/dashboard/components/checkhq/useCheckHQCompanyOnboardingStatus';
import useCheckHQEmployeeOnboardingStatus from '@/modules/dashboard/components/checkhq/useCheckHQEmployeeOnboardingStatus';
import * as Routes from '@/routes/routes';
import { CONTENT_BLOCKS } from '@/utils/constants';
import {
  checkAccountForAccountSetup,
  checkAccountForSubscriptionReminder,
  extractEventName,
} from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { ONBOARDING_STEPPER_IDS, ONBOARDING_STRUCTURE } from './constants';

const { completed, current, pending } = VERTICAL_STEPPER_SIDEBAR_STATUS;

const {
  company,
  employees,
  payrollSettings,
  taxes,
  finishOnboarding,
  bankAccount,
  filingAuthorization,
} = ONBOARDING_STEPPER_IDS;

const { contractor } = ONBOARDING_STRUCTURE;

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

const getStepStatus = ({
  isStepCompleted,
  step,
  currentStep,
}: {
  isStepCompleted: boolean;
  step: string;
  currentStep: string;
}) => {
  return isStepCompleted ? completed : currentStep === step ? current : pending;
};

const useAccountSetupLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useCalendlyEventListener({
    onEventTypeViewed: () => {
      trackEvent('buttonClick', {
        name: 'Book a call',
        currentPage: extractEventName(window.location.pathname),
      });
    },
    onEventScheduled: () => {
      trackEvent('buttonClick', {
        name: 'Calendly: Call Scheduled',
        currentPage: extractEventName(window.location.pathname),
      });
    },
  });

  const { isLoading: isLoadingAuth0 } = useAuth0();
  const {
    account,
    isAccountLoading,
    isAuthenticatedAsImpersonate,
    company: companyInfo,
    isCheckHqUser,
  } = useAccountInfo();

  const { billingInfo, isBillingInfoLoading, hasSubscriptions } = useBillingInfo();
  const { isSomeTeamMemberIncomplete } = useCheckHQEmployeeOnboardingStatus();

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  const bannerInfo = checkAccountForSubscriptionReminder({
    subscriptionInfo: billingInfo,
    account,
  });

  const location = useLocation();
  const currentPath = location.pathname;

  const {
    isCompanyStepCompleted,
    isScheduleStepCompleted,
    isTaxesStepCompleted,
    isEmployeesStepCompleted,
    isContractorStepCompleted,
    isFinishOnboardingStepCompleted,
    onBoardingStructure,
    shouldShowAccountSetup,
    isAccountSetupCompleted,
    isTeamMemberStepCompleted,
    isBankAccountStepCompleted,
    isFilingAuthorizationStepCompleted,
  } = checkAccountForAccountSetup({
    account,
  });

  const { isCheckHQAccountUnderReview } = useCheckHQCompanyOnboardingStatus();

  useEffect(() => {
    if (!hasSubscriptions) {
      navigate(Routes.SIGNUP_STEPPER_BILLING);
    } else if (!isFinishOnboardingStepCompleted) {
      navigate(Routes.ACCOUNT_SETUP_COMPANY);
    } else if (!shouldShowAccountSetup || (shouldShowAccountSetup && isAccountSetupCompleted)) {
      navigate(Routes.DASHBOARD);
    }
  }, [
    hasSubscriptions,
    isAccountSetupCompleted,
    isFinishOnboardingStepCompleted,
    shouldShowAccountSetup,
  ]);

  const currentStep = useMemo(() => {
    if (!isCompanyStepCompleted) return company;
    const isOnlyContractorPlan = onBoardingStructure === contractor;

    const unfinishedSteps = [
      !isOnlyContractorPlan && !isScheduleStepCompleted && payrollSettings,
      !((isEmployeesStepCompleted && isContractorStepCompleted) || isTeamMemberStepCompleted) &&
        employees,
      (!isOnlyContractorPlan || isCheckHqUser) && !isTaxesStepCompleted && taxes,
      (!isOnlyContractorPlan || isCheckHqUser) && !isBankAccountStepCompleted && bankAccount,
      (!isOnlyContractorPlan || isCheckHqUser) &&
        !isFilingAuthorizationStepCompleted &&
        filingAuthorization,
    ].filter(Boolean);

    return unfinishedSteps.length > 0 ? unfinishedSteps[0] : finishOnboarding;
  }, [
    isCheckHqUser,
    isCompanyStepCompleted,
    isScheduleStepCompleted,
    isTaxesStepCompleted,
    isEmployeesStepCompleted,
    isContractorStepCompleted,
    isTeamMemberStepCompleted,
    isBankAccountStepCompleted,
    isFilingAuthorizationStepCompleted,
    onBoardingStructure,
    isLoading,
    hasSubscriptions,
  ]);

  const steps = useMemo(
    () => [
      {
        key: company,
        title: 'Company',
        icon: BuildingOfficeIcon,
        status: isCompanyStepCompleted ? completed : current,
        isHidden: false,
        contentBlock: CONTENT_BLOCKS.accountSetupCompany,
      },
      {
        key: payrollSettings,
        title: 'Payroll Settings',
        icon: CalendarDaysIcon,
        status: getStepStatus({
          isStepCompleted: isScheduleStepCompleted,
          step: payrollSettings,
          currentStep,
        }),
        isHidden: !isCheckHqUser ? onBoardingStructure === contractor : false,
        contentBlock: CONTENT_BLOCKS.accountSetupPayrollSchedule,
      },
      {
        key: employees,
        title: 'Employees',
        icon: UserGroupIcon,
        status: getStepStatus({
          isStepCompleted:
            (isEmployeesStepCompleted && isContractorStepCompleted) || isTeamMemberStepCompleted,
          step: employees,
          currentStep,
        }),
        isHidden: false,
        contentBlock: CONTENT_BLOCKS.accountSetupEmployees,
        additionalKeysForRoute: [contractor],
        showInProgress: isSomeTeamMemberIncomplete,
      },
      {
        key: taxes,
        title: 'Taxes',
        icon: DocumentTextIcon,
        status: getStepStatus({ isStepCompleted: isTaxesStepCompleted, step: taxes, currentStep }),
        isHidden: onBoardingStructure === contractor && !isCheckHqUser,
        contentBlock: CONTENT_BLOCKS.accountSetupTaxes,
      },
      {
        key: bankAccount,
        title: 'Bank account',
        icon: IconBuildingBank,
        status: getStepStatus({
          isStepCompleted: isBankAccountStepCompleted,
          step: bankAccount,
          currentStep,
        }),
        isHidden: !isCheckHqUser,
      },
      {
        key: filingAuthorization,
        title: 'Filing authorization',
        icon: IconShieldLock,
        status: getStepStatus({
          isStepCompleted: isFilingAuthorizationStepCompleted,
          step: filingAuthorization,
          currentStep,
        }),
        isHidden: !isCheckHqUser,
      },
      {
        key: finishOnboarding,
        title: 'Finish Setup',
        icon: DocumentCheckIcon,
        status: getStepStatus({
          isStepCompleted: isFinishOnboardingStepCompleted,
          step: finishOnboarding,
          currentStep,
        }),
      },
    ],
    [
      onBoardingStructure,
      isCompanyStepCompleted,
      isScheduleStepCompleted,
      isTaxesStepCompleted,
      isEmployeesStepCompleted,
      isContractorStepCompleted,
      isFinishOnboardingStepCompleted,
      currentStep,
      isTeamMemberStepCompleted,
      isSomeTeamMemberIncomplete,
    ],
  );

  const activeStepIdentifier = steps.find(step => currentPath?.includes(step.key))?.contentBlock;
  const activeStep = steps.find(step => currentPath?.includes(step.key))?.key;

  const handleClick = (item: VerticalStepperSidebarStepType) => {
    if (item.status !== pending) {
      navigate(`/account-setup/${item.key}`);
    }
  };

  const verticalStepper = useVerticalStepperSidebar({ steps, defaultStep: company, handleClick });

  const isTeamMemberRouts = pathname === Routes.ACCOUNT_SETUP_EMPLOYEE;
  const shouldShowWithoutCard = [
    Routes.ACCOUNT_SETUP_CURRENT_EMPLOYEE.replace(':employeeId', ''),
    Routes.ACCOUNT_SETUP_CURRENT_CONTRACTOR.replace(':contractorId', ''),
    Routes.ACCOUNT_SETUP_PAYROLL_SETTINGS,
  ].some(teamMemberPath => pathname.includes(teamMemberPath));

  const { bannerVariantToShow } = bannerInfo || {};

  const isAdditionHeaderBanner =
    isAppModeDemo ||
    bannerInfo?.bannerVariantToShow ||
    isAuthenticatedAsImpersonate ||
    isCheckHQAccountUnderReview;

  const shouldShowCustomerSupportBox = activeStep !== finishOnboarding;

  const { employeeId, contractorId } = useParams();
  const currentEmployeeRoute = Routes.ACCOUNT_SETUP_CURRENT_EMPLOYEE.replace(
    ':employeeId',
    employeeId || '',
  );
  const currentContractorRoute = Routes.ACCOUNT_SETUP_CURRENT_CONTRACTOR.replace(
    ':contractorId',
    contractorId || '',
  );

  // Check if the current route matches the modified route
  const isEditEmployeeRoute =
    location.pathname === currentEmployeeRoute || location.pathname === currentContractorRoute;

  return {
    verticalStepper,
    activeStepIdentifier,
    isTeamMemberRouts,
    shouldShowWithoutCard,
    bannerVariantToShow,
    isAdditionHeaderBanner,
    isLoading,
    shouldShowCustomerSupportBox,
    isEditEmployeeRoute,
  };
};

export default useAccountSetupLayout;
