import { useEffect, useMemo, useState } from 'react';

import useGetPayrollSchedule from '@/api/payrollSchedule/useGetPayrollSchedule';
import { useAccountInfo } from '@/hooks';
import { EMPLOYEE_PAYROLL_TYPES } from '@/utils/constants';
import { isArrayHasData } from '@/utils/helpers';

import {
  useGetPayrollCheck,
  useGetPayrollHistory,
  useGetRunnableActiveEmployee,
  useGetRunnablePayroll,
} from './apis';
import { RunPayrollTodoItems, SelectRegularPayrollForm } from './components';
import { EmployeePayrollHistory } from './components/EmployeePayrollHistory';
import { EMPLOYEE_MANUAL_PAY_HISTORY_STATUS } from './components/EmployeePayrollHistory/utils/constants';

const useRunPayroll = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [step, setStep] = useState(1);
  const [isOpenFillMissedTax, setisOpenFillMissedTax] = useState<boolean>(false);
  const [isDoneActions, setIsDoneActions] = useState<boolean>(false);
  const [isTestPayrollVisible, setIsTestPayrollVisible] = useState(false);
  const [isViewTestPayrollVisible, setIsViewTestPayrollVisible] = useState(false);
  const [isPayrollScheduleOpen, setIsPayrollScheduleOpen] = useState(false);

  const { companyId, company, refetchAccountInfo } = useAccountInfo();

  const { data: scheduleData } = useGetPayrollSchedule(companyId);

  const {
    data: payrollWarnings,
    refetch: refetchWarning,
    isLoading: isLoadingPayrollCheck,
    isFetching: isFetchingPayrollCheck,
  } = useGetPayrollCheck({
    companyId,
  });

  const runPayrollWarnings = useMemo(
    () => (Array.isArray(payrollWarnings?.data) ? payrollWarnings.data : []),
    [payrollWarnings],
  );

  const hasPayrollSchedule = useMemo(() => {
    const hasSchedule = !!runPayrollWarnings.filter(el => el.action === 'add_payroll_schedule')[0]
      ?.isDone;

    return hasSchedule;
  }, [runPayrollWarnings]);

  const {
    data: payrollsData,
    refetch: refetchPayrolls,
    isLoading: isLoadingPayrolls,
    isFetching: isFetchingPayrolls,
  } = useGetRunnablePayroll({
    companyId,
    rqParams: {
      enabled: hasPayrollSchedule,
    },
  });

  const { data: runnableEmployeesData, isLoading: isLoadingRunnableActiveEmployees } =
    useGetRunnableActiveEmployee({ companyId });

  // TO DO: Need to fetch when schedule is done in contractor only company.
  // useEffect(() => {
  //   if (!runnableEmployeesData && !isPayrollScheduleOpen) {
  //     refetchWarning();
  //   }
  // }, [runnableEmployeesData, isPayrollScheduleOpen]);

  const { data: payrollHistory } = useGetPayrollHistory({ companyId });

  const runnableEmployees = useMemo(
    () => (Array.isArray(runnableEmployeesData?.data) ? runnableEmployeesData.data : []),
    [runnableEmployeesData],
  );

  const payrolls = useMemo(
    () => (Array.isArray(payrollsData?.data) ? payrollsData.data : []),
    [payrollsData],
  );

  useEffect(() => {
    setIsDoneActions(
      Array.isArray(runPayrollWarnings) ? runPayrollWarnings.every(item => item?.isDone) : true,
    );
  }, [runPayrollWarnings]);

  const manualPayrollHistoriesStatus = company?.manualPayrollHistoriesStatus;

  useEffect(() => {
    refetchAccountInfo();
  }, []);

  useEffect(() => {
    if (payrollHistory) {
      const isTestPayrollVisible =
        !isArrayHasData(payrollHistory) ||
        !payrollHistory.some(data => data?.type === EMPLOYEE_PAYROLL_TYPES.regular);
      const isTestPayroll = payrollHistory.some(data => data?.type === 'test');
      setIsTestPayrollVisible(isTestPayrollVisible);
      setIsViewTestPayrollVisible(isTestPayroll);
    }
  }, [payrollHistory]);

  const shouldShowSetupPayHistoryCard = useMemo(() => {
    const { pending, in_progress, pending_approval } = EMPLOYEE_MANUAL_PAY_HISTORY_STATUS;
    return (
      !!manualPayrollHistoriesStatus &&
      [pending, in_progress, pending_approval].includes(manualPayrollHistoriesStatus)
    );
  }, [manualPayrollHistoriesStatus]);

  const refetchRunPayrollData = () => {
    refetchWarning();
    if (hasPayrollSchedule) {
      refetchPayrolls();
    }
  };

  const renderContent = () => {
    if (shouldShowSetupPayHistoryCard && isDoneActions) {
      return (
        <>
          <EmployeePayrollHistory />
          <SelectRegularPayrollForm
            payrolls={payrolls}
            warning={runPayrollWarnings}
            getPayrollsList={refetchRunPayrollData}
            runnableEmployees={runnableEmployees}
            isDraftButton={shouldShowSetupPayHistoryCard}
          />
        </>
      );
    } else {
      if (!isDoneActions) {
        return (
          <RunPayrollTodoItems
            setIsPayrollScheduleOpen={setIsPayrollScheduleOpen}
            items={runPayrollWarnings}
            companyId={companyId}
            onToggleTaxModal={() => setisOpenFillMissedTax(isOpen => !isOpen)}
          />
        );
      } else {
        return (
          <div className="flex w-full">
            <SelectRegularPayrollForm
              payrolls={payrolls}
              warning={runPayrollWarnings}
              getPayrollsList={refetchRunPayrollData}
              runnableEmployees={runnableEmployees}
            />
          </div>
        );
      }
    }
  };

  const handlePayrollSchedule = () => {
    setStep(2);
    setIsPayrollScheduleOpen(true);
    setIsEdit(true);
  };

  const isLoading =
    isLoadingPayrolls ||
    isLoadingRunnableActiveEmployees ||
    isLoadingPayrollCheck ||
    isFetchingPayrolls;

  return {
    isPayrollScheduleOpen,
    setIsPayrollScheduleOpen,
    isDoneActions,
    renderContent,
    isLoading,
    isOpenFillMissedTax,
    setisOpenFillMissedTax,
    refetchWarning,
    manualPayrollHistoriesStatus,
    scheduleData,
    handlePayrollSchedule,
    isEdit,
    setIsEdit,
    setStep,
    step,
    isTestPayrollVisible,
    isViewTestPayrollVisible,
  };
};

export default useRunPayroll;
