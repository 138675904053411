import moment from 'moment';

import { useGetAppSettings } from '@/api';

type Holiday = {
  holiday: string;
  date: string;
};

type DisabledDate = {
  startDate: Date;
  endDate: Date;
};

export function useGetBankHolidays(): DisabledDate[] {
  const { data: appSettingResponse } = useGetAppSettings();

  const holidays = appSettingResponse?.data?.bankHolidays;

  let disabledDates: DisabledDate[] = [];
  for (const year in holidays) {
    if (holidays.hasOwnProperty(year)) {
      const yearDisabledDates = holidays[year].map((holiday: Holiday) => {
        const validDate = moment(holiday.date, 'DD/MM/YYYY').toDate();

        return {
          startDate: new Date(validDate),
          endDate: new Date(validDate),
        };
      });

      disabledDates = disabledDates.concat(yearDisabledDates);
    }
  }
  return disabledDates;
}
