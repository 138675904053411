import { deleteCall } from '@/api/axios';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { DELETE_GARNISHMENT } from './employeeApiRoutes';

interface UseDeleteBenefitArgs {
  companyId: number;
  employeeId: number | null;
  onSuccess?: () => void;
}

const deleteGarnishment = (companyId: number, employeeId: number | null, garnishmentId: number) =>
  deleteCall(DELETE_GARNISHMENT({ companyId, employeeId, id: garnishmentId }));

const useDeleteGarnishment = ({
  companyId,
  employeeId,
  onSuccess,
}: UseDeleteBenefitArgs): UseMutationResult<AxiosResponse<any, any>, Error, number, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (garnishmentId: number) => deleteGarnishment(companyId, employeeId, garnishmentId),
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries({ queryKey: ['getGarnishments'] });
    },
  });
};

export default useDeleteGarnishment;
