import { Controller, RegisterOptions } from 'react-hook-form';

import InputDatePicker, { InputDatePickerProps } from '../InputDatePicker/InputDatePicker';

export interface FormInputDatePickerProps extends Omit<InputDatePickerProps, 'value' | 'onChange'> {
  /**
   * The name of the input field.
   */
  name: string;
  isDisabled?: boolean;
  shouldUnregister?: boolean;
  /**
   * Additional validation rules for the field.
   */
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

const FormInputDatePicker = ({
  name,
  isDisabled,
  rules,
  shouldUnregister = true,
  isSingle,
  ...rest
}: FormInputDatePickerProps) => {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => (
        <InputDatePicker
          {...rest}
          errorMessage={error && error.message}
          isDisabled={Boolean(isDisabled)}
          isSingle={isSingle}
          value={isSingle ? { startDate: field.value, endDate: field.value } : field.value}
          onChange={date => {
            const newValue = isSingle ? date.startDate : date;
            field.onChange(newValue);
          }}
        />
      )}
      rules={rules}
    />
  );
};

export default FormInputDatePicker;
