import { ReactComponent as ArrowIcon } from '@/assets/arrowSmooth.svg';
import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { LoaderComponent } from '@/components/ui';
import { ConfirmationModal } from '@/modules/payrollSchedule/components';

import EmployerTaxItem from '../EmployerTaxItem/EmployerTaxItem';
import { EmployerTaxItemModalProps } from './types';
import useEmployerTaxItemModal from './useEmployerTaxItemModal';

const EmployerTaxItemModal = ({
  taxItemId,
  isOpen,
  onCancel,
  shouldShowAllFields,
  onSubmit,
}: EmployerTaxItemModalProps) => {
  const {
    currentTaxItem,
    isFetchingEmployerTaxData,
    isFederalTaxItem,
    handleIsDirty,
    handleDrawerClose,
    isDirtyModalOpen,
    handleConfirmDirtyModal,
    handleCloseDirtyModal,
  } = useEmployerTaxItemModal({
    taxItemId,
    onCancel,
  });

  return (
    <>
      {isDirtyModalOpen && (
        <ConfirmationModal
          isOpen={isDirtyModalOpen}
          handleConfirm={handleConfirmDirtyModal}
          handleClose={handleCloseDirtyModal}
        />
      )}
      {isOpen && (
        <DialogComponent
          open={isOpen}
          onCancel={handleDrawerClose}
          width="w-screen sm:w-[610px]"
          height="h-screen sm:max-h-[90vh] sm:h-auto"
          modalClasses="sm:rounded-xl pb-5"
        >
          <div className="flex h-[40px] items-center justify-between border-b border-gray-100 py-[10px]">
            <div className="pl-[16px]">
              <Button variant="icon" icon={ArrowIcon} children="Back" onPress={handleDrawerClose} />
            </div>
            <div className="pr-[16px]">
              <Button variant="icon" icon={CloseIcon} onPress={handleDrawerClose} />
            </div>
          </div>
          <div className="flex flex-col items-center px-5 py-10">
            <div className="w-full max-w-[400px]">
              {isFetchingEmployerTaxData ? (
                <LoaderComponent />
              ) : (
                !!currentTaxItem && (
                  <EmployerTaxItem
                    shouldShowAllFields={shouldShowAllFields}
                    onSubmit={onSubmit}
                    currentTaxItem={currentTaxItem}
                    isFederalTaxItem={isFederalTaxItem}
                    isLastStep={true}
                    handleIsDirty={handleIsDirty}
                    handleBackBtnClick={() => onCancel()}
                  />
                )
              )}
            </div>
          </div>
        </DialogComponent>
      )}
    </>
  );
};

export default EmployerTaxItemModal;
