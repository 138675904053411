import { useState } from 'react';

type employeeInfo = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  employeeContribution: string;
  companyContribution: string;
};

export type EmployeeDeductionCardProps = {
  employeeData: employeeInfo;
  deductionData: Object;
  isShowingEdit?: boolean;
};

const useEmployeeDeductionCard = () => {
  const [isDeductionDrawerOpen, setIsDeductionDrawerOpen] = useState<boolean>(false);
  const handleDeductionDrawer = () => setIsDeductionDrawerOpen(!isDeductionDrawerOpen);
  return { handleDeductionDrawer, isDeductionDrawerOpen };
};
export default useEmployeeDeductionCard;
