import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { forwardRef, MutableRefObject, useImperativeHandle, useRef } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DeductionClickType } from '@/modules/employee/forms';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';
import * as routes from '@/routes/routes';
import { DEDUCTION_TYPES, DEDUCTION_TYPES_NAME } from '@/utils/constants';

import CheckHQWrapper from '../../CheckHQWrapper/CheckHQWrapper';
import ContactSupportButton from '../../ContactSupportButton';
import RadioButtonsCardGroup from '../../RadioButtonsCardGroup';
import BenefitFields from '../components/BenefitFields';
import CustomPostTaxDeductionFields from '../components/CustomPostTaxDeductionFields';
import GarnishmentFields from '../components/GarnishmentFields';
import { EmployeeDeductionTable } from './components';
import EmployeeDeductionCard from './components/EmployeeDeductionCard/EmployeeDeductionCard';
import { useDeductionForm } from './useDeductionForm';

type DeductionFormProps = {
  onSubmit: () => void;
  data?: any;
  handleIsDirty: (value: boolean) => void;
  isAssigned: boolean;
  isEdit: boolean;
  isInsideApp: boolean;
  isCompanyLevel?: boolean;
  isCheckHqUser?: boolean;
  handleDeductionClick: (data: DeductionClickType) => void;
};

type AssignedDeductionDetailsProps = {
  deductionTypeName?: string;
  subTypeName: string;
  subTypeTitle?: string | number | null;
  deductionSubType?: string;
  hasRunPayroll: boolean;
  form: UseFormReturn<any>;
};

export type DeductionFormHandle = {
  validateForm: () => Promise<boolean>;
  submitForm: () => void;
};

const PayrollWarning = () => (
  <div className="mb-6 flex flex-row items-start rounded-lg bg-yellow-100 p-4">
    <ExclamationCircleIcon className="w-7 text-yellow-500" />
    <p className="pl-2 text-sm text-gray-500">
      Deduction details cannot be edited since it has been used to calculate payroll. Need to make
      changes? <ContactSupportButton supportType="bot" />
    </p>
  </div>
);

const AssignedDeductionDetails = ({
  deductionTypeName,
  subTypeName,
  subTypeTitle,
  deductionSubType,
  hasRunPayroll,
  form,
}: AssignedDeductionDetailsProps) => (
  <div>
    <div className="flex justify-between border-b py-3">
      <h4 className="text-gray-400">Deduction Type</h4>
      <p>{deductionTypeName}s</p>
    </div>
    {subTypeName !== 'custom' && (
      <div className="flex justify-between border-b py-3">
        <h4 className="text-gray-400">{deductionTypeName} Type</h4>
        <p>{deductionSubType}</p>
      </div>
    )}
    {hasRunPayroll && (
      <div className="flex justify-between border-b py-3">
        <h4 className="text-gray-400">{deductionTypeName} Title</h4>
        <p>{subTypeTitle}</p>
      </div>
    )}

    <CheckHQWrapper>
      {hasRunPayroll && deductionTypeName === DEDUCTION_TYPES.Benefit && (
        <div className="flex justify-between border-b py-3">
          <h4 className="text-gray-400">Effective start date</h4>
          <p>{moment(form.getValues('effectiveStart')).format('ddd, MMM DD')}</p>
        </div>
      )}
      {hasRunPayroll && deductionTypeName === DEDUCTION_TYPES.Benefit && (
        <div className="flex justify-between border-b py-3">
          <h4 className="text-gray-400">Effective end date</h4>
          <p>
            {form.getValues('effectiveEnd')
              ? moment(form.getValues('effectiveEnd')).format('ddd, MMM DD')
              : '-'}
          </p>
        </div>
      )}
    </CheckHQWrapper>
  </div>
);

const UnassignedDeductionForm = ({ deductionFormData }: { deductionFormData: any }) => (
  <div className="gap-medium flex flex-col">
    <CheckHQWrapper hideItem>
      <div className="flex flex-col gap-4">
        <div className="w-full">
          <Controller
            name="type"
            control={deductionFormData.form.control}
            render={({ field: { value, onChange } }) => (
              <RadioButtonsCardGroup
                options={deductionFormData.deductionOptions.map(
                  ({ name, title, ...rest }: any) => ({
                    label: title,
                    value: name,
                    ...rest,
                  }),
                )}
                onChange={onChange}
                defaultValue={value}
              />
            )}
          />
        </div>
      </div>
    </CheckHQWrapper>
    <CheckHQWrapper>
      {!deductionFormData.isCompanyLevel && !deductionFormData.isEdit && (
        <div className="gap-medium flex flex-col">
          <span className="text-medium font-F37Bolton-Medium">
            What type of deduction are you adding?
          </span>
          <div className="w-full">
            <Controller
              name="type"
              control={deductionFormData.form.control}
              render={({ field: { value, onChange } }) => (
                <RadioButtonsCardGroup
                  options={deductionFormData.deductionOptions.map(
                    ({ name, title, ...rest }: any) => ({
                      label: title,
                      value: name,
                      ...rest,
                    }),
                  )}
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
          </div>
        </div>
      )}
    </CheckHQWrapper>

    {deductionFormData.shouldShowDeductionTypeField && !deductionFormData.hasRunPayroll && (
      <>
        <DeductionTypeField deductionFormData={deductionFormData} />
      </>
    )}
  </div>
);

const DeductionTypeField = ({ deductionFormData }: { deductionFormData: any }) => {
  return (
    <>
      {deductionFormData.isBenefitDeductionSelected && (
        <BenefitFields
          options={deductionFormData.deductionTypeOptions}
          isAssigned={false}
          form={deductionFormData.form}
          isEdit={deductionFormData.isEdit}
          isCheckHqUser={deductionFormData.isCheckHqUser}
        />
      )}
      {deductionFormData.isGarnishmentDeductionSelected && (
        <GarnishmentFields options={deductionFormData.deductionTypeOptions} isAssigned={false} />
      )}
      {deductionFormData.isCustomPostTaxDeductionsSelected && <CustomPostTaxDeductionFields />}
    </>
  );
};

const EmployeesSection = ({ deductionFormData }: { deductionFormData: any }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-8 flex flex-col gap-4">
      <h4 className="font-F37Bolton-Medium text-xl">
        {deductionFormData.isAssigned ? 'Employees assigned' : 'Employees'}
      </h4>
      {!deductionFormData.isAssigned ? (
        <span className="flex">
          No employees assigned. Go to&nbsp;
          <span
            className="cursor-pointer underline"
            onClick={() => {
              if (deductionFormData.isInsideApp) {
                navigate(routes.TEAM_MEMBERS);
              } else {
                navigate(routes.ACCOUNT_SETUP_EMPLOYEE);
              }
            }}
          >
            employees page
          </span>
          &nbsp; to assign people to this deduction.
        </span>
      ) : (
        <>
          <div className="hidden sm:block">
            <EmployeeDeductionTable
              employeesData={deductionFormData.employeesData}
              deductionInfo={deductionFormData.deductionInfo}
              refetchDeductionList={deductionFormData.refetchDeductionList}
            />
          </div>
          <div className="flex flex-col gap-4 sm:hidden">
            {deductionFormData.employeesData.map((employee: any, idx: number) => (
              <EmployeeDeductionCard
                key={idx}
                employeeData={employee}
                deductionData={deductionFormData.deductionInfo || {}}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export const CheckHQDeductionForm = forwardRef<DeductionFormHandle, DeductionFormProps>(
  (
    {
      onSubmit,
      data,
      handleIsDirty,
      isEdit,
      isAssigned,
      isInsideApp,
      isCompanyLevel,
      isCheckHqUser,
      handleDeductionClick,
    },
    ref,
  ) => {
    const deductionFormData = useDeductionForm({
      data,
      handleIsDirty,
      onSubmit,
      isCompanyLevel,
    });

    // Internal ref for the form element
    const formRef = useRef<HTMLFormElement | null>(null);

    useImperativeHandle(ref, () => {
      const form = deductionFormData.form;
      const type = form.watch('type');

      const validateForm = async () => {
        if (isCheckHqUser && form.getValues('type') === DEDUCTION_TYPES_NAME.garnishment) {
          form.setValue('garnishmentTitle', 'Child Support');
          form.setValue('garnishmentType', 1);
        }
        return form.trigger();
      };

      const getDeductionName = () => {
        if (type === DEDUCTION_TYPES_NAME.garnishment) {
          return form.watch('garnishmentTitle') || 'Child Support';
        }

        if (type === DEDUCTION_TYPES_NAME.custom) {
          return form.watch('customPostTaxDeductionTitle') || 'Custom Post-tax Deduction';
        }

        return form.watch('benefitTitle') || 'Benefit';
      };

      const submitForm = async () => {
        if (type !== DEDUCTION_TYPES_NAME.benefit) {
          await handleDeductionClick({
            data: { ...form.watch(), name: getDeductionName() },
            isAssigned: false,
          });
        }

        form.handleSubmit(onSubmit)();
      };

      return { validateForm, submitForm };
    });

    return (
      <div>
        <FormProvider {...deductionFormData.form}>
          <form
            className="mt-6 flex flex-col"
            ref={formRef}
            onSubmit={deductionFormData.form.handleSubmit(onSubmit)}
          >
            {deductionFormData.hasRunPayroll && <PayrollWarning />}
            {isAssigned ? (
              <AssignedDeductionDetails
                deductionTypeName={deductionFormData.deductionTypeName}
                subTypeName={deductionFormData.subTypeName}
                subTypeTitle={deductionFormData.subTypeTitle}
                deductionSubType={deductionFormData.deductionSubType}
                hasRunPayroll={deductionFormData.hasRunPayroll}
                form={deductionFormData.form}
              />
            ) : (
              <UnassignedDeductionForm deductionFormData={{ ...deductionFormData, isEdit }} />
            )}
          </form>
        </FormProvider>
        {isEdit &&
          deductionFormData.deductionTypeName === DEDUCTION_TYPES_NAME.benefit && ( // show this section for checkhq user if type is benefit
            <EmployeesSection deductionFormData={{ ...deductionFormData, isInsideApp }} />
          )}
      </div>
    );
  },
);

type BlinkDeductionFormType = {
  onSubmit: () => void;
  data?: DeductionTypes;
  formRef: MutableRefObject<null>;
  handleIsDirty: (value: boolean) => void;
  isAssigned: boolean;
  isEdit: boolean;
  isInsideApp: boolean;
  isCompanyLevel?: boolean;
};

export const BlinkDeductionForm = ({
  formRef,
  onSubmit,
  data,
  handleIsDirty,
  isEdit,
  isAssigned,
  isCompanyLevel,
  isInsideApp,
}: BlinkDeductionFormType) => {
  const deductionFormData = useDeductionForm({
    data,
    handleIsDirty,
    onSubmit,
    isCompanyLevel,
  });

  return (
    <div>
      <FormProvider {...deductionFormData.form}>
        <form
          className="mt-6 flex flex-col"
          ref={formRef}
          onSubmit={deductionFormData.handleSubmit}
        >
          {deductionFormData.hasRunPayroll && <PayrollWarning />}
          {isAssigned ? (
            <AssignedDeductionDetails
              deductionTypeName={deductionFormData.deductionTypeName}
              subTypeName={deductionFormData.subTypeName}
              subTypeTitle={deductionFormData.subTypeTitle}
              deductionSubType={deductionFormData.deductionSubType}
              hasRunPayroll={deductionFormData.hasRunPayroll}
              form={deductionFormData.form}
            />
          ) : (
            <UnassignedDeductionForm deductionFormData={{ ...deductionFormData, isEdit }} />
          )}
        </form>
      </FormProvider>
      {isEdit && <EmployeesSection deductionFormData={{ ...deductionFormData, isInsideApp }} />}
    </div>
  );
};

CheckHQDeductionForm.displayName = 'CheckHQDeductionForm';
