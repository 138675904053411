import moment from 'moment';
import { useFormContext } from 'react-hook-form';

import { useAccountInfo } from '@/hooks';
import { BENEFITS_TO_ID_MAP } from '@/utils/constants';

type UseEffectivePayPeriodProps = {
  companyEffectiveStart?: string;
};

const useEffectivePayPeriod = ({ companyEffectiveStart }: UseEffectivePayPeriodProps) => {
  const { isCheckHqUser } = useAccountInfo();
  const form = useFormContext();
  const {
    getValues,
    formState: { errors },
  } = form;

  const type = getValues('type');
  const benefitType = getValues('benefitType');
  const isHSAContributionVisible = benefitType === BENEFITS_TO_ID_MAP.HSA;
  const companyBenefitStartDate = moment(companyEffectiveStart).startOf('day').toDate();

  return {
    form,
    errors,
    isHSAContributionVisible,
    type,
    isCheckHqUser,
    companyBenefitStartDate,
  };
};

export default useEffectivePayPeriod;
