import { MutationOptions, useMutation } from '@tanstack/react-query';

import { postCall } from '@/api/axios';

import { mutationEndpoints } from './endpoints';

const useSkipRunnablePayroll = ({
  companyId,
  mutationParams,
}: {
  companyId: number;
  mutationParams?: MutationOptions;
}) => {
  const { mutationKey, url, invalidateKeys } = mutationEndpoints.skipRunnablePayroll(companyId);

  return useMutation({
    mutationKey,
    mutationFn: data => postCall(url, data),
    ...mutationParams,
  });
};

export default useSkipRunnablePayroll;
