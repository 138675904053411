import { useMemo, useState } from 'react';

import { useAccountInfo } from '@/hooks';

import { PayrollHistory } from './types';

export const payrollHistoryStatus = {
  0: { label: 'Pending', variant: '' },
  1: { label: 'Paid', variant: 'completed' },
  3: { label: 'Skipped', variant: 'default' },
};

export const checkHqPayrollHistoryStatus = {
  0: { label: 'Draft', variant: 'draft' },
  1: { label: 'Approved', variant: 'completed' },
  2: { label: 'Cancelled', variant: 'default' },
  3: { label: 'Skipped', variant: 'default' },
  4: { label: 'Pending', variant: 'inProgress' },
  5: { label: 'Processing', variant: 'completed' },
  6: { label: 'Failed', variant: 'danger' },
  7: { label: 'Partially paid', variant: 'default' },
};

export interface PayrollHistoryProps {
  historyData: PayrollHistory[];
}

export type HistoryStatus = typeof payrollHistoryStatus | typeof checkHqPayrollHistoryStatus;

const usePayrollHistory = ({ historyData }: PayrollHistoryProps) => {
  const { isCheckHqUser } = useAccountInfo();
  const [itemsToShow, setItemsToShow] = useState(10);

  const payrollHistoryDataToShow = historyData?.filter(payroll => payroll.type !== 'test');

  const updatedData = payrollHistoryDataToShow?.slice(0, itemsToShow);

  const loadMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  const historyStatus: HistoryStatus = useMemo(
    () => (!isCheckHqUser ? payrollHistoryStatus : checkHqPayrollHistoryStatus),
    [isCheckHqUser],
  );

  const shouldShowViewMoreButton =
    payrollHistoryDataToShow.length > 10 && updatedData.length !== payrollHistoryDataToShow.length;

  return {
    loadMore,
    updatedData,
    historyStatus,
    shouldShowViewMoreButton,
    isCheckHqUser,
  };
};

export default usePayrollHistory;
