import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import GarnishmentIcon from '@/assets/icons/GarnishmentIcon';
import MoneyBag from '@/assets/icons/MoneyBag';
import Umbrella from '@/assets/icons/Umbrella';
import { useAccountInfo } from '@/hooks';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';
import { CHECKHQ_BENEFITS_TO_ID_MAP, CHECKHQ_BENEFIT_TYPES } from '@/utils/checkHQConstants';
import {
  BENEFITS,
  BENEFITS_TO_ID_MAP,
  DEDUCTION_TYPES,
  DEDUCTION_TYPES_NAME,
  GARNISHMENT_TO_ID_MAP,
} from '@/utils/constants';
import { getKeyByValue } from '@/utils/getObjectKey';
import { checkAccountForAccountSetup } from '@/utils/helpers';

import useGetCompanyLevelDeductions from '../../CompanyLevelDeductions/apis/companyLevelDeduction/useGetCompanyLevelDeductions';
import { DeductionFormSchema } from './validation';

const deductionTypeConstant = {
  benefits: 'Benefit',
  garnishments: 'Garnishment',
  customPostTaxDeductions: 'Custom Post-tax Deduction',
};

type UseDeductionType = {
  data?: DeductionTypes;
  handleIsDirty: (value: boolean) => void;
  onSubmit: () => void;
  isCompanyLevel?: boolean;
};

export const useDeductionForm = ({
  data,
  handleIsDirty,
  onSubmit,
  isCompanyLevel,
}: UseDeductionType) => {
  const navigate = useNavigate();
  const { companyId, account, isCheckHqUser } = useAccountInfo();
  const [deductionInfo, setDeductionInfo] = useState(data);

  const {
    benefitType,
    garnishmentType,
    type,
    name,
    canBeEditedOrDeleted,
    effectiveStart,
    effectiveEnd,
  } = deductionInfo || {};

  const keyName =
    (isCompanyLevel && isCheckHqUser) || type === DEDUCTION_TYPES_NAME.benefit
      ? 'benefitTitle'
      : type === DEDUCTION_TYPES_NAME.garnishment
      ? 'garnishmentTitle'
      : 'customPostTaxDeductionTitle';

  const form = useForm({
    resolver: yupResolver(DeductionFormSchema(isCheckHqUser)),
    defaultValues: {
      benefitType: benefitType || null,
      garnishmentType: garnishmentType || null,
      type: isCheckHqUser && isCompanyLevel ? DEDUCTION_TYPES.Benefit : type,
      [keyName]: name,
      effectiveStart: effectiveStart || null,
      effectiveEnd: effectiveEnd || null,
    },
    mode: 'onSubmit',
  });

  const { isDirty } = form.formState;

  useEffect(() => {
    if (isDirty) {
      handleIsDirty(isDirty);
    }
  }, [isDirty]);

  const deductionOptions =
    isCheckHqUser && isCompanyLevel
      ? []
      : isCheckHqUser
      ? [
          {
            id: 'garnishments',
            title: isCheckHqUser ? 'Child support deductions' : 'Garnishments',
            name: 'Garnishment',
            description: isCheckHqUser
              ? "Court-ordered deductions repay child support from an employee's wages."
              : "Court-ordered deductions repay debts, e.g., child support, unpaid taxes, from an employee's wages.",
            icon: GarnishmentIcon,
          },
          {
            id: 'customPostTaxDeduction',
            title: 'Custom post-tax deductions',
            name: 'Custom Post-tax Deduction',
            description:
              'Include post-tax deductions like loan repayments, charity contributions, and insurance premiums.',
            icon: MoneyBag,
          },
        ]
      : [
          {
            id: 'Benefits',
            name: 'Benefit',
            title: 'Benefits',
            description:
              'Benefits that the company offers to employees like 401K, health insurance, FSA, etc.',
            icon: Umbrella,
          },
          {
            id: 'garnishments',
            title: isCheckHqUser ? 'Child support deductions' : 'Garnishments',
            name: 'Garnishment',
            description: isCheckHqUser
              ? "Court-ordered deductions repay child support from an employee's wages."
              : "Court-ordered deductions repay debts, e.g., child support, unpaid taxes, from an employee's wages.",
            icon: GarnishmentIcon,
          },
          {
            id: 'customPostTaxDeduction',
            title: 'Custom post-tax deductions',
            name: 'Custom Post-tax Deduction',
            description:
              'Include post-tax deductions like loan repayments, charity contributions, and insurance premiums.',
            icon: MoneyBag,
          },
        ];

  const deductionType = form.watch('type');
  const isBenefitDeductionSelected = deductionType === deductionTypeConstant.benefits;
  const isGarnishmentDeductionSelected = deductionType === deductionTypeConstant.garnishments;
  const isCustomPostTaxDeductionsSelected =
    deductionType === deductionTypeConstant.customPostTaxDeductions;

  const benefitsTypes = (isCheckHqUser ? CHECKHQ_BENEFIT_TYPES : BENEFITS).map(key => ({
    name: key,
    value: (isCheckHqUser ? CHECKHQ_BENEFITS_TO_ID_MAP : BENEFITS_TO_ID_MAP)[key],
  }));

  const garnishmentTypes = [
    { name: 'Child support', value: 1 },
    { name: 'Custom garnishment', value: 2 },
  ];

  const deductionTypeOptions =
    isCheckHqUser && isCompanyLevel
      ? benefitsTypes
      : isBenefitDeductionSelected
      ? benefitsTypes
      : garnishmentTypes;

  const shouldShowDeductionTypeField =
    isBenefitDeductionSelected ||
    isGarnishmentDeductionSelected ||
    isCustomPostTaxDeductionsSelected ||
    !deductionType;

  const { data: companyLevelDeductionList, refetch: refetchDeductionList } =
    useGetCompanyLevelDeductions({
      companyId,
      queryParams: {
        enabled: false,
      },
    });

  useEffect(() => {
    if (data) {
      const deductionData = companyLevelDeductionList.find(deduction => deduction.id === data.id);
      setDeductionInfo(deductionData);
    }
  }, [companyLevelDeductionList]);

  const handleSubmit = (e?: React.FormEvent | any) => {
    if (e?.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isCheckHqUser && form.getValues('type') === 'Garnishment') {
      form.setValue('garnishmentTitle', 'Child Support');
      form.setValue('garnishmentType', 1);
    }

    form.handleSubmit(onSubmit)();
  };

  const { isTaxesStepCompleted } = checkAccountForAccountSetup({
    account,
  });

  const deductionTypeName = form.watch('type');
  const subTypeName =
    deductionTypeName === 'Benefit'
      ? 'benefit'
      : deductionTypeName === 'Garnishment' || deductionTypeName === 'Child support deductions'
      ? 'garnishment'
      : 'custom';

  const keyObject = deductionTypeName === 'Benefit' ? BENEFITS_TO_ID_MAP : GARNISHMENT_TO_ID_MAP;
  const subTypeValue = parseInt(form.watch(`${subTypeName}Type`));
  const subTypeTitle = form.watch(`${subTypeName}Title`);
  const deductionSubType = getKeyByValue(keyObject, subTypeValue);

  const formatContribution = (amount: string, unit: number) => {
    return unit === 1 ? `$${amount}` : `${amount}%`;
  };

  const deductionEmployees = deductionInfo?.employees?.map((employee: any) => {
    const realDeductionArrayToCheckForTableRow =
      deductionInfo.type === DEDUCTION_TYPES_NAME.benefit
        ? employee.employeeBenefits
        : deductionInfo.type === DEDUCTION_TYPES_NAME.garnishment
        ? employee.employeeGarnishments
        : employee.employeeCustomPostTaxDeductions;
    const checkIfEmployeeHasThisParentDeduction = realDeductionArrayToCheckForTableRow.find(
      (deduction: any) => deductionInfo.id === deduction.companyDeductionId,
    );
    const theRealDeductionObject = checkIfEmployeeHasThisParentDeduction;

    const employeeContributionData =
      !theRealDeductionObject.companyContribution ||
      theRealDeductionObject.amountPerPayPeriod ||
      theRealDeductionObject.percentageGrossPay
        ? {
            contribution:
              (theRealDeductionObject.amountPerPayPeriod ||
                theRealDeductionObject.percentageGrossPay ||
                theRealDeductionObject?.amount) ??
              0,
            unit: theRealDeductionObject.deductionPortion,
            limit: theRealDeductionObject.deductionLimit,
            date: theRealDeductionObject.deductionToDate,
          }
        : undefined;
    const companyContributionData = theRealDeductionObject.companyContribution
      ? {
          contribution:
            theRealDeductionObject.companyAmountPerPayPeriod ||
            theRealDeductionObject.companyPercentageGrossPay,
          unit: theRealDeductionObject.companyContribution,
          limit: theRealDeductionObject.companyDeductionLimit,
          date: theRealDeductionObject.companyDeductionToDate,
        }
      : undefined;
    return {
      name: `${employee.firstName} ${employee.lastName}`,
      firstName: employee.firstName,
      id: employee.id,
      employeeContribution: employeeContributionData
        ? `${formatContribution(
            employeeContributionData.contribution,
            employeeContributionData.unit,
          )} / pay check`
        : 'No contribution',
      companyContribution: companyContributionData
        ? `${formatContribution(
            companyContributionData.contribution,
            companyContributionData.unit,
          )} / pay check`
        : 'No contribution',
    };
  });

  return {
    form,
    deductionOptions,
    isCustomPostTaxDeductionsSelected,
    isGarnishmentDeductionSelected,
    isBenefitDeductionSelected,
    deductionTypeOptions,
    shouldShowDeductionTypeField,
    employeesData: deductionEmployees,
    isAssigned: !!deductionInfo?.totalEmployeesWithDeduction,
    navigate,
    handleSubmit,
    deductionSubType,
    subTypeName,
    deductionTypeName,
    hasRunPayroll: deductionInfo ? !canBeEditedOrDeleted : false,
    subTypeTitle,
    refetchDeductionList,
    deductionInfo,
    isTaxesStepCompleted,
    isCompanyLevel,
    isCheckHqUser,
  };
};
