import { Bars3Icon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { Tab, TabList, TabPanel, TabProps, Tabs } from 'react-aria-components';

import CalendarPreview from '@/components/shared/CalendarPreview/CalendarPreview';
import CheckHQWrapper from '@/components/shared/CheckHQWrapper/CheckHQWrapper';
import { payFrequencyByValue } from '@/utils/helpers';

import PayrollScheduleCard from '../PayrollScheduleCard/PayrollScheduleCard';
import { ReviewScheduleContentProps } from './types';
import useReviewScheduleContent from './useReviewScheduleContent';

function MyTab(props: TabProps & { showSeparator?: boolean }) {
  return (
    <Tab
      {...props}
      className={({ isSelected }) => `
          flex w-fit cursor-pointer items-center p-2 text-center outline-none ring-emerald-500 focus-visible:ring-1
          ${
            isSelected
              ? 'bg-emerald-50 text-emerald-500'
              : 'pressed:bg-white/10 text-gray-300 hover:bg-white/10'
          }
        `}
    />
  );
}

const ReviewScheduleContent = ({
  bankHolidays,
  schedule,
  payFrequency = 1,
}: ReviewScheduleContentProps) => {
  const { AvailableViews, selectedView, viewChangeHandler } = useReviewScheduleContent();

  return (
    <div className="w-full">
      <Tabs
        className={'w-full'}
        selectedKey={selectedView}
        onSelectionChange={key => viewChangeHandler(key)}
      >
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="mr-2 flex items-center gap-2 text-[24px] font-medium leading-8">
            Current schedule
            <span className=" font-F37Bolton-Regular rounded-full border border-gray-100 px-4 py-2 text-sm">
              {payFrequencyByValue(payFrequency)}
            </span>
          </p>
          <div>
            <TabList
              aria-label="Feeds"
              className="flex w-fit gap-0 divide-x  divide-solid self-end overflow-hidden rounded-lg border border-gray-200 bg-clip-padding"
            >
              <MyTab id={AvailableViews.list}>
                <Bars3Icon className="mr-2 w-5" /> <span className="text-sm">List</span>
              </MyTab>

              <MyTab id={AvailableViews.calendar}>
                <CalendarDaysIcon className="mr-2 w-5" />
                <span className="text-sm">Calendar</span>
              </MyTab>
            </TabList>
          </div>
        </div>
        <TabPanel id={AvailableViews.list}>
          <div>
            {Array.isArray(schedule.backdated) && !!schedule.backdated.length && (
              <CheckHQWrapper hideItem>
                <p className="font-F37Bolton-Medium mb-3 mt-5">Back-dated payroll</p>
                {schedule.backdated.map(item => (
                  <PayrollScheduleCard
                    key={item.payDate}
                    payDate={item.payDate}
                    payPeriodStartDate={item.startDate}
                    payPeriodEndDate={item.endDate}
                    approvalDeadline={item?.approvalDeadline}
                  />
                ))}
              </CheckHQWrapper>
            )}

            {Array.isArray(schedule.overdue) && !!schedule.overdue.length && (
              <>
                <p className="font-F37Bolton-Medium mb-3 mt-5">Overdue payroll</p>
                {schedule.overdue.map(item => (
                  <PayrollScheduleCard
                    key={item.payDate}
                    payDate={item.payDate}
                    payPeriodStartDate={item.startDate}
                    payPeriodEndDate={item.endDate}
                    approvalDeadline={item?.approvalDeadline}
                  />
                ))}
              </>
            )}

            {Array.isArray(schedule.upcoming) && !!schedule.upcoming.length && (
              <>
                <p className="font-F37Bolton-Medium mb-3 mt-5">Upcoming payroll</p>
                <div>
                  {schedule.upcoming.slice(0, 4).map(item => (
                    <PayrollScheduleCard
                      key={item.payDate}
                      payDate={item.payDate}
                      payPeriodStartDate={item.startDate}
                      payPeriodEndDate={item.endDate}
                      approvalDeadline={item?.approvalDeadline}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </TabPanel>

        <TabPanel id={AvailableViews.calendar}>
          <div>
            {Array.isArray(schedule.backdated) && !!schedule.backdated.length && (
              <CheckHQWrapper hideItem>
                <p className="font-F37Bolton-Medium mb-3 mt-5">Back-dated payroll</p>
                <div className="gap-medium flex flex-wrap">
                  {schedule.backdated.map(item => (
                    <div key={item.payDate}>
                      <CalendarPreview
                        approvalDeadline={item?.approvalDeadline}
                        payPeriodStartDate={item.startDate}
                        payPeriodEndDate={item.endDate}
                        payDate={item.payDate}
                      />
                    </div>
                  ))}
                </div>
              </CheckHQWrapper>
            )}

            {Array.isArray(schedule.overdue) && !!schedule.overdue.length && (
              <>
                <p className="font-F37Bolton-Medium mb-3 mt-5">Overdue payroll</p>

                <div className="gap-medium flex flex-wrap">
                  {schedule.overdue.map(item => (
                    <div key={item.payDate}>
                      <CalendarPreview
                        bankHolidays={bankHolidays}
                        approvalDeadline={item?.approvalDeadline}
                        payPeriodStartDate={item.startDate}
                        payPeriodEndDate={item.endDate}
                        payDate={item.payDate}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}

            {Array.isArray(schedule.upcoming) && !!schedule.upcoming.length && (
              <>
                <p className="font-F37Bolton-Medium my-medium">Upcoming payroll</p>

                <div className="gap-medium flex flex-wrap">
                  {schedule.upcoming.slice(0, 4).map(item => (
                    <div key={item.payDate}>
                      <CalendarPreview
                        bankHolidays={bankHolidays}
                        approvalDeadline={item?.approvalDeadline}
                        payPeriodStartDate={item.startDate}
                        payPeriodEndDate={item.endDate}
                        payDate={item.payDate}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ReviewScheduleContent;
