import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';
import { Alert } from '@/components/ui';
import Drawer from '@/components/ui/Drawer/Drawer';
import { DeductionClickType } from '@/modules/employee/forms';
import { ConfirmationModal } from '@/modules/payrollSchedule/components';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';

import CheckHQWrapper from '../CheckHQWrapper/CheckHQWrapper';
import DeleteDeductionModal from './components/DeleteDeductionModal';
import { CheckHQDeductionForm } from './forms';
import { BlinkDeductionForm } from './forms/DeductionForm';
import useDeductionDrawer from './useDeductionDrawer';

type DeductionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  data: DeductionTypes | null;
  isEdit?: boolean;
  isInsideApp: boolean;
  onDeleteDeduction?: () => void;
  isCompanyLevel?: boolean;
  handleDeductionClick?: (data: DeductionClickType) => void;
  handleEditDeduction?: (data: any) => void;
};

const DeductionDrawer = ({
  isOpen,
  onClose,
  data,
  isEdit = false,
  isInsideApp,
  onDeleteDeduction,
  isCompanyLevel,
  handleDeductionClick,
  handleEditDeduction,
}: DeductionDrawerProps) => {
  const {
    formRef,
    onSubmit,
    handleSubmit,
    isLoading,
    handleIsDirty,
    isDisable,
    handleDeleteDeduction,
    isDeletingDeduction,
    isDeleteDeduction,
    handleDeleteDeductionModal,
    isAssigned,
    openDirtyDeduction,
    handleDrawerClose,
    handleConfirmDirtyDeduction,
    handleCloseDirtyDeduction,
    isCheckHqUser,
  } = useDeductionDrawer({
    onClose,
    isEdit,
    data,
    onDeleteDeduction,
    handleEditDeduction,
  });

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        title={
          isEdit
            ? `Edit ${isCheckHqUser && isCompanyLevel ? 'company-wide benefit' : 'deduction'}`
            : `Add a ${isCheckHqUser && isCompanyLevel ? 'company-wide benefit' : 'deduction'}`
        }
        modelClasses="w-full max-w-[720px]"
        footer={
          (data?.canBeEditedOrDeleted || !isEdit || (isCheckHqUser && isEdit)) && (
            <div
              className={twMerge(
                'p-x-large flex items-center justify-end',
                isEdit ? 'justify-between' : '',
              )}
            >
              {isEdit && (
                <Button
                  variant="text"
                  onPress={handleDeleteDeductionModal}
                  parentClasses="text-red-500"
                >
                  Delete
                </Button>
              )}

              <Button
                type="submit"
                variant="primary"
                parentClasses="w-auto px-6 py-3"
                children={
                  isEdit
                    ? 'Save changes'
                    : `Add ${isCheckHqUser && isCompanyLevel ? 'benefit' : 'deductions'}`
                }
                onPress={handleSubmit}
                isDisabled={isDisable}
                isLoading={isLoading}
                width="w-[150px] min-w-[150px]"
              />
            </div>
          )
        }
      >
        {(!isEdit && !isCheckHqUser) || (isCheckHqUser && isCompanyLevel && !isEdit) ? (
          <Alert
            variant="information"
            subtitle="This benefit will be saved and can be assigned to multiple employees. You will be able to add the amounts per employee"
            parentClasses="mt-4"
            isDeletable={false}
          />
        ) : !isCheckHqUser ? (
          <h4 className="font-F37Bolton-Medium pt-6 text-xl">Basic details</h4>
        ) : null}
        <CheckHQWrapper hideItem>
          <BlinkDeductionForm
            formRef={formRef}
            onSubmit={onSubmit}
            data={data}
            handleIsDirty={handleIsDirty}
            isAssigned={isAssigned || false}
            isEdit={isEdit}
            isInsideApp={isInsideApp}
            isCompanyLevel={isCompanyLevel}
            isCheckHqUser={isCheckHqUser}
            handleDeductionClick={handleDeductionClick}
          />
        </CheckHQWrapper>
        <CheckHQWrapper>
          <CheckHQDeductionForm
            ref={formRef}
            onSubmit={onSubmit}
            data={data}
            handleIsDirty={handleIsDirty}
            isAssigned={isAssigned || false}
            isEdit={isEdit}
            isInsideApp={isInsideApp}
            isCompanyLevel={isCompanyLevel}
            isCheckHqUser={isCheckHqUser}
            handleDeductionClick={handleDeductionClick}
          />
        </CheckHQWrapper>
      </Drawer>
      {isDeleteDeduction && (
        <DeleteDeductionModal
          isOpen={isDeleteDeduction}
          onClose={handleDeleteDeductionModal}
          onDelete={handleDeleteDeduction}
          isLoading={isDeletingDeduction}
          isAssigned={isAssigned || false}
        />
      )}
      {openDirtyDeduction && (
        <ConfirmationModal
          isOpen={openDirtyDeduction}
          handleConfirm={handleConfirmDirtyDeduction}
          handleClose={handleCloseDirtyDeduction}
        />
      )}
    </>
  );
};
export default DeductionDrawer;
