import { DEDUCTION_TYPES } from '@/utils/constants';

type DeductionTypeProps = {
  type: (typeof DEDUCTION_TYPES)[keyof typeof DEDUCTION_TYPES];
};

const DeductionType = ({ type }: DeductionTypeProps) => {
  return (
    <span className="h-min rounded-[8px] border border-gray-100 px-2 py-1 text-[12px] text-navy-900">
      {type}
    </span>
  );
};

export default DeductionType;
