import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { deleteCall } from '@/api/axios';

import { DELETE_BENEFIT } from './employeeApiRoutes';

interface UseDeleteBenefitArgs {
  companyId: number;
  employeeId: number | null;
  onSuccess?: () => void;
}

const deleteBenefit = (companyId: number, employeeId: number, benefitId: number) =>
  deleteCall(DELETE_BENEFIT({ companyId, employeeId, id: benefitId }));

const useDeleteBenefit = ({
  companyId,
  employeeId,
  onSuccess,
}: UseDeleteBenefitArgs): UseMutationResult<AxiosResponse<any, any>, Error, number, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (benefitId: number) => deleteBenefit(companyId, employeeId, benefitId),
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries({
        queryKey: ['getBenefits'],
      });
    },
  });
};

export default useDeleteBenefit;
