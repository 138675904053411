import { Models } from '@rematch/core';

import { _1099nec } from './1099-nec';
import { auth } from './auth';
import { company } from './company';
import { contractor } from './contractor';
import { dashboard } from './dashboard';
import { demo } from './demo';
import { employee } from './employee';
import { industry } from './industry';
import { members } from './members';
import { schedule } from './schedule';
import { user } from './user';
import { utility } from './utility';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  utility: typeof utility;
  contractor: typeof contractor;
  employee: typeof employee;
  members: typeof members;
  company: typeof company;
  dashboard: typeof dashboard;
  schedule: typeof schedule;
  _1099nec: typeof _1099nec;
  user: typeof user;
  industry: typeof industry;
  demo: typeof demo;
}

export const models: RootModel = {
  auth,
  utility,
  contractor,
  employee,
  members,
  dashboard,
  schedule,
  company,
  user,
  industry,
  _1099nec,
  demo,
};
