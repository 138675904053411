import { CalendarIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { IconMessage, IconPhone } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as Illustration } from '@/assets/illustration/supportIllustration.svg';
import Button from '@/components/Button/Button';
import { Button as UpdatedButton } from '@/components/ui/designSystem/Button/Button';
import { useAccountInfo } from '@/hooks';
import { Dispatch } from '@/redux/store';
import { SUPPORT_CONTACT_INFO } from '@/utils/constants';

type SupportBoxProps = {
  onBookCall?: () => void;
  closeHandler?: () => void;
};

const SupportBox = ({ onBookCall, closeHandler }: SupportBoxProps) => {
  const { account } = useAccountInfo();
  const dispatch = useDispatch<Dispatch>();

  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  const { firstName, lastName, email } = account || {};

  const openTicketThrowEmail = () => {
    window.FreshworksWidget('identify', 'ticketForm', {
      name: `${firstName} ${lastName}`,
      email: email,
    });
    window.FreshworksWidget('open');
  };

  const supportItems = [
    ...(onBookCall
      ? [
          {
            id: 'bookCall',
            label: 'Book a call',
            icon: <CalendarIcon className="w-5 text-gray-400 group-hover:text-emerald-500" />,
            onButtonPress: onBookCall,
          },
        ]
      : []),
    {
      id: 'email',
      label: 'Email',
      icon: <EnvelopeIcon className="w-5 text-gray-400 group-hover:text-emerald-500" />,
      onButtonPress: openTicketThrowEmail,
    },
    ...(isDesktop
      ? [
          {
            id: 'callNow',
            label: `Call now: ${SUPPORT_CONTACT_INFO.mobileNumber}`,
            icon: <IconPhone className="w-5 text-gray-400 group-hover:text-emerald-500" />,
            onButtonPress: () =>
              (window.location.href = `tel:+${SUPPORT_CONTACT_INFO.mobileNumber}`),
          },
        ]
      : [
          {
            id: 'chatWithUs',
            label: `Chat with us`,
            icon: <IconMessage className="w-5 text-gray-400 group-hover:text-emerald-500" />,
            onButtonPress: () => window.fcWidget.open(),
          },
        ]),
  ];

  useEffect(() => {
    const checkScreenSize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="bg-primary-emerald-light-bg p-medium rounded-t-2x-small relative flex flex-col">
          <span className="text-content-heading text-large font-F37Bolton-Bold">Need help?</span>
          <span className="text-content-body-strong text-small w-[170px]">
            Speak to one of our onboarding specialists
          </span>
          <Illustration className="absolute right-0" />
        </div>
        <div className="gap-small p-medium flex items-center justify-center">
          <UpdatedButton
            children="Chat with us"
            onPress={() => {
              window.fcWidget.open();
              dispatch.utility.CLOSE_HELP_POPOVER();
            }}
            className="hidden w-full lg:flex"
            startIcon={<IconMessage className="mr-x-small size-5" />}
          />
          <UpdatedButton
            children="Call now: (888) 870-1009"
            onPress={() => (window.location.href = `tel:+${SUPPORT_CONTACT_INFO.mobileNumber}`)}
            className="text-small flex w-full text-nowrap lg:hidden"
            startIcon={<IconMessage className="mr-x-small size-5" />}
          />
        </div>
        <div className="px-medium pb-x-small flex flex-col">
          {supportItems.map(item => (
            <Button
              key={item.id}
              variant="text"
              parentClasses="flex items-center gap-2 !justify-start py-2 pr-2 group hover:text-emerald-500"
              onPress={() => {
                dispatch.utility.CLOSE_HELP_POPOVER();
                item.onButtonPress();
                closeHandler && closeHandler();
              }}
              height="h-auto"
            >
              {item.icon} {item.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupportBox;
