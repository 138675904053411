import { useEffect, useMemo, useState } from 'react';

import { useAccountInfo } from '@/hooks';
import { taxInformation, TaxItem } from '@/redux/dto/company';
import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';

import { useGetCompanyTaxInformation } from './apis';

const useEmployerTaxItemModal = ({ taxItemId, onCancel }: any) => {
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { companyId } = useAccountInfo();

  const [currentTaxItem, setCurrentTaxItem] = useState<TaxItem | null>(null);

  const { data, isFetching: isFetchingEmployerTaxData } = useGetCompanyTaxInformation({
    companyId,
    queryParams: {
      gcTime: 0,
    },
  });

  const stateTaxData: taxInformation | null = useMemo(() => {
    return Array.isArray(data)
      ? data.find(
          (taxInformation: taxInformation) =>
            Array.isArray(taxInformation.taxItems) &&
            taxInformation.taxItems.some(taxItem => taxItem.id === taxItemId),
        ) ?? null
      : null;
  }, [data, taxItemId]);

  useEffect(() => {
    if (stateTaxData) {
      const { taxItems } = stateTaxData;

      const taxItem = taxItems.find(taxItem => taxItem.id === taxItemId);

      setCurrentTaxItem(taxItem ?? null);
    }
  }, [stateTaxData, taxItemId]);

  const isFederalTaxItem = stateTaxData
    ? stateTaxData.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION
    : false;

  const handleIsDirty = (isDirty: boolean) => {
    setIsFormDirty(isDirty);
  };

  const handleCloseDirtyModal = () => {
    setIsDirtyModalOpen(false);
  };

  const handleConfirmDirtyModal = () => {
    setIsDirtyModalOpen(false);
    onCancel();
  };

  const handleDrawerClose = () => {
    if (isFormDirty) {
      setIsDirtyModalOpen(true);
    } else {
      onCancel();
    }
  };

  return {
    currentTaxItem,
    isFetchingEmployerTaxData,
    isFederalTaxItem,
    handleIsDirty,
    handleDrawerClose,
    isDirtyModalOpen,
    handleConfirmDirtyModal,
    handleCloseDirtyModal,
  };
};

export default useEmployerTaxItemModal;
