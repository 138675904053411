import { MutationEndpoint, QueryEndpoint } from '@/api/types';
import { queryEndpoints as contractorQueryEndpoints } from '@/modules/contractor/apis/endpoints';
import { queryEndpoints as employeeQueryEndpoints } from '@/modules/employee/apis/endpoints';
export const queryEndpoints = {
  getCheckHQSyncData: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'sync-data'],
    url: `/company/${companyId}/checkhq/sync`,
  }),
  getCheckHQBankAccount: (bankAccountId: string): QueryEndpoint => ({
    queryKey: ['bank-account', bankAccountId],
    url: `/company/${bankAccountId}/checkhq/sync`,
  }),
  getCheckHQCompanyAditionalDetails: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'verification-documents'],
    url: `/company/${companyId}/checkhq/component/verification-documents`,
  }),
};

export const mutationEndpoints = {
  postCheckHQBankAccount: (
    companyId: number,
    contractorId: number,
    isContractor: boolean,
  ): MutationEndpoint => ({
    mutationKey: ['add-bank-account'],
    url: '/bank-account',
    invalidateKeys: [
      isContractor
        ? contractorQueryEndpoints.contractor(companyId, contractorId).queryKey
        : employeeQueryEndpoints.employee(companyId, contractorId).queryKey,
    ],
  }),
  postNetPaySpilt: (
    companyId: number,
    contractorId: number,
    isContractor: boolean,
  ): MutationEndpoint => ({
    mutationKey: ['net-pay-split'],
    url: '/net-pay-split',
    invalidateKeys: [
      isContractor
        ? contractorQueryEndpoints.contractor(companyId, contractorId).queryKey
        : employeeQueryEndpoints.employee(companyId, contractorId).queryKey,
    ],
  }),
  patchCheckHQBankAccount: (
    companyId: number,
    employeeId: number,
    bankAccountId: number,
    isContractor: boolean,
  ): MutationEndpoint => ({
    mutationKey: [
      'company',
      companyId,
      'employee',
      employeeId,
      'update-bank-account',
      bankAccountId,
    ],
    url: `/bank-account/${bankAccountId}`,
    invalidateKeys: [
      isContractor
        ? contractorQueryEndpoints.contractor(companyId, employeeId).queryKey
        : employeeQueryEndpoints.employee(companyId, employeeId).queryKey,
    ],
  }),
  deleteCheckHQBankAccount: (
    companyId: number,
    employeeId: number,
    bankAccountId: number,
    isContractor: boolean,
  ): MutationEndpoint => ({
    mutationKey: ['delete-bank-account', bankAccountId],
    url: `/bank-account/${bankAccountId}`,
    invalidateKeys: [
      isContractor
        ? contractorQueryEndpoints.contractor(companyId, employeeId).queryKey
        : employeeQueryEndpoints.employee(companyId, employeeId).queryKey,
    ],
  }),
};
