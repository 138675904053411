export const SUBMIT_TAX_RECORD = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/submit-tax-record`;

//Get selected user's data
export const GET_USER_INFORMATION = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | null;
}) => `/company/${companyId}/employee/${employeeId}`;

//Add new work location
export const POST_WORK_LOCATION = ({ companyId }: { companyId: number }) =>
  `company/${companyId}/work-location`;

//Save employee's work location
export const PUT_EMPLOYEES_INFORMATION = ({
  companyId,
  id,
}: {
  companyId: number;
  id: number | undefined;
}) => `company/${companyId}/employee/${id}/employment`;

//Get employee's first recent pay dates ytd
export const GET_FIRST_RECENT_PAY_DATES_YTD = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `company/${companyId}/first-recent-pay-dates-ytd/${employeeId}`;

//Get employee's Compensation Data
export const GET_EMPLOYEE_COMPENSATION = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/compensation`;

// Save employee's Compensation Data
export const SAVE_EMPLOYEE_COMPENSATION = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/compensation`;

//Get list of user's work locations
export const GET_BENEFIT = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `company/${companyId}/employee/${employeeId}/benefit`;

//Submit Employee’s Tax Benefit
export const POST_BENEFIT = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/benefit`;

//Delete Employee’s Tax Benefit
export const DELETE_BENEFIT = ({
  companyId,
  employeeId,
  id,
}: {
  companyId: number;
  employeeId: number;
  id: number;
}) => `company/${companyId}/employee/${employeeId}/benefit/${id}`;

//Update Employee’s Tax Benefit
export const PUT_BENEFIT = ({
  companyId,
  employeeId,
  id,
}: {
  companyId: number;
  employeeId: number | undefined;
  id: number;
}) => `company/${companyId}/employee/${employeeId}/benefit/${id}`;

//Get Employee’s Garnishments
export const GET_GARNISHMENT = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `company/${companyId}/employee/${employeeId}/garnishment`;

//Submit Employee’s Garnishment
export const POST_GARNISHMENT = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `company/${companyId}/employee/${employeeId}/garnishment`;

//Delete Employee’s Tax Benefit
export const DELETE_GARNISHMENT = ({
  companyId,
  employeeId,
  id,
}: {
  companyId: number;
  employeeId: number | undefined;
  id: number;
}) => `company/${companyId}/employee/${employeeId}/garnishment/${id}`;

//Update Employee’s Tax Benefit
export const PUT_GARNISHMENT = ({
  companyId,
  employeeId,
  id,
}: {
  companyId: number;
  employeeId: number | undefined;
  id: number;
}) => `company/${companyId}/employee/${employeeId}/garnishment/${id}`;

//Get Employee’s Custom Post Tax Deduction
export const GET_DEDUCTION = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `company/${companyId}/employee/${employeeId}/custom-post-tax-deduction`;

//Submit Employee’s Custom Post Tax Deduction
export const POST_DEDUCTION = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `company/${companyId}/employee/${employeeId}/custom-post-tax-deduction`;

//Delete Employee’s Custom Post Tax Deduction
export const DELETE_DEDUCTION = ({
  companyId,
  employeeId,
  id,
}: {
  companyId: number;
  employeeId: number | undefined;
  id: number;
}) => `company/${companyId}/employee/${employeeId}/custom-post-tax-deduction/${id}`;

//Update Employee’s Tax Benefit
export const PUT_DEDUCTION = ({
  companyId,
  employeeId,
  id,
}: {
  companyId: number;
  employeeId: number | undefined;
  id: number;
}) => `company/${companyId}/employee/${employeeId}/custom-post-tax-deduction/${id}`;

//Update Employee’s Tax Benefit
export const PATCH_BENEFITS = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/skipped-benefits-garnishment`;

//Get Employee YTD Tax deductions
export const GET_YTD_TAX_DEDUCTIONS = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | undefined;
}) => `/company/${companyId}/employee/${employeeId}/ytd-tax-deductions`;

// POST Tax Withholding Employee Invite
export const POST_TAX_WITHHOLDING_INVITE = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/tax-withholding/invite`;

// PATCH Tax Withholding Employee Invite
export const PATCH_TAX_WITHHOLDING_INVITE = ({
  companyId,
  employeeId,
  invitationId,
}: {
  companyId: number;
  employeeId: number;
  invitationId: number | null;
}) => `company/${companyId}/employee/${employeeId}/tax-withholding/invite/${invitationId}`;

// POST Approve Tax Withholdings
export const POST_APPROVE_TAX_WITHHOLDINGS = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number;
}) => `company/${companyId}/employee/${employeeId}/tax-withholding-details/approve`;

// GET Tax Records submitted by employee
export const GET_SHOW_TAX_RECORD = ({
  companyId,
  employeeId,
}: {
  companyId: number;
  employeeId: number | null;
}) => `company/${companyId}/employee/${employeeId}/show-tax-record`;
