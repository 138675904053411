import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useAccountInfo } from '@/hooks';
import { useGetCompanyDeductions } from '@/modules/employee/apis';
import {
  useDeleteBenefit,
  useDeleteDeduction,
  useDeleteGarnishment,
  usePostBenefit,
  usePostDeduction,
  usePostGarnishment,
  usePutBenefit,
  usePutDeduction,
  usePutGarnishment,
} from '@/modules/newTeamMembers/apis';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';
import { CHECKHQ_PAY_PERIOD_TYPES } from '@/utils/checkHQConstants';
import { BENEFITS_TO_ID_MAP, DEDUCTION_TYPES_NAME } from '@/utils/constants';
import {
  callToast,
  focusInputByName,
  getAllEmployeeDeductions,
  isArrayHasData,
} from '@/utils/helpers';

type UseEmployeeDeductionDrawerType = {
  isEdit?: boolean;
  data: DeductionTypes | null;
  employeeId: number;
  onClose: () => void;
  refetchEmployeeData?: VoidFunction;
  employeeSsn?: string | number | null;
  handleCompanyDeductionDrawer: () => void;
};
const useEmployeeDeductionDrawer = ({
  isEdit,
  data,
  employeeId,
  onClose,
  refetchEmployeeData,
  employeeSsn,
  handleCompanyDeductionDrawer,
}: UseEmployeeDeductionDrawerType) => {
  const { companyId, isCheckHqUser } = useAccountInfo();
  const { type, id, benefitType, garnishmentType, name, checkHqId: employeeCheckHqId } = data || {};
  const [hasCompanyContribution, setHasCompanyContribution] = useState(false);
  const [isUnassignDeduction, setIsUnassignDeduction] = useState(false);
  const [isNoticePopupOpen, setIsNoticePopupOpen] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);

  const getAllDeduction = isArrayHasData(data?.employees)
    ? getAllEmployeeDeductions(data, employeeId)
    : [data];

  const hasPayrollRun = data
    ? (data?.employees || [])?.find(emp => emp.id === employeeId)?.payrollRun
    : false;

  const filterCurrentDeduction =
    getAllDeduction?.find(deduction => deduction?.companyDeductionId === id) || data;

  const getAddBenefitSchema = () => {
    let baseSchema = {};

    baseSchema = {
      ...baseSchema,
      deductionLimit: Yup.number().positive('Amount must be positive').nullable().notRequired(),
      amountPerPayPeriod: Yup.number().when(['deductionPortion', 'calculatedAs'], {
        is: (deductionPortion: string | number, calculatedAs: string | number) => {
          return deductionPortion == 1 || calculatedAs == 1;
        },
        then: schema =>
          schema.positive('Amount must be positive').required('Amount per pay period is required'),
        otherwise: schema => schema.notRequired(),
      }),
      percentageGrossPay: Yup.number().when(['deductionPortion', 'calculatedAs'], {
        is: (deductionPortion: string | number, calculatedAs: string | number) =>
          deductionPortion == 2 || calculatedAs == 2,
        then: schema =>
          schema
            .positive('Percentage must be positive')
            .required('Percentage of gross pay is required'),
        otherwise: schema => schema.notRequired(),
      }),
    };

    let companyDeductions = {};
    if (type !== DEDUCTION_TYPES_NAME.garnishment) {
      baseSchema = {
        ...baseSchema,
        calculatedAs: Yup.string().when(['period'], {
          is: (period: string) => {
            return period === 'pay_period';
          },
          then: schema => schema.required('Calculation type is required'),
          otherwise: schema => schema.notRequired(),
        }),
      };
    }

    if (type === DEDUCTION_TYPES_NAME.benefit && hasCompanyContribution) {
      companyDeductions = {
        ...companyDeductions,
        companyCalculatedAs: Yup.string()
          .nullable()
          .when(['period'], {
            is: (period: string) => period === 'pay_period',
            then: schema => schema.required('Calculation type is required'),
            otherwise: schema => schema.notRequired(),
          }),
        companyAmountPerPayPeriod: Yup.number()
          .nullable()
          .when(['companyContribution', 'companyCalculatedAs'], {
            is: (
              companyContribution: string | number,
              companyCalculatedAs: string | number | null,
            ) =>
              companyCalculatedAs != null && (companyContribution == 1 || companyCalculatedAs == 1),
            then: schema =>
              schema
                .positive('Amount must be positive')
                .required('Amount per pay period is required'),
            otherwise: schema => schema.notRequired(),
          }),
        companyDeductionLimit: Yup.number()
          .positive('Amount must be positive')
          .nullable()
          .notRequired(),
        companyPercentageGrossPay: Yup.number()
          .nullable()
          .when(['companyContribution', 'companyCalculatedAs'], {
            is: (
              companyContribution: string | number,
              companyCalculatedAs: string | number | null,
            ) =>
              companyCalculatedAs != null && (companyContribution == 2 || companyCalculatedAs == 2),
            then: schema =>
              schema
                .positive('Percentage must be positive')
                .max(100, 'Percentage cannot be more than 100')
                .required('Percentage of gross pay is required'),
            otherwise: schema => schema.notRequired(),
          }),
      };
    }

    let effectivePayPeriod = {};
    let hsaContribution = {};
    let garnishmentValidations = {};

    if (isCheckHqUser) {
      effectivePayPeriod = {
        ...effectivePayPeriod,
        effectiveStart: Yup.string()
          .required('Effective Start date is Required')
          .typeError('Effective Start date is Required'),
      };

      if (benefitType === BENEFITS_TO_ID_MAP.HSA) {
        hsaContribution = {
          ...hsaContribution,
          hsaContributionLimit: Yup.string().required('HSA Contribution Limit is Required'),
        };
      }

      if (type === DEDUCTION_TYPES_NAME.garnishment) {
        garnishmentValidations = {
          ...garnishmentValidations,
          amount: Yup.number().required('Amount per pay check is Required'),
          maxPercent: Yup.number().required('Maximum Percentage is Required'),
          agency: Yup.string().required('Agency is Required'),
          externalId: Yup.string().required('External Id is Required'),
          issueDate: Yup.string().required('Issue Date is Required'),
        };
      }
    }
    return Yup.object().shape({
      ...baseSchema,
      ...companyDeductions,
      ...effectivePayPeriod,
      ...hsaContribution,
      ...garnishmentValidations,
    } as any);
  };

  const form = useForm({
    defaultValues: {
      ...filterCurrentDeduction,
      calculatedAs:
        (filterCurrentDeduction?.deductionPortion || filterCurrentDeduction?.calculatedAs) ?? null,
      companyCalculatedAs:
        (filterCurrentDeduction?.companyContribution ||
          filterCurrentDeduction?.companyCalculatedAs) ??
        null,
      hasCompanyContribution: filterCurrentDeduction?.companyContribution > 0 ? 1 : 0,
      type,
      id,
      benefitType,
      garnishmentType,
      name,
      period: filterCurrentDeduction?.period || CHECKHQ_PAY_PERIOD_TYPES.pay_period,
      effectiveStart: filterCurrentDeduction?.effectiveStart ?? undefined,
      effectiveEnd: filterCurrentDeduction?.effectiveEnd ?? undefined,
      hsaContributionLimit: filterCurrentDeduction?.hsaContributionLimit ?? undefined,
      amount:
        (filterCurrentDeduction?.amount || filterCurrentDeduction?.amountPerPayPeriod) ?? undefined,
      maxPercent:
        (filterCurrentDeduction?.maxPercent || filterCurrentDeduction?.percentageGrossPay) ??
        undefined,
      agency: filterCurrentDeduction?.agency ?? undefined,
      issueDate: filterCurrentDeduction?.issueDate ?? undefined,
      externalId: filterCurrentDeduction?.externalId ?? undefined,
      annualLimit: filterCurrentDeduction?.annualLimit ?? undefined,
      totalAmount: filterCurrentDeduction?.totalAmount ?? undefined,
      managed: filterCurrentDeduction?.managed ? 1 : 0 ?? undefined,
    },
    mode: 'onSubmit',
    resolver: yupResolver(getAddBenefitSchema()),
  });

  const formRef = useRef(null);

  const {
    watch,
    formState: { isDirty },
  } = form;

  const isCompanyContribution = watch('hasCompanyContribution');
  const period = watch('period');

  useEffect(() => {
    setHasCompanyContribution(form.getValues('hasCompanyContribution'));
  }, [isCompanyContribution]);

  useEffect(() => {
    form.reset(form.getValues());
  }, []);

  const onError = (error: FieldErrors) => {
    if (error) {
      if (error.effectiveStart) {
        focusInputByName('effectiveStart');
      }
    }
  };

  const onNoticeCancel = () => {
    setIsNoticePopupOpen(false);
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const { mutateAsync: postBenefitMutate, isPending: isCreatingBenefit } = usePostBenefit({
    companyId,
    employeeId,
  });

  const { mutateAsync: putBenefitMutate, isPending: isUpdatingBenefit } = usePutBenefit({
    companyId,
    employeeId,
  });

  const { mutate: mutateBenefit, isPending: isUnassignBenefit } = useDeleteBenefit({
    companyId,
    employeeId,
  });

  const { mutateAsync: postGarnishmentMutate, isPending: isCreatingGarnishment } =
    usePostGarnishment({
      companyId,
      employeeId,
    });

  const { mutateAsync: putGarnishmentMutate, isPending: isUpdatingGarnishment } = usePutGarnishment(
    {
      companyId,
      employeeId,
    },
  );

  const { mutate: mutateGarnishment, isPending: isUnassignGarnishment } = useDeleteGarnishment({
    companyId,
    employeeId,
  });

  const { mutateAsync: postDeductionMutate, isPending: isCreatingDeduction } = usePostDeduction({
    companyId,
    employeeId,
  });

  const { mutateAsync: putDeductionMutate, isPending: isUpdatingDeduction } = usePutDeduction({
    companyId,
    employeeId,
  });

  const { mutate: mutateDeduction, isPending: isUnAssigningDeduction } = useDeleteDeduction({
    companyId,
    employeeId,
  });

  const { refetch: refetchDeductionList } = useGetCompanyDeductions({
    companyId,
    queryParams: {
      enabled: false,
    },
  });

  const handleRefetchData = () => {
    refetchDeductionList();
    onClose();
  };

  const onSaveBenefit = ({ isEdit, payload, onSuccessHandler }: any) => {
    if (isEdit) {
      putBenefitMutate(payload).then(() => {
        handleRefetchData();
        onSuccessHandler();
      });
    } else {
      postBenefitMutate(payload).then(() => {
        handleRefetchData();
        onSuccessHandler();
      });
    }
  };

  const onSaveGarnishment = ({ isEdit, payload, onSuccessHandler }: any) => {
    if (isEdit) {
      putGarnishmentMutate(payload).then(() => {
        handleRefetchData();
        onSuccessHandler();
      });
    } else {
      postGarnishmentMutate(payload).then(() => {
        handleRefetchData();
        onSuccessHandler();
      });
    }
  };

  const onSaveDeduction = ({ isEdit, payload, onSuccessHandler }: any) => {
    if (isEdit) {
      putDeductionMutate(payload).then(() => {
        handleRefetchData();
        onSuccessHandler();
      });
    } else {
      postDeductionMutate(payload).then(() => {
        handleRefetchData();
        onSuccessHandler();
      });
    }
  };

  const onDeleteBenefit = (benefitId: number) =>
    mutateBenefit(benefitId, {
      onSuccess: () => {
        handleRefetchData();
      },
    });
  const onDeleteGarnishment = (garnishmentId: number) =>
    mutateGarnishment(garnishmentId, {
      onSuccess: () => {
        handleRefetchData();
        isCheckHqUser && refetchEmployeeData && refetchEmployeeData();
      },
    });
  const onDeleteDeduction = (deductionId: number) =>
    mutateDeduction(deductionId, {
      onSuccess: () => {
        handleRefetchData();
        isCheckHqUser && refetchEmployeeData && refetchEmployeeData();
      },
    });

  const handleToastMessageOnSuccess = (isEdit?: boolean, type?: string) => {
    if (type === DEDUCTION_TYPES_NAME.benefit) {
      if (!isEdit) {
        callToast('success', 'Benefit assigned successfully!');
      } else {
        callToast('success', 'Assigned benefit updated successfully!');
      }
    } else {
      if (!isEdit) {
        callToast('success', 'Deduction assigned successfully!');
      } else {
        callToast('success', 'Assigned deduction updated successfully!');
      }
    }
  };

  const handleEmployeeDeduction = (data?: any) => {
    if (hasPayrollRun && !isNoticePopupOpen) {
      setIsNoticePopupOpen(true);
      return;
    }

    const parseOrNull = (value: any) => (value ? parseFloat(value) : null);

    const {
      type = '',
      calculatedAs = null,
      amountPerPayPeriod,
      percentageGrossPay,
      companyDeductionLimit = null,
      deductionLimit = null,
      companyPercentageGrossPay = null,
      companyCalculatedAs = null,
      companyAmountPerPayPeriod = 0,
      id = 0,
      hasCompanyContribution = 0,
      effectiveStart = undefined,
      effectiveEnd = undefined,
      hsaContributionLimit = 0,
      agency = '',
      externalId = '',
      issueDate = '',
      managed = 1,
      annualLimit,
      totalAmount,
      amount = 0,
      maxPercent = 0,
    } = data || {};

    const deductionPortion =
      isCheckHqUser && period === CHECKHQ_PAY_PERIOD_TYPES.monthly
        ? 1
        : parseInt(calculatedAs) || 1;

    const companyDeductionPortion = hasCompanyContribution
      ? isCheckHqUser && period === CHECKHQ_PAY_PERIOD_TYPES.monthly
        ? 1
        : parseOrNull(companyCalculatedAs)
      : null;

    const companyLevelDeductionIRI = `/api/company/${companyId}/deduction/${id}`;

    const basePayload = {
      id: filterCurrentDeduction?.id ?? undefined,
      deductionPortion,
      amountPerPayPeriod: deductionPortion === 2 ? null : parseOrNull(amountPerPayPeriod) ?? null,
      deductionToDate: null,
      ...(isCheckHqUser
        ? {}
        : {
            deductionLimit: parseOrNull(deductionLimit),
            percentageGrossPay:
              deductionPortion === 1 ? null : parseOrNull(percentageGrossPay) ?? null,
          }),
      ...(isCheckHqUser && type !== DEDUCTION_TYPES_NAME.benefit
        ? {}
        : { companyDeduction: companyLevelDeductionIRI }),
      percentageGrossPay: deductionPortion === 1 ? null : parseFloat(percentageGrossPay) ?? null,
    };

    const addBenefitPayload = (payload: any) => ({
      ...payload,
      companyContribution: companyDeductionPortion,
      companyAmountPerPayPeriod:
        hasCompanyContribution === 0 || companyDeductionPortion === 2
          ? null
          : parseOrNull(companyAmountPerPayPeriod),
      companyPercentageGrossPay:
        hasCompanyContribution === 0 || companyDeductionPortion === 1
          ? null
          : parseOrNull(companyPercentageGrossPay),
      companyDeductionToDate: null,
      companyDeductionLimit: parseOrNull(companyDeductionLimit),
    });

    const addCheckHqPayload = (payload: any) => ({
      ...payload,
      effectiveStart,
      effectiveEnd,
      employee: employeeCheckHqId ?? null,
      rate: deductionPortion === 1 ? parseFloat(amountPerPayPeriod) ?? undefined : undefined,
      percent: deductionPortion === 2 ? parseFloat(percentageGrossPay) : undefined ?? undefined,
      period: period,
    });

    const addHsaPayload = (payload: any) => ({
      ...payload,
      hsaContributionLimit: parseFloat(hsaContributionLimit) ?? 0,
    });

    const addGarnishmentPayload = (payload: any) => ({
      ...payload,
      amount,
      maxPercent,
      agency,
      externalId,
      issueDate,
      managed: !!managed,
      amountPerPayPeriod: parseOrNull(isCheckHqUser ? amount : amountPerPayPeriod),
      percentageGrossPay: parseOrNull(isCheckHqUser ? maxPercent : percentageGrossPay),
      type: garnishmentType || 1,
    });

    const addCustomPayload = (payload: any) => ({
      ...payload,
      annualLimit: parseFloat(annualLimit) ?? null,
      amount: deductionPortion === 1 ? parseFloat(amountPerPayPeriod) ?? null : null,
      percent: deductionPortion === 2 ? parseFloat(percentageGrossPay) ?? null : null,
      totalAmount: parseFloat(totalAmount) ?? null,
      name,
    });

    let payload = basePayload;

    if (type === DEDUCTION_TYPES_NAME.benefit) {
      payload = addBenefitPayload(payload);
    }

    if (isCheckHqUser) {
      payload = addCheckHqPayload(payload);

      if (type === DEDUCTION_TYPES_NAME.benefit) {
        if (benefitType === BENEFITS_TO_ID_MAP.HSA) {
          payload = addHsaPayload(payload);
        }
      }

      if (type === DEDUCTION_TYPES_NAME.garnishment) {
        payload = addGarnishmentPayload(payload);
      }

      if (type === DEDUCTION_TYPES_NAME.custom) {
        payload = addCustomPayload(payload);
      }
    }

    const savePayload = {
      isEdit,
      payload,
      onSuccessHandler: () => {
        isCheckHqUser &&
          !isEdit &&
          type !== DEDUCTION_TYPES_NAME.benefit &&
          handleCompanyDeductionDrawer();
        handleToastMessageOnSuccess(isEdit, type);
        refetchEmployeeData && refetchEmployeeData();
      },
    };

    switch (type) {
      case DEDUCTION_TYPES_NAME.benefit:
        onSaveBenefit(savePayload);
        break;
      case DEDUCTION_TYPES_NAME.garnishment:
        onSaveGarnishment(savePayload);
        break;
      default:
        onSaveDeduction(savePayload);
        break;
    }
  };

  const handleUnassignEmployeeDeduction = () => {
    const employeeDeductionId = filterCurrentDeduction?.id ?? 0;
    if (type === DEDUCTION_TYPES_NAME.benefit) {
      onDeleteBenefit(employeeDeductionId);
    } else if (type === DEDUCTION_TYPES_NAME.garnishment) {
      onDeleteGarnishment(employeeDeductionId);
    } else {
      onDeleteDeduction(employeeDeductionId);
    }
  };

  const handleUnassignDeductionModal = () => setIsUnassignDeduction(!isUnassignDeduction);

  const isSavingDeductionLoading =
    isCreatingBenefit ||
    isCreatingDeduction ||
    isCreatingGarnishment ||
    isUpdatingBenefit ||
    isUpdatingDeduction ||
    isUpdatingGarnishment;

  const isUnassignLoading = isUnAssigningDeduction || isUnassignBenefit || isUnassignGarnishment;

  const handleCloseDirtyModal = () => {
    setIsDirtyModalOpen(false);
  };

  const handleConfirmDirtyModal = () => {
    setIsDirtyModalOpen(false);
    onClose();
  };

  const handleDrawerClose = () => {
    if (isDirty) {
      setIsDirtyModalOpen(true);
    } else {
      onClose();
    }
  };

  const isSaveDeductionDisabled =
    !isDirty || (isCheckHqUser && data?.type === DEDUCTION_TYPES_NAME.garnishment && !employeeSsn);

  const handleEditDeduction = (payload: any) => {
    if (isCheckHqUser) {
      if (data?.type === DEDUCTION_TYPES_NAME.garnishment) {
        onSaveGarnishment({ isEdit: true, payload });
      } else if (data?.type === DEDUCTION_TYPES_NAME.custom) {
        onSaveDeduction({
          isEdit: true,
          payload,
          onSuccessHandler: () => refetchEmployeeData && refetchEmployeeData(),
        });
      }
    }
  };

  return {
    form,
    formRef,
    handleSubmit,
    isSaveDeductionDisabled,
    isUnassignDeduction,
    handleUnassignDeductionModal,
    handleUnassignEmployeeDeduction,
    handleEmployeeDeduction,
    isSavingDeductionLoading,
    isUnassignLoading,
    isNoticePopupOpen,
    setIsNoticePopupOpen,
    onNoticeCancel,
    isDirtyModalOpen,
    handleCloseDirtyModal,
    handleConfirmDirtyModal,
    handleDrawerClose,
    onError,
    isCheckHqUser,
    handleEditDeduction,
  };
};
export default useEmployeeDeductionDrawer;
