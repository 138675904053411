import { EMPLOYEE_PAYROLL_TYPES } from '@/utils/constants';

export const RUN_PAYROLL_TITLE_DESCRIPTION_MAP = {
  [EMPLOYEE_PAYROLL_TYPES.bonus]: {
    title: 'Employee Payroll: Bonus Only',
    subtitle: 'Distribute one-time bonuses for outstanding work.',
  },
  [EMPLOYEE_PAYROLL_TYPES.commission]: {
    title: 'Employee Payroll: Commission only',
    subtitle: 'Calculate commissions based on sales or targets.',
  },
  [EMPLOYEE_PAYROLL_TYPES.off_cycle]: {
    title: 'Off-cycle Payroll',
    subtitle: 'Make an unscheduled payment to employees outside regular pay periods',
  },
};

export const BONUS_PAYROLL_TYPE = {
  netPay: 'netPay',
  grossPay: 'grossPay',
};

export const OFF_CYCLE_PAYROLL_TAX_RATE_TYPE = {
  supplemental: 'supplemental',
  regular: 'regular',
};

export const PAYROLL_TYPE_TO_NAME_MAP = {
  [EMPLOYEE_PAYROLL_TYPES.test]: 'Test',
  [EMPLOYEE_PAYROLL_TYPES.regular]: 'Regular',
  [EMPLOYEE_PAYROLL_TYPES.bonus]: 'Bonus',
  [EMPLOYEE_PAYROLL_TYPES.commission]: 'Commission',
  [EMPLOYEE_PAYROLL_TYPES.off_cycle]: 'Off-cycle',
};

export const offCyclePayrollTypes: string[] = ['off_cycle', 'commission', 'bonus'];
