import { SparklesIcon } from '@heroicons/react/24/outline';
import { Controller } from 'react-hook-form';

import { ReactComponent as Illustration } from '@/assets/illustration/test-payroll-illustration.svg';
import { InputDatePicker } from '@/components';
import Button from '@/components/Button/Button';

import { twMerge } from 'tailwind-merge';
import { RunTestPayrollCardType } from './types';
import useRunTestPayrollCard from './useRunTestPayrollCard';

export const RunTestPayrollCard = ({
  isDone = false,
  payrollInfo,
  isIconShowing = true,
  isSecondaryButton = false,
  cardWrapperClass,
}: RunTestPayrollCardType) => {
  const {
    handleTestPayroll,
    form,
    isTestPayrollLoading,
    changeDateOfPayment,
    register,
    errors,
    disableDates,
    handleSubmit,
    payFrequency,
    formattedPeriod,
    componentRef,
    componentWidth,
    payDate,
    handleViewTestCalculations,
  } = useRunTestPayrollCard({ payroll: payrollInfo });

  return (
    <form className={twMerge('w-full', cardWrapperClass)} ref={componentRef}>
      <div className="flex w-full flex-col rounded-[12px] border border-gray-100 bg-[#F9F9F9] p-5 lg:p-[24px]">
        <span className="font-F37Bolton-Medium text-[28px]">
          {!isDone ? 'See how it works first! Run a test payroll' : 'Your test payroll'}
        </span>
        <span className="text-sm text-gray-400">
          See how quick and easy it will be to calculate your payroll each pay period.
        </span>
        <div
          className={` relative  mt-[20px] flex  justify-between rounded-[12px] border bg-white p-5`}
        >
          <div>
            <div className="flex gap-x-2">
              <div className="max-w-[80px] items-center rounded-[4px] bg-blue-500 px-[8px] py-[4px] text-[12px] text-white">
                Test Payroll
              </div>
              {!isDone && (
                <div className="flex max-w-[80px] items-center rounded-[4px] bg-emerald-100 px-[8px] py-[4px] text-[12px] text-white">
                  <SparklesIcon className="mr-1 size-4 text-emerald-500" />
                  <span className="text-emerald-500">New</span>
                </div>
              )}
            </div>
            <div className="mt-4 flex flex-wrap items-baseline">
              <span className="font-F37Bolton-Medium block whitespace-nowrap text-[20px]">
                {formattedPeriod}
              </span>
              <div className="mx-3 h-[14px] w-px bg-gray-200"></div>
              <span className="font-F37Bolton-Medium block text-[20px]">{payFrequency}</span>
            </div>
            {isDone ? (
              <div className="mt-4 flex flex-col">
                <span className="text-sm text-gray-400">Pay date</span>
                <span className="font-F37Bolton-Medium mt-1">{payDate}</span>
              </div>
            ) : (
              <Controller
                control={form.control}
                name={`dateOfPayment`}
                render={({ field: { value } }) => (
                  <div className={`pt-4 ${componentWidth > 550 ? 'w-[300px]' : 'w-full'}`}>
                    <InputDatePicker
                      label="Pay date"
                      value={{
                        startDate: value,
                        endDate: value,
                      }}
                      onChange={dateOfPayment => {
                        changeDateOfPayment(dateOfPayment);
                      }}
                      register={register('dateOfPayment')}
                      errorMessage={errors?.dateOfPayment?.message}
                      disabledDates={disableDates}
                      isNecessary
                      isSingle
                      popoverDirection="down"
                    />
                  </div>
                )}
              />
            )}

            {isDone ? (
              <Button
                onPress={() => {
                  handleViewTestCalculations(payrollInfo?.id);
                }}
                variant="default"
                children="View test calculations"
                parentClasses=" mt-[24px]  w-full lg:w-[190px]"
              />
            ) : (
              <Button
                onPress={handleSubmit(handleTestPayroll)}
                isLoading={isTestPayrollLoading}
                variant={isSecondaryButton ? 'default' : 'primary'}
                width="w-[160px]"
                parentClasses="mt-[24px]"
                children="Run test payroll"
              />
            )}
          </div>
          {isIconShowing && (
            <div
              className={`absolute bottom-0 right-0 hidden h-full overflow-hidden  ${
                componentWidth > 550 && 'md:block'
              }`}
            >
              <Illustration />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
