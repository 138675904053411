import { useState } from 'react';

import { UseConfirmEmployerTaxType } from './types';

const useConfirmEmployerTax = ({ toggleModal, refetch }: UseConfirmEmployerTaxType) => {
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const onSubmitTaxData = () => {
    toggleModal();
    if (refetch) {
      refetch();
    }
  };

  const closeModalHandler = () => {
    toggleModal();
    if (refetch) {
      refetch();
    }
  };

  const handleIsDirty = (isDirty: boolean) => {
    setIsFormDirty(isDirty);
  };

  const handleCloseDirtyModal = () => {
    setIsDirtyModalOpen(false);
  };

  const handleConfirmDirtyModal = () => {
    setIsDirtyModalOpen(false);
    closeModalHandler();
  };

  const handleDrawerClose = () => {
    if (isFormDirty) {
      setIsDirtyModalOpen(true);
    } else {
      closeModalHandler();
    }
  };

  const handleDirtyModelOpen = () => {
    if (isFormDirty) { setIsDirtyModalOpen(true); }

  }

  return {
    onSubmitTaxData,
    handleIsDirty,
    isDirtyModalOpen,
    handleCloseDirtyModal,
    handleConfirmDirtyModal,
    handleDrawerClose,
    handleDirtyModelOpen,
    isFormDirty,
    closeModalHandler
  };
};

export default useConfirmEmployerTax;
