import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { SubscriptionPlan } from '@/redux/dto/auth';

import SubscriptionPaymentContainer from '../SubscriptionPaymentContainer/SubscriptionPaymentContainer';

type PaymentMethodModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  availableProducts?: SubscriptionPlan[];
  parentOnCancel: () => void;
};

export const PaymentMethodModal = ({
  isOpen,
  onCancel,
  availableProducts,
  parentOnCancel,
}: PaymentMethodModalProps) => {
  return (
    <DialogComponent
      open={isOpen}
      onCancel={onCancel}
      height="h-auto max-h-[95vh]"
      modalClasses={`px-5 py-7 rounded-lg`}
    >
      <div className="absolute right-2 top-1.5 flex justify-end pr-[16px] ">
        <Button variant="icon" icon={CloseIcon} onPress={onCancel} />
      </div>

      <SubscriptionPaymentContainer
        selectedPlan="basic"
        plans={availableProducts}
        paymentSuccessHandler={parentOnCancel}
      />
    </DialogComponent>
  );
};
