import debounce from 'lodash.debounce';
import { forwardRef, useCallback, useMemo } from 'react';
import { Label, Text } from 'react-aria-components';
import CurrencyInput from 'react-currency-input-field';
import { twMerge } from 'tailwind-merge';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { InputDecimalsFieldProps } from './types';

const InputDecimalsField = forwardRef(
  (
    {
      title,
      subtitle,
      isRequired = false,
      isOptional = false,
      width,
      errorMessage,
      onChange,
      parentClasses,
      inputClasses,
      isDisabled,
      handleValueCheck,
      defaultValue,
      description,
      InputAdornment,
      adornmentClasses,
      customOnChange,
      showErrorMessage = true,
      labelEndAdornment = '',
      ...rest
    }: InputDecimalsFieldProps,
    ref,
  ) => {
    const handleInputChange = useCallback(
      debounce(() => {
        trackEvent('inputChange', {
          name: rest.name,
          currentPage: extractEventName(location.pathname),
        });
      }, 700),
      [],
    );

    const valueChangeHandler = (value: any) => {
      let formattedValue = value;
      if (handleValueCheck) {
        formattedValue = handleValueCheck(value);
      }
      handleInputChange();
      onChange && onChange(formattedValue ?? null);
      customOnChange && customOnChange(formattedValue ?? null);
    };

    const labelClassName = `font-F37Bolton-Medium text-[16px] gap-1 text-gray-900 flex items-center`;

    const inputClassName = useMemo(() => {
      let baseClass = twMerge(
        `rounded-md w-full placeholder:text-gray-200`,
        isDisabled
          ? 'border-gray-100 text-gray-100 placeholder:text-gray-100'
          : errorMessage
          ? 'border-red-500'
          : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:ring-[1px] focus:ring-blue-500',
        `shadow-sm `,
      );

      if (InputAdornment) {
        baseClass = `${baseClass} pl-[25px]`;
      }

      return `${baseClass} ${inputClasses ? inputClasses : ''}`;
    }, [errorMessage, isDisabled, InputAdornment, width, inputClasses]);

    return (
      <div
        className={`flex flex-col ${width ? width : 'w-full'} ${
          parentClasses ? parentClasses : 'gap-[8px]'
        }`}
      >
        {!!(title || labelEndAdornment || description) && (
          <div>
            {title && (
              <Label className={labelClassName}>
                {title}
                {labelEndAdornment && <>{labelEndAdornment}</>}
                {isRequired && <span className="text-red-500">*</span>}
                {isOptional && <span className="text-gray-300">(optional)</span>}
              </Label>
            )}
            {!!description && <p className="text-sm text-gray-400">{description}</p>}
          </div>
        )}
        <div className="relative h-full">
          <CurrencyInput
            onValueChange={e => {
              valueChangeHandler(e);
            }}
            onWheel={event => event.currentTarget.blur()}
            className={inputClassName}
            disabled={isDisabled}
            ref={ref}
            {...rest}
          />
          {InputAdornment && (
            <span
              className={`absolute left-[10px] top-[9px] ${isDisabled ? 'text-gray-100' : ''} ${
                adornmentClasses ?? ''
              }`}
            >
              {InputAdornment}
            </span>
          )}
        </div>
        {((showErrorMessage && errorMessage) || subtitle) && (
          <Text
            slot="description"
            className={`text-[14px] ${errorMessage ? 'text-red-500' : 'text-gray-400'}`}
          >
            {errorMessage ? errorMessage : subtitle}
          </Text>
        )}
      </div>
    );
  },
);

InputDecimalsField.displayName = 'InputDecimalsField';

export default InputDecimalsField;
