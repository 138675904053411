import { customTv } from '@/utils/tailwindMergeConfig';

export interface SeparatorProps {
  /**
   * Defines the color intensity or style of the separator.
   * It can be 'light', 'medium', or 'dark'.
   * @default "medium"
   */
  type: 'light' | 'medium' | 'dark';

  /**
   * Sets the thickness of the separator.
   * It can be either '1px' or '2px'.
   * @default "1px"
   */
  thickness: '1px' | '2px';

  /**
   * Optional additional class names to apply to the separator for further customization.
   * For example, spacing can be defined here.
   */
  className?: string;
}

export const Separator = ({ type, thickness, className }: SeparatorProps) => {
  const separatorVariant = customTv({
    base: `w-full`,
    variants: {
      type: {
        light: `bg-surface-dark-gray`,
        medium: `bg-surface-dark-gray-100`,
        dark: `bg-surface-dark-gray-200`,
      },
      thickness: {
        '1px': 'h-px',
        '2px': 'h-[2px]',
      },
    },
    defaultVariants: {
      type: 'medium',
      thickness: '1px',
    },
  });

  return <div className={separatorVariant({ type, thickness, class: className })}></div>;
};
