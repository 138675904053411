import { forwardRef } from 'react';
import { Label, Text } from 'react-aria-components';
import InputMask from 'react-input-mask';
import { twMerge } from 'tailwind-merge';

import { InputMaskTextFieldProps } from './types';
import useInputMaskTextField from './useInputMaskTextField';

const InputMaskTextField = forwardRef<HTMLInputElement, InputMaskTextFieldProps>(
  (
    {
      title,
      subtitle,
      placeholder,
      isRequired = false,
      isOptional = false,
      width,
      errorMessage,
      onChange,
      parentClasses,
      maskChar = '',
      mask = '',
      isDisabled = false,
      className,
      ...rest
    },
    ref,
  ) => {
    const { handleRef, handleInputChange, labelClassName, inputClassName } = useInputMaskTextField({
      ref,
      errorMessage,
      isDisabled,
      width,
      name: rest?.name || 'inputMask',
    });

    return (
      <div className={`flex flex-col ${width ? width : 'w-full'} ${parentClasses}`}>
        <Label className={labelClassName}>
          {title} {isRequired && <span className="text-red-500">*</span>}
          {isOptional && <span className="text-gray-300">(optional)</span>}
        </Label>
        <InputMask
          mask={mask}
          maskChar={maskChar}
          className={twMerge('disabled:placeholder:text-gray-100', className, inputClassName)}
          inputRef={el => handleRef(el)}
          value={rest.value}
          onChange={e => {
            handleInputChange();
            onChange && onChange(e.target.value);
          }}
          placeholder={placeholder}
          disabled={isDisabled}
          {...rest}
        />

        {(errorMessage || subtitle) && (
          <Text
            slot="description"
            className={`mt-[8px] text-[14px] ${errorMessage ? 'text-red-500' : 'text-gray-400'}`}
          >
            {errorMessage ? errorMessage : subtitle}
          </Text>
        )}
      </div>
    );
  },
);

InputMaskTextField.displayName = 'InputMaskTextField';

export default InputMaskTextField;
