import MissingStateTax from '../MissingStateTax/MissingStateTax';
import { AllMissingTaxesProps } from './types';
import useAllMissingTaxes from './useAllMissingTaxes';

const AllMissingTaxes = ({
  onSubmitTaxData,
  closeModalHandler,
  shouldShowAllFields,
  shouldShowAllTaxItems,
  confirmTaxForState,
  handleIsDirty,
  handleDirtyModelOpen,
  isFormDirty,
}: AllMissingTaxesProps) => {
  const { currentStateTax, isLoadingTaxInformation, onSubmitStateTaxData, refetchTaxInformation } =
    useAllMissingTaxes({
      onSubmitTaxData,
      confirmTaxForState,
    });

  return (
    <div>
      <MissingStateTax
        isFormDirty={isFormDirty}
        handleDirtyModelOpen={handleDirtyModelOpen}
        taxData={currentStateTax}
        isLoadingTaxInformation={isLoadingTaxInformation}
        onSubmitStateTaxData={onSubmitStateTaxData}
        closeModalHandler={closeModalHandler}
        shouldShowAllFields={shouldShowAllFields}
        shouldShowAllTaxItems={shouldShowAllTaxItems}
        refetchTaxInformation={refetchTaxInformation}
        handleIsDirty={handleIsDirty}
      />
    </div>
  );
};

export default AllMissingTaxes;
