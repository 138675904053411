import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useQueryHandlersProps } from './types';

const useQueryHandlers = <TData, TVariables = TData>({
  mutationParams,
  invalidateKeys,
}: useQueryHandlersProps<TData, TVariables>) => {
  const queryClient = useQueryClient();
  const onSuccess = (response: AxiosResponse<TData>, variables: TVariables, context: unknown) => {
    if (mutationParams?.onSuccess) {
      mutationParams?.onSuccess(response, variables, context);
    }
    invalidateKeys?.forEach(key => queryClient.invalidateQueries({ queryKey: key }));
  };

  return { onSuccess };
};

export default useQueryHandlers;
