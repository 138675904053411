import {
  FastSpringEmbeddedPaymentProvider,
  SubscriptionBillingCard,
  SubscriptionReminderModal,
  UpgradeSubscriptionPlanModal,
} from '@/components';
import DialogComponent from '@/components/Dialog/DialogComponent';
import RenewMultiStepModal from '@/components/shared/RenewMultiStepModal/RenewMultiStepModal';

import AddNewPaymentMethodDrawer from '../AddNewPaymentMethodDrawer/AddNewPaymentMethodDrawer';
import BillingHistory from '../BillingHistory/BillingHistory';
import { CancelSubscriptionReworked } from '../CancelSubscriptionModal/CancelSubscriptionReworked';
import SubscriptionPaymentMethodCard from '../SubscriptionPaymentMethodCard/SubscriptionPaymentMethodCard';
import { SubscriptionPlaceholder } from './components';
import useBillingCenter, { BillingCenterType } from './useBillingCenter';

const BillingCenter = ({ isManageBillingCenter, onCloseAccountModal }: BillingCenterType) => {
  const {
    subscriptionInformation,
    invoiceHistory,
    handleOpenCancelModal,
    isOpenedCancelModal,
    formattedSubscriptionData,
    closeReminderModalHandler,
    isSubscriptionReminderModalOpen,
    chooseSubscriptionPlanHandler,
    closeSubscriptionPlanHandler,
    isUpgradeSubscriptionPlanOpen,
    discountCode,
    setIsRenewPlanModalOpen,
    isRenewPlanModalOpen,
    discount,
    isAddNewPaymentMethodOpen,
    setIsAddNewPaymentMethodOpen,
  } = useBillingCenter({ isManageBillingCenter });

  return (
    <div className="max-w-[1130px] px-5 pb-5 md:px-8 md:pb-8">
      <div className="flex flex-col gap-5 md:flex-row">
        <div className="w-full">
          {formattedSubscriptionData ? (
            <SubscriptionBillingCard
              {...formattedSubscriptionData}
              onUpgradePlanClick={chooseSubscriptionPlanHandler}
              onRenewPlanClick={() => setIsRenewPlanModalOpen(true)}
              discountFromStorage={discountCode}
            />
          ) : (
            <SubscriptionPlaceholder onChooseSubscriptionPlan={onCloseAccountModal} />
          )}
        </div>
        <div className="w-full">
          <FastSpringEmbeddedPaymentProvider>
            <SubscriptionPaymentMethodCard
              onChooseSubscriptionPlan={() => setIsAddNewPaymentMethodOpen(true)}
            />
          </FastSpringEmbeddedPaymentProvider>
        </div>
      </div>

      <BillingHistory invoices={invoiceHistory} />
      {subscriptionInformation?.activeSubscriptionAgreement?.id && isOpenedCancelModal && (
        <DialogComponent
          open={isOpenedCancelModal}
          onCancel={handleOpenCancelModal}
          children={<CancelSubscriptionReworked onClose={handleOpenCancelModal} />}
          width="w-[100vw] lg:w-[610px]"
          height="h-[100vh] lg:max-h-[800px]"
        />
      )}

      {isSubscriptionReminderModalOpen && (
        <SubscriptionReminderModal
          isOpen={isSubscriptionReminderModalOpen}
          onCancel={closeReminderModalHandler}
        />
      )}
      <UpgradeSubscriptionPlanModal
        isOpen={isUpgradeSubscriptionPlanOpen}
        onCancel={closeSubscriptionPlanHandler}
        handleOpenCancelModal={handleOpenCancelModal}
      />
      <RenewMultiStepModal
        isOpen={isRenewPlanModalOpen}
        onCancel={closeSubscriptionPlanHandler}
        lastSubscriptionAgreement={
          subscriptionInformation?.subscriptionAgreements[
            subscriptionInformation?.subscriptionAgreements.length - 1
          ]
        }
        discountCode={discount}
      />
      <AddNewPaymentMethodDrawer
        isOpen={isAddNewPaymentMethodOpen}
        onClose={() => setIsAddNewPaymentMethodOpen(false)}
      />
    </div>
  );
};

export default BillingCenter;
