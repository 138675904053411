import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import { InputDatePicker } from '@/components';
import CheckHQWrapper from '@/components/shared/CheckHQWrapper/CheckHQWrapper';
import FormSelect from '@/components/ui/FormSelect';
import { DEDUCTION_TYPES_NAME } from '@/utils/constants';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { effectivePayPeriodType, hsaContributionLimitOptions } from '../constants';
import useEffectivePayPeriod from './useEffectivePayPeriod';

type EffectivePayPeriodProps = {
  companyEffectiveStart?: string;
};

const EffectivePayPeriod = ({ companyEffectiveStart }: EffectivePayPeriodProps) => {
  const { form, type, errors, isHSAContributionVisible, isCheckHqUser, companyBenefitStartDate } =
    useEffectivePayPeriod({ companyEffectiveStart });

  // Grid class calculation
  const gridClasses = (() => {
    switch (type) {
      case DEDUCTION_TYPES_NAME.custom:
        return 'grid grid-cols-1 md:grid-cols-2';
      case DEDUCTION_TYPES_NAME.benefit:
        return 'grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
      default:
        return 'flex flex-col sm:flex-row';
    }
  })();

  return (
    <div className={twMerge('gap-medium', gridClasses)}>
      {/* Benefit-specific form field */}
      {type === DEDUCTION_TYPES_NAME.benefit && (
        <CheckHQWrapper>
          <FormSelect
            isRequired
            shouldUnregister={false}
            width="w-full"
            title="Frequency"
            name="period"
            options={effectivePayPeriodType}
            parentClasses="w-full"
          />
        </CheckHQWrapper>
      )}

      {/* Effective Start Date */}
      <Controller
        control={form.control}
        name="effectiveStart"
        render={({ field: { onChange, value } }) => {
          return (
            <InputDatePicker
              label="Effective Start Date"
              value={{
                startDate: value,
                endDate: value,
              }}
              isNecessary
              placeholder="mm/dd/yyyy"
              isSingle
              onChange={({ startDate }) => onChange(startDate)}
              errorMessage={errors?.effectiveStart?.message}
              popoverDirection="down"
              minDate={type === DEDUCTION_TYPES_NAME.benefit ? companyBenefitStartDate : null}
              inputName="effectiveStart"
            />
          );
        }}
      />

      {/* Effective End Date */}
      {(type !== DEDUCTION_TYPES_NAME.custom || isCheckHqUser) && (
        <Controller
          control={form.control}
          name="effectiveEnd"
          render={({ field: { onChange, value } }) => (
            <InputDatePicker
              label="Effective End Date"
              className="flex flex-col whitespace-nowrap"
              value={{
                startDate: value,
                endDate: value,
              }}
              minDate={companyBenefitStartDate}
              placeholder="mm/dd/yyyy"
              isSingle
              onChange={({ endDate }) => onChange(endDate)}
              errorMessage={errors?.effectiveEnd?.message}
              isOptional
              popoverDirection="down"
            />
          )}
        />
      )}

      {/* HSA Contribution Limit */}
      {isHSAContributionVisible && (
        <FormSelect
          isRequired
          shouldUnregister={false}
          width="w-full"
          title="HSA contribution limit"
          name="hsaContributionLimit"
          options={hsaContributionLimitOptions}
          parentClasses="w-full"
          labelEndAdornment={
            <>
              <InformationCircleIcon
                className="size-5 cursor-pointer text-gray-500"
                data-tooltip-id="hsa-contribution-limit-info"
              />
              <Tooltip
                id="hsa-contribution-limit-info"
                className="!bg-navy-500 z-[1] !w-auto max-w-[240px] !rounded-lg !text-sm"
                content="HSA Deduction CheckHQ Type"
                place="top"
                clickable
              />
            </>
          }
        />
      )}
    </div>
  );
};

export default EffectivePayPeriod;
