import { useFormContext } from 'react-hook-form';

import { states } from '@/utils/helpers';

const useGarnishmentDeductionForm = () => {
  const form = useFormContext();
  const {
    control,
    formState: { errors },
  } = form;
  const formattedStates = states.map((state, index) => ({
    value: state[1],
    key: state[0],
    id: index + 1, // Assuming you want the id to be 1-based
  }));

  return {
    form,
    control,
    errors,
    formattedStates,
  };
};

export default useGarnishmentDeductionForm;
