import { FC } from 'react';

import DialogComponent from '@/components/Dialog/DialogComponent';
import { LoaderComponent } from '@/components/ui';
import { CalendlyModal } from '@/modules/myAccount/components/CancelSubscriptionModal/modals/CalendlyModal';

interface CustomCalendlyModalProps {
  handleCalendlyModal: () => void;
  isCalendlyLoading: boolean;
  isCalendlyModalOpen: boolean;
}

const CustomCalendlyModal: FC<CustomCalendlyModalProps> = ({
  handleCalendlyModal,
  isCalendlyLoading,
  isCalendlyModalOpen,
}) => {
  return (
    <>
      {isCalendlyModalOpen && (
        <DialogComponent
          open={isCalendlyModalOpen}
          onCancel={handleCalendlyModal}
          children={
            <div className="relative h-screen min-h-screen">
              {isCalendlyLoading && (
                <div className=" absolute flex min-h-screen w-full flex-col items-center justify-center">
                  <LoaderComponent />
                </div>
              )}

              <CalendlyModal onClose={handleCalendlyModal} />
            </div>
          }
          width="w-[100vw] lg:w-[610px]"
          height="h-[90vh] lg:max-h-[800px]"
        />
      )}
    </>
  );
};

export default CustomCalendlyModal;
