import { FormInputTextField } from '@/components/ui';
import FormSelect from '@/components/ui/FormSelect';

import CheckHQWrapper from '../../CheckHQWrapper/CheckHQWrapper';

type GarnishmentProps = {
  options: { name: string; value: string | number }[];
  isAssigned: boolean;
};

const GarnishmentFields = ({ isAssigned = false, options }: GarnishmentProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <CheckHQWrapper hideItem>
        {!isAssigned && (
          <FormSelect
            shouldUnregister={false}
            isRequired
            width="w-full"
            title="Garnishment Type"
            name="garnishmentType"
            options={options}
          />
        )}
        <FormInputTextField
          shouldUnregister={false}
          title="Garnishment Title"
          isRequired
          name="garnishmentTitle"
        />
      </CheckHQWrapper>
    </div>
  );
};

export default GarnishmentFields;
