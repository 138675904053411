import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postCall } from '@/api/axios';

import { POST_DEDUCTION } from './employeeApiRoutes';
import { usePostBenefitType } from './types';

const usePostDeduction = ({ companyId, employeeId }: usePostBenefitType) => {
  const queryClient = useQueryClient();

  const updateGarnishments = (data: any) =>
    postCall(POST_DEDUCTION({ companyId, employeeId }), data);

  return useMutation({
    mutationFn: updateGarnishments,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDeductions'] });
    },
  });
};

export default usePostDeduction;
