import { Icon, IconInfoTriangle, IconProps } from '@tabler/icons-react';
import { ForwardRefExoticComponent, RefAttributes } from 'react';

export const counterStatusMap: Record<
  number,
  {
    bgColor: string;
    textColor: string;
    icon?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  }
> = {
  3: { bgColor: 'bg-surface-primary', textColor: 'text-content-white' },
  2: { bgColor: 'bg-semantic-warning-yellow-hover', textColor: 'text-content-white' },
  1: {
    bgColor: 'bg-semantic-danger-red',
    textColor: 'text-content-white',
  },
  0: {
    icon: IconInfoTriangle,
    bgColor: 'bg-semantic-danger-red',
    textColor: 'text-content-white',
  },
};

export enum EMPLOYEE_STATUS {
  Active = 'active',
  Deactivated = 'deactivated',
}
