import { IconSquareRoundedCheckFilled } from '@tabler/icons-react';

import { ReactComponent as Illustration } from '@/assets/illustration/noPendingPayroll.svg';

import useNoPendingPayrollCard, { NoPendingPayrollCardProps } from './useNoPendingPayrollCard';

const NoPendingPayrollCard = ({
  payroll,
  isCheckHQAccountUnderReview = false,
  isCompanyHasBlockingTasks = false,
}: NoPendingPayrollCardProps) => {
  const { payrollTimeline, differenceInDays } = useNoPendingPayrollCard({
    payroll,
    isCheckHQAccountUnderReview,
    isCompanyHasBlockingTasks,
  });

  return (
    <div className="gap-medium p-medium bg-surface-light-gray rounded-small relative flex size-full h-full max-h-fit flex-col">
      <div className="gap-x-small flex ">
        <span>
          <IconSquareRoundedCheckFilled className="text-emerald-500" />
        </span>
        <span className="text-content-heading font-F37Bolton-Medium text-medium">
          Your next upcoming payroll will be ready to approve in {differenceInDays} days.
        </span>
      </div>
      <div className="gap-2x-large flex">
        {payrollTimeline?.map((item, i) => (
          <div className="gap-2x-small flex flex-col" key={i}>
            <span className="text-content-body-medium  text-x-small font-F37Bolton-Regular">
              {item?.label}
            </span>
            <span className="text-content-heading font-F37Bolton-Regular text-small">
              {item?.value}
            </span>
          </div>
        ))}
      </div>
      <div className="absolute right-[16px] top-[-21px] ">
        <Illustration className="hidden h-[135px] w-[150px] xl:block" />
      </div>
    </div>
  );
};

export default NoPendingPayrollCard;
