import { Status, StatusColorMap, Variant } from './type';

export const statusColorMap: StatusColorMap = {
  inprogress: {
    error: {
      bg: 'bg-semantic-danger-red-light-bg',
      text: 'text-semantic-danger-red text-2x-small font-F37Bolton-Medium',
      border: 'border-semantic-danger-red-light-border rounded-2x-small border',
      separator: 'border-semantic-danger-red-light-border border',
    },
    success: {
      bg: 'bg-primary-emerald-light-bg',
      text: 'text-primary-emerald-hover text-2x-small font-F37Bolton-Medium',
      border: 'border-primary-emerald-light-border rounded-2x-small border',
      separator: 'border-primary-emerald border',
    },
    warning: {
      bg: 'bg-semantic-warning-yellow-light-bg',
      text: 'text-semantic-warning-yellow-hover text-2x-small font-F37Bolton-Medium',
      border: 'border-semantic-warning-yellow-light-border rounded-2x-small border',
      separator: 'border-semantic-warning-yellow-light-border border',
    },
    draft: {
      bg: 'secondary-navy-light-bg',
      text: 'text-secondary-navy-hover text-2x-small font-F37Bolton-Medium',
      border: 'border-secondary-navy-light-border rounded-2x-small border',
      separator: 'border-semantic-navy-light border',
    },
  },
  completed: {
    error: {
      bg: 'bg-semantic-danger-red-hover',
      text: 'text-content-white text-2x-small font-F37Bolton-Medium',
      border: 'border-semantic-danger-red-light-border rounded-2x-small border',
      separator: 'border-semantic-danger-red border',
    },
    success: {
      bg: 'bg-primary-emerald',
      text: 'text-content-white text-2x-small font-F37Bolton-Medium',
      border: 'rounded-2x-small border',
      separator: 'border-primary-emerald border',
    },
    draft: {
      bg: 'secondary-navy-light-bg',
      text: 'text-secondary-navy-hover text-2x-small font-F37Bolton-Medium',
      border: 'border-secondary-navy-light-border rounded-2x-small border',
      separator: 'border-semantic-navy-light border',
    },
  },
  pending: {
    draft: {
      bg: 'secondary-navy-light-bg',
      text: 'text-secondary-navy-hover text-2x-small font-F37Bolton-Medium',
      border: 'border-secondary-navy-light-border rounded-2x-small border',
      separator: 'border-secondary-navy-light-border border',
    },
  },
  default: {
    default: {
      bg: '',
      text: '',
      border: '',
      separator: '',
    },
  },
};

export const getColor = (
  status: Status = 'default',
  variant: Variant = 'default',
): { border?: string; bg?: string; text?: string; title_border?: string } => {
  const statusColors = statusColorMap[status];
  const variantColors = statusColors[variant];
  return {
    border: variantColors?.separator,
    bg: variantColors?.bg,
    text: variantColors?.text,
    title_border: variantColors?.border,
  };
};
