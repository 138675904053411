import { IconInfoCircle } from '@tabler/icons-react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as Illustration } from '@/assets/illustration/discountBannerIllustration2.svg';
import Button from '@/components/Button/Button';
import { Status } from '@/components/ui';
import { Button as UpdatedButton } from '@/components/ui/designSystem/Button/Button';
import useDiscountInfo from '@/hooks/useDiscountInfo/useDiscountInfo';
import { Discount, SubscriptionAgreement } from '@/redux/dto/auth';

import { codeMessageMap } from '../UpgradeSubscriptionPlan/components/Banners/types';
import {
  PRICING_STRATEGY_TO_NAME_MAP,
  SUBSCRIPTION_STATUS_TO_STATUS_TYPE_MAP,
} from './utils/constants';

export type SubscriptionBillingCardProps = {
  /** Subscription Plan name */
  planName: string;

  /** Subscription status */
  status: 'active' | 'expired' | 'cancelled';

  /** Subscription plan description */
  description: string;

  /** Subscription plan price */
  price: number;

  /** Subscription plan strategy */
  pricingStrategy: 'monthly' | 'yearly';

  /** Should show the plan progress */
  isShowProgress: boolean;

  /** Show progress in employee or days */
  progressType: 'employee' | 'days';

  /** Current value of the progress in days or employee */
  currentValue: number;

  /** Max value of the plan in days or employee */
  maxValue: number;

  /** Should show the upgrade plan option */
  isUpgradePlanAvailable: boolean;

  /** Upgrade plan click handler */
  onUpgradePlanClick?: () => void;

  /** Is free trial active */
  isFreeTrial: boolean;

  /** free trial end date when free trial is active */
  freeTrialEndDate?: string | null;

  /** Next invoice date when user subscribed for the plan */
  nextInvoiceDate?: string | null;

  onRenewPlanClick: () => void;

  /** Discount codes/coupons user has applied */
  discountCodes: Discount[];

  /** Defines whether user cancelled subscription or not */
  scheduledToBeCancelled: boolean;

  /** IsSubscribed plan has free trial (Not the 3-month-free trial subscription) */
  isSubscribedPlanHasFreeTrial: boolean;
  freeTrialDaysLeftInSubscribedPlan: number;

  /** Array of subscription agreements for checking if user has any upcoming subscription */
  subscriptionAgreements: SubscriptionAgreement[];

  /** Active subscription agreement */
  subscriptionData: SubscriptionAgreement;
};

const SubscriptionBillingCard = ({
  planName,
  status,
  description,
  price,
  amountPerWorker,
  pricingStrategy,
  isShowProgress,
  currentValue,
  isFreeTrial,
  maxValue,
  progressType,
  freeTrialEndDate,
  nextInvoiceDate,
  isUpgradePlanAvailable,
  onUpgradePlanClick,
  onRenewPlanClick,
  discountCodes,
  scheduledToBeCancelled,
  freeTrialDaysLeftInSubscribedPlan,
  isSubscribedPlanHasFreeTrial,
  subscriptionAgreements,
  subscriptionData,
  discountFromStorage,
}: SubscriptionBillingCardProps) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const formattedMaxValue = maxValue >= 0 ? maxValue : 0;

  const { verifyDiscount } = useDiscountInfo();

  // formate current value for the days variant to not show current value more than max value
  const formattedCurrentValue =
    progressType === 'days'
      ? currentValue > formattedMaxValue
        ? formattedMaxValue
        : currentValue
      : currentValue;

  const progress = ((currentValue ?? 0) / formattedMaxValue) * 100;

  const formattedProgress = progress > 100 ? 100 : parseInt(progress.toString());

  const today = moment.utc().startOf('day');

  const hasUpcomingSubscriptions = subscriptionAgreements.some((sub: SubscriptionAgreement) => {
    // Excluding active subscription agreement
    if (sub.id === subscriptionData.id) return false;

    const startDate = moment.utc(sub.startDate).startOf('day');

    return startDate.isSameOrAfter(today) && ['active', 'trialing'].includes(sub.status);
  });

  const endDate = new Date(subscriptionData.endDate ?? subscriptionData.nextProcessingDate);

  const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));

  const isOneDayBeforeEnd = daysLeft <= 1 && progressType === 'days';

  const messages = codeMessageMap[discountFromStorage] || codeMessageMap.DEFAULT;

  const handleBannerButton = () => {
    return status === 'cancelled' ? onRenewPlanClick() : onUpgradePlanClick && onUpgradePlanClick();
  };

  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (bannerRef.current) {
        setIsSmallScreen(bannerRef.current.offsetWidth < 450);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      (isOneDayBeforeEnd || scheduledToBeCancelled || status === 'expired') &&
      discountCodes.length === 0 &&
      !hasUpcomingSubscriptions
    ) {
      verifyDiscount({ code: 'FULLSERVICE50-6' });
    }
  }, [
    isOneDayBeforeEnd,
    scheduledToBeCancelled,
    status,
    discountCodes.length,
    hasUpcomingSubscriptions,
    verifyDiscount,
  ]);

  return (
    <div
      className={'flex w-full flex-col gap-[18px] rounded-lg border border-gray-100 bg-white p-4'}
    >
      <Tooltip
        place="top"
        className="!bg-navy-500 !text-small z-[3] !w-auto max-w-[225px] !rounded-lg font-normal"
        id="informational-tooltip"
        content="You will be charged this amount per month only for employees or contractors that were paid during the billing cycle"
        opacity={1}
      />
      <div className="flex flex-row items-start justify-between gap-4">
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex flex-wrap items-center gap-1.5">
            <span className="font-F37Bolton-Medium text-sm capitalize">{planName}</span>
            <Status
              variant={SUBSCRIPTION_STATUS_TO_STATUS_TYPE_MAP[status]}
              className="text-xs capitalize"
            >
              {status === 'cancelled' ? 'Account terminated' : status}
            </Status>
          </div>
        </div>

        {discountCodes?.length ? (
          <div className="flex items-end justify-end">
            <div className="flex flex-col items-end">
              <div className="flex items-center">
                <span className="font-F37Bolton-Medium mr-1 text-gray-300 line-through">
                  ${Number(price ?? 0).toFixed(2)}
                </span>
                <span className="font-F37Bolton-Medium">
                  ${Number(price * ((100 - discountCodes[0]?.discount) / 100) ?? 0).toFixed(2)}
                </span>
                <span className="">/{PRICING_STRATEGY_TO_NAME_MAP[pricingStrategy]}</span>
              </div>
              {amountPerWorker && (
                <div className="mt-1 flex items-center">
                  <span className="text-small">
                    Plus <span className="text-gray-300 line-through">${amountPerWorker}</span> $
                    {(amountPerWorker * (100 - discountCodes[0]?.discount)) / 100} / paid worker
                  </span>
                  <IconInfoCircle
                    className="text-icon-disabled size-medium ml-1 cursor-pointer"
                    data-tooltip-id="informational-tooltip"
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-end justify-end">
            <span className="font-F37Bolton-Medium text-medium">
              ${Number(price ?? 0).toFixed(2)} / month
            </span>
            {amountPerWorker && (
              <div className="flex items-center">
                <span className="text-small">{`Plus ${amountPerWorker}$ / paid worker`}</span>
                <IconInfoCircle
                  className="text-icon-disabled size-medium ml-1 cursor-pointer"
                  data-tooltip-id="informational-tooltip"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <hr />

      <div className={twMerge(`flex flex-wrap justify-between gap-y-2`)}>
        <div className="text-sm">
          {isFreeTrial ? (
            <div className="flex gap-1">
              {moment(freeTrialEndDate).isValid() && (
                <>
                  <span className="text-gray-300 sm:hidden">Ends on</span>
                  <span className="font-F37Bolton-Medium hidden text-gray-300 sm:flex">
                    Free trial ends on
                  </span>
                  <span className="font-F37Bolton-Medium">
                    {moment(freeTrialEndDate).format('MMM DD, YYYY')}
                  </span>
                </>
              )}
            </div>
          ) : (
            moment(nextInvoiceDate).isValid() &&
            (status === 'cancelled' ? (
              <div className="flex gap-1">
                <span className="text-gray-300">Plan active until</span>
                <span>{moment(nextInvoiceDate).format('MMM DD, YYYY')}</span>
              </div>
            ) : (
              <div className="flex gap-1">
                <span className="text-gray-300">Next invoice:</span>
                <span>{moment(nextInvoiceDate).format('MMM DD, YYYY')}</span>
              </div>
            ))
          )}
        </div>

        <div className="font-F37Bolton-Medium flex items-center gap-1 text-emerald-500">
          <Button
            variant="text"
            height="h-auto"
            parentClasses="text-sm"
            onPress={handleBannerButton}
          >
            {status === 'cancelled' ? 'Renew plan' : 'Manage plans'}
          </Button>
        </div>
        {(isOneDayBeforeEnd ||
          scheduledToBeCancelled ||
          status === 'expired' ||
          discountFromStorage) &&
          discountCodes.length === 0 &&
          !hasUpcomingSubscriptions && (
            <div
              className="bg-primary-emerald-light-bg rounded-small p-medium relative flex w-full flex-col"
              ref={bannerRef}
            >
              <span className="text-x-large text-content-primary font-F37Bolton-Bold">
                {messages.title}
              </span>
              <span className="text-small font-F37Bolton-Medium text-content-body-strong">
                {messages.subtitle}
              </span>
              <UpdatedButton
                variant="primary"
                size="x-small"
                children={scheduledToBeCancelled ? 'Renew plan' : 'Subscribe'}
                className="mt-medium"
                onPress={() => {
                  verifyDiscount({ code: 'FULLSERVICE50-6' });
                  onRenewPlanClick();
                }}
              />
              {!isSmallScreen && <Illustration className="absolute bottom-0 right-0" />}
            </div>
          )}
      </div>
    </div>
  );
};

export default SubscriptionBillingCard;
