import {
  IconCalendarDot,
  IconCheck,
  IconChevronsRight,
  IconCoin,
  IconGift,
  IconInfoCircle,
  IconSquarePercentage,
} from '@tabler/icons-react';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from 'react-tooltip';

import { StatusBadge } from '@/components';
import { useAccountInfo } from '@/hooks';
import { EMPLOYEE_PAYROLL_TYPES, payrollType } from '@/utils/constants';
import { formatApprovalDeadline, payFrequencyByValue } from '@/utils/helpers';
import { statusSeverityMap } from '@/utils/statusSeverity';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { CheckHQHistoryStatusType, PayrollHistory } from '../../types';
import PayrollHistoryCardLink from '../PayrollHistoryCardListing/PayrollHistoryCardLink';

const CheckHQPayrollFieldCell = ({
  label,
  value,
  className,
  children,
}: {
  label: string;
  value: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}) => (
  <div className="flex flex-col">
    <div className="text-content-body-medium font-F37Bolton-Regular text-x-small text-nowrap">
      {label}
    </div>
    <div className="text-content-heading font-F37Bolton-Regular text-x-small flex text-nowrap">
      {value}
      {children}
    </div>
  </div>
);

type CheckHQPayrollHistoriesMobileViewProps = {
  payrollHistory: PayrollHistory[];
  historyStatus: CheckHQHistoryStatusType;
  deletePayrollHistory?: any;
};

const CheckHQPayrollHistoriesMobileView = ({
  payrollHistory,
  historyStatus,
  deletePayrollHistory,
}: CheckHQPayrollHistoriesMobileViewProps) => {
  const { isCheckHqUser } = useAccountInfo();

  const isAllOffCycleDraftPayroll = payrollHistory.every(({ status, type }) => status === 0);
  return (
    <>
      {Array.isArray(payrollHistory) &&
        payrollHistory.map(
          (
            {
              id,
              type,
              status,
              payPeriodStartDate,
              payPeriodEndDate,
              payDate,
              approvalDeadline,
              totalPayroll,
              skipReason,
              frequency,
              payFrequency,
              approvedAt,
              subType,
            },
            index,
          ) => {
            const statusInfo = historyStatus[status];
            const startDate = moment(payPeriodStartDate);
            const endDate = moment(payPeriodEndDate);

            const payPeriod =
              payPeriodStartDate && payPeriodEndDate
                ? `${startDate.format(`MMM DD`)} - ${endDate.format('MMM DD, YYYY')}`
                : '--';

            const formattedPayDate =
              payDate && moment(payDate).isValid() ? moment(payDate).format('MMM DD, YYYY') : '-';

            const formattedApproveDate =
              approvedAt && moment(approvedAt).isValid()
                ? `${moment(approvedAt).format('MMM DD, YY')}`
                : '-';

            const formattedApprovedDeadline = formatApprovalDeadline(
              approvalDeadline,
              undefined,
              true,
            );

            const statusLabel =
              isCheckHqUser && statusInfo?.label === 'Approved' ? 'Paid' : statusInfo?.label;

            const isPayDatePassedApprovedDeadline = moment(moment()).isAfter(payDate);

            return (
              <div
                key={index}
                className="p-medium gap-medium flex flex-col rounded-xl border border-gray-100"
              >
                {isPayDatePassedApprovedDeadline && (
                  <Tooltip
                    content="Approval deadline has crossed for the pay date you added. Change the pay date when you run this payroll"
                    id="payDate-delayed-tooltip"
                    place="top"
                    className="!bg-surface-dark !text-small p-x-small z-[1] !w-[300px] !rounded-lg border border-gray-100 !leading-5"
                  />
                )}
                {/* Payroll Type Header */}
                <div className="gap-x-small flex items-start text-center">
                  <div
                    className={twMerge(
                      'rounded-2x-small size-2x-large flex items-center justify-center text-center',
                      type === EMPLOYEE_PAYROLL_TYPES.regular
                        ? 'bg-primary-emerald-light-bg'
                        : 'bg-secondary-navy-light-bg',
                    )}
                  >
                    {type === EMPLOYEE_PAYROLL_TYPES.off_cycle ? (
                      <IconCalendarDot className="size-large" />
                    ) : type === EMPLOYEE_PAYROLL_TYPES.bonus ? (
                      <IconGift className="size-large" />
                    ) : type === EMPLOYEE_PAYROLL_TYPES.commission ? (
                      <IconSquarePercentage className="size-large" />
                    ) : (
                      <IconCoin className="text-icon-primary size-large" />
                    )}
                  </div>
                  <div className="text-content-heading font-F37Bolton-Medium text-small text-start">
                    {(!type || type == EMPLOYEE_PAYROLL_TYPES.regular) &&
                      `${payFrequencyByValue(frequency || payFrequency)} Payroll`}
                    {type !== EMPLOYEE_PAYROLL_TYPES.regular && payrollType[subType || type]}
                  </div>
                </div>

                {/* Grid Layout for Responsive Columns */}
                <div className="gap-x-large gap-y-small p-small bg-surface-light-gray rounded-x-small grid grid-cols-2 sm:grid-cols-3">
                  <CheckHQPayrollFieldCell label="Pay period" value={payPeriod || '--'} />

                  <CheckHQPayrollFieldCell
                    label={
                      isAllOffCycleDraftPayroll
                        ? 'Approval deadline'
                        : status === 3
                        ? 'Skipped on'
                        : 'Approved on'
                    }
                    value={
                      isAllOffCycleDraftPayroll
                        ? formattedApprovedDeadline || '--'
                        : formattedApproveDate || '--'
                    }
                  />

                  <CheckHQPayrollFieldCell
                    label="Pay date"
                    value={status === 4 || status === 3 ? '--' : formattedPayDate || '--'}
                    children={
                      isPayDatePassedApprovedDeadline &&
                      isAllOffCycleDraftPayroll && (
                        <div data-tooltip-id="payDate-delayed-tooltip" className="ml-[3px]">
                          <IconInfoCircle className="text-semantic-danger-red-hover size-medium cursor-pointer" />
                        </div>
                      )
                    }
                  />
                  <CheckHQPayrollFieldCell
                    label="Status"
                    value={
                      <StatusBadge
                        text={statusLabel.toUpperCase()}
                        startIcon={
                          status === 1 ? (
                            <IconCheck className="size-medium" />
                          ) : (
                            status === 3 && <IconChevronsRight className="size-medium" />
                          )
                        }
                        severity={statusSeverityMap[statusInfo?.variant]}
                        tooltipContent={
                          status === 4
                            ? `You've successfully approved this payroll. The withdrawal amount will be debited on ${formattedApproveDate} and employees are set to be paid on ${formattedPayDate}`
                            : status === 5
                            ? `This payroll is currently being processed, and employees are set to be paid on ${formattedPayDate}`
                            : status === 6
                            ? 'We were not able to process this payroll due to an issue with your company bank account. Payroll and taxes were not paid. Please contact support to resolve this issue as soon as possible.'
                            : status === 7
                            ? 'One or more employees were not paid due to an issue with their connected bank accounts. Please contact support as soon as possible to resolve this issue.'
                            : status === 3
                            ? `No employees were paid as this payroll was skipped. Reason for skipping: “${
                                skipReason || ''
                              }”`
                            : ''
                        }
                        tooltipId={status !== 1 ? `payroll-status-tooltip-${id}` : undefined}
                      />
                    }
                  />
                  <CheckHQPayrollFieldCell
                    label="Total payroll"
                    value={
                      <NumericFormat
                        value={totalPayroll || 0.0}
                        thousandSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={value => <span>${value}</span>}
                      />
                    }
                  />
                </div>

                {/* Action Link */}
                <PayrollHistoryCardLink
                  payrollId={id}
                  payrollType={type}
                  status={status}
                  isAllOffCycleDraftPayroll={isAllOffCycleDraftPayroll}
                  deletePayrollHistory={deletePayrollHistory}
                />
              </div>
            );
          },
        )}
    </>
  );
};

export default CheckHQPayrollHistoriesMobileView;
