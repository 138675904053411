import { queryEndpoints as payStubsEndPoints } from '@/api/payStubs/endpoints';
import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  teamMembers: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'team-member'],
    url: `/company/${companyId}/team-members`,
  }),
  runnableActiveEmployee: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'runnable-active-employee'],
    url: `/company/${companyId}/pay-employee/get-active`,
  }),
  getPayrollHistory: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'payroll-history'],
    url: `/company/${companyId}/employees/payroll-history`,
  }),
  getEmployeePayStubsDownloadAll: (companyId: number, payStubId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payStubId', payStubId],
    url: `/company/${companyId}/employees/payroll-history/${payStubId}/download/pay-stubs`,
  }),
  getPayStubDetail: (companyId: number, payStubId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payStubId', payStubId],
    url: `/company/${companyId}/employees/payroll-history/${payStubId}/items`,
  }),
  getEmployeeDetail: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'pay-history', 'employee', employeeId],
    url: `/company/${companyId}/employee/${employeeId}`,
  }),
  payTypes: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'pay-types'],
    url: `company/${companyId}/earning-settings`,
  }),
  timeOffPolicies: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'time-off-policies'],
    url: `/company/${companyId}/time-off-policies`,
  }),
  getDeductions: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'company-deductions'],
    url: `/company/${companyId}/deduction`,
  }),
  getPreviousNextPayDates: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'previous-next-paydates'],
    url: `/company/${companyId}/employee/previous-next-paydates`,
  }),
  getPayrollCheck: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'payroll-check'],
    url: `/company/${companyId}/payroll-check`,
  }),
  getRunnablePayroll: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'get-runnable'],
    url: `/company/${companyId}/pay-employee/get-runnable`,
  }),
  getPayrollInfo: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'payroll-info'],
    url: `/company/${companyId}/payroll-info`,
  }),
  getTaxDepositSummary: (companyId: number, payrollId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', payrollId, 'tax-deposits'],
    url: `/company/${companyId}/payroll/${payrollId}/tax-deposits`,
  }),
  downloadEmployeePayroll: (companyId: number, payrollId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', payrollId, 'cash-requirement-report'],
    url: `/company/${companyId}/payroll/${payrollId}/cash-requirement-report`,
  }),
  downloadEmployeePayrollJournal: (companyId: number, payrollId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll', payrollId, 'journal'],
    url: `/company/${companyId}/payroll/${payrollId}/journal`,
  }),
  downloadSemiWeeklyReport: (companyId: number, payrollId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'tax-liability', 'semi-weekly', 'payrollId', payrollId],
    url: `company/${companyId}/tax-liability-report/semi-weekly/download/${payrollId}`,
  }),
};

export const mutationEndpoints = {
  approvePayrollHistories: (companyId: number): MutationEndpoint => ({
    mutationKey: ['approvePayrollHistories', companyId],
    url: `company/${companyId}/employee-manual-payroll-histories`,
    invalidateKeys: [],
  }),
  savePayroll: (companyId: number, payrollId: number): MutationEndpoint => ({
    mutationKey: [],
    url: `company/${companyId}/pay-employee/${payrollId}`,
    invalidateKeys: [
      // Adding manual key for the getViewPayStubDetail queryKey to invalidate cached query of all the paystubs
      payStubsEndPoints.getViewPayStubDetail(companyId).queryKey.slice(0, 3),
    ],
  }),
  updatePayroll: (companyId: number, payrollId: number): MutationEndpoint => ({
    mutationKey: [],
    url: `company/${companyId}/pay-employee/${payrollId}/update`,
    invalidateKeys: [],
  }),
  skipPayroll: (companyId: number): MutationEndpoint => ({
    mutationKey: [],
    url: `company/${companyId}/pay-employee/skip-payroll`,
    invalidateKeys: [
      queryEndpoints.getRunnablePayroll(companyId).queryKey,
      queryEndpoints.getPayrollHistory(companyId).queryKey,
    ],
  }),
  generatePayHistory: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: [
      'company',
      companyId,
      'pay-history',
      'employeeId',
      employeeId,
      'generate-pay-history',
    ],
    url: `employee/${employeeId}/manual-payroll-history`,
    invalidateKeys: [queryEndpoints.getEmployeeDetail(companyId, employeeId).queryKey],
  }),
  saveEmployeePayHistory: (
    companyId: number,
    employeeId: number,
    payHistoryId: number,
  ): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-history', payHistoryId, 'save-pay-history'],
    url: `manual-payroll-history/${payHistoryId}`,
    invalidateKeys: [
      queryEndpoints.getEmployeeDetail(companyId, employeeId).queryKey,
      queryEndpoints.teamMembers(companyId).queryKey,
    ],
  }),
  savePayTypes: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'run-payroll', 'pay-types'],
    url: `company/${companyId}/earning-settings`,
    invalidateKeys: [queryEndpoints.payTypes(companyId).queryKey],
  }),
  createOffCyclePayroll: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'run-payroll', 'create-off-cycle--payroll'],
    url: `company/${companyId}/pay-employee`,
    invalidateKeys: [],
  }),
  approvePayroll: (companyId: number, payrollId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'run-payroll', 'payroll', payrollId, 'approve'],
    url: `/company/${companyId}/payroll/${payrollId}/approve`,
    invalidateKeys: [],
  }),
  reOpenPayroll: (companyId: number, payrollId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'run-payroll', 'payroll', payrollId, 're-open'],
    url: `/company/${companyId}/payroll/${payrollId}/re-open`,
    invalidateKeys: [],
  }),
  deletePayrollHistory: (companyId: number, payrollId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-history', payrollId, 'delete'],
    url: `company/${companyId}/employees/payroll-history/${payrollId}`,
    invalidateKeys: [queryEndpoints.getPayrollHistory(companyId).queryKey],
  }),
};
