import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { postCall } from '@/api/axios';
import { useAccountInfo } from '@/hooks';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';
import { DocumentVersionType } from '@/redux/dto/auth';

import { mutationEndpoints } from './endpoints';

type AxiosDocumentVersionResponseType = AxiosResponse<DocumentVersionType>;

const usePostTermsAcceptance = ({
  mutationParams,
}: {
  mutationParams?: UseMutationOptions<AxiosDocumentVersionResponseType, Error, DocumentVersionType>;
} = {}) => {
  const { account } = useAccountInfo();

  const accountId = account?.id;
  const { invalidateKeys, mutationKey, url } = mutationEndpoints.updateTerms(accountId);

  const { onSuccess } = useQueryHandlers({
    invalidateKeys,
    mutationParams,
  });

  return useMutation({
    mutationKey,
    mutationFn: data => postCall(url, data),
    onSuccess,
  });
};

export default usePostTermsAcceptance;
