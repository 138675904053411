import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { Status } from '@/components/ui';

import { PRICING_STRATEGY_TO_NAME_MAP } from '../utils/constants';

type SubscriptionPlanProps = {
  isSelected: boolean;
  isRecommended?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  planName: string;
  amount: number;
  description: string;
  maxEmployee: number;
  pricingStrategy?: 'monthly' | 'yearly';
  onSelectSubscriptionPlan: () => void;
  discount?: number | null;
};

const subscriptionPlan = tv({
  slots: {
    base: 'flex w-full flex-col gap-1 cursor-pointer rounded-lg border px-2.5 py-3 sm:p-6',
    planTitleSlot: 'font-F37Bolton-Medium text-lg capitalize',
    descriptionSlot: 'text-sm text-gray-400',
    amountSlot: 'font-F37Bolton-Medium text-lg',
    pricingStrategySlot: 'text-gray-300',
    employeeCountSlot: 'text-xs rounded-x-small',
  },
  variants: {
    isSelected: {
      true: {
        base: 'border-emerald-500',
      },
    },
    isDisabled: {
      true: {},
    },
    discount: {
      true: {},
    },
  },
  compoundSlots: [
    {
      isDisabled: true,
      slots: [
        'base',
        'planTitleSlot',
        'descriptionSlot',
        'amountSlot',
        'pricingStrategySlot',
        'employeeCountSlot',
      ],
      class: ['text-gray-300'],
    },
    {
      isDisabled: true,
      slots: ['employeeCountSlot'],
      class: ['bg-gray-50'],
    },
    { discount: true, slots: [`amountSlot`], class: 'text-gray-300 line-through' },
  ],
});

const SubscriptionPlan = ({
  isSelected,
  isRecommended,
  isDisabled,
  planName,
  amount,
  description,
  maxEmployee,
  pricingStrategy = 'monthly',
  onSelectSubscriptionPlan,
  isActive,
  discount,
}: SubscriptionPlanProps) => {
  const formattedPricingStrategy = PRICING_STRATEGY_TO_NAME_MAP[pricingStrategy];
  const formattedPlanName = planName?.replace('-', ' ');

  const {
    base,
    planTitleSlot,
    descriptionSlot,
    amountSlot,
    pricingStrategySlot,
    employeeCountSlot,
  } = subscriptionPlan();

  return (
    <>
      <Tooltip
        className="max-w-60 !rounded-lg text-sm"
        clickable
        id={`show-disabled-plan-${planName}-message`}
        content={
          isActive
            ? 'This is current active plan.'
            : 'This plan is not available for your current employee count.'
        }
        hidden={!isDisabled && !isActive}
      />

      <div
        data-tooltip-id={`show-disabled-plan-${planName}-message`}
        className={twMerge(base({ isSelected, isDisabled }))}
        onClick={() => onSelectSubscriptionPlan()}
      >
        <div className="flex items-center">
          <div className="flex w-full flex-1 flex-wrap items-center justify-between">
            <div className="flex flex-col items-start sm:w-1/2">
              {isRecommended && (
                <div className="rounded-md bg-emerald-500 px-2.5 py-1 text-[10px] uppercase text-white">
                  Recommended
                </div>
              )}
              <div className="rounded-2x-small text-surface-white text-2x-small bg-emerald-500 px-3 py-1">
                Current plan
              </div>
              <p className={planTitleSlot({ isDisabled })}>{formattedPlanName}</p>
              <p className={descriptionSlot({ isDisabled, className: 'hidden xl:block' })}>
                {discount ? ` Up to ${maxEmployee} employees` : description}
              </p>
            </div>
            <div className="flex items-center justify-end gap-4 sm:w-1/2 sm:gap-6">
              <div>
                <div>
                  {!!discount && (
                    <span className="text-large font-F37Bolton-Medium mr-1">
                      ${(amount * ((100 - discount) / 100)).toFixed(2)}
                    </span>
                  )}
                  <span className={amountSlot({ isDisabled, discount: !!discount })}>
                    ${amount}
                  </span>{' '}
                  <span className={pricingStrategySlot({ isDisabled })}>
                    / {formattedPricingStrategy}
                  </span>
                </div>
                <div className="flex justify-end">
                  {maxEmployee > 0 && (
                    <Status variant="completed" className={employeeCountSlot({ isDisabled })}>
                      {discount
                        ? `Save $${Math.ceil(
                            (amount - amount * ((100 - discount) / 100)) * 12,
                          )} in 12 months`
                        : `up to ${maxEmployee} employees`}
                    </Status>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4 sm:ml-6">
            <div className="size-6 rounded-full border border-gray-300 p-1">
              <div className={twMerge('size-full rounded-full', isSelected && 'bg-emerald-500')} />
            </div>
          </div>
        </div>
        <p className={descriptionSlot({ isDisabled, className: 'xl:hidden' })}>
          {discount ? ` Up to ${maxEmployee} employees` : description}
        </p>
      </div>
    </>
  );
};

export default SubscriptionPlan;
