import { ReactComponent as SetupTaxInfo } from '@/assets/illustration/setupTax.svg';
import Button from '@/components/Button/Button';

import { SetupTaxInformationProps } from './types';

const SetupTaxInformation = ({ onSubmit, stateName }: SetupTaxInformationProps) => {
  return (
    <div className="flex flex-col items-center gap-5">
      <SetupTaxInfo />
      <div>
        <p className="font-F37Bolton-Medium text-center text-2xl">
          Let&apos;s set up the {stateName ?? ''} State and Federal tax for your business
        </p>
        <p className="mt-2 text-center text-sm text-gray-400">
          Based on your business location, the following are the taxes we will need to set up
        </p>
      </div>
      <Button variant="primary" width="w-full" onPress={() => onSubmit()}>
        Let&apos;s start!
      </Button>
    </div>
  );
};

export default SetupTaxInformation;
