import { MutationOptions, useMutation } from '@tanstack/react-query';

import { postCall } from '@/api/axios';

import { mutationEndpoints } from './endpoints';

// type UpdatePasswordOptions = MutationOptions<unknown, Error, unknown, unknown>;
type UpdatePasswordOptions = Omit<
  MutationOptions<unknown, Error, unknown, unknown>,
  'mutationFn' | 'mutationKey'
>;

interface UseUpdatePasswordParams {
  mutationParams?: UpdatePasswordOptions;
}

export const useUpdatePassword = ({ mutationParams }: UseUpdatePasswordParams) => {
  const { mutationKey, url } = mutationEndpoints.updateAccountPassword();

  return useMutation({
    mutationKey,
    mutationFn: data => postCall(url, data),
    ...mutationParams,
  });
};
