import { CheckHQWrapper, FormInputDecimalsField } from '@/components';
import FormSelect from '@/components/ui/FormSelect';

import { CheckHQContributionCalculationType, ContributionCalculationType } from '../constants';
import useEmployeeContribution from './useEmployeeContribution';

const EmployeeContribution = () => {
  const {
    shouldShowContributionFields,
    contributionFieldName,
    contributionAdornment,
    contributionFieldTitle,
    period,
    isCheckHqUser,
  } = useEmployeeContribution();

  return (
    <div>
      <h4 className="font-F37Bolton-Medium text-lg">Employee contribution</h4>
      <p className="text-sm text-gray-400">
        This is the amount to be deducted from employees’ scheduled payments{' '}
      </p>
      <div className="gap-x-large pt-medium flex" key={period}>
        {period === 'pay_period' && (
          <FormSelect
            isRequired
            width="w-full"
            title="Calculated as"
            name="calculatedAs"
            options={
              isCheckHqUser ? CheckHQContributionCalculationType : ContributionCalculationType
            }
            parentClasses="max-w-[328px]"
            shouldUnregister={false}
          />
        )}
        {period === 'monthly' && (
          <FormInputDecimalsField
            shouldUnregister={false}
            title="Amount per month"
            name="amountPerPayPeriod"
            isRequired
            key={contributionFieldName}
            inputClasses="border-gray-300"
            InputAdornment="$"
            decimalsLimit={2}
            placeholder="0.00"
            step={0.01}
            decimalSeparator="."
            groupSeparator=","
            min={0}
          />
        )}
        {period === 'pay_period' && (
          <div className="flex w-full gap-6">
            {shouldShowContributionFields && (
              <FormInputDecimalsField
                shouldUnregister={false}
                title={contributionFieldTitle}
                name={contributionFieldName}
                isRequired
                key={contributionFieldName}
                inputClasses="border-gray-300"
                InputAdornment={contributionAdornment}
                decimalsLimit={2}
                placeholder="0.00"
                step={0.01}
                decimalSeparator="."
                groupSeparator=","
                min={0}
              />
            )}
          </div>
        )}
      </div>
      <CheckHQWrapper hideItem>
        <div className="mt-x-large">
          {shouldShowContributionFields && (
            <FormInputDecimalsField
              shouldUnregister={false}
              title="Annual Limit"
              name="deductionLimit"
              isOptional
              InputAdornment={'$'}
              decimalsLimit={2}
              placeholder="0.00"
              step={0.01}
              decimalSeparator="."
              groupSeparator=","
              min={0}
              inputClasses="border-gray-300"
            />
          )}
        </div>
      </CheckHQWrapper>
    </div>
  );
};
export default EmployeeContribution;
