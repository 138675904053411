import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { RunnablePayrolls } from '@/redux/dto/employee';

import { queryEndpoints } from './endpoints';

type RunnablePayrollsResponse = AxiosResponse<RunnablePayrolls>;

type UseGetRunnablePayrollType = {
  companyId: number;
  rqParams?: Omit<
    UseQueryOptions<RunnablePayrollsResponse, Error, RunnablePayrollsResponse>,
    'queryKey'
  >;
};

const useGetRunnablePayroll = ({ companyId, rqParams }: UseGetRunnablePayrollType) => {
  const { queryKey, url } = queryEndpoints.getRunnablePayroll(companyId);

  return useQuery<RunnablePayrollsResponse, Error>({
    queryKey,
    queryFn: () => getCall(url),
    ...rqParams,
  });
};

export default useGetRunnablePayroll;
