import { ReactNode, SetStateAction } from 'react';

export type PayrollScheduleTypes = {
  payFrequency: number | null;
  payDay: string | number | null;
  payDate: string | null;
  payPeriod: string | null;
  firstPayDate: number;
  secondPayDate: number;
  firstPayPeriod: string;
};

export const quarters = {
  'Q1: January - March': 1,
  'Q2: April - June': 2,
  'Q3: July - September': 3,
  'Q4: October - December': 4,
};

export type ConfirmationType = {
  isOpen?: boolean;
  handleConfirm: (data: any) => void;
  handleClose: () => void;
  variant?: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: string | ReactNode;
  confirmButtonText?: string | ReactNode;
  cancelButtonText?: string | ReactNode;
  description?: string | ReactNode;
  isConfirmLoading?: boolean;
  isPassedApprovalDeadline?: boolean;
  isPassedPayDate?: boolean;
  isConfirmDisabled?: boolean;
  customOnClose?: boolean;
};

export enum PayFrequency {
  'weekly' = 1,
  'biweekly' = 2,
  'semimonthly' = 3,
  'monthly' = 4,
  'quarterly' = 5,
}

export const PayFrequencyList = {
  weekly: 1,
  biweekly: 2,
  semimonthly: 3,
  monthly: 4,
  quarterly: 5,
};

export enum WeekDays {
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
}

export const WeekDaysList = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
};

export const getWeekDayLabel = (day: string | number) => {
  switch (day) {
    case 'Monday':
      return 'Every Monday';
    case 'Tuesday':
      return 'Every Tuesday';
    case 'Wednesday':
      return 'Every Wednesday';
    case 'Thursday':
      return 'Every Thursday';
    case 'Friday':
      return 'Every Friday';
    default:
      return day;
  }
};

export interface PayrollScheduleProps {
  isEdit: boolean;
  step: number;
  setIsPayrollScheduleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPayrollScheduleOpen?: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDashboard?: () => void;
  setIsScheduleDrawerOpen?: React.Dispatch<SetStateAction<boolean>>;
}
