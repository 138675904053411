import { useEffect, useRef, useState } from 'react';

type Props = {
  isOpenSkipModal: boolean;
  setisOpenSkipModal: (value: boolean) => void;
};

const OptionsButton = ({ setisOpenSkipModal, isOpenSkipModal }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`relative flex size-6 cursor-pointer flex-col items-center justify-evenly ${
        isOpen && 'rounded-[100px] bg-gray-100'
      }`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="size-[4px] rounded-[100px] bg-gray-300"></div>
      <div className="size-[4px] rounded-[100px] bg-gray-300"></div>
      <div className="size-[4px] rounded-[100px] bg-gray-300"></div>
      {isOpen && (
        <div
          className="absolute right-0 top-[40px] w-[224px] rounded-[6px] border border-gray-200 bg-white px-[16px] py-[6px]"
          onClick={() => setisOpenSkipModal(!isOpenSkipModal)}
        >
          <span className="text-red-500">Skip this payroll</span>
        </div>
      )}
    </div>
  );
};

export default OptionsButton;
