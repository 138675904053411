import { useEffect, useState } from 'react';
import tailwindConfig from 'tailwindcss/defaultConfig';
import resolveConfig from 'tailwindcss/resolveConfig';
const fullConfig = resolveConfig(tailwindConfig);
const breakpoints = {
  sm: parseInt(fullConfig.theme.screens.sm, 10),
  md: parseInt(fullConfig.theme.screens.md, 10),
  lg: parseInt(fullConfig.theme.screens.lg, 10),
  xl: parseInt(fullConfig.theme.screens.xl, 10),
} as const;

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const useBreakpoint = () => {
  const getBreakpoint = (): Breakpoint => {
    const width = window.innerWidth;
    if (width < breakpoints.sm) return 'xs';
    if (width < breakpoints.md) return 'sm';
    if (width < breakpoints.lg) return 'md';
    if (width < breakpoints.xl) return 'lg';
    return '2xl';
  };

  const [screen, setScreen] = useState<Breakpoint>(getBreakpoint);

  useEffect(() => {
    const handleResize = () => setScreen(getBreakpoint());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile: screen === 'xs' || screen === 'sm',
    isTablet: screen === 'md',
    isDesktop: screen === 'lg' || screen === 'xl' || screen === '2xl',
    screen, // "xs", "sm", "md", "lg", "xl", "2xl"
  };
};

export default useBreakpoint;
