import { FC, FunctionComponent, SVGProps } from 'react';
import { ButtonProps, PressEvent, Button as ReactAriaButton } from 'react-aria-components';

import { extractEventName } from '@/utils/helpers';
import { customTv } from '@/utils/tailwindMergeConfig';
import { trackEvent } from '@/utils/track';

export interface AppButtonProps extends ButtonProps {
  /**
   * Defines the visual style of the button.
   * @default primary
   */
  variant?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'gray' | 'danger' | 'link' | 'icon';

  /**
   * Sets the size of the button.
   * @default medium
   */
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

  /**
   * @deprecated This prop is deprecated and will be removed in future versions.
   * Use `isPending` instead.
   */
  isLoading?: boolean;

  /**
   * Indicates if the button is disabled. A disabled button will not trigger any actions.
   * @default false
   */
  isDisabled?: boolean;

  /**
   * Optional SVG icon component to be rendered before the button's text.
   */
  startIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;

  /**
   * Optional SVG icon component to be rendered after the button's text.
   */
  endIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;

  /**
   * Controls the width of the button.
   * - `default`: The button's width is adjusted to fit its content.
   * - `mobile-full`: The button takes the full width of its container on mobile devices and adjusts to fit content on larger screens.
   * @default default
   */
  width?: 'default' | 'mobile-full';
}

const loaderSrcMap: Record<AppButtonProps['variant'], string> = {
  primary:
    'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c65796e064e2e873f6_white-loader.png',
  secondary:
    'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c65796e064e2e873f6_white-loader.png',
  tertiary:
    'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c6572595c3bf316a34_navy-loader.png',
  white:
    'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c6a640ea918acf5d4f_emerald-loader.png',
  gray: 'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c65796e064e2e873f6_white-loader.png',
  danger:
    'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c65796e064e2e873f6_white-loader.png',
  link: 'https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/670fa5c65796e064e2e873f6_white-loader.png',
};

export const Button: FC<AppButtonProps> = ({
  children,
  startIcon,
  endIcon,
  size,
  isDisabled,
  isLoading,
  variant = 'primary',
  className,
  width,
  isPending,
  ...rest
}) => {
  const buttonVariant = customTv({
    base: `F37Bolton-Regular relative flex focus:outline-none items-center justify-center rounded-lg`,
    variants: {
      variant: {
        primary: `text-content-white bg-primary-emerald hover:bg-primary-emerald-hover focus:bg-primary-emerald-active`,
        secondary: `text-content-white bg-secondary-navy hover:bg-secondary-navy-hover focus:bg-secondary-navy-active`,
        tertiary: `border-secondary-navy-light-border text-content-body-strong border-2 hover:bg-surface-light-gray focus:bg-surface-dark-gray`,
        white: `text-primary-emerald hover:text-primary-emerald-hover focus:text-primary-emerald-active bg-white hover:bg-surface-light-gray focus:bg-surface-dark-gray`,
        gray: `text-content-heading bg-surface-dark-gray hover:bg-surface-dark-gray-100 focus:bg-surface-dark-gray-100`,
        danger: `text-content-white bg-semantic-danger-red hover:bg-semantic-danger-red-hover focus:bg-semantic-danger-red-active`,
        link: `text-primary-emerald hover:text-primary-emerald-hover focus:text-primary-emerald-active !h-fit !w-fit !p-0`,
        icon: 'text-surface-light-gray !h-fit !w-fit !p-0',
      },
      size: {
        'x-small': `h-9 py-x-small px-medium text-small`,
        small: `h-10 py-x-small px-large text-medium`,
        medium: `h-11 py-small px-x-large text-medium`,
        large: `h-12 py-small px-x-large text-large`,
        'x-large': `h-14 py-small px-x-large text-x-large`,
      },
      width: {
        default: 'w-fit',
        'mobile-full': 'w-full lg:w-fit',
      },
      isDisabled: {
        true: `cursor-default opacity-50`,
        false: '',
      },
      isLoading: {
        true: `-scale-x-100`,
        false: '',
      },
      isPending: {
        true: `-scale-x-100`,
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        isDisabled: true,
        class: 'bg-primary-emerald-light-border cursor-default',
      },
      {
        variant: 'secondary',
        isDisabled: true,
        class: 'bg-secondary-navy-light-border cursor-default',
      },
      {
        variant: 'tertiary',
        isDisabled: true,
        class: 'border-secondary-navy-light-border text-secondary-navy-light-border cursor-default',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
      width: 'default',
      isDisabled: false,
      isLoading: false,
    },
  });

  const handleOnPress = (e: PressEvent) => {
    trackEvent('buttonClick', {
      name: typeof children === 'string' ? children : 'Button',
      currentPage: extractEventName(location.pathname),
    });
    if (rest.onPress) {
      rest.onPress(e);
    }
  };

  return (
    <ReactAriaButton
      className={buttonVariant({
        variant,
        size,
        width,
        isDisabled,
        isLoading,
        class: className as string,
      })}
      isDisabled={isDisabled || isLoading || isPending}
      {...rest}
      onPress={handleOnPress}
    >
      <div
        style={{ visibility: isPending || isLoading ? 'hidden' : 'visible' }}
        className="flex items-center"
      >
        <>
          {startIcon}
          {children}
          {endIcon}
        </>
      </div>
      {(isPending || isLoading) && (
        <img
          src={loaderSrcMap[variant]}
          alt="loader"
          className="animate-reverse-spin absolute size-6"
          aria-hidden="true"
        />
      )}
      {(isPending || isLoading) && <span className="sr-only">Loading...</span>}
    </ReactAriaButton>
  );
};
