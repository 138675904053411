import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputDatePicker, LoaderComponent, RegularPayrollCardUpdated } from '@/components';
import { PtoAnnounecement } from '@/components/shared/EmployeePayTypeForm/components/PtoAnnounecement/PtoAnnounecement';
import NoPendingPayrollCard from '@/components/shared/NoPendingPayrollCard';
import { RunTestPayrollCard } from '@/components/ui/RunTestPayrollCard/RunTestPayrollCard';
import { useAccountInfo } from '@/hooks';
import { RunnablePayrolls } from '@/redux/dto/employee';
import { EmployeePayrollDataType } from '@/redux/dto/history';
import { shouldShowAnnouncement } from '@/utils/helpers';

import { useGetPayrollHistory } from '../../apis';
import NoPayrollHistory from './components/NoPayrollHistory/NoPayrollHistory';
import PayrollHistory from './components/PayrollHistory/PayrollHistory';
import { PickOffCycleType } from './components/PickOffCycleType/PickOffCycleType';

interface Props {
  payrolls: RunnablePayrolls;
  getPayrollsList: () => void;
  runnableEmployees: [];
  isDraftButton?: boolean;
}

const SelectRegularPayrollForm = ({
  payrolls,
  getPayrollsList,
  runnableEmployees,
  isDraftButton,
}: Props) => {
  const [history, setHistory] = useState<EmployeePayrollDataType[]>([]);
  const [dateOfPayment, setDateOfPayment] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [isTestPayrollVisible, setIsTestPayrollVisible] = useState(false);
  const [testPayrollData, setTestPayrollData] = useState<EmployeePayrollDataType | null>(null);

  const { companyId, account } = useAccountInfo();

  const isPtoAnnouncementClosed = account?.metadata?.isPtoAnnouncementClosed;
  const createdAt = account?.createdAt;

  const isAccountOld = shouldShowAnnouncement(createdAt);

  // Fetch payroll history
  const {
    data: payrollHistoryData,
    refetch: refetchPayrollHistory,
    isFetching: isFetchingPayrollHistory,
  } = useGetPayrollHistory({
    companyId,
    queryParams: {
      dateOfPaymentBefore: dateOfPayment?.endDate,
      dateOfPaymentAfter: dateOfPayment?.startDate,
    },
    rqParams: {
      enabled: false,
    },
  });

  const changeDateOfPayment = (date: { startDate: string | null; endDate: string | null }) => {
    setDateOfPayment(date);
  };

  useEffect(() => {
    refetchPayrollHistory();
  }, [dateOfPayment, refetchPayrollHistory]);

  useEffect(() => {
    const formattedRes = Array.isArray(payrollHistoryData) ? payrollHistoryData : [];

    // Separate regular and test payrolls
    const regularPayroll = formattedRes.filter(data => data.type !== 'test');
    const testPayroll = formattedRes.filter(data => data.type === 'test');

    // Determine visibility of test payroll
    if (!dateOfPayment?.endDate && !dateOfPayment?.startDate) {
      const hasRegularPayroll = !!regularPayroll.length;
      setIsTestPayrollVisible(!hasRegularPayroll);
      setTestPayrollData(testPayroll[0] || null);
    }

    // Update state with regular payroll data
    setHistory(regularPayroll);
  }, [payrollHistoryData, dateOfPayment]);

  const findOldestOverduePayment = (payrolls: RunnablePayrolls): number | null => {
    if (payrolls.length > 0) {
      const overduePayments = payrolls
        .filter(payroll => payroll.isOverdue)
        .map(payroll => payroll.id);

      if (overduePayments.length > 0) {
        const oldestPaymentId = overduePayments.reduce((oldestId, currentId) =>
          new Date(payrolls[currentId - 1]?.payDate) < new Date(payrolls[oldestId - 1]?.payDate)
            ? currentId
            : oldestId,
        );
        return oldestPaymentId;
      }

      return null;
    } else {
      return null;
    }
  };

  const formattedPayrolls = payrolls.map(payroll => ({
    ...payroll,
    isChecked: false,
  }));

  const oldestPaymentId = findOldestOverduePayment(formattedPayrolls);
  const runnablePayrolls = payrolls?.filter(payroll => !payroll.isOverdue && !payroll.isPast);

  const startDate = runnablePayrolls?.[0]?.payPeriod?.startDate
    ? DateTime.fromISO(runnablePayrolls[0].payPeriod.startDate, { setZone: true })
    : DateTime.now();

  const isPayPeriodStarted = startDate < DateTime.now();

  const overduePayrolls = payrolls?.filter(payroll => payroll.isOverdue);
  const pastPayrolls = payrolls?.filter(payroll => payroll.isPast);

  return (
    <div className="flex w-full">
      <div className="w-full">
        {!payrolls ? (
          <LoaderComponent />
        ) : (
          <div className="gap-2x-large flex flex-col">
            {isAccountOld && !isPtoAnnouncementClosed && <PtoAnnounecement />}
            {isDraftButton ? (
              isTestPayrollVisible &&
              !testPayrollData && (
                <div className="relative z-40">
                  <RunTestPayrollCard
                    payrollInfo={payrolls[0]}
                    isSecondaryButton={!isDraftButton}
                  />
                </div>
              )
            ) : (
              <>
                {!!pastPayrolls.length && (
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-2">
                      <span className="font-F37Bolton-Medium text-[18px]">
                        Back-dated payrolls to record
                      </span>
                      <span className="text-sm text-gray-500">
                        Please record your past payrolls before running any upcoming payrolls
                      </span>
                    </div>
                    <RegularPayrollCardUpdated
                      payrolls={formattedPayrolls}
                      oldestPaymentId={oldestPaymentId}
                      getPayrollsList={getPayrollsList}
                      getHistory={refetchPayrollHistory}
                      payroll={pastPayrolls[0]}
                      isLoading={isFetchingPayrollHistory}
                      isPast
                      runnableEmployees={runnableEmployees}
                      popoverDirection="down"
                    />
                  </div>
                )}
                {!!overduePayrolls.length && (
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-2">
                      <span className="font-F37Bolton-Medium text-[18px]">
                        Overdue payrolls to record
                      </span>
                      <span className="text-sm text-gray-500">
                        Please record your overdue payrolls before running any upcoming payrolls
                      </span>
                    </div>
                    <div className="relative z-[25] max-w-2xl">
                      <RegularPayrollCardUpdated
                        payrolls={formattedPayrolls}
                        oldestPaymentId={oldestPaymentId}
                        getPayrollsList={getPayrollsList}
                        getHistory={refetchPayrollHistory}
                        payroll={overduePayrolls[0]}
                        isLoading={isFetchingPayrollHistory}
                        isOverdue
                        runnableEmployees={runnableEmployees}
                        popoverDirection="down"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {
              <div>
                {isPayPeriodStarted && (
                  <span className="font-F37Bolton-Medium mb-large block text-[18px]">Upcoming</span>
                )}
                <div className="flex w-full flex-col gap-x-5 xl:flex-row xl:justify-between">
                  {isPayPeriodStarted ? (
                    <div
                      className={twMerge(
                        `relative flex size-full flex-col gap-y-2 lg:max-w-[650px]`,
                        !isDraftButton ? 'lg:max-w-full xl:flex-1' : '',
                      )}
                    >
                      {runnablePayrolls.map((payroll, index) => (
                        <RegularPayrollCardUpdated
                          key={index}
                          payrolls={formattedPayrolls}
                          oldestPaymentId={oldestPaymentId}
                          getPayrollsList={getPayrollsList}
                          getHistory={refetchPayrollHistory}
                          payroll={payroll}
                          isLoading={isFetchingPayrollHistory}
                          runnableEmployees={runnableEmployees}
                          popoverDirection="down"
                          isDraftButton={isDraftButton}
                        />
                      ))}
                    </div>
                  ) : (
                    <NoPendingPayrollCard payroll={runnablePayrolls[0]} />
                  )}
                  {!isDraftButton && (
                    <div className="mt-x-large hidden  size-full h-auto flex-col items-start justify-start rounded-lg md:block xl:mt-0 xl:max-h-[303px] xl:max-w-[348px]">
                      <PickOffCycleType />
                    </div>
                  )}
                </div>
              </div>
            }
            {!isDraftButton &&
              !isFetchingPayrollHistory &&
              isTestPayrollVisible &&
              !testPayrollData && (
                <div className="flex w-full">
                  <RunTestPayrollCard
                    payrollInfo={payrolls ? payrolls[0] : []}
                    isIconShowing={false}
                    isSecondaryButton={!isDraftButton}
                  />
                </div>
              )}

            <div>
              <div className="items-center justify-between rounded-t-lg px-6  py-5 md:mt-0 md:flex md:border">
                <h2 className="font-F37Bolton-Medium flex-1 whitespace-nowrap text-2xl text-gray-900">
                  Calculated payroll
                </h2>
                <div>
                  <InputDatePicker
                    width="min-w-[280px] max-w-[300px]"
                    className="mt-4 items-center justify-between gap-3 whitespace-nowrap md:mt-0 md:flex"
                    label="Filter by pay date"
                    isSingle={false}
                    value={dateOfPayment}
                    onChange={dateOfPayment => {
                      changeDateOfPayment(dateOfPayment);
                    }}
                    popoverDirection="down"
                    labelClassName="mb-2 md:mb-0 font-F37Bolton-Regular text-sm text-gray-400"
                  />
                </div>
              </div>

              {isFetchingPayrollHistory ? (
                <LoaderComponent />
              ) : history?.filter(payroll => payroll.type !== 'test').length ? (
                <PayrollHistory historyData={history} />
              ) : (
                <NoPayrollHistory />
              )}
            </div>
            {testPayrollData && isDraftButton && (
              <div>
                <RunTestPayrollCard
                  payrollInfo={payrolls ? testPayrollData : []}
                  isDone={true}
                  isSecondaryButton={true}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectRegularPayrollForm;
