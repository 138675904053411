import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

import Button from '@/components/Button/Button';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { FormInputTextArea } from '@/components/ui';
import { ConfirmationType } from '@/modules/payrollSchedule/types';
import { FormProvider } from 'react-hook-form';
import useSkipPayrollModel from './useSkipPayrollModel';

const DISCARD = 'discard';

const NewSkipPayrollModel: FC<ConfirmationType> = ({
  isOpen,
  handleConfirm,
  handleClose,
  variant = DISCARD,
  setIsOpen,
  isConfirmLoading,
}) => {
  const { form } = useSkipPayrollModel();
  const cancelText = 'Cancel';
  const confirmText = 'Skip payroll';
  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-[100]">
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm backdrop-brightness-75">
        <Dialog.Panel>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleConfirm)}>
              <div className={twMerge('w-full max-w-[335px] md:max-w-[640px]')}>
                <div className="bg-surface-white rounded-medium p-x-large gap-x-large relative flex h-full flex-col ">
                  <div className="gap-medium flex flex-col">
                    <div className="flex  justify-between">
                      <div className="flex flex-col">
                        <span className="font-F37Bolton-Bold text-x-large text-content-heading">
                          Skip this payroll?
                        </span>
                        <span className="text-medium text-content-body-medium font-F37Bolton-Regular">
                          If you skip this, the payroll will be processed as $0 and employees will
                          not be paid for this pay period
                        </span>
                      </div>
                      <div>
                        <Button
                          variant="text"
                          height="10px"
                          onPress={setIsOpen ? () => setIsOpen(false) : handleClose}
                        >
                          <XMarkIcon className="size-6" />
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="gap-x-small flex flex-col">
                    <span className="text-content-heading font-F37Bolton-Medium">
                      Reason for skipping{' '}
                      <span className="text-semantic-danger-red font-F37Bolton-Medium">*</span>
                    </span>

                    <FormInputTextArea
                      shouldUnregister={false}
                      name="skipped_reason"
                      inputProps={{ multiple: true }}
                      inputClasses="min-h-[140px] rounded-lg"
                      width="w-full"
                      // maxCharacterCount={50}
                      placeholder="Add a reason for skipping this payroll"
                    />

                    <div className={twMerge('gap-medium flex w-full flex-row justify-end ')}>
                      <Button
                        variant="tertiary"
                        parentClasses="w-auto h-[44px] px-large py-x-small border-2 border-strong font-F37Bolton-Tral text-content-body-strong"
                        onPress={handleClose}
                      >
                        <span className="text-content-body-strong font-F37Bolton-Medium text-medium">
                          {cancelText}
                        </span>
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        children={confirmText}
                        parentClasses={twMerge(
                          'w-auto px-large py-small',
                          variant === DISCARD ? 'bg-red-600 hover:bg-red-700' : '',
                          'min-w-[150px]',
                        )}
                        isLoading={isConfirmLoading}
                        isDisabled={isConfirmLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default NewSkipPayrollModel;
