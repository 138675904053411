import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { ActionWarnings } from '@/redux/dto/employee';

import { queryEndpoints } from './endpoints';

type ActionWarningsResponse = AxiosResponse<ActionWarnings>;

type UseGetPayrollCheckType = {
  companyId: number;
  rqParams?: Omit<
    UseQueryOptions<ActionWarningsResponse, Error, ActionWarningsResponse>,
    'queryKey'
  >;
};

const useGetPayrollCheck = ({ companyId, rqParams }: UseGetPayrollCheckType) => {
  const { queryKey, url } = queryEndpoints.getPayrollCheck(companyId);

  return useQuery<ActionWarningsResponse, Error>({
    queryKey,
    queryFn: () => getCall(url),
    ...rqParams,
  });
};

export default useGetPayrollCheck;
