import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

import { Button } from '@/components/ui/designSystem/Button/Button';
import { Separator } from '@/components/ui/Separator/Separator';
import { SubscriptionPlan } from '@/redux/dto/auth';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { PLANS } from '../SubscriptionBillingCard/utils/constants';

type PlanSectionProps = {
  bannerText: string;
  bannerClass: string;
  containerClasses: string;
  planInfo: SubscriptionPlan;
  planFeatures: [];
  tooltipId: string;
  hasButton: boolean;
  onCancel: () => void;
  isRenew: boolean;
  onSubscribe: () => void;
  isUpgrade: boolean;
};

type PlanCardProps = {
  plans: SubscriptionPlan[];
  currentPlan: 'full-service-payroll' | 'basic';
  onCancel?: () => void;
  isRenew: boolean;
  onSubscribe: () => void;
  isUpgrade: boolean;
};

const getFeatures = (planName: string) => {
  const baseFeatures = [
    {
      text: 'Federal, state, and local payroll taxes calculated',
      available: true,
    },
    {
      text: 'W2 and 1099-NEC tax forms for manual filing',
      available: true,
    },
    {
      text: 'Unlimited payroll calculations',
      available: true,
    },
  ];

  const fullServiceFeatures = [
    {
      text: 'Payroll taxes paid and filed automatically',
      available: planName === PLANS['full-service-payroll'],
    },
    {
      text: 'Pay your team via direct deposit',
      available: planName === PLANS['full-service-payroll'],
    },
    {
      text: 'Manage and pay child support garnishments',
      available: planName === PLANS['full-service-payroll'],
    },
    {
      text: 'Access to health, retirement, and insurance benefits',
      available: planName === PLANS['full-service-payroll'],
    },
  ];

  return [...baseFeatures, ...fullServiceFeatures];
};

type PlanName = (typeof PLANS)[keyof typeof PLANS];

const buttonTextMap: Record<
  PlanName,
  Record<'isRenew' | 'isUpgrade' | 'default', Record<boolean, string>>
> = {
  [PLANS['full-service-payroll']]: {
    isRenew: {
      true: 'Contact support to renew',
      false: 'Contact support to renew',
    },
    isUpgrade: {
      true: 'Contact support to upgrade',
      false: 'Contact support to upgrade',
    },
    default: {
      true: 'Contact support to upgrade',
      false: 'Contact support to upgrade',
    },
  },
  default: {
    isRenew: {
      true: 'Subscribe',
      false: 'Subscribe',
    },
    isUpgrade: {
      true: 'Upgrade',
      false: 'Upgrade',
    },
    default: {
      true: 'Cancel subscription',
      false: 'Cancel subscription',
    },
  },
};

const PlanSection = ({
  bannerText,
  bannerClass,
  containerClasses,
  planInfo,
  planFeatures,
  tooltipId,
  hasButton = false,
  onCancel,
  isRenew,
  onSubscribe,
  isUpgrade,
  discount,
}: PlanSectionProps) => {
  const { name, metadata } = planInfo ?? {};

  const buttonActionMap: Record<PlanName, (isRenew: boolean, isUpgrade: boolean) => void> = {
    [PLANS['full-service-payroll']]: () => window.fcWidget.open(),
    default: (isRenew: boolean, isUpgrade: boolean) => {
      if (isUpgrade) {
        onSubscribe();
      } else {
        isRenew ? onSubscribe() : onCancel();
      }
    },
  };

  const buttonText = (isRenew: boolean, isUpgrade: boolean) => {
    const planText = buttonTextMap[name as PlanName] ?? buttonTextMap.default;
    if (!isRenew && !isUpgrade) {
      return planText.default[true];
    }
    return isUpgrade ? planText.isUpgrade[isUpgrade] : planText.isRenew[isRenew];
  };

  const buttonAction = (isRenew: boolean, isUpgrade: boolean) => {
    const action = buttonActionMap[name] ?? buttonActionMap.default;
    action(isRenew, isUpgrade);
  };

  return (
    <div className={containerClasses}>
      <Tooltip
        place="top"
        className="!bg-navy-500 !text-small z-[3] !w-auto max-w-[225px] !rounded-lg font-normal"
        id={tooltipId}
        content="You will be charged this amount per month only for employees or contractors that were paid during the billing cycle"
        opacity={1}
      />
      <div className="relative w-full">
        {!(isRenew && name === PLANS['basic']) && bannerText && (
          <div className={bannerClass}>{bannerText}</div>
        )}
        <div className="flex w-full items-start justify-between">
          <div className="flex flex-col items-start gap-x-2">
            <span className="font-F37Bolton-Bold text-x-large">{name}</span>
            {!!metadata?.description && (
              <p className="text-content-body-strong text-small mt-2">{metadata.description}</p>
            )}
          </div>
        </div>

        {discount && name === PLANS['full-service-payroll'] ? (
          <div className="mt-medium flex flex-col">
            <div className="flex items-center">
              <span className="text-large font-F37Bolton-Bold text-content-body-disabled mr-1 line-through">
                ${planInfo?.availablePrices[0].amount}
              </span>
              <span className="text-large font-F37Bolton-Bold">
                ${planInfo?.availablePrices[0].amount * ((100 - discount) / 100)} / month
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-small">
                Plus{' '}
                <span className="text-content-body-disabled line-through">
                  ${planInfo?.availablePrices[0].amountPerWorker}
                </span>{' '}
                ${planInfo?.availablePrices[0].amountPerWorker * ((100 - discount) / 100)} / paid
                worker
              </span>

              <IconInfoCircle
                className="text-icon-disabled size-medium ml-1"
                data-tooltip-id={tooltipId}
              />
            </div>
            <span className="text-small text-content-primary mt-1">50% off for 6 months</span>
          </div>
        ) : (
          <div className="mt-medium flex flex-col">
            <span className="text-large font-F37Bolton-Bold">{`${planInfo?.availablePrices[0].amount}$ / month`}</span>
            <div className="flex items-center">
              <span className="text-small">{`Plus ${planInfo?.availablePrices[0].amountPerWorker}$ / paid worker`}</span>
              <IconInfoCircle
                className="text-icon-disabled size-medium ml-1"
                data-tooltip-id={tooltipId}
              />
            </div>
          </div>
        )}
        <Separator type="light" thickness="1px" className="my-medium" />
        <ul className="mt-medium flex flex-col gap-y-2">
          {planFeatures.map((feature, index) => (
            <li key={index} className="flex items-start gap-x-2">
              {feature.available ? (
                <IconCheck className="mt-1 size-4 shrink-0 text-emerald-500" />
              ) : (
                <IconX className="mt-1 size-4 shrink-0 text-gray-400" />
              )}
              <span
                className={twMerge(
                  'text-x-small text-content-body-strong',
                  !feature.available && 'text-content-body-light',
                )}
              >
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
        {hasButton && (
          <Button
            variant={name === PLANS['full-service-payroll'] ? 'primary' : 'tertiary'}
            children={buttonText(isRenew, isUpgrade)}
            className="mt-medium text-small w-full"
            onPress={() => buttonAction(isRenew, isUpgrade)}
          />
        )}
      </div>
    </div>
  );
};

export const PlanCard = ({
  plans,
  currentPlan,
  onCancel,
  isRenew,
  onSubscribe,
  isUpgrade,
  discount,
}: PlanCardProps) => {
  const currentPlanInfo = plans?.find(plan => plan.name === currentPlan);
  const otherPlanInfo = plans?.find(plan => plan.name !== currentPlan);

  const basicPlanInfo = {
    availablePrices: [{ amount: 20, amountPerWorker: 3 }],
    name: 'Basic',
  };

  const currentPlanFeatures = getFeatures(currentPlanInfo?.name);
  const otherPlanFeatures = getFeatures(otherPlanInfo?.name);

  return (
    <>
      {currentPlan === PLANS['full-service-payroll'] && (
        <PlanSection
          bannerText={isRenew ? '92% users prefer this plan' : 'current plan'}
          bannerClass={`${
            isRenew ? 'bg-semantic-accent-blue' : 'bg-primary-emerald'
          } font-F37Bolton-Medium text-2x-small text-content-white p-2x-small absolute left-1/2 top-[-30px] flex -translate-x-1/2 items-center justify-center text-nowrap text-center uppercase`}
          containerClasses="p-medium border-border-primary flex w-[290px] min-w-[140px] cursor-pointer items-center gap-x-2 rounded-xl border-2 lg:w-[320px]"
          planInfo={currentPlanInfo}
          planFeatures={currentPlanFeatures}
          tooltipId="informational-tooltip-upgrade"
          hasButton={isRenew}
          isRenew={isRenew}
          discount={discount}
        />
      )}

      {currentPlan === PLANS.basic && (
        <div className="lg:gap-medium flex flex-col lg:flex-row">
          {isRenew ? null : (
            <PlanSection
              bannerText="current plan"
              bannerClass="bg-primary-emerald font-F37Bolton-Medium text-2x-small text-content-white p-2x-small absolute left-1/2 top-[-40px] flex -translate-x-1/2 items-center justify-center text-nowrap text-center uppercase"
              containerClasses="p-medium border-border-primary flex w-full min-w-[140px] cursor-pointer items-center gap-x-2 rounded-xl border-2 lg:w-1/2"
              planInfo={basicPlanInfo}
              planFeatures={currentPlanFeatures}
              tooltipId="informational-tooltip-upgrade"
              onCancel={onCancel}
              onSubscribe={onSubscribe}
              isRenew={isRenew}
              isUpgrade={isUpgrade}
              discount={discount}
              hasButton
            />
          )}

          <PlanSection
            bannerText="92% users prefer this plan"
            bannerClass="bg-semantic-accent-blue font-F37Bolton-Medium text-2x-small text-content-white p-2x-small absolute left-1/2 top-[-30px] flex -translate-x-1/2 items-center justify-center text-nowrap text-center"
            containerClasses={`p-medium flex w-full min-w-[140px] cursor-pointer items-center gap-x-2 rounded-xl border-2  mt-medium lg:mt-0 ${
              isRenew ? 'lg:w-full' : 'lg:w-1/2'
            }`}
            planInfo={otherPlanInfo}
            planFeatures={otherPlanFeatures}
            tooltipId="informational-tooltip-upgrade"
            isRenew={isRenew}
            isUpgrade={isUpgrade}
            discount={discount}
            hasButton
          />
        </div>
      )}
    </>
  );
};
