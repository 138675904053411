import { FormInputDecimalsField } from '@/components/ui';

const CustomPostTaxDeductionForm = () => {
  return (
    <div className="gap-x-large flex flex-col">
      <div className="gap-x-small flex flex-col">
        <h4 className="font-F37Bolton-Medium text-content-heading">Contribution Settings</h4>
        <p className="text-content-body-medium text-sm">
          This is the amount to be deducted from employees scheduled payments{' '}
        </p>
      </div>
      <div className="gap-x-small flex">
        <FormInputDecimalsField
          shouldUnregister={false}
          title="Total contribution amount"
          name="totalAmount"
          isOptional
          key="totalAmount"
          inputClasses="border-gray-300"
          InputAdornment="$"
          decimalsLimit={2}
          placeholder="0.00"
          step={0.01}
          decimalSeparator="."
          groupSeparator=","
          min={0}
        />
        <FormInputDecimalsField
          shouldUnregister={false}
          title="Annual limit"
          name="annualLimit"
          isOptional
          key="annualLimit"
          inputClasses="border-gray-300"
          InputAdornment="$"
          decimalsLimit={2}
          placeholder="0.00"
          step={0.01}
          decimalSeparator="."
          groupSeparator=","
          min={0}
        />
      </div>
    </div>
  );
};

export default CustomPostTaxDeductionForm;
