import { IconCalendarMonth, IconMessage, IconPhone } from '@tabler/icons-react';
import { useState } from 'react';
import { PopupModal } from 'react-calendly';

import { ReactComponent as Illustration } from '@/assets/illustration/supportIllustration.svg';
import { Button as UpdatedButton } from '@/components/ui/designSystem/Button/Button';
import { LINKS, SUPPORT_CONTACT_INFO } from '@/utils/constants';

import { AccountSetupSupportType } from './type';

const AccountSetupSupport = ({
  onBookCall,
  isDiscountCopyVisible = false,
}: AccountSetupSupportType) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="bg-primary-emerald-light-bg p-medium rounded-t-2x-small relative flex flex-col">
          <span className="text-content-heading text-large font-F37Bolton-Bold">Need help?</span>
          {!isDiscountCopyVisible ? (
            <span className="text-content-body-strong text-small w-[170px]">
              Speak to one of our onboarding specialists
            </span>
          ) : (
            <span>
              Book a free call with a<br />
              payroll advisor and get
              <br />
              <strong className="text-content-primary "> 50% off for 6 months</strong>
            </span>
          )}
          <Illustration className="absolute right-0" />
        </div>
        <div className="gap-small py-medium flex items-center justify-center">
          <UpdatedButton
            children="Chat"
            onPress={() => window.fcWidget.open()}
            className="w-[140px]"
            startIcon={<IconMessage className="mr-x-small size-5" />}
          />

          {onBookCall ? (
            <UpdatedButton
              variant="tertiary"
              children="Book a call"
              className="w-[140px] text-nowrap"
              startIcon={<IconCalendarMonth className="mr-x-small size-5" />}
            />
          ) : (
            <>
              <UpdatedButton
                variant="tertiary"
                children="Book a call"
                className="w-[140px] text-nowrap"
                onClick={() => setShowModal(true)}
                startIcon={<IconCalendarMonth className="mr-x-small size-5" />}
              />
              <PopupModal
                url={LINKS.calendlySupport}
                rootElement={document.getElementById('root')}
                open={showModal}
                onModalClose={() => setShowModal(false)}
              />
            </>
          )}
        </div>
        <div className="mb-medium flex items-center justify-center">
          <IconPhone className="mr-x-small size-5" />
          <span>Call now: {SUPPORT_CONTACT_INFO.mobileNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupSupport;
