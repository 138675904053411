import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCallBlob } from '@/api/axios';
import { ActionWarnings } from '@/redux/dto/employee';

import { queryEndpoints } from './endpoints';

type ActionWarningsResponse = AxiosResponse<ActionWarnings>;

type UseGetInvoiceType = {
  invoiceId: number;
  rqParams?: Omit<
    UseQueryOptions<ActionWarningsResponse, Error, ActionWarningsResponse>,
    'queryKey'
  >;
};

const useGetInvoice = ({ invoiceId, rqParams }: UseGetInvoiceType) => {
  return useQuery({
    queryKey: ['invoice', invoiceId, 'download'],
    queryFn: () =>
      getCallBlob(queryEndpoints.getInvoice(invoiceId).url, {
        responseType: 'blob',
      }),
    select: (response: any) => {
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'download.pdf';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }
      return {
        rawData: response.data,
        filename: filename,
      };
    },
    retry: 0,
    enabled: false,
  });
};

export default useGetInvoice;
