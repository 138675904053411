import { MutationOptions, useMutation } from '@tanstack/react-query';

import { deleteCall } from '@/api/axios';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';
import { mutationEndpoints } from './endpoints';

const useDeleteCompanyLevelDeduction = ({
  companyId,
  deductionId,
  mutationParams,
}: {
  companyId: number;
  deductionId: number;
  mutationParams?: MutationOptions;
}) => {
  const { mutationKey, url, invalidateKeys } = mutationEndpoints.all(companyId, deductionId);
  const { onSuccess } = useQueryHandlers({
    mutationParams,
    invalidateKeys,
  });

  return useMutation({
    mutationKey,
    mutationFn: () => deleteCall(url),
    ...mutationParams,
    onSuccess,
  });
};

export default useDeleteCompanyLevelDeduction;
