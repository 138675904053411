import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSubmitRunnablePayroll } from '@/api';
import { useAccountInfo } from '@/hooks';
import { RunnablePayroll } from '@/redux/dto/employee';
import * as routes from '@/routes/routes';
import { QUERY_PARAMS } from '@/utils/constants';
import { payFrequencyByValue } from '@/utils/helpers';
import { TestValidationSchema } from '@/utils/validation';

const useRunTestPayrollCard = ({ payroll }: { payroll: RunnablePayroll }) => {
  const navigate = useNavigate();

  const { companyId } = useAccountInfo();

  const [componentWidth, setComponentWidth] = useState(0);

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      dateOfPayment: payroll?.payDate,
    },
    resolver: yupResolver(TestValidationSchema),
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  const { mutate: onPayrollRunTest, isPending: isTestPayrollLoading } = useSubmitRunnablePayroll({
    companyId,
    mutationParams: {
      onSuccess: data => {
        const id = data?.data?.id;
        navigate(
          `${routes.RUN_PAYROLL_ADD_INFORMATION.replace(':payrollId', id)}?${
            QUERY_PARAMS.isDraftEmployeePayroll
          }=true`,
        );
      },
    },
  });

  const disableDates = [
    {
      startDate: new Date(-8640000000000000), //Min Date
      endDate: new Date(new Date(payroll?.payDate).getTime() - 7 * 24 * 60 * 60 * 1000), // Paydate - 1 week
    },
    {
      startDate: new Date(new Date(payroll?.payDate).getTime() + 7 * 24 * 60 * 60 * 1000), // Paydate + 1 week
      endDate: new Date(8640000000000000), //Max Date
    },
  ];

  const handleTestPayroll = data => {
    const formData = {
      companyId,
      id: payroll?.id,
      type: 'test',
      payDate: data.dateOfPayment,
      payPeriod: payroll?.payPeriod,
      frequency: payroll?.frequency,
    };
    onPayrollRunTest(formData);
  };

  const changeDateOfPayment = date => {
    setValue('dateOfPayment', date.startDate, { shouldValidate: true });
  };

  const payFrequency = payFrequencyByValue(payroll?.frequency || payroll?.payFrequency);
  const { payPeriodStartDate: startDate, payPeriodEndDate: endDate, payPeriod } = payroll || {};

  const formattedStartDate = moment(payPeriod ? payPeriod.startDate : startDate).format('MMM D');
  const formattedEndDate = moment(payPeriod ? payPeriod.endDate : endDate).format('MMM D, YYYY');

  const formattedPeriod = `${formattedStartDate} - ${formattedEndDate}`;

  const componentRef = useRef(null);

  useEffect(() => {
    if (componentRef.current) {
      setComponentWidth(componentRef.current.offsetWidth);
    }
  }, [componentRef.current]);

  const payDate = moment(payroll?.payDate).format('MMM DD, YYYY');
  const handleViewTestCalculations = id => {
    navigate(
      `${routes.RUN_PAYROLL_DETAILS.replace(':payrollId', id)}?${
        QUERY_PARAMS.isDraftEmployeePayroll
      }=true`,
    );
  };
  return {
    handleTestPayroll,
    form,
    isTestPayrollLoading,
    changeDateOfPayment,
    control,
    register,
    errors,
    disableDates,
    handleSubmit,
    payFrequency,
    formattedPeriod,
    componentRef,
    componentWidth,
    payDate,
    handleViewTestCalculations,
  };
};
export default useRunTestPayrollCard;
