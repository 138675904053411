import moment from 'moment';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from 'react-tooltip';

import { InputDatePicker, OptionsButton, StatusBadge } from '@/components';
import { default as ButtonUpdated } from '@/components/Button/Button';
import { payFrequencyByValue } from '@/utils/helpers';

import CheckHQWrapper from '../CheckHQWrapper/CheckHQWrapper';
import NewSkipPayrollModel from '../SkipPayrollModal/NewSkipPayrollModel';
import useRegularPayrollCardUpdate, {
  RegularPayrollCardUpdateProps,
} from './useRegularPayrollCardUpdate';

const RegularPayrollCardUpdated = ({
  payroll,
  isOverdue,
  isPast,
  payrolls,
  runnableEmployees,
  getHistory,
  getPayrollsList,
  getCompaniesCurrentUser,
  popoverDirection,
  isDraftButton,
  isRunPayrollDisabled,
  isCheckHQAccountUnderReview,
  isCompanyHasBlockingTasks,
}: RegularPayrollCardUpdateProps) => {
  const {
    updatedButtonProps,
    tooltipContent,
    handleSkipPayroll,
    onSubmit,
    register,
    control,
    calculateEstimatedTotal,
    errors,
    handleSubmit,
    changeDateOfPayment,
    isOpenSkipModal,
    setisOpenSkipModal,
    formattedPeriod,
    renderBadge,
    isCalculatePayrollButtonDisabled,
    isLoading,
    pastPayrolls,
    isCheckHqUser,
    deadlineDate,
    handleCloseSkipPayroll,
    isSkipPayrollLoading,
  } = useRegularPayrollCardUpdate({
    payroll,
    isOverdue,
    isPast,
    payrolls,
    runnableEmployees,
    getHistory,
    getPayrollsList,
    getCompaniesCurrentUser,
    isDraftButton,
    isRunPayrollDisabled,
    isCheckHQAccountUnderReview,
    isCompanyHasBlockingTasks,
  });
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="gap-medium flex h-full flex-col">
        <div className="relative w-full">
          <div className="relative rounded-2xl border border-gray-100 bg-white">
            <div className="p-3 sm:p-5">
              <div className="flex items-baseline justify-between">
                <div className="bg-secondary-navy-hover px-x-small py-2x-small text-x-small mb-small rounded-2x-small flex items-center justify-center text-white">
                  <span className="block text-center">Regular Payroll</span>
                </div>
                <div className="flex items-center justify-center">
                  {renderBadge}
                  {(!isCheckHqUser || !isRunPayrollDisabled) && (
                    <OptionsButton
                      setisOpenSkipModal={setisOpenSkipModal}
                      isOpenSkipModal={isOpenSkipModal}
                    />
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <span className="font-F37Bolton-Medium mr-2 text-[20px] text-gray-500">
                  {formattedPeriod}
                </span>
                <StatusBadge text={payFrequencyByValue(payroll?.frequency)} severity={'draft'} />
              </div>
              <CheckHQWrapper hideItem>
                <Controller
                  control={control}
                  name={`dateOfPayment`}
                  render={({ field: { value } }) => (
                    <div className="mt-4 w-full lg:w-1/2">
                      <InputDatePicker
                        label="Pay date"
                        value={{
                          startDate: value,
                          endDate: value,
                        }}
                        onChange={dateOfPayment => {
                          changeDateOfPayment(dateOfPayment);
                        }}
                        register={register('dateOfPayment')}
                        errorMessage={errors?.dateOfPayment?.message}
                        disabledDates={[
                          {
                            startDate: new Date(-8640000000000000), //Min Date
                            endDate: new Date(
                              new Date(payroll.payDate).getTime() - 7 * 24 * 60 * 60 * 1000,
                            ), // Paydate - 1 week
                          },
                          {
                            startDate: new Date(
                              new Date(payroll.payDate).getTime() + 7 * 24 * 60 * 60 * 1000,
                            ), // Paydate + 1 week
                            endDate: new Date(8640000000000000), //Max Date
                          },
                        ]}
                        isNecessary
                        isSingle
                        popoverDirection={popoverDirection}
                      />
                    </div>
                  )}
                />
              </CheckHQWrapper>
              <CheckHQWrapper>
                <div className="mb-10 mr-6 mt-4 flex w-full flex-col lg:w-1/2">
                  <span className="text-sm text-gray-400">Pay date</span>
                  {payroll?.payDate && (
                    <span className="text-medium text-content-heading font-F37Bolton-Medium">
                      {moment(payroll.payDate).format('MMM DD, YYYY')}
                    </span>
                  )}
                </div>
              </CheckHQWrapper>
            </div>
            <div className="h-px w-full bg-gray-100"></div>
            <div className="flex flex-col justify-between p-3 sm:p-5 lg:flex-row">
              <div className="flex">
                <CheckHQWrapper hideItem>
                  <div className="mr-6 flex flex-col">
                    <span className="text-sm text-gray-400">Estimated payroll</span>
                    <NumericFormat
                      value={calculateEstimatedTotal()}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={value => (
                        <span className="font-F37Bolton-Medium mt-1 text-[18px] text-gray-900">
                          ${value}
                        </span>
                      )}
                    />
                  </div>
                </CheckHQWrapper>
                <CheckHQWrapper>
                  <div className="mr-6 flex flex-col">
                    <span className="text-sm text-gray-400">Submit payroll by</span>
                    <span className="font-F37Bolton-Medium text-medium text-content-heading mt-1">
                      {deadlineDate}
                    </span>
                  </div>
                </CheckHQWrapper>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-400">Who’s getting paid</span>
                  <span className="font-F37Bolton-Medium text-medium text-content-heading mt-1">
                    {runnableEmployees?.length}{' '}
                    {runnableEmployees?.length === 1 ? 'employee' : 'employees'}
                  </span>
                </div>
              </div>

              <Tooltip
                className="!bg-navy-500 z-[1] !w-auto max-w-[240px] !rounded-lg !text-sm"
                id={`payroll-disabled-id-${payroll.id}`}
                content={tooltipContent}
                hidden={
                  isLoading ||
                  (!isDraftButton &&
                    !pastPayrolls?.length &&
                    !isOverdue &&
                    !isCheckHQAccountUnderReview &&
                    isCompanyHasBlockingTasks)
                }
              />
              <ButtonUpdated
                {...updatedButtonProps}
                variant="primary"
                children={`${isCheckHqUser ? 'Run' : 'Calculate'} payroll`}
                type="button"
                onPress={handleSubmit(onSubmit)}
                width="w-full lg:w-[170px]"
                isDisabled={isCalculatePayrollButtonDisabled}
                isLoading={isLoading}
                parentClasses="mt-4 lg:mt-0"
              />
            </div>
          </div>
        </div>
      </form>
      {isOpenSkipModal && (
        <NewSkipPayrollModel
          isOpen={isOpenSkipModal}
          handleConfirm={handleSkipPayroll}
          handleClose={handleCloseSkipPayroll}
          isConfirmLoading={isSkipPayrollLoading}
        />
      )}
    </>
  );
};

export default RegularPayrollCardUpdated;
