import { useMemo, useState } from 'react';

import { useFastSpringPaymentMethodContext } from '@/components/shared/FastSpringEmbeddedPaymentProvider/FastSpringEmbeddedPaymentProvider';
import { useBillingInfo } from '@/hooks';

import { getPaymentDateFromReceipt } from './utils/helpers';

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

const useSubscriptionPaymentMethodCard = () => {
  const [isEditPaymentMethodModalOpen, setIsEditPaymentMethodModalOpen] = useState(false);

  const { billingInfo: subscriptionInformation } = useBillingInfo();

  const { loadEmbeddedFastSpringPayment, isLoadingFastSpringData, isFastSpringIdAvailable } =
    useFastSpringPaymentMethodContext();

  const isCancellable =
    !subscriptionInformation?.activeSubscriptionAgreement?.scheduledToBeCancelled &&
    subscriptionInformation?.activeSubscriptionAgreement?.status !== 'canceled' &&
    !isAppModeDemo;

  const formattedData = useMemo(() => {
    const paymentMethods = subscriptionInformation?.paymentMethods;
    const invoices = subscriptionInformation?.invoicesToDisplay;

    const defaultPaymentMethod =
      Array.isArray(paymentMethods) &&
      !!paymentMethods.length &&
      paymentMethods.reduce((max, curr) =>
        curr.id > max.id && curr.status === 'default' ? curr : max,
      );

    /**
     * Get the Payment date from the latest invoice.
     */
    const mostRecentPayment =
      Array.isArray(invoices) && !!invoices.length
        ? invoices.reduce((max, curr) => (curr.id > max.id ? curr : max))
        : null;

    const receiptDetails = Array.isArray(mostRecentPayment?.receipts)
      ? mostRecentPayment?.receipts[0]?.details
      : [];

    let formattedLastPaymentDate = null;

    if (receiptDetails) {
      formattedLastPaymentDate = getPaymentDateFromReceipt(receiptDetails);
    }

    if (defaultPaymentMethod) {
      return {
        ...defaultPaymentMethod,
        acceptedBy: defaultPaymentMethod.name,
        type: defaultPaymentMethod.type,
        lastPaymentDate: formattedLastPaymentDate,
      };
    }
    return null;
  }, [subscriptionInformation]);

  const editPaymentMethodClickHandler = () => {
    setIsEditPaymentMethodModalOpen(true);
  };

  const closePaymentMethodHandler = () => {
    setIsEditPaymentMethodModalOpen(false);
  };

  const successPaymentMethodHandler = () => {
    setIsEditPaymentMethodModalOpen(false);
  };

  return {
    isCancellable,
    formattedData,
    loadEmbeddedFastSpringPayment,
    isLoadingFastSpringData,
    isFastSpringIdAvailable,
    editPaymentMethodClickHandler,
    closePaymentMethodHandler,
    isEditPaymentMethodModalOpen,
    successPaymentMethodHandler,
  };
};

export default useSubscriptionPaymentMethodCard;
