import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { getCall } from '@/api/axios';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';

import { queryEndpoints } from './endpoints';

export type UseGetCompanyLevelDeductionsType = { companyId: number; queryParams?: UseQueryOptions };

const useGetCompanyDeductions = ({
  companyId,
  queryParams,
}: UseGetCompanyLevelDeductionsType): UseQueryResult<DeductionTypes[], Error> => {
  const { queryKey, url } = queryEndpoints.getDeductions(companyId);

  return useQuery<DeductionTypes[], Error>({
    queryKey,
    queryFn: () => getCall(url),
    select: data => data.data,
    ...queryParams,
  });
};

export default useGetCompanyDeductions;
