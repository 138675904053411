import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  contractor: (companyId: number, contractorId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'contractor', contractorId],
    url: `/company/${companyId}/contractor/${contractorId}`,
  }),
  payStubsHistory: (
    companyId: number,
    contractorId: number,
    page: number | null,
    dateOfPaymentBefore: string | null,
    dateOfPaymentAfter: string | null,
  ): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'contractor',
      contractorId,
      'paystubs-history',
      'page',
      page,
      'payment-date-before',
      dateOfPaymentBefore,
      'payment-date-after',
      dateOfPaymentAfter,
    ],
    url: `/company/${companyId}/contractor/${contractorId}/paystubs-history`,
  }),
  downloadStubs: (companyId: number, contractorId: number, payStubsId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'contractor', contractorId, 'download-stubs', payStubsId],
    url: `/company/${companyId}/contractors/${contractorId}/pay-stubs-item/${payStubsId}/download`,
  }),
  downloadPayments: (companyId: number, paymentId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'contractor', 'payment', paymentId, 'cash-requirement-report'],
    url: `/company/${companyId}/contractors/payment/${paymentId}/cash-requirement-report`,
  }),
};

export const mutationEndpoints = {
  putContractor: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'put'],
    url: `company/${companyId}/contractor/${contractorId}`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  putContractorAddress: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'put', 'address'],
    url: `company/${companyId}/contractor/${contractorId}/employment`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  putContractorCompensation: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'put', 'compensation'],
    url: `company/${companyId}/contractor/${contractorId}/compensation`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  deactivateContractor: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'deactivate-contractor'],
    url: `/company/${companyId}/contractor/${contractorId}/de-activate`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  activateContractor: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'activate-contractor'],
    url: `/company/${companyId}/contractor/${contractorId}/activate`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  deleteContractor: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'delete-contractor'],
    url: `/company/${companyId}/contractor/${contractorId}`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  patchContractor: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'patch-contractor'],
    url: `/company/${companyId}/contractor/${contractorId}`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  cancelPayment: (
    companyId: number,
    contractorId: number,
    paymentId: number,
  ): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'cancel-payment'],
    url: `/company/${companyId}/pay-contractors/${contractorId}/cancel/${paymentId}`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  postInvitation: (companyId: number, contractorId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'invite-contractor'],
    url: `/company/${companyId}/contractor/${contractorId}/invite`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
  patchInvitation: (
    companyId: number,
    contractorId: number,
    invitationId: number,
  ): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'contractor', contractorId, 'patch-invitation-contractor'],
    url: `/company/${companyId}/contractor/${contractorId}/invite/${invitationId}`,
    invalidateKeys: [queryEndpoints.contractor(companyId, contractorId).queryKey],
  }),
};

export const checkHQQueryEndpoints = {
  taxDocuments: (companyId: number, contractorId: number): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'contractor',
      contractorId,
      'tax-documents',
      'checkhq-component',
    ],
    url: `/company/${companyId}/contractor/${contractorId}/checkhq/component/tax-documents`,
  }),
  syncContractorData: (companyId: number, contractorId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'contractor', contractorId, 'sync-checkhq-contractor-data'],
    url: `/company/${companyId}/contractor/${contractorId}/checkhq/sync`,
  }),
};
