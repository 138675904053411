import React from 'react';
import { Controller } from 'react-hook-form';

import InputMaskTextField from '../InputMaskTextField';
import { FormInputMaskTextFieldProps } from './types';

const FormInputMaskTextField: React.FC<FormInputMaskTextFieldProps> = ({
  name,
  isRequired,
  rules,
  isDisabled,
  shouldUnregister = true,
  onBlur,
  onCustomChange,
  ...rest
}) => (
  <Controller
    name={name}
    shouldUnregister={shouldUnregister}
    disabled={Boolean(isDisabled)}
    render={({
      field: { value, onBlur: onFormInputBlur, onChange, ref, ...restField },
      fieldState: { error },
    }) => {
      const handleChange = onCustomChange
        ? (newValue: any) => onCustomChange(newValue, onChange)
        : onChange;
      return (
        <InputMaskTextField
          errorMessage={error && !!error && error.message}
          isRequired={Boolean(isRequired || rules?.required)}
          value={value}
          isDisabled={isDisabled}
          ref={ref}
          {...rest}
          {...restField}
          onChange={handleChange}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            onFormInputBlur();
            onBlur?.(event);
          }}
        />
      );
    }}
    rules={rules}
  />
);

export default FormInputMaskTextField;
