import React from 'react';

import DialogComponent from '@/components/Dialog/DialogComponent';
import { UrgentModal } from '@/components/ui/alerts/UrgentModal/UrgentModal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

type UnassignDeductionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoading?: boolean;
  employeeName: string;
};

const UnassignDeductionModal: React.FC<UnassignDeductionModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  isLoading = false,
  employeeName,
}) => {
  return (
    <DialogComponent
      open={isOpen}
      onCancel={onClose}
      width="w-[95vw] sm:w-[352px]"
      height="h-auto"
      modalClasses="rounded-[8px] relative"
    >
      <UrgentModal
        icon={<ExclamationTriangleIcon className="w-6  text-red-500" />}
        title={`Unassign deduction for ${employeeName}?`}
        subTitle="This change may affect the future payroll calculations"
        isConfirmLoading={isLoading}
        onConfirm={onDelete}
        onClose={onClose}
        onCancel={onClose}
        confirmButtonText="Unassign"
        buttonWarperClasses="flex flex-row justify-between"
      />
    </DialogComponent>
  );
};

export default UnassignDeductionModal;
