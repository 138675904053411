import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

import { AlertType } from './types';
import { useAlert } from './useAlert';

export const Alert = ({
  variant,
  parentClasses,
  title,
  subtitle,
  isDeletable = true,
  isIconVisible = true,
  onClose,
}: AlertType) => {
  const { isVisible, setIsVisible, alertStyles, alertIcon } = useAlert();
  const { t } = useTranslation();

  return isVisible ? (
    <div
      className={`relative flex w-full items-start rounded-lg p-4 ${alertStyles[variant]} ${
        parentClasses && parentClasses
      }`}
    >
      {isIconVisible && alertIcon[variant]}
      <div className="ml-3 flex flex-col items-start pr-11">
        {title && (
          <span className="font-F37Bolton-Medium flex text-[16px] text-gray-500">{t(title)}</span>
        )}
        <span className="text-left text-sm text-gray-500">
          {typeof subtitle === 'string' ? t(subtitle) : subtitle}
        </span>
      </div>
      {isDeletable && (
        <XMarkIcon
          className="absolute right-4 top-4 size-4 cursor-pointer text-gray-400"
          onClick={() => {
            onClose?.();
            setIsVisible(false);
          }}
        />
      )}
    </div>
  ) : null;
};
