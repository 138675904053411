import { twMerge } from 'tailwind-merge';
import { VerticalStepperProps } from './types';

const VerticalStepper = ({
  tabs,
  currentTab,
  parentClasses,
  width,
  stepClasses,
}: VerticalStepperProps) => {
  return (
    <div className={twMerge(`flex w-full flex-col gap-6`, parentClasses, width)}>
      {tabs.map(tab => (
        <div key={tab.id} className={twMerge('flex w-full gap-2', stepClasses)}>
          <div className="flex grow flex-col">{tab.content}</div>
        </div>
      ))}
    </div>
  );
};

export default VerticalStepper;
