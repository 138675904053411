import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postCall } from '@/api/axios';
import { usePostBenefitType } from './types';
import { POST_GARNISHMENT } from './employeeApiRoutes';

const usePostGarnishment = ({ companyId, employeeId }: usePostBenefitType) => {
  const queryClient = useQueryClient();

  const updateGarnishments = (data: any) =>
    postCall(POST_GARNISHMENT({ companyId, employeeId }), data);

  return useMutation({
    mutationFn: updateGarnishments,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getGarnishments'] });
    },
  });
};

export default usePostGarnishment;
