export const CHECKHQ_COMPONENT_EVENTS = {
  CHECK_COMPONENT__APP_LOADED: 'check-component-app-loaded',
  CHECK_COMPONENT_EMPLOYEE_PAYMENT_SETUP_COMPLETE:
    'check-component-employee-payment-setup-complete',
  CHECK_COMPONENT_EMPLOYEE_BANK_ACCOUNT_UPDATED: 'check-component-employee-bank-account-updated',
  CHECK_COMPONENT_EMPLOYEE_WITHHOLDINGS_SETUP_COMPLETE:
    'check-component-employee-withholdings-setup-complete',
  CHECK_COMPONENT_EMPLOYEE_SSN_SETUP_COMPLETE: 'check-component-employee-ssn-setup-complete',
  CHECK_COMPONENT_VERIFICATION_DOCUMENT_COMPLETE: 'check-component-verification-document-complete',
} as const;

// Constants for statuses
export const CHECKHQ_ONBOARDING_STATUSES = {
  completed: 'completed',
  needs_attention: 'needs_attention',
  blocking: 'blocking',
} as const;

export const CHECKHQ_IMPLEMENTATION_STATUSES = {
  not_initiated: 'not_initiated',
  in_review: 'in_review',
  needs_attention: 'needs_attention',
  denied: 'denied',
  four_day_approved: 'four_day_approved',
  two_day_approved: 'two_day_approved',
  completed: 'completed',
} as const;

export const CHECKHQ_REQUIREMENTS_CATEGORIES = {
  company_provided_documents: 'company_provided_documents',
  failed_payroll_debit: 'failed_payroll_debit',
} as const;

export const CHECKHQ_REQUIREMENTS_STATUSES = {
  open: 'open',
  pending: 'pending',
  resolved: 'resolved',
  closed: 'closed',
  failed: 'failed',
} as const;

export const CHECKHQ_BLOCKER_STATES = {
  bank_account: 'bank_account',
  filing_authorization: 'filing_authorization',
  setup_parameters: 'setup_parameters',
} as const;

export const BANK_ACCOUNT_BLOCKING_SUBSTEPS: Record<string, string> = {
  bank_account_debit_authorization_submitted:
    'Submit bank details for bank account debit authorization',
  bank_account_exists: 'Add bank account',
  bank_account_verified: 'Verify bank account',
};

export const COMPANY_ONBOARDING_STEP_CONFIGS: Record<
  string,
  { title: string; description: string; actionState: keyof typeof CHECKHQ_BLOCKER_STATES }
> = {
  bank_account: {
    title: 'Bank Accounts',
    description: 'Required for paying employees and tax remittance',
    actionState: CHECKHQ_BLOCKER_STATES.bank_account,
  },
  filing_authorization: {
    title: 'Filing Authorization',
    description: 'We need some more documents for automated tax filing',
    actionState: CHECKHQ_BLOCKER_STATES.filing_authorization,
  },
  setup_parameters: {
    title: 'Set up taxes',
    description: 'We need some more documents to set up your company for payroll.',
    actionState: CHECKHQ_BLOCKER_STATES.setup_parameters,
  },
};

export const CHECKHQ_PAYMETHOD_TYPES = {
  manual: 'manual',
  direct_deposit: 'direct_deposit',
};

export const CHECKHQ_PAYMETHOD_TYPE_TITLE = {
  manual: 'Cash/check',
  direct_deposit: 'Direct Deposit',
};

export const CHECKHQ_BENEFIT_TYPES = [
  '125 Medical',
  '125 Vision',
  '125 Dental',
  '125 Disability',
  '125 Accident',
  '125 Cancer',
  '125 Critical Illness',
  '125 Hospital',
  '125 Life',
  '125 Medical Other',
  '401(k)',
  '403(b)',
  '457',
  'Roth 401(k)',
  'Roth 403(b)',
  'Roth 457',
  'FSA Medical',
  'FSA Dependent Care',
  'HSA',
  'SIMPLE IRA',
];

type BenefitIdMap = Record<string, number>;

export const CHECKHQ_BENEFITS_TO_ID_MAP: BenefitIdMap = {
  '125 Medical': 1, // Maps to 125_medical
  '125 Vision': 2, // Maps to 125_vision
  '125 Dental': 3, // Maps to 125_dental
  '125 Disability': 14, // Maps to 125_disability
  '125 Accident': 20, // Maps to 125_accident
  '125 Cancer': 15, // Maps to 125_cancer
  '125 Critical Illness': 17, // Maps to 125_critical_illness
  '125 Hospital': 18, // Maps to 125_hospital
  '125 Life': 16, // Maps to 125_life
  '125 Medical Other': 19, // Maps to 125_medical_other
  '401(k)': 7, // Maps to 401k
  '403(b)': 9, // Maps to 403b
  '457': 11, // Maps to 457
  'Roth 401(k)': 8, // Maps to roth_401k
  'Roth 403(b)': 10, // Maps to roth_403b
  'Roth 457': 12, // Maps to roth_457
  'FSA Medical': 4, // Maps to fsa_medical
  'FSA Dependent Care': 5, // Maps to fsa_dependent_care
  HSA: 6, // Maps to hsa
  'SIMPLE IRA': 13, // Maps to simple_ira
};

export const CHECKHQ_PAY_PERIOD_TYPES = {
  pay_period: 'pay_period',
  monthly: 'monthly',
};
