import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAccountInfo } from '@/hooks';

const useEmployeeContribution = () => {
  const { isCheckHqUser } = useAccountInfo();
  const form = useFormContext();
  const { watch, setValue } = form || {};

  const deductionCalculatedAs = watch('calculatedAs');
  const isCalculatedAsFixedAmount = parseInt(deductionCalculatedAs) === 1;
  const shouldShowContributionFields = [1, 2].includes(parseInt(deductionCalculatedAs));
  const period = form.watch('period') || 'pay_period';

  // Constants for dynamic field properties
  const contributionFieldName = isCalculatedAsFixedAmount
    ? 'amountPerPayPeriod'
    : 'percentageGrossPay';
  const contributionFieldTitle = isCalculatedAsFixedAmount
    ? 'Amount per pay check'
    : 'Percentage per pay check';
  const contributionAdornment = isCalculatedAsFixedAmount ? '$' : '%';

  useEffect(() => {
    if (period === 'monthly') {
      setValue('deductionCalculatedAs', 1);
    }
  }, [period, setValue]);

  return {
    deductionCalculatedAs,
    isCalculatedAsFixedAmount,
    shouldShowContributionFields,
    contributionFieldName,
    contributionAdornment,
    contributionFieldTitle,
    isCheckHqUser,
    period,
  };
};
export default useEmployeeContribution;
