import { twMerge } from 'tailwind-merge';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import { checkAccountForSubscriptionReminder } from '@/utils/helpers';

import AccountSetupSupport from '../AccountSetupSupport/AccountSetupSupport';
import ContentBlockInfoBox from '../ContentBlockInfoBox/ContentBlockInfoBox';
import { AccountSetupInfoCardsProps } from './types';

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

const AccountSetupInfoCards = ({
  wrapperClasses,
  identifier,
  isTeamMemberRouts,
}: AccountSetupInfoCardsProps) => {
  const { isAuthenticatedAsImpersonate, account } = useAccountInfo();
  const { billingInfo } = useBillingInfo();

  const bannerInfo = checkAccountForSubscriptionReminder({
    subscriptionInfo: billingInfo,
    account,
  });

  // Additional height of header from the demo banner/subscription reminder banner/Impersonation Reminder banner
  const isAdditionHeaderBanner =
    isAppModeDemo || bannerInfo?.bannerVariantToShow || isAuthenticatedAsImpersonate;

  return (
    <div
      className={twMerge(`w-full xl:w-[335px]`, isTeamMemberRouts && 'xl:w-full', wrapperClasses)}
    >
      <div
        className={twMerge(
          `flex items-center md:right-0 md:top-[88px]`,
          !isTeamMemberRouts && 'md:sticky ',
          isAdditionHeaderBanner && 'md:top-[144px]',
        )}
      >
        <div className="flex w-full flex-col gap-4 overflow-hidden ">
          {!!identifier && <ContentBlockInfoBox identifier={identifier} />}
          <div className="border-navy-100 hidden w-full justify-center rounded-md border bg-white lg:flex">
            <div className="w-full max-w-[335px]">
              <AccountSetupSupport
                isDiscountCopyVisible={!!account?.metadata?.isOnboardingCallScheduled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupInfoCards;
