import { MutableRefObject, useState } from 'react';
import { SubmitErrorHandler, UseFormReturn } from 'react-hook-form';

import { DeductionClickType } from '@/modules/employee/forms';

type EmployeeData = { firstName: string; lastName: string };

export type EmployeeDeductionProps = {
  form: UseFormReturn<any>;
  data: {
    name: string;
    employees: EmployeeData[];
    id: number;
    type: string;
    benefitType?: string;
    garnishmentType?: string;
    totalEmployeesWithDeduction: number;
    effectiveStart?: string;
  };
  formRef: MutableRefObject<null>;
  onDeductionSubmit: () => void;
  onDeleteDeduction: () => void;
  isShowingEditDeduction?: boolean;
  onError: SubmitErrorHandler<any>;
  employeeSsn?: string | null;
  isEdit?: boolean;
  handleDeductionClick?: (data: DeductionClickType) => void;
  isCheckHqUser?: boolean;
  handleEditDeduction?: (data: any) => void;
};

const useEmployeeDeductionForm = ({
  onDeleteDeduction,
  isCheckHqUser,
}: {
  onDeleteDeduction: VoidFunction;
  isCheckHqUser?: boolean;
}) => {
  const [isDeductionDrawerOpen, setIsDeductionDrawerOpen] = useState(false);
  const handleDeductionDrawer = () => {
    if (!isCheckHqUser && isDeductionDrawerOpen) {
      onDeleteDeduction();
    }
    setIsDeductionDrawerOpen(!isDeductionDrawerOpen);
  };

  const tooltipText = (employees: EmployeeData[]) =>
    employees?.map(emp => `${emp?.firstName} ${emp?.lastName}`).join(', ');

  return { isDeductionDrawerOpen, handleDeductionDrawer, tooltipText };
};
export default useEmployeeDeductionForm;
