import { MutationEndpoint } from '@/api/types';

import { QueryEndpoint } from '../types';

export const queryEndpoints = {
  payrollHistory: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll-history', 'contractor'],
    url: `/company/${companyId}/contractors/payroll-history`,
  }),
  getActiveContractors: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'get-active-contractors'],
    url: `/company/${companyId}/pay-contractors/get-active`,
  }),
  getPayrollInfo: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll-info'],
    url: `/company/${companyId}/payroll-info
`,
  }),
  paymentRecordDetail: (companyId: number, paymentHistoryId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll-history', paymentHistoryId, 'detail'],
    url: `/company/${companyId}/contractors/payroll-history/${paymentHistoryId}/details`,
  }),
  getDownloadAllContractorPayStubs: (companyId: number, payStubId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payStubId', payStubId, 'contractor'],
    url: `/company/${companyId}/contractors/pay-stubs/${payStubId}/download`,
  }),
  getViewPayStubDetail: (
    companyId: number,
    contractorId: number,
    paymentId: number,
  ): QueryEndpoint => ({
    queryKey: ['company', companyId, 'pay-contractors', 'share'],
    url: `/company/${companyId}/contractor/${contractorId}/preview-pay-stubs/${paymentId}`,
  }),
};

export const mutationEndpoints = {
  submitPaymentInformation: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-contractors', 'submit'],
    url: `/company/${companyId}/pay-contractors/submit`,
    invalidateKeys: [],
  }),
  updatePaymentInformation: (companyId: number, paymentId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-contractors', 'update'],
    url: `/company/${companyId}/pay-contractors/${paymentId}/update`,
    invalidateKeys: [],
  }),
  sharePayStubs: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-contractors', 'share'],
    url: `/company/${companyId}/contractor-paystubs/share`,
    invalidateKeys: [],
  }),
  confirmPayment: (companyId: number, id: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-contractors', 'confirm'],
    url: `/company/${companyId}/pay-contractors/${id}/confirm`,
    invalidateKeys: [],
  }),
  reopenPayment: (companyId: number, id: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'pay-contractors', 'reopen'],
    url: `/company/${companyId}/pay-contractors/${id}/re-open`,
    invalidateKeys: [],
  }),
};
