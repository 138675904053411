import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as WarningIcon } from '@/assets/warning.svg';
import { EinDescription } from '@/components';
import DashboardTodoItem, {
  DashboardTodoItemProps,
} from '@/components/shared/DashboardTodoItem/DashboardTodoItem';
import { useAccountInfo } from '@/hooks';
import * as routes from '@/routes/routes';
import { BRAND_INFO } from '@/utils/constants';
import { isArrayHasData } from '@/utils/helpers';

interface DashboardTodoItemsProps {
  setIsPayrollScheduleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  items: DashboardTodoItemProps[];
  companyId: number;
  onToggleTaxModal: () => void;
}

const RunPayrollTodoItems: React.FC<DashboardTodoItemsProps> = ({
  setIsPayrollScheduleOpen,
  items,
  companyId,
  onToggleTaxModal,
}) => {
  const navigate = useNavigate();
  const { isCheckHqUser } = useAccountInfo();

  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);

  const getStatus = (index: number) => {
    if (items[index]) {
      return items[index]?.isDone;
    } else {
      return true;
    }
  };

  const { company } = useAccountInfo();

  const businessTypeSoleProp = company?.businessType === 1 || false;

  const isScheduleDone = items?.find(el => el.action === 'add_payroll_schedule')?.isDone;

  const newSchema = items?.map(({ action, isDone }) => {
    switch (action) {
      case 'add_company_ein_or_ssn':
        return {
          label: '1. Add your EIN or SSN',
          action: action,
          description: <EinDescription businessTypeSoleProp={businessTypeSoleProp} />,
          isDone: isDone,
          buttonText: businessTypeSoleProp ? 'Add EIN or SSN' : 'Add your EIN',
          isScheduleDone,
          onPressButton: () => navigate(routes.COMPANY),
        };
      case 'add_employee':
        return {
          label: `2. Add your employees`,
          action: action,
          isDone: isDone,
          buttonText: 'Add a employee',
          isScheduleDone,
          onPressButton: isScheduleDone
            ? () => navigate(routes.TEAM_MEMBERS)
            : () => setIsWarningOpen(true),
          description: (
            <p className="text-base text-gray-700">
              Add all the employees which are paid on the payroll schedule added
            </p>
          ),
        };
      case 'add_payroll_schedule':
        return {
          label: '1. Set your payroll schedule',
          action: 'missing_payroll_schedule',
          isDone: isDone,
          buttonText: 'Create a schedule',
          isScheduleDone,
          description: (
            <p className="text-base text-gray-700">
              Set the pay period and pay date you want to start recording on {BRAND_INFO.name}
            </p>
          ),
          onPressButton: () => setIsPayrollScheduleOpen(true),
        };
      case 'add_employer_tax':
        return {
          label: '2. Confirm employer tax info',
          action: 'missing_employee_tax_information',
          isDone: isDone,
          buttonText: 'Confirm tax information',
          isScheduleDone,
          description: (
            <p className="text-base text-gray-700">
              This will help us accurately calculate the employer taxes on employee payroll
            </p>
          ),
          onPressButton: isCheckHqUser ? () => navigate(routes.COMPANY) : () => onToggleTaxModal(),
        };
      case 'update_employees_YTD':
        return {
          label: 'Update YTD Amount',
          action: 'update_employees_YTD',
          isDone: isDone,
          buttonText: 'Update YTD Amount',
          isScheduleDone,
          description: (
            <p className="text-base text-gray-700">
              Employees’ gross pay YTD and previous tax withholdings need to be updated
            </p>
          ),
          onPressButton: () => navigate(routes.TEAM_MEMBERS),
        };
      default:
        return { action, isDone };
    }
  });

  return (
    <div className=" flex flex-col">
      <span className="mt-[8px] text-gray-500">
        Help us calculate your taxes accurately. Please add the below details once and leave the
        rest to us
      </span>
      <span className="font-F37Bolton-Medium mt-[24px] text-[18px]">
        Set up your employee payroll
      </span>

      {isArrayHasData(newSchema) &&
        newSchema
          .map((item, index) => {
            if (item.action === 'add_employee' || item.action === 'missing_payroll_schedule') {
              return (
                <DashboardTodoItem
                  companyId={companyId}
                  key={index}
                  {...item}
                  status={getStatus(index)}
                  items={items}
                />
              );
            }
          })
          .reverse()}
      {isArrayHasData(newSchema) &&
        newSchema?.map((item, index) => {
          if (item.action === 'update_employees_YTD') {
            return (
              <DashboardTodoItem
                companyId={companyId}
                key={index}
                {...item}
                status={getStatus(index)}
                items={items}
              />
            );
          }
        })}
      {isWarningOpen && (
        <div className="rounded-medium mt-[16px] flex w-full border border-[#F6BA50] bg-[#FEF8EE] p-[16px]">
          <div>
            <WarningIcon />
          </div>
          <span className="ml-[12px] text-gray-400">
            Please add your payroll schedule first so we can help you determine employees YTD
            amounts
          </span>
        </div>
      )}
      <span className="font-F37Bolton-Medium mt-[24px] text-[18px]">
        Complete your business profile
      </span>
      {isArrayHasData(newSchema) &&
        newSchema?.map((item, index) => {
          if (
            item.action === 'add_company_ein_or_ssn' ||
            item.action === 'missing_employee_tax_information'
          ) {
            return (
              <DashboardTodoItem
                companyId={companyId}
                key={index}
                {...item}
                status={getStatus(index)}
                items={items}
              />
            );
          }
        })}
    </div>
  );
};

export default RunPayrollTodoItems;
