import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  employee: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId],
    url: `/company/${companyId}/employee/${employeeId}`,
  }),
  taxRecords: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'tax-records'],
    url: `/company/${companyId}/employee/${employeeId}/show-tax-record`,
  }),
  benefits: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'benefit'],
    url: `/company/${companyId}/employee/${employeeId}/benefit`,
  }),
  garnishments: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'garnishment'],
    url: `/company/${companyId}/employee/${employeeId}/garnishment`,
  }),
  deductions: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'deduction'],
    url: `/company/${companyId}/employee/${employeeId}/custom-post-tax-deduction`,
  }),
  payStubsHistory: (
    companyId: number,
    employeeId: number,
    page: number | null,
    dateOfPaymentBefore: string | null,
    dateOfPaymentAfter: string | null,
  ): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'employee',
      employeeId,
      'paystubs-history',
      'page',
      page,
      'payment-date-before',
      dateOfPaymentBefore,
      'payment-date-after',
      dateOfPaymentAfter,
    ],
    url: `/company/${companyId}/employees/${employeeId}/paystubs-history`,
  }),
  previewTaxForms: (companyId: number, employeeId: number, taxFormId: number): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'employee',
      employeeId,
      'tax-forms',
      'w2',
      taxFormId,
      'preview',
    ],
    url: `/tax-forms/w2/${taxFormId}/preview`,
  }),
  downloadTaxForm: (companyId: number, employeeId: number, taxFormId: number): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'employee',
      employeeId,
      'tax-forms',
      'w2',
      taxFormId,
      'download',
    ],
    url: `/tax-forms/w2/${taxFormId}/download`,
  }),
  yearlyTaxForms: (companyId: number, employeeId: number, year: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'tax-forms', 'w2', 'list', year],
    url: `/tax-forms/w2/list/${year}`,
  }),
  getDeductions: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'get-deductions'],
    url: `/company/${companyId}/deduction`,
  }),
};

export const mutationEndpoints = {
  postEmployee: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', 'post'],
    url: `company/${companyId}/employee/create`,
    invalidateKeys: [],
  }),
  patchEmployee: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'patch'],
    url: `/company/${companyId}/employee/${employeeId}`,
    invalidateKeys: [],
  }),
  postTaxRecord: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'submit-tax-record'],
    url: `/company/${companyId}/employee/${employeeId}/submit-tax-record`,
    invalidateKeys: [queryEndpoints.employee(companyId, employeeId).queryKey],
  }),
  postEmployeeInvite: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'employee-invite'],
    url: `company/${companyId}/employee/${employeeId}/tax-withholding/invite`,
    invalidateKeys: [queryEndpoints.employee(companyId, employeeId).queryKey],
  }),
  patchEmployeeInvite: (
    companyId: number,
    employeeId: number,
    invitationId: number,
  ): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'employee-invite'],
    url: `/company/${companyId}/employee/${employeeId}/tax-withholding/invite/${invitationId}`,
    invalidateKeys: [
      queryEndpoints.employee(companyId, employeeId).queryKey,
      queryEndpoints.taxRecords(companyId, employeeId).queryKey,
    ],
  }),
  postApproveTaxWithholdings: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'approve-tax-withholdings'],
    url: `/company/${companyId}/employee/${employeeId}/tax-withholding-details/approve`,
    invalidateKeys: [
      queryEndpoints.employee(companyId, employeeId).queryKey,
      queryEndpoints.taxRecords(companyId, employeeId).queryKey,
    ],
  }),
  deactivateEmployee: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'employee-status'],
    url: `/company/${companyId}/employee/${employeeId}/de-activate`,
    invalidateKeys: [queryEndpoints.employee(companyId, employeeId).queryKey],
  }),
  activateEmployee: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'employee-status'],
    url: `/company/${companyId}/employee/${employeeId}/activate`,
    invalidateKeys: [queryEndpoints.employee(companyId, employeeId).queryKey],
  }),
  deleteEmployee: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'employee', employeeId, 'delete-employee'],
    url: `/company/${companyId}/employee/${employeeId}`,
    invalidateKeys: [queryEndpoints.employee(companyId, employeeId).queryKey],
  }),
  patchEmployeeW2Form: (companyId: number, employeeId: number, id: number): MutationEndpoint => ({
    mutationKey: ['w2FormId', id, 'patch'],
    url: `/tax-forms/w2/${id}`,
    invalidateKeys: [queryEndpoints.employee(companyId, employeeId).queryKey],
  }),
  shareEmployeeW2Form: (formId: number): MutationEndpoint => ({
    mutationKey: ['w2FormId', formId, 'share'],
    url: `/tax-forms/w2/${formId}/share`,
  }),
};

export const checkHQQueryEndpoints = {
  paymentSetupComponent: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'checkhq-payment-setup'],
    url: `/company/${companyId}/employee/${employeeId}/checkhq/component/payment-setup`,
  }),
  federalTaxesComponent: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'checkhq-federal-taxes'],
    url: `/company/${companyId}/employee/${employeeId}/checkhq/component/withholdings-setup`,
  }),
  stateTaxesComponent: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'checkhq-tax-documents'],
    url: `/company/${companyId}/employee/${employeeId}/checkhq/component/tax-documents`,
  }),
  syncEmployeeTaxes: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'sync-checkhq-employee-tax'],
    url: `/company/${companyId}/employee/${employeeId}/checkhq/sync`,
  }),
  employeeTaxDocumentComponent: (companyId: number, employeeId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'employee', employeeId, 'checkhq-employee-tax-documents'],
    url: `/company/${companyId}/employee/${employeeId}/checkhq/component/tax-documents`,
  }),
  companyReportsComponent: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'checkhq-company-reports'],
    url: `/company/${companyId}/checkhq/component/company-reports`,
  }),
};
