import { useMutation } from '@tanstack/react-query';

import { patchCall } from '@/api/axios';

import { UPDATE_COMPANY_TAX_INFORMATION } from '../employerTaxApiRoutes';
import { usePatchCompanyTaxInformationType } from './types';

const usePatchCompanyTaxInformation = ({
  companyId,
  mutationParams,
}: usePatchCompanyTaxInformationType) => {
  const updateTaxInformation = (data: any) =>
    patchCall(UPDATE_COMPANY_TAX_INFORMATION(companyId), data);

  return useMutation({
    mutationFn: updateTaxInformation,
    ...mutationParams,
  });
};

export default usePatchCompanyTaxInformation;
