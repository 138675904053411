import EmployerTaxItem from '@/components/shared/EmployerTaxItem/EmployerTaxItem';
import { LoaderComponent } from '@/components/ui';

import { SetupFederalTaxProps } from './types';
import useSetupFederalTax from './useSetupFederalTax';

const SetupFederalTax = ({
  onSubmit,
  isLastStep,
  handleBackBtnClick,
  hideSubmitButton,
  handleIsDirty,
}: SetupFederalTaxProps) => {
  const { federalTaxData, isLoadingTaxInformation } = useSetupFederalTax();

  return (
    <div className="mt-6">
      {isLoadingTaxInformation ? (
        <LoaderComponent />
      ) : (
        !!federalTaxData &&
        federalTaxData.map(taxItem => {
          return (
            <EmployerTaxItem
              handleBackBtnClick={handleBackBtnClick}
              key={taxItem.id}
              onSubmit={onSubmit}
              shouldShowAllFields={true}
              currentTaxItem={taxItem}
              isLastStep={isLastStep}
              isFederalTaxItem={true}
              shouldShowBackBtn={hideSubmitButton}
              handleIsDirty={handleIsDirty}
            />
          );
        })
      )}
    </div>
  );
};

export default SetupFederalTax;
