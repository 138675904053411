import { DateTime } from 'luxon';

import { useAccountInfo } from '@/hooks';
import { RunnablePayroll } from '@/redux/dto/employee';
import { formatApprovalDeadline } from '@/utils/helpers';

// Define a common type
type NoPendingPayrollCardBase = {
  payroll: RunnablePayroll;
  isCheckHQAccountUnderReview?: boolean;
  isCompanyHasBlockingTasks?: boolean;
};

// Use the base type for both cases
export type NoPendingPayrollCardProps = NoPendingPayrollCardBase;

type UseNoPendingPayrollCardTypes = NoPendingPayrollCardBase;

const useNoPendingPayrollCard = ({ payroll }: UseNoPendingPayrollCardTypes) => {
  const { isCheckHqUser } = useAccountInfo();
  const startDate = DateTime.fromISO(payroll?.payPeriod?.startDate, { setZone: true });
  const endDate = DateTime.fromISO(payroll?.payPeriod?.endDate, { setZone: true });
  const currentDate = DateTime.now();

  const formattedStartDate = startDate.toFormat('d MMM');
  const formattedEndDate = endDate.toFormat('d MMM, yyyy');
  const formattedPeriod = `${formattedStartDate} - ${formattedEndDate}`;

  const differenceInDays = Math.ceil(startDate.diff(currentDate, 'days').days) || 0;
  const nextPayDate = DateTime.fromISO(payroll?.payDate, { setZone: true });
  const formattedNextPayDate = nextPayDate.toFormat('d MMM, yyyy');

  const formattedApprovalDeadline = formatApprovalDeadline(
    payroll?.approvalDeadline,
    undefined,
    true,
  );

  const payrollTimeline = [
    {
      label: 'For pay period',
      value: formattedPeriod,
    },
    isCheckHqUser && {
      label: 'Approval deadline',
      value: formattedApprovalDeadline,
    },
    {
      label: 'Pay date',
      value: formattedNextPayDate,
    },
  ];

  return {
    payrollTimeline,
    differenceInDays,
  };
};
export default useNoPendingPayrollCard;
