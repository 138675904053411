import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { Link } from 'react-aria-components';
import { tv } from 'tailwind-variants';

import { ReactComponent as CheckIcon } from '@/assets/checkmark.svg';
import { findLastIndexPolyfill } from '@/utils/helpers';

import { VERTICAL_STEPPER_SIDEBAR_STATUS } from './constants';
import { VerticalStepperSidebarProps } from './types';

const { completed, pending, disabled } = VERTICAL_STEPPER_SIDEBAR_STATUS;

const ConnectorLine = ({ show = true }: { show: boolean }) => {
  return (
    <div className={`absolute flex h-full flex-col justify-center ${!show && 'hidden'}`}>
      <div className="mb-2 ml-5 h-[calc(100%-36px)] border-l-2 border-dotted border-gray-200" />
    </div>
  );
};

// eslint-disable-next-line tailwindcss/no-custom-classname
export const verticalStepperSidebar = tv({
  slots: {
    base: 'w-full h-auto bg-white flex-col gap-y-8 overflow-y-auto p-5 sm:p-[22px]',
    tab: 'block relative h-[66px] w-full rounded-xl px-4 sm:px-4 py-3 relative pointer',
    iconTitleWrapper: 'flex-row gap-4 ',
    iconWrapper: 'size-[42px] justify-center rounded-full bg-gray-100',
    icon: 'size-6',
    title: 'flex flex-col',
    rightIcon: 'size-4 ml-auto',
    markIcon: 'size-5 text-emerald-500',
    inProgressText: 'text-xs text-yellow-500 hidden',
  },
  variants: {
    status: {
      current: {
        tab: 'cursor-pointer',
        iconWrapper: 'bg-emerald-500',
        icon: 'text-white',
      },
      completed: {
        tab: 'cursor-pointer',
        iconWrapper: 'bg-emerald-100',
      },
      pending: {
        title: 'text-gray-300',
        icon: 'text-gray-300',
      },
      disabled: '',
    },
    isHidden: {
      true: {
        tab: 'hidden',
      },
    },
    isActive: {
      true: {
        tab: 'bg-gray-100 cursor-pointer',
        iconWrapper: 'bg-emerald-500',
        icon: 'text-white',
      },
    },
    showInProgress: {},
    size: {
      sm: {
        icon: 'size-4',
      },
    },
  },
  compoundSlots: [
    {
      slots: ['title', 'iconWrapper', 'iconTitleWrapper', 'base'],
      class: ['flex'],
    },
    {
      slots: ['iconTitleWrapper', 'iconWrapper'],
      class: ['items-center'],
    },
    {
      slots: ['title'],
      status: ['current', 'pending'],
      isActive: true,
      class: ['text-black'],
    },
    {
      slots: ['inProgressText'],
      status: ['current'],
      isActive: false,
      class: ['flex'],
    },
    {
      slots: ['inProgressText'],
      showInProgress: true,
      class: ['flex'],
    },
  ],
});

const VerticalStepperSidebar = ({
  steps,
  currentStep,
  handleClick,
}: VerticalStepperSidebarProps) => {
  const {
    base,
    tab,
    iconTitleWrapper,
    iconWrapper,
    icon,
    title,
    rightIcon,
    markIcon,
    inProgressText,
  } = verticalStepperSidebar();

  const currentStepData = steps.find(item => item.key === currentStep);
  const visibleSteps = steps.filter(item => !item.isHidden);
  const currentStepIndex = visibleSteps.findIndex(item => item.key === currentStep) ?? 0;

  useEffect(() => {
    const handleClick = () => {
      const sidebarEl = document.getElementById('sidebar');
      sidebarEl?.classList.toggle('-translate-y-full');
      const dropdownIcon = document.getElementById('dropdownIcon');
      dropdownIcon?.classList.toggle('rotate-180');
    };

    const currentStepElement = document.getElementById('mobileStepperHeader');
    currentStepElement?.addEventListener('click', handleClick);

    return () => {
      currentStepElement?.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="relative">
      {currentStepData && (
        <div className="bg-gray relative flex flex-col p-5 md:hidden">
          <div className="">
            <p className="mb-[10px] text-xs text-gray-300">ONBOARDING CHECKLIST</p>
            <div
              className="flex cursor-pointer justify-between rounded-lg bg-white p-4"
              id="mobileStepperHeader"
            >
              <div className="flex items-center gap-3">
                <div>
                  <currentStepData.icon className={icon({ size: 'sm' })} aria-hidden="true" />
                </div>
                <div>{currentStepData.title}</div>
              </div>
              <div className="flex items-center gap-2">
                <div>
                  <span>{currentStepIndex + 1}</span>
                  <span className="text-gray-300">/{visibleSteps.length}</span>
                </div>
                <div>
                  <ChevronDownIcon id="dropdownIcon" className="size-5 transition" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        id="sidebar"
        className="absolute top-28 z-[-1] h-[calc(100vh-114px-56px)] w-full -translate-y-full overflow-y-auto backdrop-blur-sm transition md:relative md:top-0 md:z-[1] md:flex md:h-full md:translate-y-0"
      >
        <div className={base()}>
          {Array.isArray(steps) &&
            steps.map((item, index, arr) => {
              const { icon: Icon, isHidden, key, status, showInProgress } = item;
              const isStepActive = currentStep === item.key;
              const isShowConnector = findLastIndexPolyfill(arr, data => !data.isHidden) !== index;

              return (
                <Link
                  key={key}
                  className={tab({
                    status: status,
                    isActive: isStepActive,
                    isHidden: isHidden,
                  })}
                  isDisabled={[pending, disabled].includes(status)}
                  onPress={() => {
                    handleClick(item);
                    const sidebarEl = document.getElementById('sidebar');
                    sidebarEl?.classList.toggle('-translate-y-full');
                  }}
                >
                  <div className={iconTitleWrapper()}>
                    <div
                      className={iconWrapper({
                        status: status && !isStepActive ? pending : status,
                        isActive: isStepActive,
                      })}
                    >
                      {status === completed && currentStep !== key && !showInProgress ? (
                        <CheckIcon className={markIcon()} aria-hidden="true" />
                      ) : (
                        <Icon
                          className={icon({
                            status: status && !isStepActive ? pending : status,
                            isActive: isStepActive,
                            showInProgress: showInProgress,
                          })}
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className={title({ status: status, isActive: isStepActive })}>
                      {item.title}
                      <span
                        className={inProgressText({
                          status: status,
                          isActive: isStepActive,
                          showInProgress: showInProgress,
                        })}
                      >
                        In progress
                      </span>
                    </div>
                    {isStepActive ? (
                      <ArrowRightIcon className={rightIcon()} aria-hidden="true" />
                    ) : null}
                  </div>
                  <ConnectorLine show={isShowConnector} />
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default VerticalStepperSidebar;
