import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AccountSetupLayout } from '@/components';
import DashBoardLayout from '@/components/shared/DashboardLayout';
import Loader from '@/components/ui/Loader/Loader';
import { useGrowthbookSetup } from '@/hooks';
import useDiscountInfo from '@/hooks/useDiscountInfo/useDiscountInfo';

import pagesRoutesData from './pagesRoutesData';
import PrivateRoute from './PrivateRoute';

const StackRoutes = () => {
  useGrowthbookSetup();

  const urlParams = new URLSearchParams(location.search);
  const discount = urlParams.get('discount');

  const { verifyDiscount } = useDiscountInfo();

  useEffect(() => {
    if (discount) {
      verifyDiscount({
        code: discount,
      });
    }
  }, [discount, verifyDiscount]);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<DashBoardLayout />}>
          {pagesRoutesData.privateRoutes.map(
            ({
              path,
              loadPageComponent,
              navigateTo = '',
              caseSensitive = false,
              replace = false,
              state = {},
            }) => {
              const LazyLoadedPage = loadPageComponent;

              return (
                <Route
                  key={path}
                  path={path}
                  caseSensitive={caseSensitive}
                  element={
                    loadPageComponent ? (
                      <Suspense fallback={<Loader />}>
                        <LazyLoadedPage />
                      </Suspense>
                    ) : (
                      <Navigate to={navigateTo} state={state} replace={replace} />
                    )
                  }
                />
              );
            },
          )}
        </Route>

        <Route element={<AccountSetupLayout />}>
          {pagesRoutesData.accountSetupRoutes.map(
            ({
              path,
              loadPageComponent,
              navigateTo = '',
              caseSensitive = false,
              replace = false,
              state = {},
            }) => {
              const LazyLoadedPage = loadPageComponent;

              return (
                <Route
                  key={path}
                  path={path}
                  caseSensitive={caseSensitive}
                  element={
                    loadPageComponent ? (
                      <Suspense fallback={<Loader />}>
                        <LazyLoadedPage />
                      </Suspense>
                    ) : (
                      <Navigate to={navigateTo} state={state} replace={replace} />
                    )
                  }
                />
              );
            },
          )}
        </Route>
      </Route>

      {pagesRoutesData.publicRoutes.map(
        ({
          path,
          loadPageComponent,
          navigateTo = '',
          caseSensitive = false,
          replace = false,
          state = {},
        }) => {
          const LazyLoadedPage = loadPageComponent;

          return (
            <Route
              key={path}
              path={path}
              caseSensitive={caseSensitive}
              element={
                loadPageComponent ? (
                  <Suspense fallback={<Loader />}>
                    <LazyLoadedPage />
                  </Suspense>
                ) : (
                  <Navigate to={navigateTo} state={state} replace={replace} />
                )
              }
            />
          );
        },
      )}
    </Routes>
  );
};

export default StackRoutes;
