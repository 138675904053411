import { tv } from 'tailwind-variants';

/**
 * @constant StatusBadgeVariants
 * @description
 * Tailwind Variants utility for styling status badges with different severity levels.
 * Provides a consistent appearance for status indicators across the application.
 */
export const StatusBadgeVariants = tv({
  /**
   * Base styles applied to all status badges.
   * Includes common styling such as padding, border radius, text size, and flex utilities.
   */
  base: 'rounded-2x-small px-2x-small flex w-fit items-center gap-1 text-nowrap border py-[2px] !text-2x-small leading-[12px]',

  /**
   * Variants defining different severity levels.
   * Each severity applies distinct colors for the border, background, and text.
   */
  variants: {
    severity: {
      success:
        'bg-primary-emerald-light-bg text-primary-emerald border-primary-emerald-light-border',
      warning:
        'bg-semantic-warning-yellow-light-bg text-semantic-warning-yellow border-semantic-warning-yellow-light-border',
      danger:
        'bg-semantic-danger-red-light-bg text-semantic-danger-red border-semantic-danger-red-light-border',
      info: 'border-semantic-accent-blue-light-border bg-semantic-accent-blue-light-bg text-semantic-accent-blue',
      draft:
        'bg-secondary-navy-light-bg border-secondary-navy-light-border text-secondary-navy-hover',
    },
  },

  /**
   * Default severity variant applied when no explicit severity is provided.
   */
  defaultVariants: {
    severity: 'success',
  },
});
