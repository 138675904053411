import { IconInfoCircle } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

import { twMerge } from '@/utils/tailwindMergeConfig';

import { StatusBadgeVariants } from './StatusBadgeVariants';

/**
 * @component StatusBadge
 * @description A badge component used to display status indicators with different severity levels.
 *
 * @prop {string} text - The text to be displayed inside the badge.
 * @prop {'info' | 'success' | 'warning' | 'danger' | 'draft'} [severity='success']
 * Indicates the type of status, affecting the badge's styling.
 *
 * @example
 * <StatusBadge severity="info" text="Pending" />
 * <StatusBadge severity="success" text="Active" />
 * <StatusBadge severity="danger" text="Error" />
 */
type StatusBadgeProps = {
  severity?: 'info' | 'success' | 'warning' | 'danger' | 'draft';
  text: string;
  tooltipId?: string;
  tooltipContent?: string;
  startIcon?: React.ReactNode;
  className?: string;
};

/**
 * A small, styled component representing a status indicator.
 * It dynamically applies styles based on the `severity` prop.
 */
const StatusBadge = ({
  severity = 'success',
  text,
  tooltipContent,
  tooltipId,
  startIcon,
  className,
}: StatusBadgeProps) => {
  return (
    <>
      <span className={twMerge(StatusBadgeVariants({ severity }), className)}>
        {startIcon}
        {text}{' '}
        {tooltipContent && tooltipId && (
          <IconInfoCircle data-tooltip-id={tooltipId} className="size-small" />
        )}
      </span>
      <Tooltip
        place="top"
        className="!bg-navy-500 !text-small z-[3] !w-auto max-w-[225px] cursor-pointer !rounded-lg font-normal"
        id={tooltipId}
        content={tooltipContent}
        opacity={1}
        closeEvents={{ mouseout: true }}
      />
    </>
  );
};

export default StatusBadge;
