import moment from 'moment';

export const useGetWeekendDates = () => {
  const weekends = [];

  let startDate = moment().startOf('day');
  const endDate = moment().add(1, 'year').endOf('day');

  while (startDate.isSameOrBefore(endDate)) {
    if (startDate.day() === 6 || startDate.day() === 0) {
      const formattedDate = startDate.format('YYYY-MM-DD');

      weekends.push({
        startDate: new Date(formattedDate + 'T00:00:00'),
        endDate: new Date(formattedDate + 'T23:59:59'),
      });
    }

    startDate = moment(startDate).add(1, 'day').startOf('day');
  }

  return { weekends };
};
