import { useCallback, useEffect, useMemo, useState } from 'react';

import EmployerTaxItem from '@/components/shared/EmployerTaxItem/EmployerTaxItem';
import { SUTA_TAX_ITEM_ID } from '@/components/shared/EmployerTaxItem/utils/constants';
import { useAccountInfo } from '@/hooks';
import { taxInformation } from '@/redux/dto/company';
import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';
import { handleEmployerTaxData } from '@/utils/helpers';

import useGetCompanyTaxInformation from '../../apis/useGetCompanyTaxInformation/useGetCompanyTaxInformation';
import SetupFederalTax from '../SetupFederalTax/SetupFederalTax';
import { StateTaxInfoType, UseMissingStateTaxType } from './types';

const useMissingStateTax = ({
  taxData,
  shouldShowAllFields,
  shouldShowAllTaxItems,
  closeModalHandler,
  handleIsDirty,
  isFormDirty,
  handleDirtyModelOpen,
}: UseMissingStateTaxType) => {
  const { companyId } = useAccountInfo();

  const {
    data: allStateTaxInformation,
    isLoading: isLoadingStateTaxInformation,
    refetch: refetchStateTaxInformation,
  } = useGetCompanyTaxInformation({
    companyId,
    queryParams: {
      queryKey: [companyId, 'confirmEmployerTax', taxData?.stateAbbreviation, 'stateTaxData'],
    },
  });

  const [currentTab, setCurrentTab] = useState(1);
  const [isReviewTaxInfoModalOpen, setIsReviewTaxInfoModalOpen] = useState(false);
  const [isSetupTaxInfoModalOpen, setIsSetupTaxInfoModalOpen] = useState(true);

  const stateTaxInfo: StateTaxInfoType = useMemo(() => {
    if (Array.isArray(allStateTaxInformation) && taxData) {
      return allStateTaxInformation.find(
        (state: taxInformation) => state.stateAbbreviation === taxData.stateAbbreviation,
      );
    }
  }, [taxData, allStateTaxInformation]);

  const formattedTaxItems = useMemo(() => {
    return stateTaxInfo && Array.isArray(stateTaxInfo?.taxItems)
      ? handleEmployerTaxData({ taxItems: stateTaxInfo?.taxItems, shouldShowAllFields })
      : [];
  }, [stateTaxInfo, shouldShowAllTaxItems]);

  const federalTaxItems = Array.isArray(allStateTaxInformation)
    ? allStateTaxInformation.find(item => item.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION)
        ?.taxItems
    : [];

  const formattedFederalTaxItems = Array.isArray(federalTaxItems)
    ? handleEmployerTaxData({
        taxItems: federalTaxItems,
        shouldShowAllFields: shouldShowAllFields,
      })
    : [];

  // Add extra 1 Tab to add Federal step as part of stepper if federal tax items are not hidden
  const totalTabs = formattedTaxItems.length + (formattedFederalTaxItems.length ? 1 : 0);

  const nextBtnClickHandler = useCallback(() => {
    if (currentTab === totalTabs) {
      setIsReviewTaxInfoModalOpen(true);
    } else {
      setCurrentTab(prev => prev + 1);
    }
    refetchStateTaxInformation();
  }, [currentTab, totalTabs, refetchStateTaxInformation]);

  const backBtnHandler = () => {
    if (isFormDirty && handleDirtyModelOpen) {
      handleDirtyModelOpen();
    } else {
      if (isReviewTaxInfoModalOpen) {
        setIsReviewTaxInfoModalOpen(false);
      } else if (isSetupTaxInfoModalOpen) {
        closeModalHandler();
      } else {
        if (currentTab > 1) {
          setCurrentTab(prev => prev - 1);
        } else {
          setIsSetupTaxInfoModalOpen(true);
        }
      }
    }
  };

  const onSubmitReviewTaxInfo = () => {
    closeModalHandler();
  };

  const setupTaxInfoBtnClickHandler = () => {
    setIsSetupTaxInfoModalOpen(false);
  };

  const formattedTabs = useMemo(() => {
    // Sort tax items, so SUTA Tax item should be first
    const sortedTaxItems = [
      ...formattedTaxItems.filter(taxItem => taxItem.id.includes(SUTA_TAX_ITEM_ID)),
      ...formattedTaxItems.filter(taxItem => !taxItem.id.includes(SUTA_TAX_ITEM_ID)),
    ];

    const taxItemTabs = sortedTaxItems.map((taxItem, index) => {
      const id = index + 1;
      return {
        id: id,
        title: taxItem.description,
        disabled: false,
        content: (
          <div key={taxItem.id} className="mt-6">
            <EmployerTaxItem
              onSubmit={nextBtnClickHandler}
              shouldShowAllFields={shouldShowAllFields}
              currentTaxItem={taxItem}
              isLastStep={totalTabs - 1 === index}
              isFederalTaxItem={stateTaxInfo?.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION}
              handleIsDirty={handleIsDirty}
              handleBackBtnClick={backBtnHandler}
            />
          </div>
        ),
      };
    });

    const federalTaxTabId = taxItemTabs.length + 1;
    const federalTaxTab = {
      id: federalTaxTabId,
      title: '',
      disabled: false,
      content: (
        <SetupFederalTax
          onSubmit={nextBtnClickHandler}
          isLastStep={true}
          handleIsDirty={handleIsDirty}
          handleBackBtnClick={backBtnHandler}
        />
      ),
    };

    const taxItems = [...taxItemTabs];

    if (formattedFederalTaxItems.length) {
      taxItems.push(federalTaxTab);
    }

    return taxItems;
  }, [formattedTaxItems, shouldShowAllFields, nextBtnClickHandler, currentTab]);

  const [tabs, setTabs] = useState(formattedTabs);

  useEffect(() => {
    setTabs(formattedTabs);
  }, [formattedTabs]);

  return {
    tabs,
    currentTab,
    setCurrentTab,
    isReviewTaxInfoModalOpen,
    backBtnHandler,
    onSubmitReviewTaxInfo,
    isSetupTaxInfoModalOpen,
    setupTaxInfoBtnClickHandler,
    isLoadingStateTaxInformation,
  };
};

export default useMissingStateTax;
