import moment, { Moment } from 'moment';
import { memo, useEffect } from 'react';

import { FormInputDatePicker } from '@/components';
import { useAccountInfo } from '@/hooks';
import { getBusinessDaysWithHolidays } from '@/modules/payrollSchedule/utils';
import { useGetBankHolidays } from '@/modules/runPayroll/utils/useGetBankHolidays';
import { useGetWeekendDates } from '@/utils/useGetWeekendDates';

type OffCyclePayrollEarningFormType = {
  payDate: string | null;
  hasDirectDeposit?: boolean;
  handleFormField?: (name: string, value: any) => void;
};

const OffCyclePayrollEarningForm = memo(
  ({ payDate, hasDirectDeposit, handleFormField }: OffCyclePayrollEarningFormType) => {
    const bankHolidayDates = useGetBankHolidays();
    const { company, isCheckHqUser } = useAccountInfo();
    const { weekends } = useGetWeekendDates();

    const companyStartDate = new Date(company?.startDate);
    const todayDate = new Date();
    const minDate = isCheckHqUser
      ? todayDate > companyStartDate
        ? todayDate
        : companyStartDate
      : '';
    const DATE_FORMAT = 'YYYY-MM-DD';
    const today: Moment = moment().startOf('day');
    const nextThreeBusinessDays = getBusinessDaysWithHolidays(today, 3, bankHolidayDates);
    const weekendsAndBankHolidays = bankHolidayDates.concat(weekends);
    const disabledDates = isCheckHqUser
      ? hasDirectDeposit
        ? [...weekendsAndBankHolidays, ...nextThreeBusinessDays]
        : weekendsAndBankHolidays
      : [];

    const dateOfPaymentIsInclde = nextThreeBusinessDays.some(dateRange => {
      const startDate = moment(dateRange.startDate).format(DATE_FORMAT);
      const selectedDay = payDate && moment(payDate).format(DATE_FORMAT);
      return startDate === selectedDay;
    });

    useEffect(() => {
      if (hasDirectDeposit && dateOfPaymentIsInclde) {
        handleFormField && handleFormField('payDate', null);
      }
    }, [hasDirectDeposit, dateOfPaymentIsInclde]);

    return (
      <div className="flex w-full flex-col flex-wrap items-start gap-x-6 gap-y-3 sm:w-auto sm:flex-row">
        <FormInputDatePicker
          readOnly
          popoverDirection="down"
          name="payDate"
          title="Pay date"
          className="w-full sm:w-[320px]"
          isSingle
          minDate={minDate}
          isRequired
          disabledDates={disabledDates}
        />
        <FormInputDatePicker
          popoverDirection="down"
          name="payPeriod"
          title="Pay period"
          className="w-full sm:w-[320px]"
          isRequired
          isSingle={false}
        />
      </div>
    );
  },
);
OffCyclePayrollEarningForm.displayName = 'OffCyclePayrollEarningForm';
export default OffCyclePayrollEarningForm;
