import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import { LINKS, navigation } from '@/utils/constants';
import { checkAccountForSubscriptionReminder, keyUp } from '@/utils/helpers';

const SideBar = () => {
  const { isCheckHqUser } = useAccountInfo();
  const location = useLocation();
  const currentPath = location.pathname;

  const { isAuthenticatedAsImpersonate, account } = useAccountInfo();

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';
  const { billingInfo: subscriptionInfo } = useBillingInfo();
  const bannerInfo = checkAccountForSubscriptionReminder({ subscriptionInfo, account });

  // Additional height of header from the demo banner/subscription reminder banner/Impersonation Reminder banner
  const isAdditionHeaderBanner =
    isAppModeDemo || bannerInfo?.bannerVariantToShow || isAuthenticatedAsImpersonate;

  return (
    <div
      className={twMerge(
        `fixed hidden h-[calc(100vh-72px)] max-h-screen w-[270px] flex-col border-r border-gray-100 bg-white lg:flex`,
        isAdditionHeaderBanner && `h-[calc(100vh-132px)]`,
      )}
    >
      <div className="relative flex h-full flex-col overflow-hidden">
        <nav
          className="h-full flex-1 flex-col gap-y-1 overflow-y-auto bg-white py-4"
          aria-label="Sidebar"
        >
          {navigation
            .filter(item => {
              if (item.name === 'Reports' && !isCheckHqUser) return false;
              if (item.name === 'Tax Liability' && isCheckHqUser) return false;
              return true;
            })
            .map((item, index) => {
              const isCurrent = currentPath.startsWith(item.path);
              if (item.label) {
                return (
                  <span
                    key={index}
                    className="menu-height:py-0 ml-6 mr-[12px] flex pb-[15px] pt-[20px] text-sm text-gray-400"
                    aria-hidden="true"
                  >
                    {item.name}
                  </span>
                );
              }
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`
                  ${
                    isCurrent
                      ? 'border-emerald-500 bg-[#F2F9F8] text-emerald-500'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }
                  group flex h-[44px] items-center
                `}
                >
                  <item.icon
                    className={`
                    ${isCurrent ? 'text-emerald-500' : 'text-gray-400 group-hover:text-gray-500'}
                    ml-6 mr-[12px] size-6
                  `}
                    aria-hidden="true"
                    color={isCurrent ? '#008C70' : '#1E1E1E'}
                  />
                  <span className="flex-1">{item.name}</span>
                </Link>
              );
            })}
        </nav>
        <div className="absolute bottom-0 flex w-full flex-col items-start justify-center bg-white">
          <span className="menu-height:static flex h-11 w-full items-center justify-center border-t border-gray-100 text-sm text-gray-400">
            <span
              onKeyUp={keyUp}
              tabIndex={0}
              role="button"
              className="mr-[5px] text-gray-400 underline underline-offset-2 hover:cursor-pointer hover:text-gray-500"
              onClick={() => window.open(LINKS.termsOfUse, '_blank')}
            >
              T&C
            </span>{' '}
            and{' '}
            <span
              onKeyUp={keyUp}
              tabIndex={0}
              role="button"
              className="ml-[5px] text-gray-400 underline underline-offset-2 hover:cursor-pointer hover:text-gray-500"
              onClick={() => window.open(LINKS.privacyPolicy, '_blank')}
            >
              Privacy Policy
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
