import moment from 'moment';
import { useRef, useState } from 'react';

import { useAccountInfo } from '@/hooks';
import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';
import { DEDUCTION_TYPES_NAME } from '@/utils/constants';
import { callToast } from '@/utils/helpers';

import useAddCompanyLevelDeduction from '../CompanyLevelDeductions/apis/companyLevelDeduction/useAddCompanyLevelDeduction';
import useDeleteCompanyLevelDeduction from '../CompanyLevelDeductions/apis/companyLevelDeduction/useDeleteCompanyLevelDeduction';
import useEditCompanyLevelDeduction from '../CompanyLevelDeductions/apis/companyLevelDeduction/useEditCompanyLevelDeduction';
import { DeductionFormHandle } from './forms/DeductionForm';

type UseDeductionDrawerType = {
  onClose: () => void;
  isEdit?: boolean;
  data: DeductionTypes | null;
  onDeleteDeduction?: () => void;
  handleEditDeduction?: (data: any) => void;
};
const useDeductionDrawer = ({
  onClose,
  isEdit,
  data,
  onDeleteDeduction,
  handleEditDeduction,
}: UseDeductionDrawerType) => {
  const { companyId, isCheckHqUser } = useAccountInfo();

  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [openDirtyDeduction, setOpenDirtyDeduction] = useState<boolean>(false);
  const [isDeleteDeduction, setIsDeletingDeduction] = useState(false);
  const [isDeductionDrawerOpen, setIsDeductionDrawerOpen] = useState<boolean>(false);
  const formRef = useRef<DeductionFormHandle>(null);

  const isAssigned = data?.assignedToEmployees;

  const { mutate: addCompanyLevelDeduction, isPending: isAddingDeduction } =
    useAddCompanyLevelDeduction({
      companyId,
      mutationParams: {
        onSuccess: () => {
          onClose();
          callToast('success', 'New company benefit type created successfully!');
        },
      },
    });

  const { mutate: editCompanyLevelDeduction, isPending: isEditingDeduction } =
    useEditCompanyLevelDeduction({
      companyId,
      deductionId: data ? data.id : 0,
      mutationParams: {
        onSuccess: () => {
          onClose();
          callToast('success', 'Company benefit changes save successfully!');
        },
      },
    });

  const { mutate: deleteCompanyLevelDeduction, isPending: isDeletingDeduction } =
    useDeleteCompanyLevelDeduction({
      companyId,
      deductionId: data ? data.id : 0,
      mutationParams: {
        onSuccess: () => {
          callToast('success', 'Company benefit deleted successfully!');
          onClose();
          handleDeleteDeductionModal();
          onDeleteDeduction && onDeleteDeduction();
        },
      },
    });

  const onSubmit = (formData: any) => {
    const {
      type,
      benefitType,
      benefitTitle,
      customPostTaxDeductionTitle,
      garnishmentTitle,
      garnishmentType,
      effectiveStart,
      effectiveEnd,
    } = formData || {};

    let payload = {
      name:
        type === DEDUCTION_TYPES_NAME.benefit
          ? benefitTitle
          : type === DEDUCTION_TYPES_NAME.garnishment
          ? garnishmentTitle
          : customPostTaxDeductionTitle,
      type,
      ...(type === DEDUCTION_TYPES_NAME.benefit &&
        benefitType && { benefitType: parseInt(benefitType) }),
      ...(type === DEDUCTION_TYPES_NAME.garnishment &&
        garnishmentType && { garnishmentType: parseInt(garnishmentType) }),
    };

    if (isCheckHqUser && type === DEDUCTION_TYPES_NAME.benefit) {
      payload = {
        ...payload,
        effectiveStart: moment(effectiveStart).endOf('day').toISOString(),
        effectiveEnd,
      };
    }

    if (isEdit) {
      if (isCheckHqUser && type !== DEDUCTION_TYPES_NAME.benefit) {
        handleEditDeduction && handleEditDeduction({ ...payload, id: data?.id });
      } else {
        editCompanyLevelDeduction(payload);
      }
    } else {
      if (!isCheckHqUser || (isCheckHqUser && type === DEDUCTION_TYPES_NAME.benefit)) {
        addCompanyLevelDeduction(payload);
      }
    }
  };

  const handleSubmit = async () => {
    if (formRef.current) {
      if (isCheckHqUser) {
        const isValid = await formRef.current.validateForm();
        if (isValid) {
          formRef.current.submitForm();
        } else {
          console.log('Form validation failed');
        }
      } else {
        const formElement = formRef.current as unknown as HTMLFormElement;
        if (formElement) {
          formElement.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }
      }
    }
  };

  const handleIsDirty = (isDirty: boolean) => {
    setIsDisable(!isDirty);
  };

  const handleDeleteDeduction = () => {
    deleteCompanyLevelDeduction();
  };
  const handleDeleteDeductionModal = () => setIsDeletingDeduction(!isDeleteDeduction);
  const handleDeductionDrawer = () => setIsDeductionDrawerOpen(!isDeductionDrawerOpen);

  const handleCloseDirtyDeduction = () => setOpenDirtyDeduction(false);

  const handleConfirmDirtyDeduction = () => {
    setOpenDirtyDeduction(false);
    onClose();
  };

  const handleDrawerClose = () => {
    if (!isDisable) {
      setOpenDirtyDeduction(true);
    } else {
      onClose();
    }
  };

  const isLoading = isAddingDeduction || isEditingDeduction;

  return {
    formRef,
    onSubmit,
    handleSubmit,
    isLoading,
    handleIsDirty,
    isDisable,
    handleDeleteDeduction,
    isDeletingDeduction,
    isDeleteDeduction,
    handleDeleteDeductionModal,
    isDeductionDrawerOpen,
    setIsDeductionDrawerOpen,
    handleDeductionDrawer,
    isAssigned,
    openDirtyDeduction,
    handleDrawerClose,
    handleConfirmDirtyDeduction,
    handleCloseDirtyDeduction,
    isCheckHqUser,
  };
};
export default useDeductionDrawer;
