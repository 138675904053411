import { HandSpeaker } from '@/assets/icons';
import ContactSupportButton from '@/components/shared/ContactSupportButton';

const PaymentFailedBanner = ({ isContractor }: { isContractor?: boolean }) => {
  return (
    <div className="md:gap-small py-medium flex flex-col justify-between bg-gray-900 px-4 md:h-[60px] md:flex-row md:items-center">
      <div className="gap-medium flex">
        <HandSpeaker stroke="red" />
        <div className="text-small max-w-[295px] text-white sm:max-w-full">
          Payment has failed, please contact support to resolve this issue.{' '}
          {isContractor ? 'Contractors' : 'Employees'} will not be paid and future{' '}
          {isContractor ? 'payment' : 'payroll'} is blocked until this is resolved.
        </div>
      </div>

      <ContactSupportButton variant="danger" size="x-small" className="text-white" />
    </div>
  );
};

export default PaymentFailedBanner;
