import Button from '@/components/Button/Button';
import EmployerTaxInformation from '@/components/shared/EmployerTaxInformation/EmployerTaxInformation';

import { ReviewTaxInformationProps } from './types';
import useReviewTaxInformation from './useReviewTaxInformation';

const ReviewTaxInformation = ({ taxData, onSubmit }: ReviewTaxInformationProps) => {
  const {
    formattedTaxData,
    isFetchingTaxInformation,
    refetchTaxData,
    onSaveReviewData,
    isSavingReviewTaxInformation,
  } = useReviewTaxInformation({
    taxData,
    onSubmit,
  });

  return (
    <div className="flex flex-col">
      <EmployerTaxInformation
        isLoadingTaxInformation={isFetchingTaxInformation}
        taxInformation={formattedTaxData}
        refetchAllTaxes={refetchTaxData}
      />
      <Button
        variant="primary"
        width="w-full"
        parentClasses="mt-3"
        onPress={() => onSaveReviewData()}
        isDisabled={isFetchingTaxInformation}
        isLoading={isSavingReviewTaxInformation}
      >
        Next
      </Button>
    </div>
  );
};

export default ReviewTaxInformation;
