import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  getInvoice: (invoiceId: number): QueryEndpoint => ({
    queryKey: ['invoice', invoiceId, 'download'],
    url: `/invoice/${invoiceId}/download`,
  }),
  getReceipt: (receiptId: number): QueryEndpoint => ({
    queryKey: ['receipt', receiptId, 'download'],
    url: `/invoice/${receiptId}/download/receipt`,
  }),
};

export const mutationEndpoints = {
  updateAccountInfo: (accountId: number): MutationEndpoint => ({
    mutationKey: ['accountInfo', accountId],
    url: `account/${accountId}`,
    invalidateKeys: [],
  }),

  updateAccountPassword: () => ({
    mutationKey: ['accountPassword'],
    url: '/update-password',
    invalidateKeys: [],
  }),
};
