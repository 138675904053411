import { useAuth0 } from '@auth0/auth0-react';

import useBillingCenterInfo from '@/api/billingCenterInfo/useBillingCenterInfo';
import { checkActiveFreeTrialInAccount } from '@/utils/helpers';

import useAccountInfo from '../useAccountInfo/useAccountInfo';

const useBillingInfo = () => {
  const { isAuthenticated } = useAuth0();
  const { isAuthenticatedAsImpersonate } = useAccountInfo();

  const {
    data,
    isLoading: isBillingInfoLoading,
    refetch: refetchBillingInfo,
  } = useBillingCenterInfo({
    rqParams: {
      enabled: isAuthenticated || isAuthenticatedAsImpersonate,
      //Invalidate Billing info after 1 hour
      staleTime: 1000 * 60 * 60,
      gcTime: Infinity,
      retry: 2,
    },
  });

  const billingInfo = data?.data;

  const hasSubscriptions =
    Array.isArray(billingInfo?.subscriptionAgreements) &&
    !!billingInfo.subscriptionAgreements.length;

  const activeSubscriptionAgreement = billingInfo?.activeSubscriptionAgreement;

  const { isSubscribedPlanHasFreeTrial, freeTrialDaysLeftInSubscribedPlan } =
    checkActiveFreeTrialInAccount({
      subscriptionInfo: billingInfo,
    });

  return {
    billingInfo,
    isBillingInfoLoading,
    hasSubscriptions,
    activeSubscriptionAgreement,
    refetchBillingInfo,
    isSubscribedPlanHasFreeTrial,
    freeTrialDaysLeftInSubscribedPlan,
  };
};

export default useBillingInfo;
