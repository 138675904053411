import { IconChevronRight } from '@tabler/icons-react';

import { twMerge } from '@/utils/tailwindMergeConfig';

import usePickOffCycleType from './usePickOffCycleType';

export const PickOffCycleType = ({ isRunPayrollDisabled }: { isRunPayrollDisabled?: boolean }) => {
  const { formattedOffCyclePayrollTypes } = usePickOffCycleType({ isRunPayrollDisabled });

  return (
    <>
      {formattedOffCyclePayrollTypes.map(
        ({ type, title, icon: Icon, clickHandler, isDisabled }) => (
          <div
            data-tooltip-id="payroll-under-review-id"
            key={type}
            className={twMerge(
              'mt-medium flex h-[64px] w-full cursor-pointer items-center justify-between rounded-lg border border-gray-100 p-3 first:mt-0',
              isDisabled ? '!text-gray-200' : 'hover:border-emerald-500',
            )}
            aria-disabled={isDisabled}
            {...(isDisabled ? {} : { onClick: clickHandler })}
          >
            <div className="flex items-center">
              <div className="mr-small text-content-heading  bg-secondary-navy-light-bg flex size-10 items-center justify-center rounded-full p-3 md:rounded-lg">
                <Icon className="size-x-large" />
              </div>
              <div className="gap-2x-small flex flex-col">
                <span className="font-F37Bolton-Medium text-content-heading text-medium">
                  {title}
                </span>
              </div>
            </div>
            <IconChevronRight className="size-x-large text-icon-disabled" />
          </div>
        ),
      )}
    </>
  );
};
