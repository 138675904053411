import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../Button/Button';

interface DialogComponentProps {
  open: boolean;
  onCancel: () => void;
  children: ReactNode;
  width?: string;
  height?: string;
  dialogClasses?: string;
  modalClasses?: string;
  modalTitle?: string | React.ReactNode;
  /**
   * Is modal closed when clicked outside of it or on Esc key press
   * @default false
   */
  isDismissable?: boolean;
  showCloseIcon?: boolean;
  closeButtonClass?: string;
  modalTitleClasses?: string;
}

const DialogComponent: FC<DialogComponentProps> = ({
  open,
  onCancel,
  children,
  width,
  height,
  dialogClasses,
  modalClasses,
  isDismissable,
  modalTitle,
  showCloseIcon,
  closeButtonClass,
}) => {
  return (
    <Dialog
      open={!!open}
      onClose={isDismissable ? onCancel : () => {}}
      className={`relative z-[100] ${dialogClasses ? dialogClasses : ''}`}
    >
      <div className="fixed inset-0 z-50">
        <div className="absolute left-0 top-0 flex h-full w-screen items-center justify-center backdrop-blur-sm backdrop-brightness-75">
          <Dialog.Panel>
            <div
              className={twMerge(
                ` rounded-small relative overflow-y-auto ${
                  height ? height : 'h-[100vh] sm:max-h-[95vh] sm:min-h-[95vh]'
                } ${width ? width : 'w-[610px]'} bg-white ${modalClasses} `,
              )}
            >
              <div className="flex justify-between">
                {modalTitle && modalTitle}
                {showCloseIcon && (
                  <div className="flex !flex-col !items-start">
                    <Button
                      variant="text"
                      parentClasses="h-fit"
                      className={closeButtonClass}
                      onPress={onCancel}
                    >
                      <XMarkIcon className="size-6" />
                    </Button>
                  </div>
                )}
              </div>
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogComponent;
