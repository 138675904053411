import moment from 'moment';
import React from 'react';

import { formatApprovalDeadline } from '@/utils/helpers';

interface PayrollScheduleCardProps {
  payDate: string;
  payPeriodStartDate: string;
  payPeriodEndDate: string;
  approvalDeadline?: string | null;
}

const PayrollScheduleCard: React.FC<PayrollScheduleCardProps> = ({
  payDate,
  payPeriodStartDate,
  payPeriodEndDate,
  approvalDeadline,
}) => {
  const payDateObject = moment(payDate);
  const payPeriodStartDateObject = moment(payPeriodStartDate);
  const payPeriodEndDateObject = moment(payPeriodEndDate);

  const formattedDate = payDateObject.format('ddd, MMM D');
  const formattedStartDate = payPeriodStartDateObject.format('MM/DD/YYYY');
  const formattedEndDate = payPeriodEndDateObject.format('MM/DD/YYYY');
  const formattedDeadlineDate = formatApprovalDeadline(approvalDeadline, 'ddd, MMM D', true);

  return (
    <div className="mt-[20px] w-full rounded-2xl border border-gray-100 bg-white">
      <div className="flex h-[50px] items-center justify-between border-b border-b-gray-100 px-[16px]">
        <span className="text-gray-400">Pay period</span>
        <span>
          {formattedStartDate} - {formattedEndDate}
        </span>
      </div>
      <div className="flex h-[50px] items-center justify-between px-[16px]">
        <span className="text-gray-400">Pay Date</span>
        <span>{formattedDate}</span>
      </div>
      {formattedDeadlineDate && (
        <div className="flex h-[50px] items-center justify-between border-t border-b-gray-100 px-[16px]">
          <span className="text-gray-400">Run payroll by</span>
          <span>{formattedDeadlineDate}</span>
        </div>
      )}
    </div>
  );
};

export default PayrollScheduleCard;
