import { DateTime } from 'luxon';

import useGetInvoice from '@/modules/myAccount/api/useGetInvoice';
import useGetReceipt from '@/modules/myAccount/api/useGetReceipt';
import { downloadPayStub } from '@/utils/helpers';

const useBillingHistoryView = ({ invoice }: any) => {
  const payDate = DateTime.fromISO(invoice.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  const {
    refetch: downloadInvoice,
    isLoading: isLoadingInvoice,
    isRefetching: isRefetchingInvoice,
  } = useGetInvoice({
    invoiceId: invoice.id,
  });

  const {
    refetch: downloadReceipt,
    isLoading: isLoadingReceipt,
    isRefetching: isRefetchingReceipt,
  } = useGetReceipt({
    receiptId: invoice.id,
  });

  const handleDownloadInvoice = async () => {
    downloadInvoice().then(data => {
      downloadPayStub(data.data?.rawData, invoice.id, true, 'Invoice_');
    });
  };
  const handleDownloadReceipt = async () => {
    downloadReceipt().then(data => {
      downloadPayStub(data.data?.rawData, invoice.id, true, 'Receipt_');
    });
  };

  return {
    handleDownloadInvoice,
    handleDownloadReceipt,
    payDate,
    isLoadingInvoice: isLoadingInvoice || isRefetchingInvoice,
    isLoadingReceipt: isLoadingReceipt || isRefetchingReceipt,
  };
};
export default useBillingHistoryView;
