import { FormProvider } from 'react-hook-form';

import { UnsavedDataConfirmationModal } from '@/components';
import Button from '@/components/Button/Button';
import Drawer from '@/components/ui/Drawer/Drawer';

import InputPasswordField from '../InputPasswordField/InputPasswordField';
import useEditPasswordDrawer from './useEditPasswordDrawer';

interface EditPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  account?: any;
  isLoading?: boolean;
  handlePasswordChange: (data: { currentPassword: string; newPassword: string }) => void;
}

const EditPasswordDrawer: React.FC<EditPasswordModalProps> = ({
  isOpen,
  onClose,
  handlePasswordChange,
  isLoading = false,
}: EditPasswordModalProps) => {
  const {
    form,
    isDirty,
    handlePasswordChange: onSubmit,
    cancelUnsavedChangesHandler,
    confirmUnsavedChangesHandler,
    drawerCloseHandler,
    isConfirmationModalOpen,
  } = useEditPasswordDrawer({
    handlePasswordChange,
    onClose,
    isOpen,
  });
  return (
    <Drawer
      open={isOpen}
      onClose={drawerCloseHandler}
      title="Change password"
      modelClasses="w-full md:!w-[640px]"
    >
      <FormProvider {...form}>
        <form className=" flex flex-col gap-5 py-6" onSubmit={form.handleSubmit(onSubmit)}>
          <InputPasswordField
            shouldUnregister={false}
            name="currentPassword"
            title="Current password"
            placeholder="Enter current password"
          />

          <InputPasswordField
            shouldUnregister={false}
            name="newPassword"
            title="New password"
            placeholder="Enter new password"
          />
          <InputPasswordField
            shouldUnregister={false}
            name="newPassword2"
            title="Confirm new password"
            placeholder="Confirm new password"
          />

          <span className="text-base">
            Password must be at least 8 characters long and include at least one uppercase letter,
            one lowercase letter, and one number.
          </span>

          <div className="absolute bottom-0 ml-[-24px] flex  w-full justify-between gap-3 border-t p-5 ">
            <Button
              variant="light"
              children="Close"
              width="w-full max-w-fit py-3 px-6"
              onPress={drawerCloseHandler}
            />

            <Button
              isDisabled={!isDirty}
              isLoading={isLoading}
              variant="primary"
              type="submit"
              children="Save password"
              width="w-full  sm:min-w-[157px] py-3 max-w-fit px-6"
            />
          </div>
          <UnsavedDataConfirmationModal
            isOpen={isConfirmationModalOpen}
            onCancel={cancelUnsavedChangesHandler}
            onConfirm={confirmUnsavedChangesHandler}
          />
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default EditPasswordDrawer;
