import { useEffect, useState } from 'react';

import { taxInformation } from '@/redux/dto/company';
import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';

import { useAccountInfo } from '@/hooks';
import useGetCompanyTaxInformation from '../../apis/useGetCompanyTaxInformation/useGetCompanyTaxInformation';
import { UseAllMissingTaxesType } from './types';

const useAllMissingTaxes = ({ onSubmitTaxData, confirmTaxForState }: UseAllMissingTaxesType) => {
  const { companyId } = useAccountInfo();

  const {
    data,
    isLoading: isLoadingTaxInformation,
    refetch: refetchTaxInformation,
  } = useGetCompanyTaxInformation({
    companyId,
  });

  const [currentStateTax, setCurrentStateTax] = useState<taxInformation | null>(null);

  useEffect(() => {
    let pendingTaxState = null;
    if (confirmTaxForState) {
      pendingTaxState = Array.isArray(data)
        ? data.find((state: taxInformation) => state.stateAbbreviation === confirmTaxForState) ??
          null
        : null;
    } else {
      pendingTaxState = Array.isArray(data)
        ? data.find(
            (state: taxInformation) =>
              state.status === 'pending' &&
              state.stateAbbreviation !== FEDERAL_TAX_STATE_ABBREVIATION,
          ) ??
          data.find(
            (state: taxInformation) => state.stateAbbreviation !== FEDERAL_TAX_STATE_ABBREVIATION,
          )
        : null;
    }
    setCurrentStateTax(pendingTaxState);
  }, [data, confirmTaxForState]);

  const onSubmitStateTaxData = () => {
    onSubmitTaxData();
  };

  return { currentStateTax, isLoadingTaxInformation, onSubmitStateTaxData, refetchTaxInformation };
};

export default useAllMissingTaxes;
