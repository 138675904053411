import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useGetAccountInfo, useUpdateAccountInfo, useUpdateCompany } from '@/api';
import { queryEndpoints } from '@/api/account/endpoints';
import { Account } from '@/redux/dto/auth';
import { BROWSER_STORAGE_KEYS } from '@/utils/constants';
import { callToast, formatErrorMessage } from '@/utils/helpers';

const useAccountInfo = () => {
  const queryClient = useQueryClient();

  const { isAuthenticated } = useAuth0();

  /**
   * Check if the account is authenticated as Impersonate by accessToken stored in session storage
   */
  const isAuthenticatedAsImpersonate = !!sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.impersonateUserAccessToken,
  );

  const {
    data,
    isLoading: isAccountLoading,
    refetch: refetchAccountInfo,
  } = useGetAccountInfo({
    rqParams: {
      enabled: isAuthenticated || isAuthenticatedAsImpersonate,
      // Invalidate Account info after every 15 minutes
      staleTime: 1000 * 60 * 15,
      gcTime: Infinity,
      retry: 2,
    },
  });

  const account = data?.data;

  const accountId = account?.id;
  const company = account?.businessProfile;
  const companyId = company?.id ?? 0; // To fix Typescript error
  const checkHqId = company?.checkHqId;
  const isCheckHqUser = company?.isUsingCheckHq;
  const checkHqOnboardingData = company?.checkHqOnboardingData;
  const isDemoAccount =
    account?.email.includes('demo+') && account?.email.includes('@blinkpayroll.com');

  const { mutateAsync: patchAccount, isPending: isPatchingAccountDetails } = useUpdateAccountInfo({
    accountId: Number(accountId),
    mutationParams: {
      onSuccess: data => {
        if (data?.data) {
          queryClient.setQueryData(queryEndpoints.getAccountInfo().queryKey, data);
        }
      },
    },
  });

  const {
    mutateAsync: patchCompany,
    isPending: isPatchingCompanyDetails,
    error: patchCompanyError,
  } = useUpdateCompany({
    companyId: companyId,
    mutationParams: {
      onSuccess: data => {
        if (data?.data) {
          // Update company data in Account after successful mutation
          // We are invalidating after the Company mutation, but saved data was not available between mutation and account api fetched again
          queryClient.setQueryData(
            queryEndpoints.getAccountInfo().queryKey,
            (cacheData: AxiosResponse<Account>) => {
              return { ...cacheData, data: { ...cacheData.data, businessProfile: data.data } };
            },
          );
        }
      },
      onError: error => {
        if (!error?.response?.data?.suggestedAlternative) {
          const errorMessage = formatErrorMessage(error);
          callToast('error', `${errorMessage}`);
        }
      },
    },
  });

  // TO DO: Need to Replace - dispatch.auth.getUserInformation() when removing redux from dashboard.
  // useEffect(() => {
  //   if (!whoAmIRef.current && account) {
  //     initializeThirdPartyProfiles(account);
  //     whoAmIRef.current = true;
  //   }
  // }, [account]);

  return {
    isAccountLoading,
    account,
    company,
    companyId,
    patchAccount,
    isPatchingAccountDetails,
    patchCompany,
    isPatchingCompanyDetails,
    refetchAccountInfo,
    isAuthenticatedAsImpersonate,
    checkHqId,
    checkHqOnboardingData,
    isCheckHqUser,
    isDemoAccount,
    patchCompanyError,
  };
};

export default useAccountInfo;
