import { MutationOptions, useMutation } from '@tanstack/react-query';

import { postCall } from '@/api/axios';

import { mutationEndpoints } from './endpoints';

const useSubmitRunnablePayroll = ({
  companyId,
  mutationParams,
}: {
  companyId: number;
  mutationParams?: MutationOptions;
}) => {
  const { mutationKey, url } = mutationEndpoints.submitRunnablePayroll(companyId);

  return useMutation({
    mutationKey,
    mutationFn: data => postCall(url, data),
    ...mutationParams,
  });
};

export default useSubmitRunnablePayroll;
