import { InformationCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Text } from 'react-aria-components';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';
import { Status } from '@/components/ui';
import { DEDUCTION_TYPES } from '@/utils/constants';

import CheckHQWrapper from '../../CheckHQWrapper/CheckHQWrapper';
import DeductionType from './DeductionType';

const DeductionCard = ({
  title = 'Deduction title',
  subCategory = '',
  type,
  companyCardEmployeeCount,
  isAssigned = false,
  employeeContribution = '',
  employeeContributionUnit = 1,
  companyContribution = null,
  companyContributionUnit = 1,
  buttonText,
  buttonAction,
  isCardHighlighted = true,
  payPeriod,
  tooltipText,
  id,
}: any) => {
  // Helper function to format contribution based on unit
  const formatContribution = (amount: string, unit: number) => {
    if (!amount) return 'No contribution';
    return unit === 1 ? `$${amount}` : `${amount}%`;
  };

  return (
    <div
      className={twMerge(
        'w-full max-w-2xl rounded-lg border border-gray-100 p-4',
        isCardHighlighted && isAssigned ? '!border-emerald-500' : '',
      )}
    >
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          {isAssigned && (
            <Status className="rounded-2x-small bg-emerald-500 text-white">
              <div className="flex items-center gap-1 px-[2] py-px">
                <CheckCircleIcon className="size-4" />
                <Text>Assigned</Text>
              </div>
            </Status>
          )}
          <div className="flex flex-wrap items-center gap-2">
            <CheckHQWrapper hideItem>
              <Text className="font-F37Bolton-Medium flex-1 text-gray-900">{title}</Text>
            </CheckHQWrapper>
            <CheckHQWrapper>
              <Text
                className="font-F37Bolton-Medium flex-1 break-words text-gray-900"
                title={title}
              >
                {type === DEDUCTION_TYPES.Garnishment ? 'Child Support' : title}
              </Text>
            </CheckHQWrapper>
            <DeductionType type={type} />
          </div>
          <CheckHQWrapper>
            {type === DEDUCTION_TYPES.Benefit && (
              <Text className="text-sm text-gray-400">{subCategory}</Text>
            )}
          </CheckHQWrapper>
          <CheckHQWrapper hideItem>
            <Text className="text-sm text-gray-400">{subCategory}</Text>
          </CheckHQWrapper>
        </div>
        {buttonText && buttonAction && (
          <Button
            isDisabled={false}
            onPress={buttonAction}
            variant="tertiary"
            parentClasses="w-fit py-2 ml-2 px-4 rounded-lg !text-black text-[14px] h-fit min-w-xl"
            {...(buttonText === 'Assign'
              ? {
                  icon: PlusIcon,
                  iconClasses: 'size-[20px] text-black mr-2',
                }
              : {})}
            children={buttonText}
          />
        )}
      </div>
      {companyCardEmployeeCount >= 0 ? (
        <div className="mt-4 flex items-center gap-1 border-t pt-4">
          <Text className="text-sm text-gray-900">
            {companyCardEmployeeCount > 0
              ? `${companyCardEmployeeCount} employees assigned`
              : 'No employees assigned'}
          </Text>
          {companyCardEmployeeCount > 0 && (
            <InformationCircleIcon
              data-tooltip-id={`company-deduction-employees-${id}`}
              className="size-4 text-gray-400 hover:cursor-pointer"
            />
          )}
          <Tooltip
            place="bottom"
            className="max-w-60 !rounded-lg text-sm"
            id={`company-deduction-employees-${id}`}
            content={tooltipText}
          />
        </div>
      ) : null}

      {isAssigned && (
        <div className="mt-4 flex flex-col  gap-2 border-t pt-4">
          <div className="flex justify-between">
            <Text className="text-sm text-gray-400">Employee contribution</Text>
            <Text className="text-sm text-gray-900">
              {employeeContribution
                ? `${formatContribution(employeeContribution, employeeContributionUnit)} / ${
                    payPeriod === 'monthly' ? 'month' : 'pay check'
                  }`
                : 'No contribution'}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text className="text-sm text-gray-400">Company contribution</Text>
            <Text className="text-sm text-gray-900">
              {companyContribution
                ? `${formatContribution(companyContribution, companyContributionUnit)} / ${
                    payPeriod === 'monthly' ? 'month' : 'pay check'
                  }`
                : 'No contribution'}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeductionCard;
