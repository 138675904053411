import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetSyncData } from '@/api';
import { useAccountInfo } from '@/hooks';
import * as routes from '@/routes/routes';
import {
  BANK_ACCOUNT_BLOCKING_SUBSTEPS,
  CHECKHQ_BLOCKER_STATES,
  CHECKHQ_IMPLEMENTATION_STATUSES,
  CHECKHQ_ONBOARDING_STATUSES,
  CHECKHQ_REQUIREMENTS_CATEGORIES,
  CHECKHQ_REQUIREMENTS_STATUSES,
  COMPANY_ONBOARDING_STEP_CONFIGS,
} from '@/utils/checkHQConstants';
import { isArrayHasData } from '@/utils/helpers';

import { RequirementItem } from './types';

const hasOtherRequirements = (data: RequirementItem[]) => {
  // Filter out items where requirement is 'company_financial_verification'
  const nonFinancialVerification = data.filter(
    (item: RequirementItem) => item.requirement !== 'company_financial_verification',
  );

  // If no other requirements exist, return false
  return nonFinancialVerification.length > 0;
};

const useCheckHQCompanyOnboardingStatus = () => {
  const { account, companyId, isCheckHqUser } = useAccountInfo();
  const navigate = useNavigate();

  const {
    data: syncData,
    isLoading: isSyncCompanyDataLoading,
    refetch: refetchCompanySyncData,
  } = useGetSyncData({
    companyId,
    rqParams: {
      enabled: !!isCheckHqUser,
    },
  });

  const { onboard, implementation } = syncData?.checkHqOnboardingData ?? {};

  const checkHqRequirementsData = syncData?.checkHqRequirementsData || [];

  const isCompanyNeedsToProvideDocuments =
    hasOtherRequirements(checkHqRequirementsData) &&
    checkHqRequirementsData.some(
      category =>
        category.categories[0] === CHECKHQ_REQUIREMENTS_CATEGORIES.company_provided_documents &&
        category.status === CHECKHQ_REQUIREMENTS_STATUSES.failed,
    );

  const isCompanyHasFailedPayrollDeposit = useMemo(() => {
    return (
      isArrayHasData(checkHqRequirementsData) &&
      checkHqRequirementsData?.some(
        item =>
          item.categories.includes(CHECKHQ_REQUIREMENTS_CATEGORIES.failed_payroll_debit) &&
          item.status === CHECKHQ_REQUIREMENTS_STATUSES.failed,
      )
    );
  }, [checkHqRequirementsData]);

  const formatSteps = (
    steps: string[] | undefined,
    onboard: Record<string, any>,
    status: string,
  ): Record<string, any>[] => {
    if (!steps) return [];

    return steps
      .map(step => {
        const config = COMPANY_ONBOARDING_STEP_CONFIGS[step] || {};
        const substepData = onboard[step];

        if (Array.isArray(substepData)) {
          // Check if the array contains objects (for substeps with names and statuses)
          if (substepData.length > 0 && typeof substepData[0] === 'object') {
            const filteredSubsteps = substepData
              .filter(substep => substep.status === status)
              .map(substep => substep.name);

            if (filteredSubsteps.length > 0) {
              return {
                step,
                substeps: filteredSubsteps,
                title: config.title,
                description: config.description,
                actionName: 'Complete',
                action: () =>
                  navigate(routes.COMPANY, {
                    state: config.actionState,
                  }),
              };
            }
          } else {
            // If the array is directly substep strings
            if (onboard.status === status) {
              const filteredSubsteps = substepData.map(substep => {
                if (typeof substep === 'string' && step === CHECKHQ_BLOCKER_STATES.bank_account) {
                  return BANK_ACCOUNT_BLOCKING_SUBSTEPS[substep] ?? substep;
                }
                return typeof substep === 'string' ? substep : substep.name;
              });
              return {
                step,
                substeps: filteredSubsteps as string[],
                title: config.title,
                description: config.description,
                actionName: 'Complete',
                action: () =>
                  navigate(routes.COMPANY, {
                    state: config.actionState,
                  }),
              };
            }
          }
        }

        return null;
      })
      .filter(step => step !== null) as Record<string, any>[];
  };

  // Blocking steps
  const blockingSteps = formatSteps(
    onboard?.blocking_steps,
    onboard || {},
    CHECKHQ_ONBOARDING_STATUSES.blocking,
  );

  // Remaining steps
  const remainingSteps = formatSteps(
    onboard?.remaining_steps,
    onboard || {},
    CHECKHQ_ONBOARDING_STATUSES.needs_attention,
  );

  const isCompanyBlocking = onboard?.status === CHECKHQ_ONBOARDING_STATUSES.blocking;
  const isCompanyCompleted = onboard?.status === CHECKHQ_ONBOARDING_STATUSES.completed;
  const isCompanyNeedsAttention = onboard?.status === CHECKHQ_ONBOARDING_STATUSES.needs_attention;

  const passedAccountSetupFinishOnboardingStepMetaData =
    account?.metadata?.passedAccountSetupFinishOnboardingStep;

  const isCheckHQAccountUnderReview =
    isCheckHqUser &&
    passedAccountSetupFinishOnboardingStepMetaData &&
    (implementation?.status === CHECKHQ_IMPLEMENTATION_STATUSES.in_review ||
      implementation?.status === CHECKHQ_IMPLEMENTATION_STATUSES.needs_attention);

  return {
    blockingSteps,
    remainingSteps,
    implementation,
    isLoading: isSyncCompanyDataLoading,
    isCompanyBlocking,
    isCompanyCompleted,
    isCompanyNeedsAttention,
    isCompanyNeedsToProvideDocuments,
    isCompanyHasFailedPayrollDeposit,
    isCheckHQAccountUnderReview,
    refetchCompanySyncData,
  };
};

export default useCheckHQCompanyOnboardingStatus;
