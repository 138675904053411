import { ReactNode } from 'react';

import { useAccountInfo } from '@/hooks';

type CheckHQWrapperProps = {
  children?: ReactNode;
  hideItem?: boolean;
};

const CheckHQWrapper: React.FC<CheckHQWrapperProps> = ({
  children,
  hideItem = false,
}: CheckHQWrapperProps): JSX.Element | null => {
  const { isCheckHqUser } = useAccountInfo();

  // Return null if the condition for 'hideItem' or 'checkHqId' is met
  if ((hideItem && isCheckHqUser) || (!hideItem && !isCheckHqUser)) {
    return null;
  }

  return <>{children}</>;
};

export default CheckHQWrapper;
