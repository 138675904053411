import { twMerge } from 'tailwind-merge';

import { ReactComponent as DocumentSVG } from '@/assets/illustration/document.svg';
import { payrollHistoryStatusType } from './type';

const PayrollHistoryStatusCard = ({
  cardImage,
  title,
  subTitle,
  cardImageStyle,
  subTitleStyle,
  actions,
  isCardImageVisible = false,
}: payrollHistoryStatusType) => {
  return (
    <div className="bg-navy-500 relative flex flex-col rounded-lg p-6">
      <div className="flex flex-col md:max-w-[90%]">
        <span className="text-[28px] font-medium text-white sm:w-[328px]">{title}</span>
        <span className={twMerge('pt-[5px] text-sm text-gray-50 sm:max-w-[370px]', subTitleStyle)}>
          {subTitle}
        </span>
        {actions}
      </div>

      <div className={twMerge(`absolute bottom-0 right-3 hidden md:block`, cardImageStyle)}>
        {isCardImageVisible ? <DocumentSVG /> : cardImage}
      </div>
    </div>
  );
};

export default PayrollHistoryStatusCard;
