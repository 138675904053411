import { useRadio, useRadioGroup } from '@react-aria/radio';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import { ForwardedRef, forwardRef, useRef } from 'react';
import { Tooltip } from 'react-tooltip';

import { isArrayHasData } from '@/utils/helpers';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { PLANS } from '../shared/SubscriptionBillingCard/utils/constants';
import { Separator } from '../ui/Separator/Separator';

interface Option {
  value: string | number | boolean | null;
  label: string;
  disabled?: boolean;
  description?: string;
  price: number;
  paidWorkerPrice: number;
}

interface RadioOptionProps {
  option: Option;
  state: RadioGroupState;
  globalDisabled?: boolean;
  trackEvent?: (name: string, data: Object) => void;
  onChange: (value: string | number | boolean | null) => void;
  selectedValue: string | number | boolean | null;
  error?: boolean;
  optionClasses?: string;
  index: number;
  discount?: number;
}

const RadioOption = ({
  option,
  state,
  globalDisabled,
  trackEvent,
  onChange,
  selectedValue,
  error,
  optionClasses,
  index,
  discount,
}: RadioOptionProps) => {
  const { value, disabled, label, description, price, paidWorkerPrice } = option;

  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useRadio(
    {
      'aria-label': label,
      //@ts-ignore
      value: value,
      isDisabled: globalDisabled || disabled,
    },
    state,
    ref,
  );

  const isSelected = selectedValue == value;

  const handleChange = () => {
    onChange(value);
    if (trackEvent) trackEvent('inputChange', option);
  };

  const features = [
    { text: 'Federal, state, and local payroll taxes calculated', available: true },
    { text: 'W2 and 1099-NEC tax forms for manual filing', available: true },
    { text: 'Unlimited payroll calculations', available: true },
    {
      text: 'Payroll taxes paid and filed automatically',
      available: value === PLANS['full-service-payroll'],
    },
    {
      text: 'Pay your team via direct deposit',
      available: value === PLANS['full-service-payroll'],
    },
    {
      text: 'Manage and pay child support garnishments',
      available: value === PLANS['full-service-payroll'],
    },
    {
      text: 'Access to health, retirement, and insurance benefits',
      available: value === PLANS['full-service-payroll'],
    },
  ];

  return (
    <label
      htmlFor={inputProps.id}
      className={twMerge(
        `p-medium flex w-full min-w-[140px] max-w-[320px] cursor-pointer items-center gap-x-2 rounded-xl border-2`,
        isSelected ? 'border-border-primary' : 'border-border-medium',
        globalDisabled || disabled ? 'cursor-not-allowed opacity-50' : '',
        !!error && 'border-red-500',
        optionClasses ? optionClasses : '',
        index !== 0 && 'mt-medium lg:mt-0',
      )}
    >
      <Tooltip
        place="top"
        className="!bg-navy-500 !text-small z-[3] !w-auto max-w-[225px] !rounded-lg font-normal"
        id="informational-tooltip"
        content="You will be charged this amount per month only for employees or contractors that were paid during the billing cycle"
        opacity={1}
        closeEvents={{ mouseout: true }}
      />
      <input
        {...inputProps}
        ref={ref}
        onChange={handleChange}
        checked={isSelected}
        disabled={globalDisabled || disabled}
        className="visuallyHidden"
      />
      <div className="relative w-full">
        <div className="flex w-full items-start justify-between">
          <div className="flex flex-col items-start gap-x-2">
            {label && <span className="font-F37Bolton-Bold text-x-large">{label}</span>}
            {!!description && (
              <p className="text-content-body-strong text-small mt-2">{description}</p>
            )}
          </div>

          <div
            className={twMerge(
              `ml-medium mt-1 flex size-6 flex-shrink-0 items-center justify-center rounded-full p-[3px]`,
              isSelected ? 'bg-emerald-500' : 'border',
            )}
          >
            <div className={twMerge(`size-3 rounded-full`, isSelected ? ' bg-white' : '')}></div>
          </div>
        </div>
        {value === PLANS.basic && (
          <div className="mt-medium flex flex-col">
            <span className="text-large font-F37Bolton-Bold">{`$${price} / month`}</span>
            <div className="flex items-center">
              <span className="text-small">{`Plus $${paidWorkerPrice} / paid worker`}</span>
              <IconInfoCircle
                className="text-icon-disabled size-medium ml-1"
                data-tooltip-id="informational-tooltip"
              />
            </div>
          </div>
        )}
        {value === PLANS['full-service-payroll'] && !discount && (
          <div className="mt-medium flex flex-col">
            <span className="text-large font-F37Bolton-Bold">{`$${price} / month`}</span>
            <div className="flex items-center">
              <span className="text-small">{`Plus $${paidWorkerPrice} / paid worker`}</span>
              <IconInfoCircle
                className="text-icon-disabled size-medium ml-1"
                data-tooltip-id="informational-tooltip"
              />
            </div>
          </div>
        )}
        {value === PLANS['full-service-payroll'] && discount && (
          <div className="mt-medium flex flex-col">
            <div className="text-large ">
              <span className="text-content-body-disabled font-F37Bolton-Bold mr-1 line-through">
                ${price}
              </span>
              <span className="font-F37Bolton-Bold">{`$${
                price * ((100 - discount) / 100) ?? 0
              } / month`}</span>
            </div>
            <div className="flex items-center">
              <span className="text-small">
                Plus{' '}
                <span className="text-content-body-disabled mr-1 line-through">
                  ${paidWorkerPrice}
                </span>{' '}
                ${paidWorkerPrice * ((100 - discount) / 100) ?? 0} / paid worker
              </span>
              <IconInfoCircle
                className="text-icon-disabled size-medium ml-1"
                data-tooltip-id="informational-tooltip"
              />
            </div>
            <span className="text-2x-small font-F37Bolton-Medium text-content-primary mt-1 uppercase">
              50% off for 6 months
            </span>
          </div>
        )}
        <Separator type="light" thickness="1px" className="my-medium" />
        <ul className="mt-medium flex flex-col gap-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start gap-x-2">
              {feature.available ? (
                <IconCheck className="mt-1 size-4 shrink-0 text-emerald-500" />
              ) : (
                <IconX className="mt-1 size-4 shrink-0 text-gray-400" />
              )}
              <span
                className={twMerge(
                  'text-x-small text-content-body-strong',
                  !feature.available && 'text-content-body-light',
                )}
              >
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </label>
  );
};

export interface RadioGroupWithDescriptionProps {
  label?: string;
  options: Option[];
  description?: string;
  showAsterisk?: boolean;
  disabled?: boolean;
  trackEvent?: (name: string, data: Object) => void;
  onChange: (value: string | number | boolean | null) => void;
  selectedValue: string | number | boolean | null;
  errorMessage?: string;
  optionWrapperClasses?: string;
  optionClasses?: string;
  labelClasses?: string;
  width?: string;
}

const RadioGroupWithDescription = forwardRef(
  (
    {
      label,
      options,
      description,
      showAsterisk,
      disabled: globalDisabled,
      trackEvent,
      onChange,
      selectedValue,
      errorMessage,
      optionWrapperClasses,
      optionClasses,
      labelClasses,
      width,
      discount,
    }: RadioGroupWithDescriptionProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const state = useRadioGroupState({
      //@ts-ignore
      defaultValue: selectedValue,
    });
    const { radioGroupProps, labelProps } = useRadioGroup(
      { label, 'aria-label': label ? label : 'radio-group' },
      state,
    );

    if (!isArrayHasData(options)) return null;

    return (
      <div {...radioGroupProps} className={`flex flex-col gap-y-2 ${width && width}`}>
        {label && (
          <p {...labelProps} className={`font-F37Bolton-Medium ${labelClasses}`}>
            {label} {showAsterisk && <span className="font-bold text-red-500">*</span>}
          </p>
        )}
        <div
          className={`flex flex-col gap-x-2 lg:flex-row ${
            optionWrapperClasses ? optionWrapperClasses : ''
          }`}
          ref={ref}
        >
          {options.map((option, index) => (
            <RadioOption
              key={index}
              index={index}
              option={option}
              state={state}
              globalDisabled={globalDisabled}
              trackEvent={trackEvent}
              onChange={onChange}
              selectedValue={selectedValue}
              error={!!errorMessage}
              optionClasses={optionClasses}
              discount={discount}
            />
          ))}
        </div>
        {description && <p className="text-sm text-gray-400">{description}</p>}
        {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
      </div>
    );
  },
);

RadioGroupWithDescription.displayName = 'RadioGroupWithDescription';

export default RadioGroupWithDescription;
