import { LoaderComponent } from '@/components';
import Button from '@/components/Button/Button';
import ContactSupportButton from '@/components/shared/ContactSupportButton';
import Drawer from '@/components/ui/Drawer/Drawer';

import { ConfirmationModal } from './components';
import ReviewScheduleContent from './components/ReviewScheduleContent/ReviewScheduleContent';
import PayrollScheduleForm from './forms/PayrollScheduleForm/PayrollScheduleForm';
import { PayrollScheduleProps } from './types';
import usePayrollSchedule from './usePayrollSchedule';

const PayrollSchedule: React.FC<PayrollScheduleProps> = ({
  setIsPayrollScheduleOpen,
  isPayrollScheduleOpen,
  isEdit,
  setIsEdit,
  setStep,
  step,
  refetchDashboard,
}: PayrollScheduleProps) => {
  const {
    onSubmit,
    loading,
    isPreviewLoading,
    isFetchingRunnablePayrolls,
    previewSchedule,
    handleSubmitSchedule,
    handleBackButton,
    isConfirmationOpen,
    handleEditSchedule,
    isScheduleEditable,
    previewScheduleButtonRef,
    previewScheduleData,
    handleSaveButtonValidation,
    isSaveButtonDisabled,
    payFrequency,
    bankHolidays,
    handleIsDirty,
    handleConfirmDirtyModal,
    handleCloseDirtyModal,
    handleDrawerClose,
  } = usePayrollSchedule({
    setIsPayrollScheduleOpen,
    isPayrollScheduleOpen,
    isEdit,
    setStep,
    step,
    setIsEdit,
    refetchDashboard,
  });

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="p-5">
            <PayrollScheduleForm
              submitHandler={onSubmit}
              schedule={previewScheduleData}
              isLoadingPreviewData={loading}
              ref={previewScheduleButtonRef}
              hideSubmitButton
              handleSaveButtonValidation={handleSaveButtonValidation}
              handleIsDirty={handleIsDirty}
            />
          </div>
        );
      case 2:
        return (
          <div className="relative overflow-hidden">
            <div className=" pt-6">
              {isPreviewLoading || loading || isFetchingRunnablePayrolls ? (
                <div className="p-5">
                  <LoaderComponent />
                </div>
              ) : (
                <ReviewScheduleContent
                  bankHolidays={bankHolidays}
                  schedule={previewSchedule}
                  payFrequency={payFrequency}
                />
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        open={isPayrollScheduleOpen}
        title={step === 1 ? 'Set the payroll schedule' : 'Preview schedule'}
        onClose={handleDrawerClose}
        modelClasses="max-w-[640px]"
        isBackIcon={step === 1}
        onBackAction={handleBackButton}
        footer={
          step === 2 ? (
            <div
              className={`flex w-full ${
                !isScheduleEditable && isEdit ? 'justify-between' : 'justify-end'
              } p-5`}
            >
              {!isScheduleEditable && isEdit && (
                <span className="max-w-[284px]">
                  To edit your payroll schedule after running payroll, please
                  <ContactSupportButton className="text-medium  ml-1 inline-block lowercase text-emerald-500 underline underline-offset-2" />
                </span>
              )}
              <Button
                variant="primary"
                parentClasses="min-w-[147px] max-w-[147px]"
                isLoading={loading}
                isDisabled={isEdit && !isScheduleEditable}
                onPress={isEdit ? handleEditSchedule : handleSubmitSchedule}
              >
                {isEdit ? 'Edit Schedule' : 'Save Schedule'}
              </Button>
            </div>
          ) : (
            <div className="flex w-full justify-end p-5">
              <Button
                isDisabled={isSaveButtonDisabled}
                onPress={() => previewScheduleButtonRef.current?.submitForm()}
                variant="primary"
                parentClasses="w-full md:w-[150px]"
                isLoading={loading}
              >
                Preview schedule
              </Button>
            </div>
          )
        }
      >
        <div className="max-w-[700px] bg-white">{renderStep()}</div>
      </Drawer>
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          handleConfirm={handleConfirmDirtyModal}
          handleClose={handleCloseDirtyModal}
        />
      )}
    </>
  );
};

export default PayrollSchedule;
