import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { taxInformation } from '@/redux/dto/company';

import { GET_COMPANY_TAX_INFORMATION } from '../employerTaxApiRoutes';
import { UseGetCompanyTaxInformationType } from './types';

const useGetCompanyTaxInformation = ({
  companyId,
  queryParams,
}: UseGetCompanyTaxInformationType): UseQueryResult<taxInformation[], Error> => {
  const fetchEmployerTax = (): Promise<AxiosResponse<taxInformation[], Error>> =>
    getCall(GET_COMPANY_TAX_INFORMATION(companyId));

  return useQuery({
    queryKey: [companyId, 'employerTax'],
    queryFn: fetchEmployerTax,
    select: ({ data }) => data,
    ...queryParams,
  });
};

export default useGetCompanyTaxInformation;
