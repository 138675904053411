import { deleteCall } from '@/api/axios';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { DELETE_DEDUCTION } from './employeeApiRoutes';

interface UseDeleteBenefitArgs {
  companyId: number;
  employeeId: number | null;
  onSuccess?: () => void;
}

const deleteDeduction = (companyId: number, employeeId: number | null, garnishmentId: number) =>
  deleteCall(DELETE_DEDUCTION({ companyId, employeeId, id: garnishmentId }));

const useDeleteDeduction = ({
  companyId,
  employeeId,
  onSuccess,
}: UseDeleteBenefitArgs): UseMutationResult<AxiosResponse<any, any>, Error, number, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (garnishmentId: number) => deleteDeduction(companyId, employeeId, garnishmentId),
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries({ queryKey: ['getDeductions'] });
    },
  });
};

export default useDeleteDeduction;
