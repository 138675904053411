import { LoaderComponent } from '@/components/ui';

import SubscriptionPlanGroup from '../SubscriptionPlanGroup/SubscriptionPlanGroup';
import useManageSubscriptionPlan from './useManageSubscriptionPlan';

type ManageSubscriptionPlanType = {
  choosePlanTitle: string;
  upgradeSuccessHandler: () => void;
  downgradeSuccessHandler: () => void;
  openCancelModalHandler: () => void;
  onCancel: () => void;
};

const ManageSubscriptionPlan = ({
  upgradeSuccessHandler,
  downgradeSuccessHandler,
  openCancelModalHandler,
  onCancel,
}: ManageSubscriptionPlanType) => {
  const {
    handlePlanChange,
    isLoadingSubscriptionPlans,
    plans,
    recommendedPlan,
    selectedPlan,
    confirmPlanHandler,
    isPending,
    correctSubscriptionAgreement,
    discount,
  } = useManageSubscriptionPlan({ upgradeSuccessHandler, downgradeSuccessHandler, onCancel });

  return (
    <div>
      {isLoadingSubscriptionPlans ? (
        <LoaderComponent />
      ) : (
        <SubscriptionPlanGroup
          plans={plans}
          onSelectSubscriptionPlan={handlePlanChange}
          selectedPlan={selectedPlan}
          recommendedPlan={recommendedPlan}
          onConfirmPlan={confirmPlanHandler}
          isPending={isPending}
          openCancelModalHandler={openCancelModalHandler}
          activeSubscriptionAgreement={correctSubscriptionAgreement}
          onCancel={onCancel}
          discount={discount}
        />
      )}
    </div>
  );
};

export default ManageSubscriptionPlan;
