import { IconChevronDown } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { NumericFormat } from 'react-number-format';

import { LoaderComponent, Menu } from '@/components';
import { Tag } from '@/components/ui/Tag/Tag';

import { BillingHistoryCardProps } from '../../type';
import { statusColorMap, statusTextMap } from '../BillingHistoryTableRow/BillingHistoryTableRow';

const BillingHistoryCard = ({
  data,
  isLoading,
  handleDownloadInvoice,
  handleDownloadReceipt,
}: BillingHistoryCardProps) => {
  const payDate = DateTime.fromISO(data?.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  const handleMenuActions = (key?: string) => {
    switch (key) {
      case 'download_invoice':
        handleDownloadInvoice();
        break;
      case 'download_receipt':
        if (data?.status === 'paid') {
          handleDownloadReceipt();
        } else {
          break;
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col gap-4 sm:hidden">
      <div className="rounded-xl border">
        <div className="flex border-b border-gray-50">
          <span className="p-small text-large font-F37Bolton-Medium">{payDate}</span>
        </div>
        <div className="flex justify-between border-b border-gray-50">
          <span className="p-small text-small text-content-body-medium w-2/5">Description</span>
          <span className="p-small text-small">{data.plan}</span>
        </div>
        <div className="flex justify-between border-b border-gray-50">
          <span className="p-small text-small text-content-body-medium w-2/5">Amount</span>
          <NumericFormat
            value={data?.discountAmount ? data?.discountAmount : data?.amount}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
            renderText={value => <span className="p-small text-small">${value}</span>}
          />
        </div>
        <div className="flex border-b border-gray-50">
          <span className="p-small text-small text-content-body-medium w-2/5">Payment status</span>
          <div className="p-small flex w-3/5 items-center justify-end">
            <Tag
              tagStyle="default"
              tagType={statusColorMap[data?.status] ?? 'danger'}
              children={statusTextMap[data?.status] ?? 'Failed'}
              className="capitalize"
            />
          </div>
        </div>
        <div className="border-t p-4">
          {/* <Button onPress={handleAction} variant="tertiary" className="text-small h-9 w-full">
            {data?.fsInvoiceUrl ? 'View receipt' : 'Download receipt'}
          </Button> */}
          {isLoading ? (
            <LoaderComponent height={42} />
          ) : (
            <Menu
              menuButtonVariant="tertiary"
              menuButtonClasses="h-auto text-small !w-full !text-content-body-strong !py-x-small !px-medium"
              menuButtonLoading={isLoading}
              menuButtonLabel="Download"
              menuButtonIcon={<IconChevronDown className="size-medium text-icon-dark ml-1" />}
              menuItemsList={[
                {
                  key: 'download_invoice',
                  label: 'Download invoice',
                },
                {
                  key: 'download_receipt',
                  label: 'Download receipt',
                  className:
                    data?.status === 'paid' ? '' : 'text-content-body-light !cursor-default',
                  tooltipContent: 'You can download the receipt after the invoice has been paid',
                  tooltipId: data?.status === 'paid' ? null : 'receipt-tooltip',
                },
              ]}
              menuWrapperClasses="min-w-[200px]"
              onAction={(actionKey?: Key) => handleMenuActions(actionKey)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingHistoryCard;
