import React from 'react';

import DialogComponent from '@/components/Dialog/DialogComponent';
import { UrgentModal } from '@/components/ui/alerts/UrgentModal/UrgentModal';

type PayrollImpactNoticePopupProps = {
  isNoticePopupOpen: boolean;
  setIsNoticePopupOpen: (open: boolean) => void;
  onNoticeCancel: () => void;
};

const PayrollImpactNoticePopup: React.FC<PayrollImpactNoticePopupProps> = ({
  isNoticePopupOpen,
  setIsNoticePopupOpen,
  onNoticeCancel,
}) => {
  return (
    <DialogComponent
      open={isNoticePopupOpen}
      onCancel={() => setIsNoticePopupOpen(false)}
      width="sm:w-[352px] w-[400px]"
      height="h-auto"
      modalClasses="rounded-[8px] relative"
    >
      <UrgentModal
        title={`This change will impact upcoming payroll calculations and adjustments.`}
        onCancel={onNoticeCancel}
        onClose={() => setIsNoticePopupOpen(false)}
        closeButtonText="Got it"
      />
    </DialogComponent>
  );
};

export default PayrollImpactNoticePopup;
