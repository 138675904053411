import { ReactNode } from 'react';

import { customTv } from '@/utils/tailwindMergeConfig';

interface TagProps {
  /**
   * Defines the visual style of the tag.
   * - 'default': A more subtle visual style.
   * - 'loud': A bolder visual style with higher contrast.
   * @default "default"
   */
  tagStyle: 'default' | 'loud';

  /**
   * Specifies the type of the tag, which affects its color and background.
   * - 'success': Green color scheme, usually indicating success or a positive action.
   * - 'accent': Blue color scheme, for highlighting important information.
   * - 'warning': Yellow color scheme, for warnings or alerts.
   * - 'danger': Red color scheme, indicating danger or errors.
   * - 'neutral': Navy color scheme, for neutral information.
   * @default "success"
   */
  tagType: 'success' | 'accent' | 'warning' | 'danger' | 'neutral';

  /**
   * Optional additional class names for further customization of the tag.
   */
  className?: string;

  /**
   * The content to be rendered inside the tag.
   * Typically a text label or other inline content.
   */
  children: ReactNode;
}

export const Tag = ({ tagStyle, tagType, className, children }: TagProps) => {
  const tagVariant = customTv({
    base: `flex items-center justify-center rounded-2x-small py-0.5 px-x-small text-center !text-x-small w-fit`,
    variants: {
      tagStyle: {
        default: '',
        loud: '',
      },
      tagType: {
        success: 'text-primary-emerald-hover bg-primary-emerald-light-bg',
        accent: 'bg-semantic-accent-blue-light-bg text-semantic-accent-blue-hover',
        warning: 'text-semantic-warning-yellow-hover bg-semantic-warning-yellow-light-bg',
        danger: 'text-semantic-danger-red-hover bg-semantic-danger-red-light-bg',
        neutral: 'text-secondary-navy-hover bg-secondary-navy-light-bg',
      },
    },
    compoundVariants: [
      {
        tagStyle: 'loud',
        tagType: 'success',
        class: 'bg-primary-emerald text-content-white',
      },
      {
        tagStyle: 'loud',
        tagType: 'accent',
        class: 'bg-semantic-accent-blue-hover text-content-white',
      },
      {
        tagStyle: 'loud',
        tagType: 'warning',
        class: 'bg-semantic-warning-yellow-hover text-content-white',
      },
      {
        tagStyle: 'loud',
        tagType: 'danger',
        class: 'bg-semantic-danger-red-hover text-content-white',
      },
      {
        tagStyle: 'loud',
        tagType: 'neutral',
        class: 'bg-secondary-navy-hover text-content-white',
      },
    ],
    defaultVariants: {
      tagStyle: 'default',
      tagType: 'success',
    },
  });

  return <div className={tagVariant({ tagStyle, tagType, class: className })}>{children}</div>;
};
