import moment from 'moment';
import { NumericFormat } from 'react-number-format';

import {
  StatusBadge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components';
import { PAYROLL_TYPE_TO_NAME_MAP } from '@/modules/runPayroll/utils/constants';
import { statusSeverityMap } from '@/utils/statusSeverity';

import PayrollHistoryTableActionLink from './PayrollHistoryTableActionLink';

const PayrollHistoryTableListing = ({ payrollHistory, historyStatus, isCheckHqUser }: any) => {
  return (
    <Table className="text-sm">
      <TableHeader className="bg-gray-50">
        <TableRow>
          <TableHead className="pl-6">Pay date</TableHead>
          <TableHead>Period</TableHead>
          <TableHead>Type</TableHead>
          <TableHead>Status</TableHead>
          <TableHead className="text-right">Payroll cost</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {payrollHistory
          ?.filter(item => !(item.status === 4 && !item.checkHQCalculatedData))
          ?.map((item, index) => {
            const statusInfo = historyStatus[item.status];

            const isSameYear =
              item.payPeriodStartDate && item.payPeriodEndDate
                ? moment(item.payPeriodStartDate).year() === moment(item.payPeriodEndDate).year()
                : false;

            const payPeriod =
              item.payPeriodStartDate && item.payPeriodEndDate
                ? moment(item.payPeriodStartDate).format(`MMM DD${!isSameYear ? ', YYYY' : ''}`) +
                  ' - ' +
                  moment(item.payPeriodEndDate).format('MMM DD, YYYY')
                : '--';

            const formattedPayDate =
              item.payDate && moment(item.payDate).isValid()
                ? moment(item.payDate).format('MMM DD,YYYY')
                : '-';

            const cashTipsValue =
              item?.payments?.reduce((sum, payment) => sum + (payment.cashTips || 0), 0) ?? 0;

            const totalPayroll = isCheckHqUser
              ? item?.checkHQCalculatedData?.totals?.liability - cashTipsValue
              : item.totalPayroll;

            return (
              <TableRow key={index} className="px-4">
                <TableCell className="py-3 pl-6 text-gray-400">{formattedPayDate}</TableCell>
                <TableCell className="py-3">{payPeriod}</TableCell>
                <TableCell className="py-3 text-gray-400">
                  {PAYROLL_TYPE_TO_NAME_MAP[item.type]}
                </TableCell>
                <TableCell className="py-3">
                  <StatusBadge
                    text={
                      isCheckHqUser
                        ? statusInfo?.label === 'Approved'
                          ? 'Paid'
                          : statusInfo?.label
                        : statusInfo?.label
                    }
                    severity={statusSeverityMap[statusInfo?.variant]}
                  />
                </TableCell>
                <TableCell className="py-3 text-right text-gray-400">
                  <NumericFormat
                    value={isNaN(totalPayroll) ? 0 : totalPayroll}
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={value => <span>${value}</span>}
                  />
                </TableCell>

                <TableCell className="py-3 pr-6 text-right">
                  <PayrollHistoryTableActionLink
                    payrollId={item?.id}
                    payrollType={item?.type}
                    status={item?.status}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default PayrollHistoryTableListing;
