import { field } from '@/redux/dto/company';

export const formateValueBasedOnType = (field: field) => {
  const value = field.value === null ? field.defaultValue : field.value;
  switch (field.type) {
    case 'Money':
      return value ? `$ ${value}` : null;

    case 'Percentage':
      return value && !isNaN(Number(value)) ? `${Number(value).toFixed(4)}%` : null;

    case 'String':
      return `${value}`;

    case 'Integer':
      return `${value}`;

    case 'Choice': {
      const choices = Array.isArray(field.choices) ? field.choices : [];
      const selectedChoice = choices.find(item => item.value === value);

      return `${selectedChoice ? selectedChoice.label : value}`;
    }
    case 'List': {
      const choices = Array.isArray(field.choices) ? field.choices : [];
      const selectedChoice = choices.find(item => item.value === value);

      return `${selectedChoice ? selectedChoice.label : value}`;
    }

    default:
      return field.value || field.defaultValue;
  }
};
