import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { LoaderComponent } from '@/components';
import { useAccountInfo, useBillingInfo } from '@/hooks';
import { Dispatch, RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { impersonationLogoutHandler, useLocationEffect } from '@/utils/helpers';

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { isLoading: isLoadingAuth0, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { isAuthenticatedAsImpersonate, isAccountLoading, account: accountInfo } = useAccountInfo();
  const { isBillingInfoLoading, billingInfo: subscriptionInfo } = useBillingInfo();

  const subscriptionAgreements = subscriptionInfo?.subscriptionAgreements;

  const hasSubscriptions = Array.isArray(subscriptionAgreements) && !!subscriptionAgreements.length;

  const authErrMessage = useSelector((state: RootState) => state.auth?.error?.error);

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  //TODO: remove this when billing info from redux is replaced with hook
  // For now keep it as when the subscirption data is change, refetch the billing info from hook
  // UPD: Removed. Keeping comments in case something goes wrong.
  // useEffect(() => {
  //   if (subscriptionInfo) {
  //     refetchBillingInfo();
  //   }
  // }, [subscriptionInfo]);

  useEffect(() => {
    // Just forcing a refresh of identity, otherwise it only happens on /callback
    if (!isAppModeDemo && (isAuthenticated || isAuthenticatedAsImpersonate)) {
      dispatch.auth.getUserInformation();
      dispatch.auth.getOnboardinUserStatus();
    }
  }, [isAuthenticatedAsImpersonate, isAuthenticated, isAppModeDemo, dispatch]);

  useLocationEffect();

  const handleAuth0Logout = () => {
    const redirectUrl = window.location.pathname + window.location.search;

    logout({
      logoutParams: {
        returnTo: window.location.origin + `/login?redirectTo=${redirectUrl}`,
      },
    });
    window.fcWidget?.user?.clear();
    localStorage.clear();
    sessionStorage.clear();
    impersonationLogoutHandler();
  };

  const handleAuthCheck = async () => {
    const isUserAuthenticated = isAuthenticated || isAuthenticatedAsImpersonate;
    if (!isAppModeDemo && !isUserAuthenticated) {
      if (authErrMessage === 'login_required') {
        navigate(routes.LOGOUT);
      }
      try {
        const token = await getAccessTokenSilently();
        if (!token) {
          handleAuth0Logout();
        }
      } catch (error) {
        handleAuth0Logout();
      }
    }
  };

  useEffect(() => {
    handleAuthCheck();
  }, [hasSubscriptions, authErrMessage, isAuthenticated, isAuthenticatedAsImpersonate]);

  if (isLoading || !accountInfo) {
    return (
      <div className="flex h-dvh items-center justify-center">
        <LoaderComponent />
      </div>
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
