import BillingHistoryCard from '../BillingHistoryCard/BillingHistoryCard';
import BillingHistoryTableRow from '../BillingHistoryTableRow/BillingHistoryTableRow';
import useBillingHistoryView from './useBillingHistoryView';

const BillingHistoryView = ({ data, isCardView = true }: any) => {
  const { isLoadingInvoice, isLoadingReceipt, handleDownloadInvoice, handleDownloadReceipt } =
    useBillingHistoryView({
      invoice: data,
    });
  return isCardView ? (
    <BillingHistoryCard
      data={data}
      // handleAction={handleAction}
      handleDownloadInvoice={handleDownloadInvoice}
      handleDownloadReceipt={handleDownloadReceipt}
      isLoading={isLoadingInvoice || isLoadingReceipt}
    />
  ) : (
    <BillingHistoryTableRow
      billData={data}
      handleDownloadInvoice={handleDownloadInvoice}
      handleDownloadReceipt={handleDownloadReceipt}
      isLoading={isLoadingInvoice || isLoadingReceipt}
    />
  );
};

export default BillingHistoryView;
