import { ReactComponent as Illustration } from '@/assets/illustration/downgradeModalIllustration.svg';
import Button from '@/components/Button/Button';
import { useBillingInfo } from '@/hooks';
import { Dispatch } from '@/redux/store';
import { useDispatch } from 'react-redux';

interface DowngradeModalProps {
  onGotIt: () => void;
}

export const DowngradeModal = ({ onGotIt }: DowngradeModalProps) => {
  const { billingInfo: subscriptionInformation } = useBillingInfo();
  const dispatch = useDispatch<Dispatch>();

  const activeSubscriptionAgreement =
    subscriptionInformation?.subscriptionAgreements[
    subscriptionInformation?.subscriptionAgreements?.length - 1
    ];
  const activeSubscriptionPlan = activeSubscriptionAgreement?.plan;

  const planLimits = {
    starter: 'up to 2 team members',
    pro: 'up to 5 team members',
    advanced: 'up to 15 team members',
    'advanced-plus': 'up to 25 team members',
  };

  const teamMemberLimitText = planLimits[activeSubscriptionPlan];

  return (
    <div className="flex max-w-[498px] flex-col items-center justify-center ">
      <Illustration />
      <span className="font-F37Bolton-Medium mt-6 text-center text-[38px]">
        Your plan has been downgraded
      </span>
      <span className="mt-1 text-center text-gray-400">
        You've successfully downgraded your plan to manage payroll for {teamMemberLimitText}.
      </span>
      <Button
        variant="primary"
        children="Got it"
        width="w-[416px]"
        parentClasses="mt-6"
        onPress={onGotIt}
      />
      <div className="flex flex-wrap justify-center text-sm text-gray-300">
        Have questions? Our{' '}
        <Button
          variant="text"
          width="w-auto"
          parentClasses="text-emerald-500 mx-1"
          height="h-auto"
          onPress={() => {
            onGotIt();
            window.fcWidget.open();
          }}
        >
          support team
        </Button>
        is here to help!
      </div>
    </div>
  );
};
