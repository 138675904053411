import { forwardRef, useEffect, useRef } from 'react';
import { Text } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { InputCheckboxFieldProps } from './types';

const InputCheckboxField = forwardRef(
  (
    {
      onChange,
      value,
      label,
      inputClasses,
      parentClasses,
      wrapperClasses,
      errorMessage,
      customOnChange,
      isIndeterminate = false,
      ...rest
    }: InputCheckboxFieldProps,
    ref,
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = isIndeterminate;
      }
    }, [isIndeterminate]);

    return (
      <div className={`${wrapperClasses ? wrapperClasses : ''}`}>
        <label
          className={`flex cursor-pointer items-center gap-2 ${parentClasses ? parentClasses : ''}`}
        >
          <input
            type="checkbox"
            className={twMerge(
              `cursor-pointer rounded-[4px]  text-emerald-500 focus:outline-none  focus:ring-0 focus:ring-offset-0`,
              rest.disabled && 'border-gray-200',
              inputClasses,
            )}
            onChange={e => {
              if (onChange) {
                onChange(e);
              }
              if (customOnChange) {
                customOnChange(e);
              }
            }}
            checked={!!value}
            {...rest}
            ref={el => {
              checkboxRef.current = el;
              if (typeof ref === 'function') ref(el);
              else if (ref) ref.current = el;
            }}
          />
          {label}
        </label>
        {errorMessage && (
          <Text slot="description" className={`text-[14px] text-red-500`}>
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

InputCheckboxField.displayName = 'InputCheckboxField';

export default InputCheckboxField;
