import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetTeamMembers } from '@/api';
import { useGetSubscriptionPlans } from '@/api/services/apis/subscriptionPlans/useGetSubscriptionPlans';
import { useAccountInfo, useBillingInfo } from '@/hooks';
import useBreakpoint from '@/hooks/useBreakPoint/useBreakPoint';
import useDiscountInfo from '@/hooks/useDiscountInfo/useDiscountInfo';
import { AVAILABLE_SUBSCRIPTIONS, FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';

import { SubscriptionPlanType } from './types';
import { UPGRADE_PLAN_STEPPER_STEP_IDS } from './utils/constants';

const useUpgradeSubscriptionPlan = () => {
  const location = useLocation();
  const { isDesktop } = useBreakpoint();

  const { company, companyId } = useAccountInfo();
  const { numberOfEmployees = 0, numberOfContractors = 0 } = company ?? {};
  const numberOfTeamMembers = numberOfContractors + numberOfEmployees;

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [plans, setPlans] = useState<SubscriptionPlanType[]>([]);
  const [recommendedPlan, setRecommendedPlan] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(UPGRADE_PLAN_STEPPER_STEP_IDS.selectPlanStep);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState<boolean>(false);

  const discountCode = sessionStorage.getItem('discountCode');

  const { hasSubscriptions } = useBillingInfo();
  const { verifyDiscount, discountObject, discount, isDiscountLoading } = useDiscountInfo();

  const checkScreenWidth: () => void = () => {
    setIsMobileSize(window.innerWidth <= 1024);
  };

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const { data, isLoading: isLoadingSubscriptionPlans } = useGetSubscriptionPlans({});

  const { data: membersList, isLoading: isLoadingTeamMembers } = useGetTeamMembers(companyId);

  const employeeCount = membersList?.filter(member => member.status === 'active')?.length;

  useEffect(() => {
    const memberCount = employeeCount ? employeeCount : numberOfTeamMembers;

    setPlans(
      Array.isArray(data?.availableProducts)
        ? data.availableProducts
            .filter(plans => plans.name !== FREE_3_MONTH_TRIAL_SUBSCRIPTION)
            .map(plan => {
              return {
                ...plan,
                price:
                  plan.availablePrices.find(pr => pr.pricingStrategy === 'monthly')?.amount ?? 0, // Need to remove monthly and put per_worker when all user are moved on new plans
                isDisabled:
                  plan.metadata?.maxSeats < memberCount &&
                  plan.name !== AVAILABLE_SUBSCRIPTIONS.advancedPlus,
              };
            })
            .sort((a, b) => a.price - b.price)
        : [],
    );
  }, [data, employeeCount]);

  useEffect(() => {
    const sortedPlans = [...plans].sort((a, b) => a.metadata?.maxSeats - b.metadata?.maxSeats);
    const calculatedTeamMemberCount = employeeCount ? employeeCount : numberOfTeamMembers;
    const suitablePlan = sortedPlans.find(
      plan => plan.metadata?.maxSeats >= calculatedTeamMemberCount,
    );
    if (suitablePlan && !recommendedPlan) {
      setRecommendedPlan(suitablePlan.name);
    }
    if (suitablePlan && !selectedPlan) {
      setSelectedPlan(suitablePlan.name);
    }
  }, [plans, selectedPlan, recommendedPlan, employeeCount, numberOfTeamMembers]);

  const handlePlanChange = (plan: string) => {
    setSelectedPlan(plan);
  };

  const confirmPlanHandler = () => {
    if (selectedPlan) {
      setCurrentStep(UPGRADE_PLAN_STEPPER_STEP_IDS.paymentStep);
      if (isMobileSize) {
        setIsPaymentModalOpen(true);
      } else {
        setIsPaymentModalOpen(false);
      }
    }
  };

  const closePaymentModalHandler = () => {
    setIsPaymentModalOpen(false);
  };

  useEffect(() => {
    if (discountCode) verifyDiscount({ code: discountCode });
    if (location.pathname === '/signup-stepper/billing' && !discountCode)
      verifyDiscount({ code: 'FULLSERVICE50-6' });
  }, [discountCode, verifyDiscount]);

  return {
    isLoadingSubscriptionPlans:
      isLoadingSubscriptionPlans || isLoadingTeamMembers || isDiscountLoading,
    plans,
    selectedPlan,
    handlePlanChange,
    recommendedPlan,
    confirmPlanHandler,
    isPaymentModalOpen,
    closePaymentModalHandler,
    isMobileSize,
    hasSubscriptions,
    discountCode: discountObject?.code,
    discount,
    isDesktop,
  };
};

export default useUpgradeSubscriptionPlan;
