import { ReactComponent as ArrowIcon } from '@/assets/arrowSmooth.svg';
import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import Tabs from '@/components/Tabs/Tabs';
import { LoaderComponent } from '@/components/ui';

import ReviewTaxInformation from '../ReviewTaxInformation/ReviewTaxInformation';
import SetupTaxInformation from '../SetupTaxInformation/SetupTaxInformation';
import { MissingStateTaxProps } from './types';
import useMissingStateTax from './useMissingStateTax';

const MissingStateTax = ({
  taxData,
  isLoadingTaxInformation,
  onSubmitStateTaxData,
  closeModalHandler,
  shouldShowAllFields,
  shouldShowAllTaxItems,
  refetchTaxInformation,
  handleIsDirty,
  handleDirtyModelOpen,
  isFormDirty,
}: MissingStateTaxProps) => {
  const {
    tabs,
    currentTab,
    setCurrentTab,
    isReviewTaxInfoModalOpen,
    backBtnHandler,
    onSubmitReviewTaxInfo,
    isSetupTaxInfoModalOpen,
    setupTaxInfoBtnClickHandler,
    isLoadingStateTaxInformation,
  } = useMissingStateTax({
    taxData,
    onSubmitStateTaxData,
    shouldShowAllFields,
    shouldShowAllTaxItems,
    closeModalHandler,
    refetchTaxInformation,
    handleIsDirty,
    handleDirtyModelOpen,
    isFormDirty,
  });

  return (
    <div>
      <div className="flex h-[40px] items-center justify-between border-b border-gray-100 py-[10px]">
        <div className="pl-[16px]">
          <Button variant="icon" icon={ArrowIcon} children="Back" onPress={backBtnHandler} />
        </div>
        <div className="pr-[16px]">
          <Button variant="icon" icon={CloseIcon} onPress={() => closeModalHandler()} />
        </div>
      </div>
      <div className="flex flex-col items-center px-5 py-10">
        <div className="w-full max-w-[400px]">
          {isLoadingTaxInformation || isLoadingStateTaxInformation ? (
            <LoaderComponent />
          ) : (
            !!taxData && (
              <div>
                {isSetupTaxInfoModalOpen ? (
                  <SetupTaxInformation
                    stateName={taxData.stateName}
                    onSubmit={setupTaxInfoBtnClickHandler}
                  />
                ) : isReviewTaxInfoModalOpen ? (
                  <ReviewTaxInformation taxData={taxData} onSubmit={onSubmitReviewTaxInfo} />
                ) : (
                  !!tabs.length && (
                    <Tabs
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      tabs={tabs}
                      variant="checkMark"
                      width="w-full"
                    />
                  )
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MissingStateTax;
