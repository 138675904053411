import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getCall } from '@/api/axios';

import { queryEndpoints } from './endpoints';

type UseGetCheckHQTypes = {
  companyId: number;
  rqParams?: Omit<UseQueryOptions<any, Error, any>, 'queryKey'>;
};

const useGetSyncData = ({ companyId, rqParams }: UseGetCheckHQTypes) => {
  const { queryKey, url } = queryEndpoints.getCheckHQSyncData(companyId);

  return useQuery<any, Error>({
    queryKey,
    queryFn: async () => {
      const res = await getCall(url);
      return res.data;
    },
    ...rqParams,
  });
};
export default useGetSyncData;
