import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import { useAccountInfo } from '@/hooks';
import { callToast } from '@/utils/helpers';

import { useUpdateAccountInfo, useUpdatePassword } from '../../api';

const useAccountBasicInfo = () => {
  const [isEditBasicInfo, setIsEditBasicInfo] = useState<boolean>(false);
  const [isEditPassword, setIsEditPassword] = useState<boolean>(false);

  const handleEditBasicInfoDrawer = () => {
    setIsEditBasicInfo(!isEditBasicInfo);
  };
  const handleEditPasswordDrawer = () => {
    setIsEditPassword(!isEditPassword);
  };

  const { user, isLoading } = useAuth0();
  const { account, refetchAccountInfo } = useAccountInfo();

  const isLoggedInViaGoogle = user?.sub?.includes('google');

  const profileData = [
    {
      id: 'name',
      label: 'Name',
      value:
        account?.firstName && account?.lastName ? account?.firstName + ' ' + account?.lastName : '',
    },
    {
      id: 'phone_num',
      label: 'Phone number',
      value: account?.phoneNumber || account?.preferredContactNumber,
    },
    {
      id: 'email_address',
      label: 'Email Address',
      value: account?.email,
    },
  ];

  const { mutate: updatePassword, isPending: isUpdatingPassword } = useUpdatePassword({
    mutationParams: {
      onSuccess: () => {
        handleEditPasswordDrawer();
        callToast('success', 'Password updated successfully!');
        refetchAccountInfo();
      },
    },
  });

  const { mutate: updateAccountInfo, isPending: isUpdatingAccountInfo } = useUpdateAccountInfo({
    accountId: account?.id,
    mutationParams: {
      onSuccess: () => {
        refetchAccountInfo();
        handleEditBasicInfoDrawer();
        callToast('success', 'Basic Info updated successfully!');
      },
    },
  });

  const handlePasswordUpdate = ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }) => {
    updatePassword({ currentPassword, newPassword });
  };

  return {
    isEditBasicInfo,
    handleEditBasicInfoDrawer,
    profileData,
    isLoggedInViaGoogle,
    account,
    isLoading,
    user,
    handleEditPasswordDrawer,
    isEditPassword,
    isUpdatingAccountInfo,
    handlePasswordUpdate,
    isUpdatingPassword,
    updateAccountInfo,
  };
};
export default useAccountBasicInfo;
