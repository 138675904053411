import { useAuth0 } from '@auth0/auth0-react';

import { useGetSubscriptionPlans } from '@/api/services/apis/subscriptionPlans/useGetSubscriptionPlans';

import useAccountInfo from '../useAccountInfo/useAccountInfo';

const usePlansInfo = () => {
  const { isAuthenticated } = useAuth0();
  const { isAuthenticatedAsImpersonate } = useAccountInfo();

  const { data: { availableProducts } = {}, isLoading: isPlansDataLoading } =
    useGetSubscriptionPlans({
      rqParams: {
        enabled: isAuthenticated || isAuthenticatedAsImpersonate,
        //Invalidate Plans info after 1 hour
        staleTime: 1000 * 60 * 60,
        gcTime: Infinity,
        retry: 2,
      },
    });

  return { availableProducts, isPlansDataLoading };
};

export default usePlansInfo;
