import { useState } from 'react';

import * as routes from '@/routes/routes';

import { ViewType } from './types';

const AvailableViews: { [key in ViewType]: ViewType } = {
  list: 'list',
  calendar: 'calendar',
};

const useReviewScheduleContent = () => {
  /**
   * After account setup show the list view as default view
   */

  const isInsideAccountSetup = window.location.pathname.includes(
    routes.ACCOUNT_SETUP_PAYROLL_SETTINGS,
  );

  const defaultView = isInsideAccountSetup ? AvailableViews.calendar : AvailableViews.list;

  const [selectedView, setSelectedView] = useState<ViewType>(defaultView);

  const viewChangeHandler = (view: ViewType) => {
    setSelectedView(view);
  };

  return { viewChangeHandler, selectedView, AvailableViews };
};

export default useReviewScheduleContent;
