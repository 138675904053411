import { useEffect, useState } from 'react';

import { useBillingInfo } from '@/hooks';
import useDiscountInfo from '@/hooks/useDiscountInfo/useDiscountInfo';
import { AVAILABLE_SUBSCRIPTIONS, FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';
import { isArrayHasData } from '@/utils/helpers';

type useSubscriptionGlobalBannerProps = {
  remainingDays: number;
};

const useSubscriptionGlobalBanner = ({ remainingDays }: useSubscriptionGlobalBannerProps) => {
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [isUpgradeWithPaymentModalOpen, setIsUpgradeWithPaymentModalOpen] = useState(false);

  const { billingInfo: subscriptionInformation } = useBillingInfo();
  const { verifyDiscount, discount } = useDiscountInfo();

  const activeSubscriptionAgreement = subscriptionInformation?.activeSubscriptionAgreement;
  const paymentMethods = subscriptionInformation?.paymentMethods;
  const subscriptionAgreements = subscriptionInformation?.subscriptionAgreements;
  const lastSubscriptionAgreement =
    subscriptionInformation?.subscriptionAgreements[
      subscriptionInformation?.subscriptionAgreements.length - 1
    ];

  const filteredSubscriptions = subscriptionAgreements?.filter(
    item => item.plan !== FREE_3_MONTH_TRIAL_SUBSCRIPTION,
  );
  const isPaidPlanScheduledToBeCancelled = isArrayHasData(filteredSubscriptions)
    ? filteredSubscriptions?.every(item => item.scheduledToBeCancelled)
    : false;

  const subscribePlanClickHandler = () => {
    const shouldRenderPayment =
      !activeSubscriptionAgreement ||
      activeSubscriptionAgreement?.status === 'canceled' ||
      !paymentMethods?.some(method => method.status === 'default') ||
      isPaidPlanScheduledToBeCancelled ||
      activeSubscriptionAgreement?.scheduledToBeCancelled;

    if (shouldRenderPayment) {
      setIsUpgradeWithPaymentModalOpen(true);
    } else {
      setIsUpgradePlanModalOpen(true);
    }
  };

  const closeSubscriptionModalHandler = () => {
    setIsUpgradeWithPaymentModalOpen(false);
    setIsUpgradePlanModalOpen(false);
  };

  const isOldPlan = Object.values(AVAILABLE_SUBSCRIPTIONS).includes(
    activeSubscriptionAgreement?.plan,
  );

  useEffect(() => {
    if (
      ((remainingDays <= 1 &&
        activeSubscriptionAgreement?.plan === FREE_3_MONTH_TRIAL_SUBSCRIPTION) ||
        activeSubscriptionAgreement?.plan !== FREE_3_MONTH_TRIAL_SUBSCRIPTION) &&
      !isOldPlan
    ) {
      verifyDiscount({ code: 'FULLSERVICE50-6' });
    }
  }, [remainingDays, activeSubscriptionAgreement, verifyDiscount]);

  return {
    isUpgradePlanModalOpen,
    subscribePlanClickHandler,
    closeSubscriptionModalHandler,
    isUpgradeWithPaymentModalOpen,
    lastSubscriptionAgreement,
    discount,
  };
};

export default useSubscriptionGlobalBanner;
