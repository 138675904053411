import { IconDotsVertical } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { Menu } from '@/components';
import { EMPLOYEE_PAYROLL_TYPES } from '@/utils/constants';

import usePayrollHistoryLink, { PayrollHistoryLinkProps } from '../../hooks/usePayrollHistoryLink';

const PayrollHistoryCardLink = ({
  payrollId,
  payrollType,
  status,
  isAllOffCycleDraftPayroll,
  deletePayrollHistory,
}: PayrollHistoryLinkProps) => {
  const { payrollLink, handleMenuActions } = usePayrollHistoryLink({
    payrollId,
    payrollType,
    status,
    deletePayrollHistory,
  });

  return isAllOffCycleDraftPayroll ? (
    <div className="gap-medium flex items-center justify-end">
      <Link
        className="px-medium text-x-small py-x-small rounded-x-small h-fit  text-nowrap border-2"
        to={payrollLink?.redirectTo}
      >
        View draft
      </Link>
      <div className="flex w-[10px] justify-center">
        {payrollType?.toLowerCase() !== EMPLOYEE_PAYROLL_TYPES.regular && (
          <Menu
            menuButtonVariant="text"
            menuItemsList={[{ key: 'delete', label: 'Delete' }]}
            menuButtonIcon={<IconDotsVertical className="text-gray-300 " />}
            onAction={(actionKey?: Key) => handleMenuActions(actionKey as string)}
            menuWrapperClasses="min-w-fit p-0 text-semantic-danger-red-hover"
          />
        )}
      </div>
    </div>
  ) : (
    <div className="flex justify-end border-gray-100">
      <Link
        className="font-F37Bolton-Medium  w-fit  rounded-lg border-2 px-6 py-1.5 text-center text-sm leading-6"
        to={payrollLink.redirectTo}
      >
        View
      </Link>
    </div>
  );
};
export default PayrollHistoryCardLink;
