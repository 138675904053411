import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';
import PlanRadioGroup from '@/components/PlanRadioGroup/PlanRadioGroup';

import { SubscriptionPlanType } from '../types';

type SubscriptionPlanGroupProps = {
  plans: SubscriptionPlanType[];
  selectedPlan: string | null;
  recommendedPlan: string | null;
  onSelectSubscriptionPlan: (plan: string) => void;
  confirmPlanHandler: () => void;
  onCancel: () => void;
  choosePlanTitle: string;
  subTitle?: string | ReactNode;
  subTitleStyle?: string;
  discount?: number | null;
  shouldShowFreeTrialBadge?: boolean;
};

const SubscriptionPlanGroup = ({
  plans,
  selectedPlan,
  onSelectSubscriptionPlan,
  recommendedPlan,
  confirmPlanHandler,
  choosePlanTitle = 'Your free trial is over!',
  subTitle,
  onCancel,
  subTitleStyle,
  discount,
  shouldShowFreeTrialBadge,
}: SubscriptionPlanGroupProps) => {
  const form = useForm({
    defaultValues: {
      selectedPlan: 'Full-Service Payroll',
    },
  });

  const selectedPlanForm = form.watch('selectedPlan');

  const options = plans.map(plan => ({
    label: plan.name,
    value: plan.name,
    description: plan.description,
    price: plan.availablePrices[0].amount || 0,
    paidWorkerPrice: plan.availablePrices[0].amountPerWorker || 0,
  }));

  useEffect(() => {
    onSelectSubscriptionPlan(selectedPlanForm);
  }, [selectedPlanForm, onSelectSubscriptionPlan]);

  return (
    <div className="flex h-full max-w-[320px] flex-col justify-center gap-5">
      <div>
        <div className="flex w-full items-center justify-between">
          <div className="gap-x-small flex w-full flex-wrap items-center justify-between">
            <h2 className="font-F37Bolton-Bold text-x-large text-content-heading">
              {choosePlanTitle}
            </h2>
          </div>
        </div>
        {subTitle && (
          <p
            className={twMerge(
              'text-content-body-medium text-small font-F37Bolton-Regular',
              subTitleStyle,
            )}
          >
            {/* {subTitle} */}
            Don’t worry—you won’t be charged until you run your first payroll.
          </p>
        )}
      </div>
      {/* TODO:// Need to discuss */}
      {/* {discount && <DiscountBanner />} */}
      <div>
        <Controller
          name="selectedPlan"
          control={form.control}
          render={({ field }) => (
            <PlanRadioGroup
              selectedValue={field.value}
              label=""
              optionWrapperClasses="w-full"
              options={options}
              showAsterisk
              {...field}
              name={field.name}
              onChange={value => {
                field.onChange(value);
                if (!field.isDisabled) {
                  onSelectSubscriptionPlan(value);
                }
              }}
              discount={discount}
            />
          )}
        />
      </div>

      <div className="flex gap-3 lg:hidden">
        {/* <Button variant="tertiary" parentClasses="w-full py-2.5" height="h-auto" onPress={onCancel}>
          Cancel
        </Button> */}
        <Button
          variant="primary"
          parentClasses="w-full py-2.5"
          height="h-auto"
          onPress={() => confirmPlanHandler()}
        >
          Add a payment method
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanGroup;
