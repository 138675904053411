import { IconSparkles } from '@tabler/icons-react';
import { tv } from 'tailwind-variants';

import { Button } from '@/components/ui/designSystem/Button/Button';

export const bannerStyles = tv({
  base: 'border-primary-emerald-light-border bg-primary-emerald-light-bg px-x-small hidden items-center gap-2 rounded-full border py-1.5 md:flex',
});

export const badgeStyles = tv({
  base: 'border-primary-emerald-light-border px-x-small text-x-small text-primary-emerald inline-flex h-[22px] items-center gap-1 rounded-full border bg-white',
});

export const textStyles = tv({
  base: 'text-small text-content-heading font-F37Bolton-Medium',
});

interface AnnouncementBannerProps {
  onAnnouncementModalOpen: () => void;
}

const AnnouncementBanner = ({ onAnnouncementModalOpen }: AnnouncementBannerProps) => {
  return (
    <div className={bannerStyles()}>
      <span className={badgeStyles()}>
        New <IconSparkles className="size-medium" />
      </span>
      <p className={textStyles()}>Direct deposit and tax payments 🎉</p>
      <Button
        onPress={onAnnouncementModalOpen}
        variant="link"
        className="text-small font-F37Bolton-Medium"
      >
        Upgrade
      </Button>
    </div>
  );
};

export default AnnouncementBanner;
