import DialogComponent from '@/components/Dialog/DialogComponent';
import { ConfirmationModal } from '@/modules/payrollSchedule/components';

import { AllMissingTaxes } from './components';
import { ConfirmEmployerTaxProps } from './types';
import useConfirmEmployerTax from './useConfirmEmployerTax';

const ConfirmEmployerTax = ({
  isOpen,
  toggleModal,
  refetch,
  confirmTaxForState,
}: ConfirmEmployerTaxProps) => {
  const {
    onSubmitTaxData,
    handleIsDirty,
    isDirtyModalOpen,
    handleCloseDirtyModal,
    handleConfirmDirtyModal,
    handleDrawerClose,
    handleDirtyModelOpen,
    isFormDirty,
    closeModalHandler,
  } = useConfirmEmployerTax({
    toggleModal,
    refetch,
  });

  return (
    <>
      {isDirtyModalOpen && (
        <ConfirmationModal
          isOpen={isDirtyModalOpen}
          handleConfirm={handleConfirmDirtyModal}
          handleClose={handleCloseDirtyModal}
        />
      )}
      <DialogComponent
        open={isOpen}
        onCancel={handleDrawerClose}
        width="w-screen sm:w-[610px]"
        height="h-screen sm:max-h-[90vh] sm:h-auto"
        modalClasses="sm:rounded-xl pb-5"
      >
        <AllMissingTaxes
          onSubmitTaxData={onSubmitTaxData}
          closeModalHandler={closeModalHandler}
          shouldShowAllFields={false}
          shouldShowAllTaxItems={false}
          confirmTaxForState={confirmTaxForState}
          handleIsDirty={handleIsDirty}
          handleDirtyModelOpen={handleDirtyModelOpen}
          isFormDirty={isFormDirty}
        />
      </DialogComponent>
    </>
  );
};

export default ConfirmEmployerTax;
