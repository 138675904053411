import React, { FC } from 'react';

import { ReactComponent as UncheckedIcon } from '@/assets/uncheckedTodo.svg';
import { ReactComponent as CheckedIcon } from '@/assets/checkedTodo.svg';
import { ReactComponent as ArrowIcon } from '@/assets/backUpdated.svg';

export interface DashboardTodoItemProps {
  isDone: boolean;
  label: string;
  companyId: number;
  action: string;
  description: React.ReactNode;
  onPressButton: () => void;
  buttonText: string;
  status?: boolean;
  onToggleTaxModal: () => void;
  isScheduleDone?: boolean;
}

export const DashboardTodoItem: FC<DashboardTodoItemProps> = ({
  status,
  isScheduleDone,
  action,
  label,
  description,
  onPressButton,
}) => {
  const handleButtonPressClick = () => {
    if (!status) {
      return onPressButton();
    }
  };
  return (
    <div
      className={` mt-[20px] flex ${
        !status && 'cursor-pointer'
      } flex-col rounded-2xl border border-gray-300 
   p-[20px] shadow-[3px_3px_0px_#C4C8CB] ${
     isScheduleDone
       ? action === 'add_employee'
         ? 'bg-white'
         : status
         ? ''
         : 'bg-white'
       : 'bg-white'
   }`}
      onClick={handleButtonPressClick}
    >
      <div
        className={`flex items-center ${
          !isScheduleDone && action === 'add_employee' && 'opacity-50'
        }`}
      >
        <div>
          {status ? (
            <CheckedIcon className="min-h-[20px] min-w-[20px]" />
          ) : (
            <UncheckedIcon className="min-h-[20px] min-w-[20px]" />
          )}
        </div>
        <span
          className={`ml-[10px] block ${
            status ? 'text-gray-400 line-through' : 'text-[#0075FF]'
          } font-F37Bolton-Medium`}
        >
          {label}
        </span>
        {!status && <ArrowIcon className="ml-auto rotate-180" />}
      </div>
      <div className={`${!isScheduleDone && action === 'add_employee' && 'opacity-50'}`}>
        {!status && description && <p className="mt-[12px] text-gray-500">{description}</p>}
      </div>
    </div>
  );
};

export default DashboardTodoItem;
