import {
  IconCalendarDot,
  IconCheck,
  IconChevronsRight,
  IconCoin,
  IconGift,
  IconInfoCircle,
  IconSquarePercentage,
} from '@tabler/icons-react';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from 'react-tooltip';

import { StatusBadge } from '@/components';
import { useAccountInfo } from '@/hooks';
import { EMPLOYEE_PAYROLL_TYPES, payrollType } from '@/utils/constants';
import { formatApprovalDeadline, payFrequencyByValue } from '@/utils/helpers';
import { statusSeverityMap } from '@/utils/statusSeverity';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { CheckHQHistoryStatusType, PayrollHistory } from '../../types';
import PayrollHistoryTableActionLink from '../PayrollHistoryTableListing/PayrollHistoryTableActionLink';

export const CheckHQPayrollFieldCell = ({
  label,
  value,
  className,
  children,
}: {
  label: string;
  value: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}) => (
  <div className={twMerge(`gap-2x-small flex flex-col ${className ? className : ''}`)}>
    <div className="text-content-body-medium font-F37Bolton-Regular text-x-small">{label}</div>
    <div className="text-content-heading font-F37Bolton-Regular text-small flex items-center">
      {value}
      {children}
    </div>
  </div>
);

type CheckHQPayrollHistoriesProps = {
  payrollHistory: PayrollHistory[];
  historyStatus: CheckHQHistoryStatusType;
  deletePayrollHistory: any;
};

const CheckHQPayrollHistories = ({
  payrollHistory,
  historyStatus,
  deletePayrollHistory,
}: CheckHQPayrollHistoriesProps) => {
  const { isCheckHqUser } = useAccountInfo();

  const isAllOffCycleDraftPayroll = payrollHistory.every(({ status }) => status === 0);

  return (
    <div>
      {payrollHistory.map(
        (
          {
            id,
            type,
            status,
            payPeriodStartDate,
            payPeriodEndDate,
            payDate,
            approvalDeadline,
            totalPayroll,
            skipReason,
            frequency,
            payFrequency,
            approvedAt,
            subType,
            payments,
          },
          index,
        ) => {
          const statusInfo = historyStatus[status];

          const startDate = moment(payPeriodStartDate);
          const endDate = moment(payPeriodEndDate);

          const payPeriod =
            payPeriodStartDate && payPeriodEndDate
              ? `${startDate.format(`MMM DD`)} - ${endDate.format('MMM DD, YYYY')}`
              : '--';

          const formattedPayDate =
            payDate && moment(payDate).isValid() ? moment(payDate).format('MMM DD, YYYY') : '-';

          const formattedApproveDate =
            approvedAt && moment(approvedAt).isValid()
              ? `${moment(approvedAt).format('MMM DD, YY')}`
              : '--';

          const formattedApprovedDeadline = formatApprovalDeadline(
            approvalDeadline,
            'MMM DD',
            true,
          );

          const statusLabel =
            isCheckHqUser && statusInfo?.label === 'Approved' ? 'Paid' : statusInfo?.label;

          const isPayDatePassedApprovedDeadline = moment(moment()).isAfter(payDate);

          const cashTipsValue = Array.isArray(payments)
            ? payments.reduce((sum, payment) => sum + (payment.cashTips || 0), 0)
            : 0;

          const totalPayrollAmount = Number(totalPayroll) - Number(cashTipsValue) || 0;

          return (
            <div
              key={index}
              className="bg-surface-white rounded-x-small my-medium px-medium py-x-small gap-2x-large flex items-center justify-between border"
            >
              {isPayDatePassedApprovedDeadline && isAllOffCycleDraftPayroll && (
                <Tooltip
                  content="Approval deadline has crossed for the pay date you added. Change the pay date when you run this payroll"
                  id="payDate-delayed-tooltip"
                  place="top"
                  className="!bg-surface-dark !text-small p-x-small z-[1] !w-[300px] !rounded-lg border border-gray-100 !leading-5"
                />
              )}
              <div className="gap-x-small flex items-center text-center ">
                <div
                  className={twMerge(
                    'rounded-2x-small size-2x-large flex items-center justify-center text-center',
                    type === EMPLOYEE_PAYROLL_TYPES.regular
                      ? 'bg-primary-emerald-light-bg'
                      : 'bg-secondary-navy-light-bg',
                  )}
                >
                  {type === EMPLOYEE_PAYROLL_TYPES.off_cycle ? (
                    <IconCalendarDot className="size-large" />
                  ) : type === EMPLOYEE_PAYROLL_TYPES.bonus ? (
                    <IconGift className="size-large" />
                  ) : type === EMPLOYEE_PAYROLL_TYPES.commission ? (
                    <IconSquarePercentage className="size-large" />
                  ) : (
                    <IconCoin className="text-icon-primary size-large" />
                  )}
                </div>
                <div className="text-content-heading font-F37Bolton-Medium text-small w-[140px]  break-all text-start">
                  {(!type || type == EMPLOYEE_PAYROLL_TYPES.regular) &&
                    `${payFrequencyByValue(frequency || payFrequency)} Payroll`}
                  {type !== EMPLOYEE_PAYROLL_TYPES.regular && payrollType[subType || type]}
                </div>
              </div>

              {/* Grid Layout for Responsive Columns */}
              <div className="gap-x-large gap-y-small p-small bg-surface-light-gray rounded-x-small flex w-full">
                <CheckHQPayrollFieldCell
                  label="Pay period"
                  value={payPeriod || '--'}
                  className="w-[160px]"
                />
                <CheckHQPayrollFieldCell
                  label={
                    isAllOffCycleDraftPayroll
                      ? 'Approval deadline'
                      : status === 3
                      ? 'Skipped on'
                      : 'Approved on'
                  }
                  className="w-[120px]"
                  value={
                    isAllOffCycleDraftPayroll
                      ? formattedApprovedDeadline || '--'
                      : formattedApproveDate || '--'
                  }
                />

                <CheckHQPayrollFieldCell
                  label="Pay date"
                  value={status === 4 || status === 3 ? '--' : formattedPayDate || '--'}
                  className="flex-1"
                  children={
                    isPayDatePassedApprovedDeadline &&
                    isAllOffCycleDraftPayroll && (
                      <div data-tooltip-id="payDate-delayed-tooltip" className="ml-[3px]">
                        <IconInfoCircle className="text-semantic-danger-red-hover size-medium cursor-pointer" />
                      </div>
                    )
                  }
                />
                <CheckHQPayrollFieldCell
                  label="Status"
                  className="flex-1"
                  value={
                    <StatusBadge
                      startIcon={
                        status === 1 ? (
                          <IconCheck className="size-medium" />
                        ) : (
                          status === 3 && <IconChevronsRight className="size-medium" />
                        )
                      }
                      text={statusLabel.toUpperCase()}
                      severity={statusSeverityMap[statusInfo?.variant]}
                      tooltipContent={
                        status === 4
                          ? `You've successfully approved this payroll. The withdrawal amount will be debited on ${formattedApproveDate} and employees are set to be paid on ${formattedPayDate}`
                          : status === 5
                          ? `This payroll is currently being processed, and employees are set to be paid on ${formattedPayDate}`
                          : status === 6
                          ? 'We were not able to process this payroll due to an issue with your company bank account. Payroll and taxes were not paid. Please contact support to resolve this issue as soon as possible.'
                          : status === 7
                          ? 'One or more employees were not paid due to an issue with their connected bank accounts. Please contact support as soon as possible to resolve this issue.'
                          : status === 3
                          ? `No employees were paid as this payroll was skipped. Reason for skipping: “${
                              skipReason || ''
                            }”`
                          : ''
                      }
                      tooltipId={status !== 1 ? `payroll-status-tooltip-${id}` : undefined}
                    />
                  }
                />
                <CheckHQPayrollFieldCell
                  label="Total payroll"
                  className="flex-1"
                  value={
                    <NumericFormat
                      value={totalPayrollAmount}
                      thousandSeparator=","
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={value => <span className="font-F37Bolton-Medium">${value}</span>}
                    />
                  }
                />
              </div>
              <PayrollHistoryTableActionLink
                payrollId={id}
                payrollType={type}
                status={status}
                isAllOffCycleDraftPayroll={isAllOffCycleDraftPayroll}
                deletePayrollHistory={deletePayrollHistory}
              />
            </div>
          );
        },
      )}
    </div>
  );
};

export default CheckHQPayrollHistories;
