import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  getDeductions: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'get-deductions'],
    url: `/company/${companyId}/deduction`,
  }),
};

export const mutationEndpoints = {
  addDeduction: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'add-deduction'],
    url: `/company/${companyId}/deduction`,
    invalidateKeys: [queryEndpoints.getDeductions(companyId).queryKey],
  }),
  allBenefit: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'benefit', employeeId],
    url: `company/${companyId}/employee/${employeeId}/benefit`,
    invalidateKeys: [queryEndpoints.getDeductions(companyId).queryKey],
  }),
  allGarnishment: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'garnishment', employeeId],
    url: `company/${companyId}/employee/${employeeId}/garnishment`,
    invalidateKeys: [queryEndpoints.getDeductions(companyId).queryKey],
  }),
  allCustomPost: (companyId: number, employeeId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'custom-post-tax-deduction', employeeId],
    url: `company/${companyId}/employee/${employeeId}/custom-post-tax-deduction`,
    invalidateKeys: [queryEndpoints.getDeductions(companyId).queryKey],
  }),
  all: (companyId: number, deductionId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, deductionId],
    url: `/company/${companyId}/deduction/${deductionId}`,
    invalidateKeys: [queryEndpoints.getDeductions(companyId).queryKey],
  }),
};
