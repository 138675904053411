import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type CardProps = HTMLAttributes<HTMLDivElement>;

const Card: FC<CardProps> = ({ children, className, ...rest }) => {
  return (
    <div className={twMerge('rounded-xl', className)} {...rest}>
      {children}
    </div>
  );
};

export default Card;
