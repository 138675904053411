import { handleEmployerTaxData } from '@/utils/helpers';
import { useMemo } from 'react';
import { UseEmployerTaxInformationType } from './types';

const useEmployerTaxInformation = ({ taxInformation }: UseEmployerTaxInformationType) => {
  const isDataAvailable = useMemo(
    () =>
      !!taxInformation.filter(
        item =>
          !!handleEmployerTaxData({
            taxItems: item.taxItems,
            shouldShowAllFields: true,
          }).length,
      ).length,
    [taxInformation],
  );
  return { isDataAvailable };
};

export default useEmployerTaxInformation;
