import React from 'react';
import { Controller } from 'react-hook-form';

import InputTextArea from '../InputTextArea';
import { FormInputTextAreaProps } from './types';

const FormInputTextArea: React.FC<FormInputTextAreaProps> = ({
  name,
  isRequired,
  rules,
  disabled,
  shouldUnregister = true,
  maxCharacterCount,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      disabled={Boolean(disabled)}
      rules={rules}
      render={({ field: { value = '', ...restField }, fieldState: { error } }) => (
        <InputTextArea
          errorMessage={error?.message}
          isRequired={Boolean(isRequired || rules?.required)}
          value={value}
          disabled={disabled}
          {...(maxCharacterCount && { maxCharacterCount })}
          {...rest}
          {...restField}
        />
      )}
    />
  );
};

export default FormInputTextArea;
