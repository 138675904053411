import { twMerge } from 'tailwind-merge';

export interface RadioButtonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  inputClasses?: string;
}
const Radiobutton = ({ inputClasses, ...rest }: RadioButtonProps) => {
  return (
    <input
      className={twMerge(
        'h-[24px] w-[24px] border-gray-300 hover:cursor-pointer hover:border-gray-400 focus:ring-0 focus:ring-offset-0',
        inputClasses,
      )}
      type="radio"
      name="radiobutton"
      {...rest}
    />
  );
};

export default Radiobutton;
