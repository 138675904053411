import { GiftIcon } from '@heroicons/react/24/outline';
import { IconCalendarDot, IconSquarePercentage } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from '@/routes/routes';

const usePickOffCycleType = ({ isRunPayrollDisabled }: { isRunPayrollDisabled?: boolean }) => {
  const navigate = useNavigate();

  const offCyclePayrollTypes = useMemo(
    () => [
      {
        type: 'offCycle',
        title: 'Off-cycle Payroll',
        icon: IconCalendarDot,
        clickHandler: () => navigate(routes.RUN_OFF_CYCLE_PAYROLL_SELECT_TYPE),
        isEnabled: true,
        isDisabled: isRunPayrollDisabled,
      },
      {
        type: 'bonus',
        title: 'Bonus Payroll',
        icon: GiftIcon,
        clickHandler: () => navigate(routes.RUN_BONUS_PAYROLL_SELECT_BONUS_TYPE),
        isEnabled: true,
        isDisabled: isRunPayrollDisabled,
      },
      {
        type: 'commission',
        title: 'Commission Payroll',
        icon: IconSquarePercentage,
        clickHandler: () => navigate(routes.RUN_COMMISSION_PAYROLL_SELECT_TYPE),
        isEnabled: true,
        isDisabled: isRunPayrollDisabled,
      },
    ],
    [navigate, isRunPayrollDisabled],
  );

  const formattedOffCyclePayrollTypes = offCyclePayrollTypes.filter(({ isEnabled }) => isEnabled);

  return { formattedOffCyclePayrollTypes };
};

export default usePickOffCycleType;
