/* eslint-disable react/prop-types */

import { AppButtonProps, Button } from '@/components/ui/designSystem/Button/Button';
import { handleContactSupport } from '@/utils/helpers';

import ContactSupportPopover from '../ContactSupportPopover/ContactSupportPopover';

type ContactSupportButtonProps = {
  supportType?: 'bot' | 'help';
} & AppButtonProps;

const ContactSupportButton: React.FC<ContactSupportButtonProps> = ({
  supportType = 'help',
  ...rest
}) => {
  return supportType === 'bot' ? (
    <Button
      variant="link"
      size="x-small"
      className="inline text-gray-500 underline"
      onPress={handleContactSupport}
      {...rest}
    >
      Contact support
    </Button>
  ) : (
    <ContactSupportPopover
      accessibleComponent={
        <Button
          variant="link"
          size="x-small"
          className="inline-block text-sm text-black underline underline-offset-2"
          {...rest}
        >
          Contact support
        </Button>
      }
    />
  );
};

export default ContactSupportButton;
