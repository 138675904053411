import { Controller } from 'react-hook-form';

import { CheckHQWrapper, FormInputDecimalsField } from '@/components';
import RadioButtonVerticalGroup from '@/components/RadioButtonGroup/RadioButtonVerticalGroup';
import FormSelect from '@/components/ui/FormSelect';

import { CheckHQContributionCalculationType, ContributionCalculationType } from '../constants';
import useCompanyContribution from './useCompanyContribution';

const CompanyContribution = () => {
  const {
    isCalculatedAsFixedAmount,
    shouldShowContributionFields,
    form,
    hasCompanyContribution,
    period,
    isCheckHqUser,
  } = useCompanyContribution();

  return (
    <div>
      <h4 className="font-F37Bolton-Medium text-lg">Company contribution</h4>
      <p className="text-sm text-gray-400">
        This is the amount that company contributes towards this deduction
      </p>
      <div className="flex flex-col gap-6 pt-4">
        <Controller
          control={form.control}
          name="hasCompanyContribution"
          render={({ field: { onChange, value = 0 } }) => (
            <RadioButtonVerticalGroup
              labelClasses="focus-visible:ring-0"
              options={[
                { label: 'Yes', value: 1 },
                { label: 'No contribution', value: 0 },
              ]}
              onChange={onChange}
              defaultValue={value}
              parentClasses="!flex-row !m-0 gap-3"
            />
          )}
        />
        {hasCompanyContribution && period === 'pay_period' && (
          <div className="gap-x-large flex">
            <FormSelect
              isRequired
              shouldUnregister={false}
              width="w-full"
              title="Calculated as"
              name="companyCalculatedAs"
              options={
                isCheckHqUser ? CheckHQContributionCalculationType : ContributionCalculationType
              }
              parentClasses="max-w-[328px] w-full"
            />
            <div className="flex w-full gap-6">
              {shouldShowContributionFields && (
                <FormInputDecimalsField
                  isRequired
                  shouldUnregister={false}
                  title={
                    isCalculatedAsFixedAmount ? 'Amount per pay check' : 'Percentage per pay check'
                  }
                  name={`${
                    isCalculatedAsFixedAmount
                      ? 'companyAmountPerPayPeriod'
                      : 'companyPercentageGrossPay'
                  }`}
                  key={`${
                    isCalculatedAsFixedAmount
                      ? 'companyAmountPerPayPeriod'
                      : 'companyPercentageGrossPay'
                  }`}
                  InputAdornment={isCalculatedAsFixedAmount ? '$' : '%'}
                  decimalsLimit={2}
                  placeholder="0.00"
                  step={0.01}
                  decimalSeparator="."
                  groupSeparator=","
                  min={0}
                  inputClasses="border-gray-300"
                />
              )}
            </div>
          </div>
        )}
        {hasCompanyContribution && period === 'monthly' && (
          <FormInputDecimalsField
            isRequired
            shouldUnregister={false}
            title="Amount per month"
            name="companyAmountPerPayPeriod"
            key="companyAmountPerPayPeriod"
            InputAdornment="$"
            decimalsLimit={2}
            placeholder="0.00"
            step={0.01}
            decimalSeparator="."
            groupSeparator=","
            min={0}
            inputClasses="border-gray-300"
          />
        )}
      </div>
      <CheckHQWrapper hideItem>
        <div className="mt-x-large">
          {shouldShowContributionFields && (
            <FormInputDecimalsField
              title="Annual Limit"
              shouldUnregister={false}
              name="companyDeductionLimit"
              isOptional
              InputAdornment={'$'}
              decimalsLimit={2}
              placeholder="0.00"
              step={0.01}
              decimalSeparator="."
              groupSeparator=","
              inputClasses="border-gray-300"
              min={0}
            />
          )}
        </div>
      </CheckHQWrapper>
    </div>
  );
};

export default CompanyContribution;
