import { useEffect, useMemo, useRef, useState } from 'react';
import { useFilter } from 'react-aria';
import { ComboBoxProps } from 'react-aria-components';

export type AutoCompleteOptionsType = {
  id: string | number;
  name: string;
};

export interface AppAutoCompleteProps extends ComboBoxProps<any> {
  options: AutoCompleteOptionsType[];
  isRequired?: boolean;
  title?: string;
  errorMessage?: string;
  onChange?: (value: string) => void;
  value?: any
}

const useAutoComplete = ({ options }: { options: AutoCompleteOptionsType[] }) => {
  const inputRef = useRef(null);
  const [popoverWidth, setPopoverWidth] = useState('auto');
  let { startsWith } = useFilter({ sensitivity: 'base' });
  let [filterValue, setFilterValue] = useState('');

  const filteredItems = useMemo(() => {
    if (filterValue.length <= 2) return [];
    return options.filter(item => startsWith(item.name, filterValue));
  }, [options, filterValue]);

  useEffect(() => {
    if (inputRef.current) {
      setPopoverWidth(inputRef.current.offsetWidth);
    }
  }, [inputRef]);
  return { popoverWidth, filteredItems, setFilterValue, filterValue, inputRef };
};
export default useAutoComplete;
