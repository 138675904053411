import { Alert, LoaderComponent } from '@/components';
import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';

import TaxInformation from '../TaxInformation/TaxInformation';
import { EmployerTaxInformationProps } from './types';
import useEmployerTaxInformation from './useEmployerTaxInformation';

const EmployerTaxInformation = ({
  isLoadingTaxInformation,
  taxInformation,
  refetchAllTaxes,
}: EmployerTaxInformationProps) => {
  const { isDataAvailable } = useEmployerTaxInformation({ taxInformation });

  return isLoadingTaxInformation ? (
    <div className="flex flex-col items-center justify-center">
      <LoaderComponent />
    </div>
  ) : (
    <div className="flex flex-col items-start">
      <div>
        <p className="font-F37Bolton-Medium text-2xl">Employer taxes</p>
        <Alert
          variant="information"
          subtitle="We’ve automatically added your remaining Federal and State tax rates. If you believe these rates differ for you, feel free to edit them"
          parentClasses="mt-medium"
          isDeletable={false}
        />
      </div>

      {Array.isArray(taxInformation) && isDataAvailable ? (
        <div className="my-7 flex w-full flex-col gap-10">
          {taxInformation
            .filter(taxInfo => taxInfo.stateAbbreviation !== FEDERAL_TAX_STATE_ABBREVIATION)
            .map(stateTax => (
              <TaxInformation
                taxData={stateTax}
                key={stateTax.stateAbbreviation}
                refetchData={refetchAllTaxes}
              />
            ))}
          {taxInformation
            .filter(taxInfo => taxInfo.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION)
            .map(federalTax => (
              <TaxInformation
                taxData={federalTax}
                key={federalTax.stateAbbreviation}
                refetchData={refetchAllTaxes}
              />
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default EmployerTaxInformation;
