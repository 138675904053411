import { useAuth0 } from '@auth0/auth0-react';

import usePostVerifyDiscount from '@/api/services/apis/discount/usePostVerifyDiscount';
import { DiscountType } from '@/redux/dto/auth';

import useAccountInfo from '../useAccountInfo/useAccountInfo';

const useDiscountInfo = () => {
  const { isAuthenticated } = useAuth0();
  const { isAuthenticatedAsImpersonate } = useAccountInfo();

  const {
    data,
    mutate: verifyDiscount,
    isPending: isDiscountLoading,
  } = usePostVerifyDiscount({
    mutationParams: {
      enabled: isAuthenticated || isAuthenticatedAsImpersonate,
      onSuccess: (response: DiscountType) => {
        response?.data?.discountObject?.code &&
          sessionStorage.setItem('discountCode', response?.data?.discountObject?.code);
      },
    },
  });

  const discountObject = data?.data?.canBeUsed ? data?.data?.discountObject : null;

  const discount = data?.data?.canBeUsed ? data?.data?.discountObject?.discount : null;

  return { verifyDiscount, discount, discountObject, isDiscountLoading };
};

export default useDiscountInfo;
