import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import usePostCancelSubscription from '@/modules/myAccount/components/CancelSubscriptionModal/apis/usePostCancelSubscription';
import { Dispatch } from '@/redux/store';

interface FormInputs {
  paymentMethod: string;
}
export interface StripePayPalProps {
  classNames?: string;
  selectedPlan: string;
  paymentSuccessHandler?: (paymentData: { amount: number; planName: string }) => void;
  discount: string;
}
export interface UseStripeAndPayPalType {
  selectedPlan: string;
  paymentSuccessHandler?: (paymentData: { amount: number; planName: string }) => void;
  discount: string;
}

const useStripeAndPaypal = ({
  selectedPlan,
  paymentSuccessHandler,
  discount,
}: UseStripeAndPayPalType) => {
  const stripe = useStripe();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | null>(null);
  const [isGeneratingPaypalToken, setIsGeneratingPaypalToken] = useState(false);

  const { billingInfo: subscriptionInfo, refetchBillingInfo } = useBillingInfo();
  const { account,refetchAccountInfo } = useAccountInfo();
  const paymentMethods = subscriptionInfo?.paymentMethods;
  const stateUserEmail = account?.email;

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('e') || stateUserEmail;
  const coupon = urlParams.get('coupon') ?? discount;

  const { mutate: mutateCancelSubscription } = usePostCancelSubscription({});

  const { register, handleSubmit, watch } = useForm<FormInputs>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      paymentMethod: 'stripe',
    },
  });
  const [cardDetail, setCardDetail] = useState({
    cardName: '',
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  });

  const handleCardDetail = ({ event, name }: any) => {
    if (event.error) return;
    setCardDetail({ ...cardDetail, [name]: event.complete || event });
  };
  const handlePayPalClick = async () => {
    if (isGeneratingPaypalToken) return;
    setIsGeneratingPaypalToken(true);
    const response = await dispatch.auth.setupPayPallToken();
    setIsGeneratingPaypalToken(false);
    window.location.assign(response.approveUrl);
  };

  const isPaymentMethodStripe = watch('paymentMethod') === 'stripe';

  useEffect(() => {
    setCardDetail({
      cardName: '',
      cardNumber: false,
      expiryDate: false,
      cvv: false,
    });
  }, [isPaymentMethodStripe]);

  const savePickedPlanToStore = async () => {
    await dispatch.auth.savePickedPlan({ type: selectedPlan });
  };

  useEffect(() => {
    savePickedPlanToStore();
  }, [selectedPlan]);

  const createPurchaseStripe = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }
    const { error: paymentError, paymentMethod: paymentMethodResponse } =
      await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          name: cardDetail?.cardName,
          email,
        },
        card: cardElement,
      });

    if (paymentError) {
      setStripeErrorMessage(paymentError.message || null);
      return;
    }

    return paymentMethodResponse.id;
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: Event) => {
    try {
      setIsLoading(true);
      if (isPaymentMethodStripe) {
        if (
          subscriptionInfo?.activeSubscriptionAgreement &&
          !subscriptionInfo?.activeSubscriptionAgreement?.price?.amountPerWorker
        ) {
          mutateCancelSubscription({
            data: { reason: '5' },
            cancellationActionType: 'cancel_subscription',
          });
        }
        const paymentMethodId = await createPurchaseStripe();
        const paymentMethodResponse = await dispatch.auth.createPaymentMethodStripe({
          paymentMethodId,
          email,
        });
        if (paymentMethodResponse) {
          const existingPaymentMethod = Array.isArray(paymentMethods)
            ? paymentMethods.find(
                payMethod =>
                  payMethod.type === 'stripe' && payMethod.id !== paymentMethodResponse.id,
              )
            : null;
          if (existingPaymentMethod) {
            await dispatch.auth.deletePaymentMethod({ id: existingPaymentMethod.id });
          }
        }
        await dispatch.auth.createSubscriptionAgreement({
          product: selectedPlan,
          coupon: selectedPlan === 'Basic' ? null : coupon,
        });

        if (selectedPlan === 'Basic' || selectedPlan === 'basic')
          sessionStorage.removeItem('discountCode');

        const responseData = await dispatch.auth.getSubscriptionStatus();
        const subscriptionAgreements =
          Array.isArray(responseData?.subscriptionAgreements) &&
          !!responseData?.subscriptionAgreements.length
            ? responseData.subscriptionAgreements.reduce((acc, curr) =>
                curr.id > acc.id ? curr : acc,
              )
            : null;
        const formattedSubscriptionData = {
          amount: subscriptionAgreements?.price?.amount || 0,
          planName: subscriptionAgreements?.plan,
          nextProcessingDate: subscriptionAgreements?.nextProcessingDate,
        };
        if (paymentMethodResponse) {
          refetchBillingInfo().then(
            () => paymentSuccessHandler && paymentSuccessHandler(formattedSubscriptionData),
          );
        }
      } else {
        const { approveUrl } = await dispatch.auth.setupPayPallToken();
        window.location.assign(approveUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      refetchAccountInfo()
      setIsLoading(false);
    }
  };

  const { cardName, cardNumber, cvv, expiryDate } = cardDetail;

  const isDisableSubmitButton = !cardName || !cardNumber || !cvv || !expiryDate;

  return {
    handleCardDetail,
    handlePayPalClick,
    stripeErrorMessage,
    onSubmit,
    isLoading,
    isDisableSubmitButton,
    handleSubmit,
    isGeneratingPaypalToken,
  };
};
export default useStripeAndPaypal;
