import { FormInputTextField } from '@/components/ui';

const CustomPostTaxDeductionFields = () => {
  return (
    <div>
      <FormInputTextField
        shouldUnregister={false}
        title="Custom Post-tax deduction title"
        isRequired
        name="customPostTaxDeductionTitle"
      />
    </div>
  );
};
export default CustomPostTaxDeductionFields;
