import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postCall } from '@/api/axios';

import { POST_BENEFIT } from './employeeApiRoutes';
import { usePostBenefitType } from './types';

const usePostBenefit = ({ companyId, employeeId }: usePostBenefitType) => {
  const queryClient = useQueryClient();

  const updateBenefits = (data: any) => postCall(POST_BENEFIT({ companyId, employeeId }), data);

  return useMutation({
    mutationFn: updateBenefits,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBenefits'] });
    },
  });
};

export default usePostBenefit;
