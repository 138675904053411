import React from 'react';

const EinDescription = ({ businessTypeSoleProp }: { businessTypeSoleProp: boolean }) => {
  if (businessTypeSoleProp) {
    return (
      <span className="text-base text-gray-500">
        If you're not sure, don't worry. Click add to find out which one to choose
      </span>
    );
  } else {
    return (
      <span className="text-base text-gray-500">
        If you don't have one, don't worry. Simply visit the{' '}
        <a
          target="_blank"
          className="text-blue-500"
          href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
          rel="noreferrer"
        >
          IRS website
        </a>{' '}
        and apply for one online
      </span>
    );
  }
};

export default EinDescription;
