import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

import Button from '@/components/Button/Button';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { ConfirmationType } from '../types';

const DISCARD = 'discard';
const SAVE_DRAFT = 'save_draft';

const ConfirmationModal: FC<ConfirmationType> = ({
  isOpen,
  handleConfirm,
  handleClose,
  variant = DISCARD,
  children,
  confirmButtonText,
  cancelButtonText,
  description,
  setIsOpen,
  isConfirmLoading,
  isConfirmDisabled,
  customOnClose,
}) => {
  const modalTitle =
    children ||
    (variant === DISCARD
      ? 'Discard changes?'
      : variant === SAVE_DRAFT
      ? 'Save as draft?'
      : 'Save changes?');
  const modalDescription = description || "Your changes won't be saved if you leave this page";

  const cancelText =
    cancelButtonText ||
    (children ? 'Cancel' : variant === DISCARD ? 'Keep editing' : 'Exit anyway');
  const confirmText =
    confirmButtonText ||
    (variant === DISCARD ? 'Discard' : variant === SAVE_DRAFT ? 'Save as draft?' : 'Save and exit');

  return (
    <Dialog
      open={isOpen}
      onClose={() => (customOnClose ? customOnClose() : handleClose())}
      className="relative z-[100]"
    >
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm backdrop-brightness-75">
        <Dialog.Panel>
          <div className={twMerge('w-full max-w-[335px] md:max-w-[400px]')}>
            <div className="bg-surface-white rounded-medium p-x-large gap-x-large relative flex h-full flex-col items-center">
              <div className="gap-x-small flex flex-col">
                <div className="flex items-center justify-between">
                  <span className="font-F37Bolton-Medium text-x-large text-content-heading">
                    {modalTitle}
                  </span>
                  <Button
                    variant="text"
                    parentClasses="h-fit"
                    onPress={setIsOpen ? () => setIsOpen(false) : handleClose}
                  >
                    <XMarkIcon className="size-6" />
                  </Button>
                </div>
                <div>
                  <span className="text-medium text-content-body-medium">{modalDescription}</span>
                </div>
              </div>
              <div className={twMerge('gap-medium flex w-full flex-col justify-end sm:flex-row')}>
                <Button
                  variant="tertiary"
                  children={cancelText}
                  parentClasses="w-auto px-large py-x-small border-2 border-strong font-F37Bolton-Medium text-content-body-strong"
                  onPress={handleClose}
                />
                <Button
                  variant="primary"
                  children={confirmText}
                  onPress={handleConfirm}
                  parentClasses={twMerge(
                    'w-auto px-large py-small',
                    variant === DISCARD ? 'bg-red-600 hover:bg-red-700' : '',
                    'min-w-[150px]',
                  )}
                  isLoading={isConfirmLoading}
                  isDisabled={isConfirmLoading || isConfirmDisabled}
                />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
