import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Radiobutton } from '@/components/ui';
import { trackEvent } from '@/utils/track';

type Option = {
  label: string;
  value: string | boolean;
  disabled?: boolean;
  id: string;
  icon: ReactNode;
  title: string;
  description: string;
  labelStyle?: string;
  highlightingBorder?: string;
};

type RadioOptionProps = {
  option: Option;
  checked: boolean;
  onChange: (value: string | boolean) => void;
  globalDisabled?: boolean;
  cardClasses?: string;
};

const RadioOption: React.FC<RadioOptionProps> = ({
  option: { label, value, icon: IconComponent, description, labelStyle, highlightingBorder },
  checked,
  onChange,
  globalDisabled = false,
  cardClasses = '',
}) => {
  return (
    <label
      className={twMerge(
        `rounded-x-small p-medium relative flex w-full flex-row justify-between border-2 border-gray-100 hover:cursor-pointer ${
          globalDisabled ? 'opacity-50' : ''
        } ${
          checked && !highlightingBorder
            ? 'border-2 !border-emerald-500 bg-emerald-50'
            : 'bg-white '
        }`,
        highlightingBorder && checked ? highlightingBorder : '',
        cardClasses,
      )}
    >
      {/* Icon section */}
      <div className="gap-medium flex items-start">
        {IconComponent && (
          <div className="p-x-small rounded-lg bg-emerald-100 [&>svg]:text-emerald-500">
            <IconComponent />
          </div>
        )}
        {/* Text content */}
        <div>
          <h3 className={twMerge('text-medium font-semibold text-gray-900', labelStyle)}>
            {label}
          </h3>
          <p className="text-small text-gray-300">{description}</p>
        </div>
      </div>
      <Radiobutton
        inputClasses="text-emerald-500"
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
      />
    </label>
  );
};

type RadioButtonsGroupProps = {
  label?: string;
  options: Option[];
  globalDisabled?: boolean;
  description?: string;
  isRequired?: boolean;
  defaultValue?: string | null;
  onChange?: (value: string) => void;
  cardClasses?: string;
};

const RadioButtonsCardGroup: React.FC<RadioButtonsGroupProps> = ({
  label,
  options,
  globalDisabled,
  isRequired = false,
  onChange,
  defaultValue,
  cardClasses,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | boolean>(defaultValue ?? false);

  const handleChange = (value: string | boolean) => {
    if (onChange) {
      onChange(value);
    }
    setSelectedValue(value);
    trackEvent('inputChange', { value });
  };

  return (
    <div>
      <p className="font-F37Bolton-Medium text-medium">
        {label} {isRequired && <span className="text-red-500">*</span>}
      </p>
      <div className="gap-medium flex flex-col">
        {options.map(option => (
          <RadioOption
            key={option.id} // Use a unique key
            option={option}
            checked={selectedValue === option.value}
            onChange={handleChange}
            globalDisabled={globalDisabled}
            cardClasses={cardClasses}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioButtonsCardGroup;
