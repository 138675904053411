import { ReactNode } from 'react';
import { Dialog, DialogTrigger, OverlayArrow, Popover } from 'react-aria-components';

import { SupportBox } from '@/components/ui/SupportHelp/components';

import CustomCalendlyModal from './CustomCalendlyModal';
import { useCustomCalendlyModal } from './useCustomCalendlyModal';

const ContactSupportPopover = ({
  accessibleComponent,
  placement = 'bottom',
}: {
  accessibleComponent: ReactNode;
  placement?: any;
}) => {
  const { handleCalendlyModal, isCalendlyModalOpen, isCalendlyLoading } = useCustomCalendlyModal();

  return (
    <>
      <DialogTrigger>
        <>{accessibleComponent}</>
        <Popover placement={placement}>
          <OverlayArrow className="group">
            <svg
              viewBox="0 0 12 12"
              data-popper-arrow
              className="group-placement-top:rotate-0 group-placement-bottom:rotate-180 size-4 fill-white drop-shadow-lg"
            >
              <path d="M0 0 L6 6 L12 0" />
            </svg>
          </OverlayArrow>
          <Dialog className="w-[320px] rounded-lg bg-white shadow-[0px_4px_12px_0px_#14213014] outline-none">
            {({ close }) => <SupportBox onBookCall={handleCalendlyModal} closeHandler={close} />}
          </Dialog>
        </Popover>
      </DialogTrigger>
      {isCalendlyModalOpen && (
        <CustomCalendlyModal
          handleCalendlyModal={handleCalendlyModal}
          isCalendlyModalOpen={isCalendlyModalOpen}
          isCalendlyLoading={isCalendlyLoading}
        />
      )}
    </>
  );
};
export default ContactSupportPopover;
