import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetTeamMembers } from '@/api';
import { useGetSubscriptionPlans } from '@/api/services/apis/subscriptionPlans/useGetSubscriptionPlans';
import { useAccountInfo, useBillingInfo } from '@/hooks';
import useDiscountInfo from '@/hooks/useDiscountInfo/useDiscountInfo';
import { Dispatch, RootState } from '@/redux/store';
import { AVAILABLE_SUBSCRIPTIONS, FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';

import { usePutDowngradePlan, usePutUpgradePlan } from '../../apis';
import { SubscriptionPlanType } from '../../types';

type useManageSubscriptionPlanType = {
  upgradeSuccessHandler: () => void;
  downgradeSuccessHandler: () => void;
  onCancel: () => void;
};

const useManageSubscriptionPlan = ({
  upgradeSuccessHandler,
  downgradeSuccessHandler,
  onCancel,
}: useManageSubscriptionPlanType) => {
  const dispatch = useDispatch<Dispatch>();
  const numberOfContractors = useSelector(
    (state: RootState) => state.auth?.businessOnboardingInfo?.numberOfContractors,
  );
  const numberOfEmployees = useSelector(
    (state: RootState) => state.auth?.businessOnboardingInfo?.numberOfEmployees,
  );
  const numberOfTeamMembers = (numberOfContractors ?? 0) + (numberOfEmployees ?? 0);

  const { companyId } = useAccountInfo();
  const { verifyDiscount, isDiscountLoading, discount } = useDiscountInfo();

  const discountCode = sessionStorage.getItem('discountCode');

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [plans, setPlans] = useState<SubscriptionPlanType[]>([]);
  const [recommendedPlan, setRecommendedPlan] = useState<string | null>(null);

  const { billingInfo: subscriptionInformation, refetchBillingInfo } = useBillingInfo();

  const { activeSubscriptionAgreement, subscriptionAgreements } = useMemo(() => {
    const { activeSubscriptionAgreement, subscriptionAgreements } = subscriptionInformation || {};
    return {
      activeSubscriptionAgreement,
      subscriptionAgreements: subscriptionAgreements?.length
        ? [...subscriptionAgreements].sort((a, b) => a.id - b.id)
        : [],
    };
  }, [subscriptionInformation]);

  const maxSeatsSubscription = subscriptionAgreements?.filter(
    subscriptionAgreement =>
      subscriptionAgreement?.price?.product?.type === 'max-seats-based' &&
      (subscriptionAgreement?.status === 'trialing' || subscriptionAgreement?.status === 'active'),
  );

  const subscriptionDataForBilling = () => {
    if (activeSubscriptionAgreement?.price?.product?.type === 'max-seats-based') {
      return activeSubscriptionAgreement;
    } else if (maxSeatsSubscription.length) {
      const agreementWithoutCancellation = maxSeatsSubscription.find(
        subscriptionAgreement => !subscriptionAgreement.scheduledToBeCancelled,
      );
      return agreementWithoutCancellation ? agreementWithoutCancellation : maxSeatsSubscription[0];
    } else if (!activeSubscriptionAgreement) {
      return subscriptionAgreements[subscriptionAgreements?.length - 1] ?? null;
    } else {
      return activeSubscriptionAgreement;
    }
  };

  const correctSubscriptionAgreement = subscriptionDataForBilling();
  const activeSubscriptionPlan = correctSubscriptionAgreement?.plan;

  const { data, isLoading: isLoadingSubscriptionPlans } = useGetSubscriptionPlans({});

  const { data: membersList, isLoading: isLoadingTeamMembers } = useGetTeamMembers(companyId);

  const { mutate: upgradePlan, isPending: isUpgradingPlan } = usePutUpgradePlan({
    mutationParams: {
      onSuccess: async () => {
        refetchBillingInfo();
        upgradeSuccessHandler();
      },
    },
  });
  const { mutate: downgradePlan, isPending: isDowngradingPlan } = usePutDowngradePlan({
    mutationParams: {
      onSuccess: async () => {
        refetchBillingInfo();
        downgradeSuccessHandler();
      },
    },
  });

  const employeeCount = membersList?.filter(member => member.status === 'active')?.length;

  useEffect(() => {
    const availableProducts = Array.isArray(data?.availableProducts) ? data.availableProducts : [];
    const memberCount = employeeCount ? employeeCount : numberOfTeamMembers;

    setPlans(
      availableProducts
        .filter(plans => plans.name !== FREE_3_MONTH_TRIAL_SUBSCRIPTION)
        .map(plan => {
          const price =
            plan.availablePrices.find(pr => pr.pricingStrategy === 'monthly')?.amount ?? 0;
          return {
            ...plan,
            price,
            isActive: correctSubscriptionAgreement?.plan === plan.name,
            isDisabled:
              plan.metadata?.maxSeats < memberCount &&
              plan.name !== AVAILABLE_SUBSCRIPTIONS.advancedPlus,
          };
        })
        .sort((a, b) => a.price - b.price),
    );
  }, [data, activeSubscriptionPlan, employeeCount, numberOfTeamMembers]);

  useEffect(() => {
    const sortedPlans = [...plans].sort((a, b) => a.metadata?.maxSeats - b.metadata?.maxSeats);

    const memberCount = employeeCount ? employeeCount : numberOfTeamMembers;

    const suitablePlan = sortedPlans.find(plan => plan.metadata?.maxSeats >= memberCount);

    if (suitablePlan && !recommendedPlan) {
      setRecommendedPlan(suitablePlan.name);
    }
    if (suitablePlan && !selectedPlan) {
      setSelectedPlan(activeSubscriptionPlan);
    }
  }, [
    plans,
    selectedPlan,
    recommendedPlan,
    activeSubscriptionPlan,
    employeeCount,
    numberOfTeamMembers,
  ]);

  const handlePlanChange = (plan: string) => {
    setSelectedPlan(plan);
  };

  const confirmPlanHandler = async () => {
    const selectedProduct = plans.find(plans => plans.name === selectedPlan);
    if (selectedProduct && correctSubscriptionAgreement) {
      if (correctSubscriptionAgreement?.price?.amount > selectedProduct.price) {
        downgradePlan({
          subscriptionAgreementId: correctSubscriptionAgreement?.id,
          product: selectedProduct.name,
        });
      } else if (correctSubscriptionAgreement?.price?.amount < selectedProduct.price) {
        upgradePlan({
          subscriptionAgreementId: correctSubscriptionAgreement?.id,
          product: selectedProduct.name,
        });
      } else {
        onCancel();
      }
    } else {
      await dispatch.auth.createSubscriptionAgreement({
        product: selectedProduct.name,
      });
    }
  };

  useEffect(() => {
    if (discountCode) verifyDiscount({ code: discountCode });
  }, [discountCode, verifyDiscount]);

  return {
    isLoadingSubscriptionPlans:
      isLoadingSubscriptionPlans || isLoadingTeamMembers || isDiscountLoading,
    plans,
    selectedPlan,
    handlePlanChange,
    recommendedPlan,
    confirmPlanHandler,
    isPending: isUpgradingPlan || isDowngradingPlan,
    correctSubscriptionAgreement,
    discount,
  };
};

export default useManageSubscriptionPlan;
