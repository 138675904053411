export const statusSeverityMap: Record<
  string,
  'info' | 'success' | 'warning' | 'danger' | 'draft'
> = {
  //info
  inviteSent: 'info',
  primary: 'info',
  // success
  active: 'success',
  paid: 'success',
  completed: 'success',
  processing: 'success',
  // draft
  draft: 'draft',
  inactive: 'draft',
  terminated: 'draft',
  default: 'draft',
  skipped: 'draft',
  // warning
  pending: 'warning',
  inProgress: 'warning',
  // danger
  blocked: 'danger',
  failed: 'danger',
  rejected: 'danger',
  deActivated: 'danger',
  danger: 'danger',
  'partially paid': 'danger',
};

export const payrollStatusMap: Record<
  string,
  'Draft' | 'Paid' | 'Skipped' | 'Pending' | 'Processing' | 'Failed' | 'Partially paid'
> = {
  0: 'Draft',
  1: 'Paid',
  3: 'Skipped',
  4: 'Pending',
  5: 'Processing',
  6: 'Failed',
  7: 'Partially paid',
};
