import { Controller } from 'react-hook-form';

import { FormInputTextField } from '@/components/ui';
import InputDatePicker from '@/components/ui/designSystem/InputDatePicker/InputDatePicker';
import FormSelect from '@/components/ui/FormSelect';

import CheckHQWrapper from '../../CheckHQWrapper/CheckHQWrapper';

type BenefitProps = {
  form?: any;
  options: { name: string; value: string | number }[];
  isAssigned: boolean;
  isEdit: boolean;
  isCheckHqUser?: boolean;
};

const BenefitFields = ({ form, isAssigned, options, isEdit, isCheckHqUser }: BenefitProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      {!isAssigned && (
        <FormSelect
          shouldUnregister={false}
          isRequired
          width="w-full"
          title="Benefit Type"
          name="benefitType"
          options={options}
          // isDisabled={isCheckHqUser && isEdit ? true : false} // TODO: Need to disable for checkhq user if product decides to do so
        />
      )}

      <FormInputTextField
        shouldUnregister={false}
        title="Benefit title"
        subtitle="This title will appear on employees' pay stubs"
        isRequired
        name="benefitTitle"
      />
      <CheckHQWrapper>
        {/* Effective Start Date */}
        <Controller
          control={form.control}
          name="effectiveStart"
          render={({ field: { onChange, value } }) => {
            return (
              <InputDatePicker
                title="Effective Start Date"
                subtitle="Date your company started offering this benefit to your workers"
                value={{
                  startDate: value,
                  endDate: value,
                }}
                isRequired
                placeholder="mm/dd/yyyy"
                isSingle
                onChange={({ startDate }: any) => onChange(startDate)}
                errorMessage={form.formState.errors?.effectiveStart?.message}
                popoverDirection="down"
                inputName="effectiveStart"
              />
            );
          }}
        />

        <Controller
          control={form.control}
          name="effectiveEnd"
          render={({ field: { onChange, value } }) => (
            <InputDatePicker
              title="Effective End Date"
              className="flex flex-col whitespace-nowrap"
              value={{
                startDate: value,
                endDate: value,
              }}
              placeholder="mm/dd/yyyy"
              isSingle
              onChange={({ endDate }: any) => onChange(endDate)}
              errorMessage={form.formState.errors?.effectiveEnd?.message}
              isOptional
              popoverDirection="down"
            />
          )}
        />
      </CheckHQWrapper>
    </div>
  );
};
export default BenefitFields;
