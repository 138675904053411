import { useCallback, useEffect, useState } from 'react';
import SmartySDK from 'smartystreets-javascript-sdk';

export type AddressEntry = {
  streetLine: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
  description?: string;
};
export type AddressEntryDetails = {
  streetAddress: string;
  aptSteNumber: string;
  city: string;
  state: string;
  zipCode: string;
  entries: number;
  description?: string;
};

const {
  core: SmartyCore,
  usAutocompletePro: { Lookup },
} = SmartySDK;

const credentials = new SmartyCore.SharedCredentials(import.meta.env.APP_SMARTY_API_KEY);
const client = new SmartyCore.ClientBuilder(credentials)
  .withLicenses(['us-autocomplete-pro-cloud'])
  .buildUsAutocompleteProClient();

export const useSmartyAutocomplete = (input: string) => {
  const [suggestions, setSuggestions] = useState<AddressEntryDetails[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSuggestions = useCallback(async () => {
    if (!input.trim()) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const lookup = new Lookup(input);
      lookup.maxResults = 10;

      const response = await client.send(lookup);
      setSuggestions(
        response.result.map((item: AddressEntry) => ({
          streetAddress: item.streetLine,
          aptSteNumber: item.secondary,
          city: item.city,
          state: item.state,
          zipCode: item.zipcode,
          entries: item.entries,
          description: `${item.streetLine} ${item.secondary} ${item.city}, ${item.state} ${item.zipcode}`,
        })) || [],
      );
    } catch {
      setError('Failed to fetch suggestions');
    } finally {
      setLoading(false);
    }
  }, [input]);

  useEffect(() => {
    const debounce = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(debounce);
  }, [input, fetchSuggestions]);

  return { suggestions, loading, error };
};
