import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useSkipRunnablePayroll, useSubmitRunnablePayroll } from '@/api';
import { useAccountInfo } from '@/hooks';
import { RunnablePayroll, RunnablePayrolls } from '@/redux/dto/employee';
import * as routes from '@/routes/routes';
import { QUERY_PARAMS } from '@/utils/constants';
import { callToast, formatApprovalDeadline, getDueDaysText } from '@/utils/helpers';

import StatusBadge from '../StatusBadge';

// Define a common type
type RegularPayrollCardUpdateBase = {
  payroll: RunnablePayroll;
  isOverdue: boolean;
  isPast: boolean;
  payrolls: RunnablePayrolls;
  runnableEmployees: [];
  getHistory: () => void;
  getPayrollsList: () => void;
  getCompaniesCurrentUser: () => void;
  popoverDirection?: string;
  isDraftButton?: boolean;
  isRunPayrollDisabled?: boolean;
  isCheckHQAccountUnderReview?: boolean;
  isCompanyHasBlockingTasks?: boolean;
};

// Use the base type for both cases
export type RegularPayrollCardUpdateProps = RegularPayrollCardUpdateBase;

type UseRegularPayrollCardUpdateTypes = RegularPayrollCardUpdateBase;

const useRegularPayrollCardUpdate = ({
  payroll,
  isOverdue,
  isPast,
  payrolls,
  runnableEmployees,
  getHistory,
  getPayrollsList,
  getCompaniesCurrentUser,
  isDraftButton,
  isRunPayrollDisabled,
  isCheckHQAccountUnderReview,
  isCompanyHasBlockingTasks,
}: UseRegularPayrollCardUpdateTypes) => {
  const navigate = useNavigate();

  const [isOpenSkipModal, setisOpenSkipModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { companyId, isCheckHqUser, company } = useAccountInfo();

  const changeDateOfPayment = date => {
    setValue('dateOfPayment', date.startDate, { shouldValidate: true });
  };

  const overduePayrolls = payrolls.filter(payroll => payroll?.isOverdue);
  const pastPayrolls = payrolls.filter(payroll => payroll?.isPast);

  const startDate = DateTime.fromISO(payroll?.payPeriod?.startDate, { setZone: true });
  const endDate = DateTime.fromISO(payroll?.payPeriod?.endDate, { setZone: true });

  const formattedStartDate = startDate.toFormat('MMM d');
  const formattedEndDate = endDate.toFormat('MMM d, yyyy');

  const formattedPeriod = `${formattedStartDate} - ${formattedEndDate}`;

  const renderBadge = useMemo(() => {
    if (isOverdue && overduePayrolls.length) {
      return (
        <div className="rounded-2x-small flex h-[28px] items-center justify-center bg-orange-100 px-1 text-xs sm:px-3 sm:text-base">
          <span className="text-sm text-orange-500">
            Overdue payroll(s) ({overduePayrolls?.length})
          </span>
        </div>
      );
    }
    if (isPast && pastPayrolls?.length) {
      return (
        <div className="flex h-[28px] items-center justify-center rounded-full bg-[#FFF0EB] px-[12px]">
          <span className="text-sm text-[#FA6B32]">
            Back-dated payroll(s) ({pastPayrolls?.length})
          </span>
        </div>
      );
    }
    return (
      <div className="mr-2">
        <StatusBadge
          text={getDueDaysText(!isCheckHqUser ? payroll?.payDate : payroll?.approvalDeadline)}
          severity={'info'}
        />
      </div>
    );
  }, [isCheckHqUser]);

  const calculateEstimatedTotal = () => {
    const hourlyRate = runnableEmployees?.reduce((sum, employee) => {
      return sum + employee?.hourlyRate;
    }, 0);
    switch (payroll?.frequency) {
      case 1:
        return hourlyRate * 40;
      case 2:
        return hourlyRate * 80;
      case 3:
        return hourlyRate * 86.6666666667;
      case 4:
        return hourlyRate * 173.333333333;
      case 5:
        return hourlyRate * 520;
      case 6:
        return hourlyRate * 2080;
      default:
        return hourlyRate * 40;
    }
  };

  const isBlocking = data => {
    const disabledStatuses = ['blocking'];
    for (const key in data) {
      if (data[key].status && disabledStatuses.includes(data[key].status)) {
        return true;
      }
    }
    return false;
  };
  const statusIsBlocked = isCheckHqUser ? isBlocking(company?.checkHqOnboardingData) : false;

  const isCalculatePayrollButtonDisabled =
    isRunPayrollDisabled ||
    isDraftButton ||
    isLoading ||
    (!isCheckHqUser && !isPast && !!pastPayrolls.length) ||
    (!isCheckHqUser && !pastPayrolls.length && !isOverdue && !!overduePayrolls.length) ||
    statusIsBlocked;

  const validationSchema = Yup.object().shape({
    dateOfPayment: Yup.string()
      .required('Date of payment is required')
      .typeError('Date of payment is required'),
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      dateOfPayment: payroll.payDate,
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync: submitRunnablePayroll } = useSubmitRunnablePayroll({
    companyId,
  });

  const onSubmit = async data => {
    setIsLoading(true);

    try {
      const formData = {
        companyId,
        id: payroll?.id,
        type: 'regular',
        payDate: data.dateOfPayment,
        payPeriod: payroll?.payPeriod,
        frequency: payroll?.frequency,
      };
      const responseSubmit = await submitRunnablePayroll(formData);

      if (responseSubmit) {
        navigate(
          `${routes.RUN_PAYROLL_ADD_INFORMATION.replace(':payrollId', responseSubmit?.data.id)}${
            isDraftButton ? `?${QUERY_PARAMS.isDraftEmployeePayroll}=true` : ''
          }`,
        );
      }
    } catch (error) {
      console.error('Failed to submit payroll:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const { mutate: skipPayroll, isPending: isSkipPayrollLoading } = useSkipRunnablePayroll({
    companyId,
    mutationParams: {
      onSuccess: () => {
        getHistory && getHistory();
        getPayrollsList && getPayrollsList();
        getCompaniesCurrentUser && getCompaniesCurrentUser();
        setIsLoading(false);
        callToast('success', 'Payroll skipped successfully');
      },
      onError: error => {
        setIsLoading(false);
        callToast('error', error.response?.data?.detail || 'Error skipping payroll');
      },
    },
  });

  const handleSkipPayroll = (skippedData: { skipped_reason?: string }) => {
    setIsLoading(isSkipPayrollLoading);
    const formData = {
      id: payroll?.id,
      payDate: payroll?.payDate,
      payPeriod: payroll?.payPeriod,
      frequency: payroll?.frequency,
      skipReason: skippedData?.skipped_reason,
    };
    skipPayroll(formData);
  };

  useEffect(() => {
    setValue('dateOfPayment', payroll?.payDate, { shouldValidate: true });
  }, [payroll?.payDate, setValue]);

  const tooltipContent = isCheckHQAccountUnderReview
    ? 'You can run payroll once account set up is complete'
    : isCompanyHasBlockingTasks
    ? 'Complete the pending tasks to run payroll'
    : isDraftButton
    ? 'Make sure to add your employees’ pay history before running upcoming payroll'
    : statusIsBlocked
    ? 'Complete the pending tasks to run payroll'
    : pastPayrolls?.length
    ? 'Make sure to record any back-dated payroll first in order to have accurate calculations for upcoming payroll '
    : overduePayrolls.length
    ? 'Make sure to record any overdue payroll first in order to have accurate calculations for upcoming payroll '
    : '';

  const updatedButtonProps = isCalculatePayrollButtonDisabled
    ? { 'data-tooltip-id': `payroll-disabled-id-${payroll.id}` }
    : {};

  const deadlineDate = formatApprovalDeadline(payroll?.approvalDeadline, null, true) || '';

  const handleCloseSkipPayroll = () => {
    setisOpenSkipModal(false);
  };

  return {
    updatedButtonProps,
    tooltipContent,
    handleSkipPayroll,
    onSubmit,
    register,
    control,
    calculateEstimatedTotal,
    errors,
    handleSubmit,
    changeDateOfPayment,
    isOpenSkipModal,
    statusIsBlocked,
    setisOpenSkipModal,
    formattedPeriod,
    renderBadge,
    isLoading,
    pastPayrolls,
    isCalculatePayrollButtonDisabled,
    isCheckHqUser,
    deadlineDate,
    handleCloseSkipPayroll,
    isSkipPayrollLoading,
  };
};
export default useRegularPayrollCardUpdate;
