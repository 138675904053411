import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';

export const useAlert = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const alertStyles = {
    information: 'bg-blue-50',
    success: 'bg-emerald-50',
    warning: 'bg-yellow-50',
    danger: 'bg-red-50',
  };

  const alertIcon = {
    information: <InformationCircleIcon className="size-6 shrink-0 text-blue-500" />,
    success: <CheckCircleIcon className="size-6 shrink-0 text-emerald-500" />,
    warning: <ExclamationCircleIcon className="size-6 shrink-0 text-yellow-500" />,
    danger: <XCircleIcon className="size-6 shrink-0 text-red-500" />,
  };

  return { isVisible, setIsVisible, alertStyles, alertIcon };
};
