import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useRef } from 'react';
import { Tab, TabList } from 'react-tabs';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { CustomTabTitlesProps } from '../../types';

const AccountSetupTeamMember = ({
  tabs,
  currentTabIndex,
  handleTabClick,
  tabTitlesComponentProps,
}: CustomTabTitlesProps) => {
  const tabListContainerRef = useRef(null);
  const shouldHideCompletedCheckIcon = !!tabTitlesComponentProps?.shouldHideCompletedCheckIcon;

  useEffect(() => {
    const centerCurrentTab = () => {
      const tabListContainer = tabListContainerRef.current as unknown as HTMLElement;
      if (!tabListContainer) return;
      const activeTabElement = tabListContainer.querySelector(
        `[role="tab"]:nth-child(${currentTabIndex + 1})`,
      ) as unknown as HTMLElement;

      if (!activeTabElement) return;

      activeTabElement.scrollIntoView({
        behavior: 'smooth',
      });
    };

    centerCurrentTab();
  }, [tabs, currentTabIndex]);

  return (
    <div>
      <div>
        <div ref={tabListContainerRef} className="scrollbar-hide overflow-auto border-b-2">
          <TabList className="flex px-5 sm:gap-2 sm:px-6 xl:gap-4">
            {tabs.map((tab, tabIndex) => {
              const isStepCompleted = tab.isCompleted ?? tabIndex < currentTabIndex;
              const tooltipId = tab?.isToolTipVisible ? { 'data-tooltip-id': tab.tooltipId } : {};
              const isError = tab.isError;

              return (
                <Fragment key={tab.id}>
                  {tab.isToolTipVisible && (
                    <Tooltip
                      place="top"
                      className="max-w-60 !rounded-lg text-sm"
                      id={tab.tooltipId}
                      content={tab.tooltipContent}
                    />
                  )}
                  <Tab
                    onClick={() => {
                      handleTabClick(tab, tabIndex);
                    }}
                    className={twMerge(
                      `flex cursor-pointer  justify-start gap-1 whitespace-nowrap p-2 focus:outline-none `,
                      currentTabIndex === tabIndex
                        ? 'border-b-2 border-b-emerald-500 text-emerald-500'
                        : isStepCompleted
                        ? 'text-gray-400'
                        : 'text-gray-200',
                      !!tab.hidden && 'hidden',
                    )}
                    id={tab.id}
                  >
                    <p {...tooltipId}>{tab.title}</p>
                    {isStepCompleted && !shouldHideCompletedCheckIcon && (
                      <CheckCircleIcon className="w-5 text-emerald-500" />
                    )}
                    {isError && !isStepCompleted && (
                      <ExclamationCircleIcon className="text-semantic-warning-yellow-hover w-5" />
                    )}
                  </Tab>
                </Fragment>
              );
            })}
          </TabList>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupTeamMember;
