import { FC, useEffect, useRef } from 'react';
import { Tabs as ReactTabs, TabPanel } from 'react-tabs';
import { twMerge } from 'tailwind-merge';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import LoaderComponent from '../LoaderComponent';
import TabTitles from './TabTitles';
import { TabsProps } from './types';

const Tabs: FC<TabsProps> = ({
  tabs,
  width,
  currentTab,
  setCurrentTab,
  isLoading,
  variant = 'progressBar',
  className,
  backwardNavigateOnTitleClick = true,
  forwardNavigateOnTitleClick = true,
  tabTitlesComponent,
  tabTitlesComponentProps,
  tabPanelClassName = '',
}) => {
  const tabListContainerRef = useRef(null);

  const visibleTabs = tabs.filter(item => !item.hidden);
  const currentTabIndex = tabs.findIndex(tab => tab.id === currentTab);

  const formattedTabIndex = currentTabIndex >= 0 ? currentTabIndex : 0;

  useEffect(() => {
    const centerCurrentTab = () => {
      const tabListContainer = tabListContainerRef.current as unknown as HTMLElement;
      if (!tabListContainer) return;
      const activeTabElement = tabListContainer.querySelector(
        `[role="tab"]:nth-child(${formattedTabIndex + 1})`,
      ) as unknown as HTMLElement;

      if (!activeTabElement) return;
      const activeTabWidth = activeTabElement.offsetWidth;
      let newScrollLeft = tabListContainer.scrollLeft;

      newScrollLeft =
        activeTabElement.offsetLeft -
        tabListContainer.offsetLeft -
        tabListContainer.offsetWidth / 2 +
        activeTabWidth / 2;

      tabListContainer.scroll({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    };

    if (variant === 'progressBar') centerCurrentTab();
  }, [tabs, formattedTabIndex, variant]);

  useEffect(() => {
    if (variant !== 'progressBar' || !visibleTabs.length) return;

    const tabListContainer = tabListContainerRef.current as unknown as HTMLElement;

    if (!tabListContainer) return;
    const tabListContainerWidth = tabListContainer.offsetWidth;

    const firstTabElement = tabListContainer.querySelector(
      `[role="tab"]:nth-child(1)`,
    ) as unknown as HTMLElement;

    // find last visible element with tab prefix
    const lastTabElement = tabListContainer.querySelector(
      `#tab${visibleTabs[visibleTabs.length - 1].id}`,
    );

    if (!firstTabElement || !lastTabElement) return;

    firstTabElement.style.marginLeft = `${
      tabListContainerWidth / 2 - firstTabElement.offsetWidth / 2
    }px`;

    const lastTabElementPreviousPadding =
      parseInt(getComputedStyle(lastTabElement, null)?.paddingRight) || 0;

    lastTabElement.style.paddingRight = `${
      tabListContainerWidth / 2 - (lastTabElement.offsetWidth - lastTabElementPreviousPadding) / 2
    }px`;

    return () => {
      if (lastTabElement) {
        lastTabElement.style.paddingRight = `${lastTabElementPreviousPadding}px`;
      }
    };
  }, [visibleTabs.length, variant]);

  useEffect(() => {
    if (formattedTabIndex >= 0) {
      trackEvent('tabChange', {
        currentTabName: visibleTabs[formattedTabIndex]?.title,
        currentPage: extractEventName(window.location.pathname),
      });
    }
  }, [formattedTabIndex, visibleTabs]);

  return (
    <ReactTabs
      className={twMerge('flex flex-col gap-4', width ? width : 'w-full', className)}
      selectedIndex={formattedTabIndex}
      onSelect={() => {
        return;
      }}
    >
      <TabTitles
        currentTab={currentTab}
        currentTabIndex={formattedTabIndex}
        setCurrentTab={setCurrentTab}
        tabs={tabs}
        ref={tabListContainerRef}
        variant={variant}
        backwardNavigateOnTitleClick={backwardNavigateOnTitleClick}
        forwardNavigateOnTitleClick={forwardNavigateOnTitleClick}
        tabTitlesComponent={tabTitlesComponent}
        tabTitlesComponentProps={tabTitlesComponentProps}
      />
      <div>
        {tabs.map(tab => {
          const Component = tab.component;
          return (
            <TabPanel key={tab.id} hidden={tab.hidden} className={tabPanelClassName}>
              {isLoading ? <LoaderComponent /> : <Component {...tab.props} />}
            </TabPanel>
          );
        })}
      </div>
    </ReactTabs>
  );
};

export default Tabs;
