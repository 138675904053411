import { useMemo } from 'react';

import { taxInformation, TaxItem } from '@/redux/dto/company';
import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';

import { useAccountInfo } from '@/hooks';
import useGetCompanyTaxInformation from '../../apis/useGetCompanyTaxInformation/useGetCompanyTaxInformation';

const useSetupFederalTax = () => {
  const { companyId } = useAccountInfo();

  const {
    data,
    isLoading: isLoadingTaxInformation,
    refetch: refetchTaxData,
  } = useGetCompanyTaxInformation({
    companyId,
    queryParams: {
      queryKey: [companyId, 'confirmEmployerTax', 'federalTax'],
    },
  });

  const federalTaxData: TaxItem[] = useMemo(() => {
    if (Array.isArray(data)) {
      const taxData = data.find(
        (stateTaxInfo: taxInformation) =>
          stateTaxInfo.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION,
      );
      return taxData ? taxData.taxItems : [];
    }
  }, [data]);

  return { isLoadingTaxInformation, federalTaxData, refetchTaxData };
};

export default useSetupFederalTax;
