import { FC } from 'react';

import { CheckHQWrapper } from '@/components';
import Button from '@/components/Button/Button';

import CheckHQPayrollHistories from './components/CheckHQPayrollHistories/CheckHQPayrollHistories';
import CheckHQPayrollHistoriesMobileView from './components/CheckHQPayrollHistoriesMobileView/CheckHQPayrollHistoriesMobileView';
import PayrollHistoryCardListing from './components/PayrollHistoryCardListing/PayrollHistoryCardListing';
import PayrollHistoryTableListing from './components/PayrollHistoryTableListing/PayrollHistoryTableListing';
import usePayrollHistory, { PayrollHistoryProps } from './usePayrollHistory';

const PayrollHistory: FC<PayrollHistoryProps> = ({ historyData, deletePayrollHistory }) => {
  const { loadMore, updatedData, historyStatus, shouldShowViewMoreButton, isCheckHqUser } =
    usePayrollHistory({
      historyData,
    });
  return (
    <div className="pt-medium">
      <CheckHQWrapper hideItem>
        <div
          className={`hidden border border-gray-100 md:block ${
            updatedData.length === historyData.length && 'rounded-b-lg'
          }`}
        >
          <PayrollHistoryTableListing
            payrollHistory={updatedData}
            historyStatus={historyStatus}
            isCheckHqUser={isCheckHqUser}
          />
        </div>
        <div className="flex flex-col gap-4 md:hidden">
          <PayrollHistoryCardListing
            payrollHistory={updatedData}
            historyStatus={historyStatus}
            isCheckHqUser={isCheckHqUser}
          />
        </div>
      </CheckHQWrapper>
      <CheckHQWrapper>
        <div
          className={`hidden md:block ${
            updatedData.length === historyData.length && 'rounded-b-lg'
          }`}
        >
          <CheckHQPayrollHistories
            payrollHistory={updatedData}
            historyStatus={historyStatus}
            deletePayrollHistory={deletePayrollHistory}
          />
        </div>
        <div className="flex flex-col gap-4 md:hidden">
          <CheckHQPayrollHistoriesMobileView
            payrollHistory={updatedData}
            historyStatus={historyStatus}
            deletePayrollHistory={deletePayrollHistory}
          />
        </div>
      </CheckHQWrapper>
      {shouldShowViewMoreButton && (
        <div className="flex justify-center rounded-b-lg md:border">
          <Button variant="text" parentClasses="text-emerald-500" onPress={loadMore}>
            View more
          </Button>
        </div>
      )}
    </div>
  );
};

export default PayrollHistory;
