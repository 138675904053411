import { useMutation, useQueryClient } from '@tanstack/react-query';

import { putCall } from '@/api/axios';
import { usePutBenefitType } from './types';
import { PUT_GARNISHMENT } from './employeeApiRoutes';

const usePutGarnishment = ({ companyId, employeeId }: usePutBenefitType) => {
  const queryClient = useQueryClient();

  const updateBenefits = (data: any) =>
    putCall(PUT_GARNISHMENT({ companyId, employeeId, id: data.id }), data);

  return useMutation({
    mutationFn: updateBenefits,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getGarnishments'] });
    },
  });
};

export default usePutGarnishment;
