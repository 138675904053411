import { Card } from '@/components';

const NoPayrollHistory = ({
  isProcessed = false,
  isDraft = false,
}: {
  isProcessed?: boolean;
  isDraft?: boolean;
}) => {
  if (isProcessed && isDraft) {
    return null;
  }

  return (
    <Card className="bg-gray  border-navy-100 mt-large border p-[32px] text-center">
      <div className="mx-auto flex flex-col justify-center md:max-w-[480px]">
        <h6 className="font-medium text-gray-900">
          {isProcessed && 'Your do not have any processed payroll'}
          {isDraft && 'Your do not have any draft payroll'}
        </h6>
        <p className="mt-1 text-sm text-gray-400">
          {isProcessed &&
            'This is where you will see all the processed payrolls. You’ll be able to view payroll details and share pay stubs'}
          {isDraft &&
            'This is where you will see all the draft payrolls. You’ll be able to edit and process the draft payrolls'}
        </p>
      </div>
    </Card>
  );
};

export default NoPayrollHistory;
