import React from 'react';

import { Invoice } from '@/redux/dto/auth';

import BillingHistoryTable from './components/BillingHistoryTable/BillingHistoryTable';
import BillingHistoryView from './components/BillingHistoryView/BillingHistoryView';

interface Props {
  invoices: Invoice[];
}

const BillingHistory: React.FC<Props> = ({ invoices }) => {
  return (
    <div className="mt-10 flex flex-col gap-4">
      <span className="font-F37Bolton-Medium text-xl text-gray-900">Invoicing</span>
      {invoices?.length > 0 ? (
        <>
          {invoices.map((bill: Invoice) => (
            <BillingHistoryView data={bill} />
          ))}
          <BillingHistoryTable data={invoices} />
        </>
      ) : (
        <div className="border-navy-100 flex flex-col items-center justify-center rounded-xl border px-8 py-[60px] text-center">
          <span className="font-F37Bolton-Medium">No invoices yet.</span>
          <span className="max-w-[400px] pt-2 text-sm text-gray-400">
            You won’t be charged until you process your 1st payroll. Your invoices and receipts will
            show here.
          </span>
        </div>
      )}
    </div>
  );
};

export default BillingHistory;
