import VerticalStepper from '@/components/ui/VerticalStepper/VerticalStepper';
import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';

import ContactSupportButton from '../ContactSupportButton';
import TaxTitle from './TaxTitle';
import { TaxInformationProps } from './types';
import useTaxInformation from './useTaxInformation';

const TaxInformation = ({ taxData, refetchData }: TaxInformationProps) => {
  const { tabs, currentTab, shouldShowSupportLink } = useTaxInformation({ taxData, refetchData });

  return (
    !!tabs.length && (
      <div className="w-full">
        <TaxTitle
          title={taxData.stateName}
          isStateTax={taxData.stateAbbreviation !== FEDERAL_TAX_STATE_ABBREVIATION}
        />
        <div className="mt-4 rounded-xl border border-gray-100">
          <VerticalStepper
            tabs={tabs}
            currentTab={currentTab}
            parentClasses="gap-0 divide-y"
            stepClasses="p-4"
          />
          {/**
           * Display Contact Support team message to Exempt form the FUTA (Federal Unemployment tax)
           */}
          {taxData.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION && (
            <p className="mb-4 ml-6 text-sm text-gray-400">
              If you believe you&apos;re exempt from FUTA, please{' '}
              {shouldShowSupportLink ? (
                <ContactSupportButton className="text-nowrap lowercase text-emerald-500 no-underline" />
              ) : (
                'contact support'
              )}{' '}
              team.
            </p>
          )}
        </div>
      </div>
    )
  );
};

export default TaxInformation;
