import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { getCall } from '@/api/axios';

import { queryEndpoints } from './endpoints';

export type UseGetCompanyLevelDeductionsType = { companyId: number; queryParams?: UseQueryOptions };

const useGetCompanyLevelDeductions = ({
  companyId,
  queryParams,
}: UseGetCompanyLevelDeductionsType): UseQueryResult<any, Error> => {
  const { queryKey, url } = queryEndpoints.getDeductions(companyId);

  return useQuery<any, Error>({
    queryKey,
    queryFn: () => getCall(url),
    select: data => data.data,
    ...queryParams,
  });
};

export default useGetCompanyLevelDeductions;
