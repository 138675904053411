import { CreditCardIcon } from '@heroicons/react/24/outline';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import Button from '@/components/Button/Button';
import { InputTextField } from '@/components/ui';

import useAddStripeCard from './useAddStripeCard';

type AddStripeCardProps = {
  onSuccess: () => void;
  onClose: () => void;
};

const AddStripeCard = ({ onClose, onSuccess }: AddStripeCardProps) => {
  const {
    handleCardDetail,
    createPurchaseStripe,
    isStripeCardDetailsValid,
    cardHolderName,
    handleCardHolderNameChange,
    isLoading,
  } = useAddStripeCard({ onSuccess });

  return (
    <div className="flex flex-col items-center p-6">
      <div className="rounded-full bg-emerald-50 p-3">
        <CreditCardIcon className="size-6 text-emerald-500" aria-label="Credit Card Icon" />
      </div>
      <div className="mt-3 flex flex-col gap-2">
        <h3 className="font-F37Bolton-Medium text-center text-2xl">Add your credit card</h3>
        <p className="text-center text-gray-400">Please enter your credit card details.</p>
      </div>

      <div className="mt-5 w-full space-y-4">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex w-full flex-col space-y-2 sm:max-w-[344px]">
            <p>Name on card</p>
            <InputTextField
              placeholder="Name"
              width="w-full"
              value={cardHolderName}
              onChange={e => handleCardHolderNameChange(e)}
              onInput={e => handleCardHolderNameChange(e)}
              inputClasses="!h-[39px] !rounded-md"
              errorMessage={!cardHolderName.length}
              showErrorMessage={false}
            />
          </div>
          <div className="flex w-full flex-col space-y-2 sm:max-w-[112px]">
            <p>Expiry</p>
            <CardExpiryElement
              onChange={e => handleCardDetail({ name: 'expiryDate', event: e })}
              onReady={e => handleCardDetail({ name: 'expiryDate', event: e })}
              options={{
                classes: {
                  base: 'w-full bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                },
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex w-full flex-col space-y-2 sm:max-w-[344px]">
            <p>Card number</p>
            <CardNumberElement
              onChange={e => handleCardDetail({ name: 'cardNumber', event: e })}
              onReady={e => handleCardDetail({ name: 'cardNumber', event: e })}
              options={{
                classes: {
                  empty: 'border-gray-300',
                  base: 'text-xl bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                  invalid: 'border-red border',
                },
              }}
            />
          </div>
          <div className="flex w-full flex-col space-y-2 sm:max-w-[112px]">
            <p>CVV</p>
            <CardCvcElement
              onChange={e => handleCardDetail({ name: 'cvv', event: e })}
              onReady={e => handleCardDetail({ name: 'cvv', event: e })}
              options={{
                classes: {
                  base: 'w-full bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full justify-between gap-3 pt-12">
        <Button variant="light" children="Go back" width="w-full py-3" onPress={onClose} />

        <Button
          variant="primary"
          type="submit"
          children="Add card"
          width="w-full py-3"
          isLoading={isLoading}
          isDisabled={!isStripeCardDetailsValid}
          // onPress={onSubmit}
          onPress={createPurchaseStripe}
        />
      </div>
    </div>
  );
};

export default AddStripeCard;
