const MoneyBag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_31309_109152)">
        <path
          id="Vector"
          d="M8.48718 21H15.5132C16.1433 21 16.7646 20.8511 17.3263 20.5654C17.888 20.2798 18.3742 19.8654 18.7454 19.3562C19.1166 18.847 19.3623 18.2573 19.4623 17.6351C19.5624 17.0129 19.514 16.3759 19.3212 15.776L17.6152 10.47C17.2913 9.4622 16.6558 8.58321 15.8004 7.95963C14.945 7.33604 13.9138 7.00003 12.8552 7H11.1452C10.0866 7.00003 9.05535 7.33604 8.19994 7.95963C7.34453 8.58321 6.7091 9.4622 6.38518 10.47L4.67918 15.776C4.48635 16.3759 4.43799 17.0129 4.53805 17.6351C4.6381 18.2573 4.88373 18.847 5.25493 19.3562C5.62613 19.8654 6.11239 20.2798 6.67408 20.5654C7.23578 20.8511 7.85702 21 8.48718 21Z"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15 3C14.3333 5.66667 13.3333 7 12 7C10.6667 7 9.66667 5.66667 9 3H15Z"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M14 11H11.5C11.1022 11 10.7206 11.158 10.4393 11.4393C10.158 11.7206 10 12.1022 10 12.5C10 12.8978 10.158 13.2794 10.4393 13.5607C10.7206 13.842 11.1022 14 11.5 14H12.5C12.8978 14 13.2794 14.158 13.5607 14.4393C13.842 14.7206 14 15.1022 14 15.5C14 15.8978 13.842 16.2794 13.5607 16.5607C13.2794 16.842 12.8978 17 12.5 17H10"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M12 10V11"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M12 17V18"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_31309_109152">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoneyBag;
