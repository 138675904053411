import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { getCall } from '@/api/axios';

import { PayrollHistoryType } from '../types';
import { queryEndpoints } from './endpoints';

type UseGetPayrollHistoryType = {
  companyId: number;
  rqParams?: Omit<UseQueryOptions, 'queryKey'>;
};

const useGetPayrollHistory = ({
  companyId,
  rqParams,
}: UseGetPayrollHistoryType): UseQueryResult<PayrollHistoryType[], Error> => {
  const { queryKey, url } = queryEndpoints.payrollHistory(companyId);

  return useQuery<PayrollHistoryType[], Error>({
    queryKey,
    queryFn: () => getCall(url),
    select: data => data.data,
    refetchOnWindowFocus: true,
    ...rqParams,
  });
};

export default useGetPayrollHistory;
