import { CalendarDate, endOfWeek, startOfWeek } from '@internationalized/date';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale } from 'react-aria-components';

import { BankHolidays } from '@/redux/dto/auth';
import { formatApprovalDeadline } from '@/utils/helpers';

import { CalendarPreviewProps } from './types';

type ExtendedDates = {
  extendedStart: string; // format: 'YYYY-MM-DD'
  extendedEnd: string; // format: 'YYYY-MM-DD'
} | null;

const useCalendarPreview = (payPeriodData: CalendarPreviewProps) => {
  const { locale } = useLocale();
  const [isMobileSize, setIsMobileSize] = useState<boolean>(false);

  const checkScreenWidth: () => void = () => {
    setIsMobileSize(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const { payPeriodStartDate, payPeriodEndDate, payDate, approvalDeadline, bankHolidays } =
    payPeriodData;

  // Returns the number of rows the month have
  const getNumberOfWeeksFromTheDate = (dateString: string) => {
    if (!dateString || !moment(dateString).isValid()) {
      return null;
    }

    const year = moment(dateString).get('year');
    const month = moment(dateString).get('month');

    const date = new Date(year, month, 1);
    const daysInMonth = moment(date).daysInMonth();

    const startingDayOfWeek = new Date(year, month, 1).getDay();

    const rows = Math.ceil((daysInMonth + startingDayOfWeek) / 7);
    return rows;
  };

  // Formate date for the calendar
  const getFormattedDate = (dateString: string | null) => {
    if (!dateString || !moment(dateString).isValid()) {
      return null;
    }

    const year = moment(dateString).get('year');
    const month = moment(dateString).get('month') + 1; // Month is zero-indexed in moment.js, add 1 to get the correct month
    const date = moment(dateString).get('date');

    return new CalendarDate(year, month, date);
  };

  const {
    isValidForPreview,
    rows: rowsToSkip,
    start,
    end,
    payDay,
    payrollDeadline,
    formattedPayrollDeadline,
    formattedPayDate,
  } = useMemo(() => {
    const isStartDateValid = payPeriodStartDate && moment(payPeriodStartDate).isValid();
    const isEndDateValid = payPeriodEndDate && moment(payPeriodEndDate).isValid();
    const isPayDateDateValid = payDate && moment(payDate).isValid();
    const isDeadlineValid = approvalDeadline && moment(approvalDeadline).isValid();

    const payDay = payDate && moment(payDate).format('ddd, MMM DD');
    const payrollDeadline = formatApprovalDeadline(approvalDeadline, 'ddd, MMM DD');

    const isValidForPreview = isStartDateValid && isEndDateValid && isPayDateDateValid;

    const rows = getNumberOfWeeksFromTheDate(payPeriodStartDate);

    const start = isStartDateValid ? getFormattedDate(payPeriodStartDate) : null;
    const end = isEndDateValid ? getFormattedDate(payPeriodEndDate) : null;
    const formattedPayrollDeadline = isDeadlineValid ? getFormattedDate(approvalDeadline) : null;
    const formattedPayDate = isPayDateDateValid ? getFormattedDate(payDate) : null;

    return {
      isValidForPreview,
      rows,
      start,
      end,
      payDay,
      payrollDeadline,
      formattedPayrollDeadline,
      formattedPayDate,
    };
  }, [payPeriodStartDate, payPeriodEndDate, approvalDeadline, payDate]);

  // Check if start date, end date and the pay date are on the same month
  const isPayPeriodOnSameMonth =
    moment(start).isSame(end, 'month') && moment(start).isSame(formattedPayDate, 'month');

  const calculateExtendedDates = useCallback(
    (startDate: CalendarDate | null, endDate: CalendarDate | null) => {
      if (!startDate || !endDate) return null;

      const startMomentDate = moment(startDate.toString());
      const endMomentDate = moment(endDate.toString());

      const extraDaysBefore = startMomentDate.isoWeekday() - 1;
      const extraDaysAfter = 7 - endMomentDate.isoWeekday();

      const extendedStart = startMomentDate.clone().subtract(extraDaysBefore, 'days');
      const extendedEnd = endMomentDate.clone().add(extraDaysAfter, 'days');

      return {
        extendedStart: extendedStart.format('YYYY-MM-DD'),
        extendedEnd: extendedEnd.format('YYYY-MM-DD'),
      };
    },
    [],
  );

  const biggerDate = moment(payDate).isAfter(moment(payPeriodEndDate))
    ? moment(payDate)
    : moment(payPeriodEndDate);
  const formattedBiggerDate = moment(biggerDate).format('YYYY-MM-DD');

  const extendedDates = useMemo(
    () => calculateExtendedDates(start, getFormattedDate(formattedBiggerDate)),
    [start, formattedBiggerDate, calculateExtendedDates],
  );

  const findHolidaysInRange = useCallback(
    (extendedDates: ExtendedDates, holidaysData: BankHolidays | undefined) => {
      if (!extendedDates || !holidaysData) return [];

      const { extendedStart, extendedEnd } = extendedDates;

      return Object.values(holidaysData)
        .flat()
        .filter(holiday => {
          const holidayDate = moment(holiday.date, 'DD/MM/YYYY');
          return (
            holidayDate.isSameOrAfter(extendedStart) && holidayDate.isSameOrBefore(extendedEnd)
          );
        })
        .map(holiday => ({
          name: holiday.holiday,
          date: moment(holiday.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }));
    },
    [],
  );

  const holidaysInRange = useMemo(
    () => findHolidaysInRange(extendedDates, bankHolidays),
    [extendedDates, bankHolidays, findHolidaysInRange],
  );

  // Remove the date that is before start date and after end date or pay date
  const filterPayPeriodDates = (date: CalendarDate) => {
    if (!start || !end) {
      return false;
    }
    const weekStart = startOfWeek(start, locale);
    const lastDate = moment(end.toString()).isSameOrAfter(formattedPayDate?.toString());
    const weekEnd = endOfWeek(lastDate ? end : formattedPayDate!, locale);

    return date.compare(weekStart) >= 0 && date.compare(weekEnd) <= 0;
  };

  // Remove the date that is after end date or pay date
  const filterPayDate = (date: CalendarDate) => {
    if (!end) {
      return false;
    }
    const lastDate = moment(end.toString()).isSameOrAfter(formattedPayDate?.toString());
    const weekEnd = endOfWeek(lastDate ? end : formattedPayDate!, locale);
    return date.compare(weekEnd) <= 0;
  };

  return {
    isValidForPreview,
    rowsToSkip,
    start,
    end,
    payDay,
    payrollDeadline,
    formattedPayrollDeadline,
    bankHolidays,
    formattedPayDate,
    isPayPeriodOnSameMonth,
    filterPayPeriodDates,
    filterPayDate,
    isMobileSize,
    holidaysInRange,
  };
};

export default useCalendarPreview;
