import { CreditCardIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { LoaderComponent } from '@/components/ui';
import { useBillingInfo } from '@/hooks';
import useDiscountInfo from '@/hooks/useDiscountInfo/useDiscountInfo';
import usePlans from '@/hooks/usePlans/usePlans';
import { SubscriptionAgreement } from '@/redux/dto/auth';
import { AVAILABLE_SUBSCRIPTIONS } from '@/utils/constants';

import { PaymentMethodModal } from '../PaymentMethodModal/PaymentMethodModal';
import { PlanCard } from '../PlanCard/PlanCard';
import { PLANS } from '../SubscriptionBillingCard/utils/constants';

type RenewMultiStepModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  discountCode: string;
  lastSubscriptionAgreement: SubscriptionAgreement;
};

const RenewMultiStepModal = ({
  isOpen,
  onCancel,
  discountCode,
  lastSubscriptionAgreement,
}: RenewMultiStepModalProps) => {
  const { availableProducts, isPlansDataLoading } = usePlans();
  const [isPaymentStepOpen, setIsPaymentStepOpen] = useState<boolean>(false);

  const { refetchBillingInfo } = useBillingInfo();
  const { discount } = useDiscountInfo();

  const handlePaymentStep = () => {
    return setIsPaymentStepOpen(true);
  };

  const isOldPlan =
    Object.values(AVAILABLE_SUBSCRIPTIONS).includes(lastSubscriptionAgreement?.plan) ||
    lastSubscriptionAgreement?.plan === '3-months-free-trial';

  if (isPlansDataLoading) return <LoaderComponent />;

  return (
    <DialogComponent
      open={isOpen}
      onCancel={onCancel}
      height="h-auto max-h-[95vh]"
      modalClasses={`px-5 py-7 rounded-lg`}
    >
      <div className="flex flex-col">
        <div className="absolute right-2 top-1.5 flex justify-end pr-[16px] ">
          <Button
            variant="icon"
            icon={CloseIcon}
            onPress={() => {
              onCancel();
            }}
          />
        </div>
        <div className="flex flex-col items-center">
          <div className="rounded-full bg-emerald-50 p-2">
            <div className="rounded-[28px] bg-emerald-100 p-2">
              <CreditCardIcon className="size-6 rounded-[28px] text-emerald-500" />
            </div>
          </div>
          <span className="font-F37Bolton-Medium mt-4 text-[24px]">Renew your subscription</span>
        </div>
        <div className="mt-medium flex items-center justify-center gap-3">
          {lastSubscriptionAgreement?.plan === PLANS['full-service-payroll'] && (
            <PlanCard
              plans={availableProducts}
              currentPlan={lastSubscriptionAgreement.plan}
              onCancel={onCancel}
              discount={lastSubscriptionAgreement?.discountCodes[0]?.discount}
              isRenew
            />
          )}
          {lastSubscriptionAgreement?.plan === PLANS.basic && (
            <PlanCard
              plans={availableProducts}
              currentPlan={lastSubscriptionAgreement.plan}
              onCancel={onCancel}
              onSubscribe={handlePaymentStep}
              discount={discountCode}
              isRenew
            />
          )}
          {isOldPlan && (
            <PlanCard
              plans={availableProducts}
              currentPlan="Basic"
              onCancel={onCancel}
              onSubscribe={handlePaymentStep}
              discount={discountCode}
              isRenew
            />
          )}
        </div>
      </div>
      <PaymentMethodModal
        isOpen={isPaymentStepOpen}
        onCancel={() => setIsPaymentStepOpen(false)}
        availableProducts={availableProducts}
        parentOnCancel={() => {
          refetchBillingInfo();
          onCancel();
        }}
      />
    </DialogComponent>
  );
};

export default RenewMultiStepModal;
