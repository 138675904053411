import { SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { ReactComponent as Illustration } from '@/assets/illustration/ptoIllustration.svg';
import Button from '@/components/Button/Button';

import { usePtoAnnounecement } from './usePtoAnnounecement';

export const PtoAnnounecement = () => {
  const { componentWidth, handleClose, componentRef, handleRedirect } = usePtoAnnounecement();

  return (
    <div ref={componentRef} className="bg-gray relative rounded-xl border border-gray-100 p-4">
      <div className="flex max-w-[400px] flex-col">
        <div className="flex w-[60px] items-center justify-center rounded bg-emerald-100 px-2 py-1">
          <SparklesIcon className="mr-1 size-4 text-emerald-500" />
          <span className="text-xs text-emerald-500">New</span>
        </div>
        <span className="font-F37Bolton-Medium mt-2 text-[28px]">Track employee time-off</span>
        <span className="mt-2 text-gray-400">
          Keep track of employees’ used and accrued PTO by creating custom time-off policies
        </span>
        <Button
          variant="primary"
          children="Create a time-off policy"
          width="max-w-[218px]"
          parentClasses="mt-4"
          onPress={handleRedirect}
        />
      </div>
      <XMarkIcon
        className="absolute right-4 top-4 size-6 cursor-pointer text-gray-400"
        onClick={handleClose}
      />
      {componentWidth && componentWidth >= 650 && (
        <Illustration className="absolute bottom-0 right-0" />
      )}
    </div>
  );
};
