import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { getCall } from '@/api/axios';
import { EmployeePayrollDataType } from '@/redux/dto/history';

import { queryEndpoints } from './endpoints';

type UseGetPayrollHistoryType = {
  companyId: number;
  queryParams?: {
    dateOfPaymentBefore?: string | null;
    dateOfPaymentAfter?: string | null;
  };
  rqParams?: Omit<UseQueryOptions, 'queryKey'>;
};

export const useGetPayrollHistory = ({
  companyId,
  queryParams,
  rqParams,
}: UseGetPayrollHistoryType): UseQueryResult<EmployeePayrollDataType[], Error> => {
  const params: Record<string, unknown> = {};

  if (queryParams) {
    const { dateOfPaymentBefore, dateOfPaymentAfter } = queryParams;

    if (dateOfPaymentBefore) {
      params['payDate[before]'] = dateOfPaymentBefore;
    }
    if (dateOfPaymentAfter) {
      params['payDate[after]'] = dateOfPaymentAfter;
    }
  }

  const { queryKey, url } = queryEndpoints.getPayrollHistory(companyId);

  return useQuery<EmployeePayrollDataType[], Error>({
    queryKey,
    queryFn: () => getCall(url, params),
    select: data => data.data,
    ...rqParams,
  });
};
