import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as Routes from '@/routes/routes';
import { handleEmployerTaxData } from '@/utils/helpers';

import EmployerTaxContent from '../EmployerTaxContent/EmployerTaxContent';
import { SUTA_TAX_ITEM_ID } from '../EmployerTaxItem/utils/constants';
import { FormattedTaxItemsType, TabType, useTaxInformationType } from './types';
import { formateValueBasedOnType } from './utils';

const useTaxInformation = ({ taxData, refetchData }: useTaxInformationType) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [tabs, setTabs] = useState<TabType[]>([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [formattedTaxItems, setFormattedTaxItems] = useState<FormattedTaxItemsType>([]);

  const shouldShowSupportLink = !currentPath?.includes(Routes.ACCOUNT_SETUP_TAXES);

  useEffect(() => {
    const taxItems = Array.isArray(taxData?.taxItems) ? taxData?.taxItems : [];

    const formattedTaxItems = handleEmployerTaxData({
      taxItems,
      shouldShowAllFields: true,
    });

    // Sort tax items, so SUTA Tax item should be first
    const sortedTaxItems = [
      ...formattedTaxItems.filter(taxItem => taxItem.id.includes(SUTA_TAX_ITEM_ID)),
      ...formattedTaxItems.filter(taxItem => !taxItem.id.includes(SUTA_TAX_ITEM_ID)),
    ];

    const currentIndex = sortedTaxItems.findIndex(({ fields, isExempt }) => {
      if (isExempt) {
        return false;
      }
      return fields.some(({ value, defaultValue }) => value === null && defaultValue === null);
    });

    const formattedCurrentIndex = currentIndex >= 0 ? currentIndex : formattedTaxItems.length;

    const formattedData = sortedTaxItems.map((data, index) => {
      const rateField = Array.isArray(data.fields)
        ? data.fields.find(item => item.name === 'RATE')
        : null;
      const shouldShowRateValue =
        !!rateField && (rateField.value || rateField.defaultValue) !== null;

      return {
        ...data,
        stepperId: index + 1,
        id: data.id,
        title: data.description,
        value: data.isExempt
          ? 'Exempt'
          : shouldShowRateValue && formattedCurrentIndex > index
          ? formateValueBasedOnType(rateField)
          : null,
        taxFields:
          Array.isArray(data.fields) && !data.isExempt
            ? data.fields
                .filter(({ name }) => name !== 'RATE')
                .map(field => {
                  const { description, name } = field;
                  return {
                    title: description,
                    value: formattedCurrentIndex > index ? formateValueBasedOnType(field) : null,
                    id: name,
                  };
                })
            : [],
      };
    });

    setCurrentTab(formattedCurrentIndex + 1);

    setFormattedTaxItems(formattedData);
  }, [taxData]);

  useEffect(() => {
    const formattedTabs = formattedTaxItems.map(({ id, stepperId, title, value, taxFields }) => ({
      id: stepperId,
      content: (
        <EmployerTaxContent
          id={id}
          title={title}
          value={value}
          taxFields={taxFields}
          currentTab={currentTab}
          stepperId={stepperId}
          onSubmit={refetchData}
        />
      ),
    }));

    setTabs(formattedTabs);
  }, [formattedTaxItems, currentTab]);

  return { tabs, currentTab, shouldShowSupportLink };
};

export default useTaxInformation;
