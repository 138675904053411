import { FormProvider } from 'react-hook-form';

import CheckHQWrapper from '@/components/shared/CheckHQWrapper/CheckHQWrapper';
import DeductionCard from '@/components/shared/CompanyLevelDeductions/components/DeductionCard';
import {
  BENEFITS_TO_ID_MAP,
  DEDUCTION_TYPES,
  DEDUCTION_TYPES_NAME,
  GARNISHMENT_TO_ID_MAP,
} from '@/utils/constants';
import { getKeyByValue } from '@/utils/getObjectKey';

import DeductionDrawer from '../../../DeductionDrawer/DeductionDrawer';
import CompanyContribution from './components/CompnayContribution/CompanyContribution';
import CustomPostTaxDeductionForm from './components/CustomPostTaxDeductionForm/CustomPostTaxDeductionForm';
import EffectivePayPeriod from './components/EffectivePayPeriod/EffectivePayPeriod';
import EmployeeContribution from './components/EmployeeContribution/EmployeeContribution';
import GarnishmentDeductionForm from './components/GarnishmentDeductionForm/GarnishmentDeductionForm';
import useEmployeeDeductionForm, { EmployeeDeductionProps } from './useEmployeeDeductionForm';

const EmployeeDeductionForm = ({
  form,
  data,
  formRef,
  onDeductionSubmit,
  onDeleteDeduction,
  isShowingEditDeduction = true,
  onError,
  employeeSsn,
  isEdit,
  handleDeductionClick,
  handleEditDeduction,
  isCheckHqUser,
}: EmployeeDeductionProps) => {
  const { isDeductionDrawerOpen, handleDeductionDrawer, tooltipText } = useEmployeeDeductionForm({
    onDeleteDeduction,
    isCheckHqUser,
  });

  return (
    <FormProvider {...form}>
      <form ref={formRef} onSubmit={form.handleSubmit(onDeductionSubmit, onError)}>
        <div className="pt-6">
          <DeductionCard
            key={data.id}
            title={data.name}
            type={data.type}
            subCategory={getKeyByValue(
              data?.type === DEDUCTION_TYPES.Benefit ? BENEFITS_TO_ID_MAP : GARNISHMENT_TO_ID_MAP,
              data.benefitType || data.garnishmentType,
            )}
            tooltipText={tooltipText(data.employees)}
            companyCardEmployeeCount={data.totalEmployeesWithDeduction}
            buttonText={isShowingEditDeduction ? 'Edit deduction' : null}
            buttonAction={
              !isCheckHqUser || (data.type === DEDUCTION_TYPES_NAME.benefit && isCheckHqUser)
                ? handleDeductionDrawer
                : null
            }
          />
          {data.type === DEDUCTION_TYPES_NAME.benefit && (
            <>
              <CheckHQWrapper>
                <div className="mt-x-large pt-x-large border-t">
                  <EffectivePayPeriod companyEffectiveStart={data?.effectiveStart} />
                </div>
              </CheckHQWrapper>
              <div className="mt-x-large pt-x-large border-t">
                <EmployeeContribution />
              </div>
              <div className="mt-x-large pt-x-large border-t">
                <CompanyContribution />
              </div>
            </>
          )}
          {data.type === DEDUCTION_TYPES_NAME.garnishment && (
            <>
              <CheckHQWrapper>
                <div className="mt-x-large pt-x-large border-t">
                  <GarnishmentDeductionForm employeeSsn={employeeSsn} isEdit={isEdit} />
                </div>
              </CheckHQWrapper>
              <CheckHQWrapper hideItem>
                <div className="mt-x-large pt-x-large border-t">
                  <EmployeeContribution />
                </div>
              </CheckHQWrapper>
            </>
          )}
          {data.type === DEDUCTION_TYPES_NAME.custom && (
            <>
              <div className="mt-x-large gap-x-large pt-x-large flex flex-col border-t">
                <EmployeeContribution />
                <CheckHQWrapper>
                  <EffectivePayPeriod />
                </CheckHQWrapper>
              </div>
              <CheckHQWrapper>
                <div className="mt-x-large pt-x-large border-t">
                  <CustomPostTaxDeductionForm />
                </div>
              </CheckHQWrapper>
            </>
          )}
        </div>
      </form>
      {isDeductionDrawerOpen && (
        <DeductionDrawer
          isOpen={isDeductionDrawerOpen}
          onClose={handleDeductionDrawer}
          data={data}
          isEdit={true}
          isInsideApp={false}
          onDeleteDeduction={onDeleteDeduction}
          isShowingEditEmployees={isShowingEditDeduction}
          handleDeductionClick={handleDeductionClick}
          handleEditDeduction={handleEditDeduction}
        />
      )}
    </FormProvider>
  );
};
export default EmployeeDeductionForm;
