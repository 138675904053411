import { LoaderComponent, UserAvatar } from '@/components';
import Button from '@/components/Button/Button';

import EditBasicInfoDrawer from '../EditBasicInfoDrawer/EditBasicInfoDrawer';
import EditPasswordDrawer from '../EditPasswordDrawer/EditPasswordDrawer';
import useAccountBasicInfo from './useAccountBasicInfo';

const AccountBasicInfo = () => {
  const {
    isEditBasicInfo,
    handleEditBasicInfoDrawer,
    profileData,
    isLoggedInViaGoogle,
    account,
    isLoading,
    user,
    handleEditPasswordDrawer,
    isEditPassword,
    isUpdatingAccountInfo,
    handlePasswordUpdate,
    isUpdatingPassword,
    updateAccountInfo,
  } = useAccountBasicInfo();

  if (isLoading) {
    return (
      <div className="pt-40">
        <LoaderComponent />
      </div>
    );
  }
  return (
    <div className="max-w-[670px] px-5 pb-5 md:px-8 md:pb-8">
      <div className="">
        <div className="flex justify-between">
          <h3 className="font-F37Bolton-Medium text-xl">Basic Info</h3>
          <Button
            variant="text"
            parentClasses="text-emerald-500"
            onPress={handleEditBasicInfoDrawer}
          >
            Edit
          </Button>
        </div>
        <div className="rounded-xl border">
          {profileData.map(info => (
            <div
              className={`flex h-fit justify-between ${
                info?.id !== 'email_address' && 'border-b'
              } p-4`}
              key={info.id}
            >
              <span className="text-base text-gray-400">{info?.label}</span>

              <span className="font-F37Bolton-Medium flex items-center gap-1 break-all text-base">
                {info?.id === 'name' && <UserAvatar width={32} height={32} src={user?.picture} />}
                {info?.value}
              </span>
            </div>
          ))}
        </div>
      </div>
      {!isLoggedInViaGoogle && (
        <div className="pt-10">
          <div className="flex justify-between">
            <h3 className="font-F37Bolton-Medium text-xl">Password</h3>
            <Button
              variant="text"
              parentClasses="text-emerald-500"
              onPress={handleEditPasswordDrawer}
            >
              Change password
            </Button>
          </div>
          <div className="rounded-xl border">
            <div className="flex h-fit justify-between p-4">
              <span className="text-base text-gray-400">Password</span>

              <span className=" font-F37Bolton-Medium text-base">************</span>
            </div>
          </div>
        </div>
      )}
      {isEditBasicInfo && (
        <EditBasicInfoDrawer
          isOpen={isEditBasicInfo}
          onClose={handleEditBasicInfoDrawer}
          account={account}
          isLoading={isUpdatingAccountInfo}
          handleSaveInfo={updateAccountInfo}
        />
      )}

      {
        <EditPasswordDrawer
          onClose={handleEditPasswordDrawer}
          isOpen={isEditPassword}
          account={account}
          isLoading={isUpdatingPassword}
          handlePasswordChange={handlePasswordUpdate}
        />
      }
    </div>
  );
};

export default AccountBasicInfo;
