import { useState } from 'react';

import { TAX_ITEM_VIEW_MODE } from './constants';
import { TaxItemViewModeType, UseEmployerTaxContentType } from './types';

const useEmployerTaxContent = ({ onSubmit, stepperId, currentTab }: UseEmployerTaxContentType) => {
  const [taxItemModalOpen, setTaxItemModalOpen] = useState<{
    isOpen: boolean;
    mode: TaxItemViewModeType | null;
  }>({
    isOpen: false,
    mode: TAX_ITEM_VIEW_MODE.add,
  });

  const openTaxItemModalHandler = (mode: TaxItemViewModeType) => {
    setTaxItemModalOpen({ isOpen: true, mode });
  };

  const onCancelTaxItemModal = () => {
    setTaxItemModalOpen({ isOpen: false, mode: null });
  };

  const onSubmitTaxItem = () => {
    setTaxItemModalOpen({ isOpen: false, mode: null });
    onSubmit();
  };

  const onTaxItemTitleClickHandler = () => {
    if (currentTab > stepperId) {
      openTaxItemModalHandler(TAX_ITEM_VIEW_MODE.edit);
    }
  };

  return {
    taxItemModalOpen,
    openTaxItemModalHandler,
    onCancelTaxItemModal,
    onSubmitTaxItem,
    onTaxItemTitleClickHandler,
  };
};

export default useEmployerTaxContent;
