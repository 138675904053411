import Button from '@/components/Button/Button';
import EmployeeDeductionDrawer from '@/components/shared/EmployeeDeductionDrawer/EmployeeDeductionDrawer';
import { TableCell } from '@/components/ui';

import useEmployeeDeductionRow, { EmployeeDeductionRowProps } from './useEmployeeDeductionRow';

const EmployeeDeductionRow = ({
  employeeData,
  deductionData,
  refetchDeduction,
}: EmployeeDeductionRowProps) => {
  const { handleDeductionDrawer, isDeductionDrawerOpen, onSussesHandler } = useEmployeeDeductionRow(
    {
      refetchDeduction,
    },
  );

  return (
    <>
      <TableCell className="font-F37Bolton-Medium">{employeeData.name}</TableCell>
      <TableCell>{employeeData.employeeContribution}</TableCell>
      <TableCell>{employeeData.companyContribution}</TableCell>
      <TableCell>
        <Button
          variant="tertiary"
          children="Edit"
          parentClasses="w-fit h-fit py-2 px-4"
          onPress={handleDeductionDrawer}
        />
      </TableCell>
      {isDeductionDrawerOpen && (
        <EmployeeDeductionDrawer
          data={deductionData}
          isEditedInPayrollSettings={true}
          isEdit={true}
          isOpen={isDeductionDrawerOpen}
          onClose={handleDeductionDrawer}
          employeeId={employeeData.id}
          employeeName={employeeData?.firstName}
          refetchDeductionData={onSussesHandler}
        />
      )}
    </>
  );
};
export default EmployeeDeductionRow;
