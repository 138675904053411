import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

import { TaxTitleProps } from './types';

const TaxTitle = ({ title, isStateTax }: TaxTitleProps) => {
  return (
    <div className="flex items-center gap-1">
      <ClipboardDocumentListIcon className="w-6 text-gray-400" />
      <span className="font-F37Bolton-Medium text-xl">
        {title} {isStateTax ? 'state taxes' : ''}
      </span>
    </div>
  );
};

export default TaxTitle;
