import { AxiosError } from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PayrollScheduleType } from '@/api/payrollSchedule/types';
import { Address } from '@/components/LocationCard/LocationCard';
import { ONBOARDING_STRUCTURE } from '@/components/shared/AccountSetupLayout/constants';
import { SUBSCRIPTION_BANNER_TYPES } from '@/components/shared/SubscriptionGlobalBanner/utils/constants';
import { Toast } from '@/components/ui/alerts/Toast/Toast';
import { useAccountInfo } from '@/hooks';
import { Account, BankHolidays, SubscriptionInfo } from '@/redux/dto/auth';
import { HidingAlgorithmType, TaxItem } from '@/redux/dto/company';
import { RunnablePayrolls } from '@/redux/dto/employee';
import { Item } from '@/types/payrollHistory';

import { BROWSER_STORAGE_KEYS, FREE_3_MONTH_TRIAL_SUBSCRIPTION, schedules } from './constants';
import { sec } from './security';

export type EmployeeSpoilerData = {
  employerTaxes: [
    {
      amount: number;
      name: string;
    },
  ];
  id: number;
  payDate: string;
  payFrequency: number;
  payments: Item[];
  status: number;
  totalDeductions: number;
  totalEmployeeTaxes: number;
  totalEmployerContributions: number;
  totalEmployerTaxes: number;
  totalHours: number;
  totalNetPay: number;
  totalPayroll: number;
  totalReimbursements: number;
  totalWages: number;
};

type KeyMapping = {
  keyName: string;
  valueName: string;
};

type MappedObject = { [key: string]: string | number };

export const getToken = async () => {
  const accessTokenFromStorage = sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.impersonateUserAccessToken,
  );
  if (!accessTokenFromStorage) {
    if (sec.getAccessTokenSilently()) {
      try {
        const accessToken = await sec.getAccessTokenSilently()({
          audience: import.meta.env.APP_AUTH_AUDIENCE,
        });
        return accessToken;
      } catch (e) {
        return null;
      }
    }
  } else {
    return accessTokenFromStorage;
  }
};

export const checkIsEmployeeInvitation = () => {
  return window.location.pathname.includes('invitation');
};

export const mapEntriesToOptions = <T extends { [key: string]: any }>(
  entriesObject: T,
  keyMapping: KeyMapping = { keyName: 'name', valueName: 'value' },
): MappedObject[] => {
  const { keyName, valueName } = keyMapping;

  return Object.entries(entriesObject).map(([value, name]) => {
    return { [keyName]: name, [valueName]: value };
  });
};

export const getEmployeeInvitationToken = () => {
  return window.location.pathname?.split('/')?.at(-1) ?? null;
};

export const callToast = (
  variant: 'success' | 'warning' | 'info' | 'error',
  subtitle: string,
  title?: string | null,
): void => {
  toast(Toast({ variant, title, subtitle }), {
    position: 'top-right',
    style: { backgroundColor: 'transparent' },
  });
};

export const downloadPayStub = (
  response: Blob | MediaSource,
  id: number,
  isPreview = true,
  name = 'PayStubs_',
) => {
  if (response instanceof Blob || response instanceof MediaSource) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response);
    link.download = `${name}${id}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
    isPreview && window.open(link.href);
  }
};

export const downloadDocument = ({
  response,
  isPreview = true,
  name = 'Report',
}: {
  response: Blob | MediaSource;
  isPreview?: boolean;
  name: string;
}) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(response);
  link.download = `${name}.pdf`;
  link.dispatchEvent(new MouseEvent('click'));
  isPreview && window.open(link.href);
};

export const generateSemiMothDisabledDate = (payFrequency: string) => {
  const disabledDatesPayDate = [];
  const disabledDatesPayPeriod = [];

  if (payFrequency === '3') {
    for (let year = 2023; year <= 2023; year++) {
      for (let month = 1; month <= 12; month++) {
        const lastDayOfMonth = new Date(year, month, 0).getDate();

        // Disable all dates except for 15th and last day of each month
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 15 && day !== lastDayOfMonth) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayDate.push({ startDate: date, endDate: date });
          }
        }
        // Disable all dates except for 1th and 16th
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 1 && day !== 16) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayPeriod.push({ startDate: date, endDate: date });
          }
        }
      }
    }
  }
  return { disabledDatesPayDate, disabledDatesPayPeriod };
};

export const generateMonthlyDisabledDate = (payFrequency: string) => {
  const disabledDatesPayDate = [];
  const disabledDatesPayPeriod = [];
  if (payFrequency === '4') {
    for (let year = 2023; year <= 2023; year++) {
      for (let month = 1; month <= 12; month++) {
        const lastDayOfMonth = new Date(year, month, 0).getDate();

        // Disable all dates except for 1st
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 1) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayDate.push({ startDate: date, endDate: date });
          }
        }
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 1) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayPeriod.push({ startDate: date, endDate: date });
          }
        }
      }
    }
  }
  return { disabledDatesPayDate, disabledDatesPayPeriod };
};
export const generateEmployeeSpoilerData = (data: EmployeeSpoilerData) => {
  if (isArrayHasData(data?.payments)) {
    const schema = data?.payments?.map(item => {
      return {
        employeeName:
          item.employee.firstName + ' ' + item.employee.middleName + ' ' + item.employee.lastName,
        employeeTaxes: item.employeeTaxes,
        otherDeductions: {
          benefits: (Array.isArray(item.benefits)
            ? item.benefits
            : Object.values(item.benefits)
          ).map(obj => {
            return {
              type: obj.type,
              name: obj.name,
              amount: obj.amount,
              employer_contribution_amount: obj.employer_contribution_amount,
            };
          }),
          garnishments: item.garnishments,
          postTaxDeductions: item.postTaxDeductions,
          oneTimeDeductions: item.oneTimeDeductions,
        },
        totalEmployeeTaxes: item.totalEmployeeTaxes,
        customHourlyRates: item.customHourlyRates,
      };
    });
    return schema;
  }
};

export const generateCompanySpolireDataPayrollDetails = (data: Item[]) => {
  const schema = data?.map(item =>
    item.employeeTaxes.map(tax => ({
      title: tax.name,
      value: tax.amount,
    })),
  );

  const descriptionCompanySpoiler = [];

  if (schema?.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Employer taxes',
      taxes: schema,
    });
  }

  const grossPayAndContributions = [];

  if (data?.grossPay !== 0) {
    grossPayAndContributions.push({
      title: 'Gross pay',
      value: data?.grossPay,
    });
  }
  if (data?.totalEmployerContributions !== 0) {
    grossPayAndContributions.push({
      title: 'Employer contribution',
      value: data?.totalEmployerContributions || 0,
    });
  }

  if (data?.totalReimbursements !== 0) {
    grossPayAndContributions.push({
      title: 'Reimbursement',
      value: data?.totalReimbursements || 0,
    });
  }

  if (grossPayAndContributions.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Gross pay and other contributions',
      taxes: grossPayAndContributions,
    });
  }

  return descriptionCompanySpoiler;
};

export const generateCompanySpolireData = (data: EmployeeSpoilerData) => {
  const schema = Object.values(data?.employerTaxes).map(
    (tax: { name: string; amount: number }) => ({
      title: tax?.name,
      value: tax?.amount,
    }),
  );

  const descriptionCompanySpoiler = [];

  if (schema.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Employer taxes',
      taxes: schema,
    });
  }

  const grossPayAndContributions = [];
  if (data?.grossPay !== 0) {
    grossPayAndContributions.push({
      title: 'Gross pay',
      value: data?.grossPay,
    });
  }

  if (data?.totalEmployerContributions !== 0) {
    grossPayAndContributions.push({
      title: 'Employer contribution',
      value: data?.totalEmployerContributions,
    });
  }

  if (data?.totalReimbursements !== 0) {
    grossPayAndContributions.push({
      title: 'Reimbursement',
      value: data?.totalReimbursements,
    });
  }

  if (grossPayAndContributions.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Gross pay and other contributions',
      taxes: grossPayAndContributions,
    });
  }

  return descriptionCompanySpoiler;
};

export const payFrequencyByValue = (num: number): keyof typeof tsMap | undefined => {
  const tsMap: Record<number, keyof typeof tsMap> = {
    1: schedules.WEEKLY.label,
    2: schedules.BI_WEEKLY.label,
    3: schedules.SEMI_MONTHLY.label,
    4: schedules.MONTHLY.label,
    5: schedules.QUARTERLY.label,
    6: schedules.ANNUALLY.label,
  };

  return tsMap[num];
};

export const returnHoursByPayFrequency = (payFrequency: number) => {
  const tsMap: Record<number, keyof typeof tsMap> = {
    1: 40,
    2: 80,
    3: 86.6666666667,
    4: 173.333333333,
    5: 520,
    6: 2080,
  };

  return tsMap[payFrequency];
};

export const keyUp = () => {
  return null;
};

export const isHtmlString = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

export const getCookieData = (name: string) => {
  let pairs = document.cookie.split('; '),
    count = pairs.length,
    parts;
  while (count--) {
    parts = pairs[count].split('=');
    if (parts[0] === name) return parts[1];
  }
  return false;
};

export const findObjectByIdPayroll = (index: number, data: RunnablePayrolls) => {
  if (index >= 0 && index < data.length) {
    return data[index].isOverdue;
  } else {
    return false;
  }
};

export function useLocationEffect() {
  const location = useLocation();
  const [previousPathname, setPreviousPathname] = useState<string | null>(null);
  const { account } = useAccountInfo();
  const uuid = account?.uuid;

  useEffect(() => {
    setPreviousPathname(location.pathname);
    if (location.pathname !== null && previousPathname !== null) {
      TagManager.dataLayer({
        dataLayer: {
          action: null,
          field: null,
          modal_response: null,
          step_name: null,
          step_num: null,
          prev_step_name: null,
          prev_step_num: null,
          route: null,
          prev_route: null,
          tab_name: null,
          tab_num: null,
          prev_tab_name: null,
          prev_tab_num: null,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'routeChange',
          newRoute: extractEventName(location.pathname),
          previousRoute: extractEventName(previousPathname),
          userId: uuid,
        },
      });
    }
  }, [location]);
}

export const extractEventName = (pathname: string) => {
  let eventName = pathname.replace(/\d+/g, '{id}');
  eventName = eventName.replace(/:/g, '');
  return eventName;
};

export const onConfirmRefresh = function (event: {
  preventDefault: () => void;
  returnValue: string;
}) {
  event.preventDefault();
  return (event.returnValue = 'Are you sure you want to leave??');
};

export const isArrayHasData = <T = Array<any>>(arr: T) => Array.isArray(arr) && !!arr.length;

export const isObjHasData = (obj?: object | Record<string, any>) =>
  Boolean(obj) && typeof obj === 'object' && !!Object.keys(obj).length;

export const capitalizeFirstChar = (str: string) => {
  if (str && typeof str === 'string') return str.charAt(0).toUpperCase() + str.slice(1);
  return false;
};

export const focusInputByName = (name: string) => {
  const inputField = document.querySelector(`input[name="${name}"]`) as HTMLInputElement | null;

  if (inputField) {
    inputField.focus();
  }
};

export const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['Armed Forces Americas', 'AA'],
  ['Armed Forces Europe', 'AE'],
  ['Armed Forces Pacific', 'AP'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'NP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['US Virgin Islands', 'VI'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];

export function convertUsStateAbbrAndName(input: string): string | null {
  const toAbbr = input.length !== 2;

  // So happy that Canada and the US have distinct abbreviations
  const provinces = [
    ['Alberta', 'AB'],
    ['British Columbia', 'BC'],
    ['Manitoba', 'MB'],
    ['New Brunswick', 'NB'],
    ['Newfoundland', 'NF'],
    ['Northwest Territory', 'NT'],
    ['Nova Scotia', 'NS'],
    ['Nunavut', 'NU'],
    ['Ontario', 'ON'],
    ['Prince Edward Island', 'PE'],
    ['Quebec', 'QC'],
    ['Saskatchewan', 'SK'],
    ['Yukon', 'YT'],
  ];

  const regions = states.concat(provinces);

  let i; // Reusable loop variable

  if (toAbbr) {
    input = input.replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    for (i = 0; i < regions.length; i++) {
      if (regions[i][0] === input) {
        return regions[i][1];
      }
    }
  } else {
    input = input.toUpperCase();
    for (i = 0; i < regions.length; i++) {
      if (regions[i][1] === input) {
        return regions[i][0];
      }
    }
  }

  return null;
}

// Check if Account is newer to ask Persona questions
export const checkAccountForPersona = (createdAt?: string) => {
  if (!createdAt) return false;

  const inputDate = new Date(createdAt);
  const comparisonDate = new Date('2023-12-20T00:00:00+00:00');

  return inputDate >= comparisonDate;
};

// Check if account is new for rendering PTO announcement on the dashboard and run payroll pages
export const shouldShowAnnouncement = (createdAt?: string) => {
  if (!createdAt) return false;

  const inputDate = new Date(createdAt);

  if (isNaN(inputDate.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }
  const comparisonDate = new Date('2024-09-12T00:00:00+00:00');

  return inputDate <= comparisonDate;
};

// Check if Account is newer to ask Onboarding Customization question
// Onboarding Customization is added on 2024-03-27.
// So, skip Customization questions for account that are older than this date.
export const checkAccountForOnboardingCustomization = (createdAtString: string) => {
  const createdAt = new Date(createdAtString);

  if (isNaN(createdAt.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }

  const comparisonDate = new Date('2024-03-27T00:00:00+00:00');
  return createdAt >= comparisonDate;
};

export const checkAccountForFreeTrialCustomization = (createdAtString: string) => {
  const createdAt = new Date(createdAtString);

  if (isNaN(createdAt.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }

  const comparisonDate = new Date('2024-06-28T00:00:00+00:00');
  return createdAt >= comparisonDate;
};

export const checkAccountForStartDate = (createdAtString: string) => {
  const createdAt = new Date(createdAtString);
  if (isNaN(createdAt.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }

  const comparisonDate = new Date('2025-02-03T00:00:00+00:00');
  return createdAt >= comparisonDate;
};

/* Handle tax data based on the hiding algorithm
 *
 * Employer Taxes will have a hidingAlgorithm as a property, both on the top level of each Tax, but only on each item of the jurisdictionData. If the hidingAlgorithm property doesn't exist, assume its value is "default".
 *
 * Based on the value of the hidingAlgorithm`, we will do the value:
 *
 *   1. If the value is default:
 *       a.If on the jurisdictionData:
 *           1. Hide it from the stepper ONLY if its type is numeric (Percentage, Money, Integer), and it has a defaultValue that’s above “0”
 *           2. Still show it in the summary
 *           3. Still show it if its type is non-numeric (current implementation doesn’t do that)
 *
 *       b. If on the taxItem:
 *           1. Hide it from the stepper if it’s rate is above 0%, and all its jurisdictionData are hidden too (based on each one’s hidingAlgorithm)
 *           2. Still show it in the summary
 *
 *   2. If the value is hideIfExempt:
 *       a. This only works on the taxItem:
 *           1. Hide it from the stepper and the summary if the taxItem has isExempt: true
 *           2. Otherwise show it in both
 *       b. If we find this on the jurisdictionData, do a console.error mentioning which tax & field has this issue
 *
 *   3. If the value is hideIfDefaultValue
 *       a. On the jurisdictionData:
 *           1. Hide on the stepper and the summary if the defaultValue is not null (so even if it’s 0)
 *       b. On the taxItem:
 *           1. Hide it from stepper and the summary if the rate has a value (even if 0), and all the jurisdictionData fields are hidden too (based on each one’s hidingAlgorithm)
 *
 */

export const handleEmployerTaxData = ({
  taxItems,
  shouldShowAllFields,
}: {
  taxItems: TaxItem[];
  shouldShowAllFields: boolean;
}) => {
  const defaultHidingAlgorithm = 'default';
  const RATE_FIELD_NAME = 'RATE';

  if (!Array.isArray(taxItems) || !taxItems?.length) {
    return [];
  }

  const formattedTaxItems = taxItems.reduce((prevTaxItems: TaxItem[], taxItem) => {
    // Format fields other than the RATE field
    const formattedFields = taxItem.fields
      .filter(item => item.name !== RATE_FIELD_NAME)
      .filter(field => {
        const fieldHidingAlgorithm: HidingAlgorithmType = field.hidingAlgorithm
          ? field.hidingAlgorithm
          : defaultHidingAlgorithm;

        switch (fieldHidingAlgorithm) {
          case 'default': {
            if (shouldShowAllFields) {
              return true;
            } else {
              return (
                ['String', 'Choice', 'List'].includes(field.type) ||
                (['Money', 'Percentage', 'Integer'].includes(field.type) &&
                  !(field.defaultValue && Number(field.defaultValue) > 0))
              );
            }
          }
          case 'hideIfExempt': {
            console.error(
              "'hideIfExempt' hidingAlgorithm found in tax field",
              field,
              'in taxItem',
              taxItem,
            );
            return false;
          }
          case 'hideIfDefaultValue': {
            return field.defaultValue === null;
          }
        }
      });

    const itemHidingAlgorithm: HidingAlgorithmType = taxItem.hidingAlgorithm
      ? taxItem.hidingAlgorithm
      : defaultHidingAlgorithm;

    const rateField = taxItem.fields.find(item => item.name === RATE_FIELD_NAME);

    let shouldShowTaxItem = true;

    switch (itemHidingAlgorithm) {
      case 'default': {
        shouldShowTaxItem =
          shouldShowAllFields || !(Number(rateField?.defaultValue) > 0 && !formattedFields.length);
        break;
      }
      case 'hideIfExempt': {
        shouldShowTaxItem = !taxItem.isExempt;
        break;
      }
      case 'hideIfDefaultValue': {
        shouldShowTaxItem = rateField?.defaultValue === null || !!formattedFields.length;
        break;
      }
    }

    if (shouldShowTaxItem) {
      return [
        ...prevTaxItems,
        { ...taxItem, fields: rateField ? [rateField, ...formattedFields] : formattedFields },
      ];
    }
    return prevTaxItems;
  }, []);

  return formattedTaxItems;
};

export const addressesAreEqual = (address1: Address, address2: Address) => {
  if (!address2) {
    return false;
  }
  return (
    address1.streetAddress === address2.streetAddress &&
    address1.city === address2.city &&
    address1.state === address2.state &&
    address1.zipCode === address2.zipCode &&
    address1.aptSteNumber === address2.aptSteNumber
  );
};

// Check if Account is newer to show account-setup layout
// Account-setup is added on 2024-05-30.
// So, skip Account-setup for account that are older than this date.
export const checkAccountForAccountSetup = ({ account }: { account: Account }) => {
  let shouldShowAccountSetup = true;
  let isCompanyStepCompleted = false;
  let isScheduleStepCompleted = false;
  let isTaxesStepCompleted = false;
  let isEmployeesStepCompleted = false;
  let isContractorStepCompleted = false;
  let isBankAccountStepCompleted = false;
  let isFilingAuthorizationStepCompleted = false;
  let isAccountSetupCompleted = false;
  let isFinishOnboardingStepCompleted = false;
  let onBoardingStructure = ONBOARDING_STRUCTURE.both;
  // For new Add team member flow
  let isTeamMemberStepCompleted = false;

  const { company, createdAt } = account ?? {};

  const companyData = company?.[0] ?? {};

  const {
    payrollSchedule,
    missingTaxes,
    numberOfContractors,
    numberOfEmployees,
    address,
    businessRole,
    businessType,
    ein,
    ssn,
    checkHqId,
  } = companyData;

  const createdAtDate = new Date(createdAt);

  const comparisonDate = new Date('2024-05-30T00:00:00+00:00');
  const isExistingAccount = createdAtDate <= comparisonDate;

  if (account) {
    if (isExistingAccount) {
      shouldShowAccountSetup = false;
    } else {
      if (Number(numberOfContractors) >= 0 && Number(numberOfEmployees) === 0) {
        onBoardingStructure = ONBOARDING_STRUCTURE.contractor;
      } else if (Number(numberOfEmployees) >= 0 && Number(numberOfContractors) === 0) {
        onBoardingStructure = ONBOARDING_STRUCTURE.employee;
      }

      isCompanyStepCompleted = !!(businessType && address && (!checkHqId ? ein || ssn : true));

      const existingMetaData = account.metadata;

      const formattedMetaData =
        existingMetaData &&
        !Array.isArray(existingMetaData) &&
        Object.entries(existingMetaData).length
          ? existingMetaData
          : {};

      const {
        passedAccountSetupEmployeeStep,
        passedAccountSetupContractorStep,
        passedAccountSetupFinishOnboardingStep,
        passedAccountSetupTeamMemberStep,
        passedAccountSetupEarningTypesStep,
        passedAccountSetupDeductionStep,
        passedAccountSetupBankAccountStep,
        passedAccountSetupFilingAuthorizationStep,
        passedAccountSetupTaxesStep,
      } = formattedMetaData;
      /**
       * Earning System deployment date; 2024-08-12
       * Earlier we only have the payroll schedule,
       * After the Earning system, Payroll setting contains 2 tabs: Payroll schedule and Pay Types
       * As Earning system doesn't have any data to check it is saved or not, we are doing based on the date and the metadata.
       **/

      const earningSystemDeploymentDate = new Date('2024-08-12T00:00:00+00:00');

      const isAccountBeforeEarningSystem = createdAtDate <= earningSystemDeploymentDate;

      if (isAccountBeforeEarningSystem) {
        isScheduleStepCompleted = payrollSchedule;
      } else {
        isScheduleStepCompleted =
          payrollSchedule &&
          !!passedAccountSetupEarningTypesStep &&
          !!passedAccountSetupDeductionStep;
      }

      isTaxesStepCompleted = !!passedAccountSetupTaxesStep;

      isEmployeesStepCompleted = !!passedAccountSetupEmployeeStep;
      isContractorStepCompleted = !!passedAccountSetupContractorStep;
      isTeamMemberStepCompleted =
        !!passedAccountSetupTeamMemberStep ||
        !!(passedAccountSetupEmployeeStep && passedAccountSetupContractorStep);

      isFinishOnboardingStepCompleted = !!passedAccountSetupFinishOnboardingStep;
      isBankAccountStepCompleted = !!passedAccountSetupBankAccountStep;
      isFilingAuthorizationStepCompleted = !!passedAccountSetupFilingAuthorizationStep;

      isAccountSetupCompleted = isFinishOnboardingStepCompleted;
    }
  }

  return {
    shouldShowAccountSetup,
    isCompanyStepCompleted,
    isScheduleStepCompleted,
    isEmployeesStepCompleted,
    isContractorStepCompleted,
    isAccountSetupCompleted,
    isTaxesStepCompleted,
    isFinishOnboardingStepCompleted,
    onBoardingStructure,
    isTeamMemberStepCompleted,
    isFilingAuthorizationStepCompleted,
    isBankAccountStepCompleted,
  };
};

export const checkAccountForSubscriptionReminder = ({
  subscriptionInfo,
  account,
}: {
  subscriptionInfo?: SubscriptionInfo;
  account?: Account;
}) => {
  if (!subscriptionInfo) {
    return null;
  }

  const hasSubscriptions = subscriptionInfo.subscriptionAgreements?.length;

  const { isAccountSetupCompleted } = checkAccountForAccountSetup({ account });

  const paymentMethods = subscriptionInfo?.paymentMethods;
  const activeSubscriptionAgreement = subscriptionInfo?.activeSubscriptionAgreement;
  const activeSubscriptionEndDate = activeSubscriptionAgreement?.endDate;
  const plan = activeSubscriptionAgreement?.plan;
  const planEndDate = moment(activeSubscriptionEndDate);
  const isEndDatePassed = planEndDate.isBefore(moment());

  const { freeTrialDaysLeftInSubscribedPlan, isSubscribedPlanHasFreeTrial } =
    checkActiveFreeTrialInAccount({ subscriptionInfo });

  /**
   * 1. Reminder banners and popup:
   *    There is no active payment method and active subscription has end date
   *
   * 2. Expired but there is grace period
   *    Same as Reminder banner but the there is no remaining days and there is grace period
   *
   *
   * 3. Plan cancelled and remain active until:
   *   When the active subscription is scheduled to be cancelled = true
   *   Will remain active until the nextProcessingDate
   *
   * 4. Account deactivated:
   *  When the active subscription is null
   */

  const daysDifference = (endDate: string) =>
    moment(endDate).startOf('day').diff(moment().startOf('day'), 'days');

  const shouldShowTheCancelledBanner = activeSubscriptionAgreement?.scheduledToBeCancelled;

  const daysToEndDate =
    (activeSubscriptionEndDate && daysDifference(activeSubscriptionEndDate)) || 0;

  const shouldShowReminderBannerAndModal =
    (isSubscribedPlanHasFreeTrial && activeSubscriptionAgreement?.scheduledToBeCancelled) ||
    (!paymentMethods?.length &&
      daysToEndDate &&
      daysToEndDate >= 0 &&
      daysToEndDate <= 30 &&
      !shouldShowTheCancelledBanner);

  let remainingDays = null;

  if (shouldShowReminderBannerAndModal) {
    if (isSubscribedPlanHasFreeTrial) {
      remainingDays = freeTrialDaysLeftInSubscribedPlan;
    } else {
      remainingDays = daysDifference(activeSubscriptionEndDate);
    }
  }

  const remainingDaysInFastSpring =
    !paymentMethods?.length &&
    activeSubscriptionAgreement?.price?.product?.type === 'time-based' &&
    !activeSubscriptionAgreement?.scheduledToBeCancelled &&
    daysToEndDate >= 0 &&
    daysToEndDate <= 95
      ? daysToEndDate
      : 0;

  const gracePeriod = activeSubscriptionAgreement?.price?.product?.gracePeriod;

  const gracePeriodEndDate =
    gracePeriod && activeSubscriptionAgreement?.endDate
      ? moment(activeSubscriptionAgreement?.endDate).add(gracePeriod, 'days').toString()
      : null;
  const gracePeriodRemainingDays = gracePeriodEndDate && daysDifference(gracePeriodEndDate);

  const shouldShowTheGraceBanner =
    !shouldShowTheCancelledBanner &&
    typeof daysToEndDate === 'number' &&
    daysToEndDate <= 30 &&
    typeof gracePeriodRemainingDays === 'number' &&
    gracePeriodRemainingDays > 0 &&
    !paymentMethods?.length;

  const nextProcessingDate = activeSubscriptionAgreement?.nextProcessingDate;

  const shouldShowDeactivatedBanner = !activeSubscriptionAgreement;

  let bannerVariantToShow = null;

  // Hide the Banner if there is no subscriptions and the account-setup is not completed, because, after the account-setup completion, there will be always subscriptions
  // If there is no subscription, then we need to show it
  if (!hasSubscriptions && !isAccountSetupCompleted) {
    bannerVariantToShow = null;
  } else if (
    shouldShowReminderBannerAndModal &&
    ((remainingDays && remainingDays > 0) || remainingDays === 0)
  ) {
    if (remainingDays) {
      if (remainingDays > 15) {
        bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES['30days'];
      } else if (remainingDays > 7) {
        bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES['15days'];
      } else if (remainingDays > 3) {
        bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES['7days'];
      } else if (remainingDays > 0) {
        bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES['3days'];
      }
    } else {
      bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES['planExpired'];
    }
  } else if (shouldShowTheGraceBanner) {
    bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES['planExpired'];
  } else if (
    shouldShowTheCancelledBanner &&
    nextProcessingDate &&
    moment(nextProcessingDate).diff(moment(), 'days') >= 0
  ) {
    bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES.subscriptionCancelled;
  } else if (shouldShowDeactivatedBanner) {
    bannerVariantToShow = SUBSCRIPTION_BANNER_TYPES.accountDeactivated;
  }
  const isFreeTrailOver =
    (!paymentMethods.length && (!activeSubscriptionAgreement || isEndDatePassed)) ||
    (daysToEndDate >= 0 && daysToEndDate <= 30 && !remainingDays && plan === '3-months-free-trial');

  const isSubscriptionCanceled = shouldShowTheCancelledBanner;

  const isShowingPayBillModal =
    !paymentMethods.length &&
    ((remainingDays && remainingDays <= 15) || isFreeTrailOver || shouldShowTheGraceBanner);

  return {
    remainingDays,
    gracePeriod,
    nextProcessingDate,
    bannerVariantToShow,
    gracePeriodEndDate,
    isFreeTrailOver,
    gracePeriodRemainingDays,
    isSubscriptionCanceled,
    isShowingPayBillModal,
    remainingDaysInFastSpring,
  };
};

export const formatErrorMessage = (error: AxiosError<any>) => {
  const errorData = error?.response?.data;

  const errorTitle = errorData?.['hydra:title'] ?? '';
  const errorDescription = errorData?.['hydra:description'] ?? '';

  const errorMessage =
    errorTitle || errorDescription
      ? `${errorTitle}: ${errorDescription}`
      : errorData?.message || 'Something went wrong';
  return errorMessage;
};

export const findCurrentEmployeeDeductions = (deductionList: any, employeeId: number) => {
  let currentEmployee = null;

  // Define priority order for processing
  const priorityOrder = ['Benefit', 'Garnishment', 'Custom Post-tax Deduction'];

  // Iterate through the deduction list following priority order
  for (const type of priorityOrder) {
    const deduction = deductionList.find(
      item => item.type === type && item.employees.some(emp => emp.id === employeeId),
    );

    if (deduction) {
      currentEmployee = deduction.employees.find(emp => emp.id === employeeId);
      if (currentEmployee) break; // Stop loop once the employee is found
    }
  }

  if (!currentEmployee) return null; // If no employee found, return null

  // Separate the employee's benefits, garnishments, and CPTDs
  const benefits = currentEmployee.employeeBenefits || [];
  const garnishments = currentEmployee.employeeGarnishments || [];
  const cptds = currentEmployee.employeeCustomPostTaxDeductions || [];

  return {
    currentEmployee,
    benefits,
    garnishments,
    cptds,
  };
};

export const getAllEmployeeDeductions = (deduction: any, id: number) => {
  const employeeDeductions = deduction.employees.find(emp => emp.id === id);
  if (!employeeDeductions) return null;

  const deductionData = [
    ...employeeDeductions.employeeBenefits.map(benefit => ({
      ...benefit,
      type: 'Benefit',
    })),
    ...employeeDeductions.employeeGarnishments.map(garnishment => ({
      ...garnishment,
      type: 'Garnishment',
    })),
    ...employeeDeductions.employeeCustomPostTaxDeductions.map(custom => ({
      ...custom,
      type: 'Custom Post-Tax Deduction',
    })),
  ];

  return deductionData;
};

export const checkIsImpersonationStarted = () => {
  const impersonateUserAccessToken = sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.impersonateUserAccessToken,
  );
  const impersonateUserSuccessToastTimeStamp = sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.impersonateUserSuccessToastTimeStamp,
  );
  if (impersonateUserAccessToken && !impersonateUserSuccessToastTimeStamp) {
    sessionStorage.setItem(
      BROWSER_STORAGE_KEYS.impersonateUserSuccessToastTimeStamp,
      new Date().toISOString(),
    );
    callToast('success', 'Impersonation Activated');
  }
};

export const checkErrorInImpersonation = () => {
  const impersonateUserAccessToken = sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.impersonateUserAccessToken,
  );
  if (impersonateUserAccessToken) {
    // TODO: manage redirect user to login after token error status modification
    // sessionStorage.removeItem(BROWSER_STORAGE_KEYS.impersonateUserAccessToken);
    // window.location.href = window.location.origin + '/login';
  }
};

export const impersonationLogoutHandler = () => {
  [
    BROWSER_STORAGE_KEYS.impersonateUserAccessToken,
    BROWSER_STORAGE_KEYS.impersonateUserSuccessToastTimeStamp,
  ].forEach(key => {
    sessionStorage.removeItem(key);
  });
};

export function findLastIndexPolyfill<T>(
  arr: T[],
  callback: (item: T, index: number, array: T[]) => boolean,
): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (callback(arr[i], i, arr)) {
      return i;
    }
  }
  return -1;
}

export const checkAccountForBrandChangeConfirmation = (createdAtString?: string) => {
  if (!createdAtString) return false;

  const createdAt = new Date(createdAtString);

  if (isNaN(createdAt.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }

  // Brand Change from PayStubs to BlinkPayroll date: 2024-10-22T00:00:00+00:00
  const comparisonDate = new Date('2024-10-22T00:00:00+00:00');
  return createdAt <= comparisonDate;
};

export const isHoliday = (date: string, bankHolidays: BankHolidays) => {
  const formattedDate = moment(date).format('DD/MM/YYYY');
  const year = new Date(date).getFullYear();

  return bankHolidays[year]?.some(data => data.date === formattedDate) ?? false;
};

export const checkActiveFreeTrialInAccount = ({
  subscriptionInfo,
}: {
  subscriptionInfo?: SubscriptionInfo;
}) => {
  const activeSubscriptionAgreement = subscriptionInfo?.activeSubscriptionAgreement;

  let isSubscribedPlanHasFreeTrial = false;
  let freeTrialDaysLeftInSubscribedPlan = 0;

  if (activeSubscriptionAgreement) {
    freeTrialDaysLeftInSubscribedPlan = activeSubscriptionAgreement.nextProcessingDate
      ? moment(activeSubscriptionAgreement.nextProcessingDate)
          .startOf('day')
          .diff(moment().startOf('day'), 'days')
      : 0;

    isSubscribedPlanHasFreeTrial =
      activeSubscriptionAgreement.status === 'trialing' &&
      !!activeSubscriptionAgreement.nextProcessingDate &&
      activeSubscriptionAgreement.plan !== FREE_3_MONTH_TRIAL_SUBSCRIPTION &&
      freeTrialDaysLeftInSubscribedPlan > 0;
  }

  return {
    isSubscribedPlanHasFreeTrial,
    freeTrialDaysLeftInSubscribedPlan,
  };
};

export const isNullArrayOfObjectField = (
  array: Array<Record<string, any>>,
  field: string,
): boolean => array.every(item => item[field] != null);

export const getDueDaysText = (date: string): string => {
  const daysDifference = moment().startOf('day').diff(moment(date).startOf('day'), 'days');

  switch (daysDifference) {
    case 1:
      return 'Due yesterday';
    case 0:
      return 'Due today';
    case -1:
      return 'Due tomorrow';
    default:
      return daysDifference > 0
        ? `Due ${daysDifference} days ago`
        : `Due in ${Math.abs(daysDifference)} days`;
  }
};

const checkDateForHoliday = ({
  date,
  bankAccountHolidays,
}: {
  date: string;
  bankAccountHolidays: PayrollScheduleType['bankHolidays'];
}) => {
  const formattedDate = moment(date).startOf('day');
  const year = formattedDate.year();
  const isWeekend = formattedDate.day() === 0 || formattedDate.day() === 6;

  if (isWeekend) {
    return true;
  }
  const yearHolidays = bankAccountHolidays?.[year];

  if (yearHolidays) {
    const isBankHoliday = yearHolidays.some(({ date: holidayDate }) => {
      const formattedHolidayDate = moment(holidayDate, 'DD/MM/YYYY', true);

      return formattedHolidayDate.isSame(formattedDate, 'day');
    });

    return isBankHoliday;
  }
  return false;
};

export const calculateApprovalDeadlineAndPayDate = ({
  payDate,
  approvalDeadline,
  bankAccountHolidays,
}: {
  payDate?: string | null;
  approvalDeadline?: string | null;
  bankAccountHolidays: PayrollScheduleType['bankHolidays'];
}) => {
  // TODO: remove this after BE fix about updated pay date
  return {
    formattedApprovalDeadline: approvalDeadline,
    updatedPayDate: payDate,
    isPassedApprovalDeadlineDate: false,
  };

  const currentDate = moment();

  const isPassedApprovalDeadlineDate = moment(approvalDeadline).isBefore(currentDate, 'days');
  const formattedApprovalDeadline = isPassedApprovalDeadlineDate
    ? currentDate.format('YYYY-MM-DD')
    : approvalDeadline;

  let updatedPayDate = payDate;

  if (isPassedApprovalDeadlineDate) {
    let dateDiffToCount = moment(formattedApprovalDeadline)
      .startOf('day')
      .diff(moment(approvalDeadline).startOf('day'), 'days');

    let formattedPayDate = payDate;

    while (dateDiffToCount > 0) {
      formattedPayDate = moment(moment(formattedPayDate).format('YYYY-MM-DD'))
        .add(1, 'day')
        .format('YYYY-MM-DD');

      const isOnHoliday = checkDateForHoliday({
        date: formattedPayDate,
        bankAccountHolidays: bankAccountHolidays,
      });

      if (!isOnHoliday) {
        dateDiffToCount--;
      }
    }

    updatedPayDate = formattedPayDate;
  }

  return {
    formattedApprovalDeadline,
    updatedPayDate,
    isPassedApprovalDeadlineDate,
  };
};

export const camelCaseToCapitalized = (input: string): string => {
  // Match words in camelCase and split them
  const words = input.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  const capitalizedWords = words
    .split(' ') // Split into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(' '); // Join the words back with a space

  return capitalizedWords;
};

export const handleContactSupport = () => {
  window.fcWidget.open();
};

export const isObjectEmpty = (objectName: object) => {
  return Object.keys(objectName).length === 0;
};

export const formatApprovalDeadline = (
  date: string | Date | null | undefined,
  format: string | null | undefined,
  isTimezone?: boolean,
) => {
  if (!date) return null;

  const formattedDate = moment(date).format(format || 'MMM D, h:mm A');

  const intlParts = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(
    moment(date).toDate(),
  );
  const tzPart = intlParts.find(part => part.type === 'timeZoneName');
  const tzAbbr = tzPart ? tzPart.value : '';

  return isTimezone ? `${formattedDate}, ${tzAbbr}` : formattedDate;
};
