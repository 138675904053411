import { tv } from 'tailwind-variants';

import useBreakpoint from '@/hooks/useBreakPoint/useBreakPoint';
import { twMerge } from '@/utils/tailwindMergeConfig';

import { getColor } from './statusColors';
import { StepperProps } from './type';

const stepper = tv({
  slots: {
    base: 'flex',
    separator: 'flex-1 border-t',
    title: 'flex items-center max-w-fit',
    component: '',
  },
  variants: {
    orientation: {
      horizontal: {
        base: 'flex-row items-start w-full',
        separator: 'border-t self-start translate-y-2.5',
        component: 'mt-2',
      },
      vertical: {
        base: 'flex-col',
        separator: 'border-l-2 border-y-0 border-r-0 translate-x-2',
        component: 'pl-x-large py-medium',
      },
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
  },
});

export const PayrollStepper = ({ steps = [], orientation, currentStep = 0 }: StepperProps) => {
  const { isMobile } = useBreakpoint();
  if (typeof orientation === 'undefined') orientation = isMobile ? 'vertical' : 'horizontal';

  const styles = stepper({ orientation });
  if (!steps.length) {
    return null;
  }

  const isVertical = orientation === 'vertical';

  return (
    <div className={twMerge('p-medium bg-surface-light-gray rounded-x-small ', styles.base())}>
      {steps.map((step, index) => {
        const isLast = index === steps.length - 1;
        const { status, variant, component, separatorClass = '' } = step;
        const { border, bg, text, title_border } = getColor(status, variant);
        return (
          <div
            key={index}
            className={twMerge(
              'gap-large flex w-full flex-col',
              !isVertical && (index === 1 ? 'min-w-[40%]' : 'min-w-[30%]'),
            )}
          >
            {isVertical ? (
              <>
                <div className="flex flex-col">
                  <div className={twMerge(styles.title(), bg, title_border)}>
                    <div className={twMerge(text)}>{step.title}</div>
                  </div>
                  <div className="flex max-w-fit">
                    {!isLast ? (
                      <div
                        className={`${styles.separator()} ${
                          currentStep <= index
                            ? 'border-secondary-navy-light-border border'
                            : ` ${border} ${separatorClass}`
                        }`}
                      />
                    ) : (
                      <div className={`${styles.separator()} invisible`} />
                    )}
                    {component && <div className={twMerge(styles.component())}>{component}</div>}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex">
                  <div className={twMerge(styles.title(), bg, title_border)}>
                    <div className={twMerge(text)}>{step.title}</div>
                  </div>
                  {!isLast ? (
                    <div
                      className={`${styles.separator()} ${
                        currentStep <= index
                          ? 'border-secondary-navy-light-border border'
                          : ` ${border} ${separatorClass}`
                      }`}
                    ></div>
                  ) : (
                    <div className={`${styles.separator()} invisible`} />
                  )}
                </div>
                {component && <div className={twMerge(styles.component())}>{component}</div>}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
