import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import {
  Alert,
  FormInputDecimalsField,
  FormInputTextField,
  InputDatePicker,
} from '@/components/ui';
import FormSelect from '@/components/ui/FormSelect';

import EffectivePayPeriod from '../EffectivePayPeriod/EffectivePayPeriod';
import useGarnishmentDeductionForm from './useGarnishmentDeductionForm';

type GarnishmentDeductionFormProps = {
  employeeSsn?: string | null;
  isEdit?: boolean;
};

const GarnishmentDeductionForm = ({ employeeSsn, isEdit }: GarnishmentDeductionFormProps) => {
  const { control, errors, formattedStates } = useGarnishmentDeductionForm();
  return (
    <div className="gap-medium flex flex-col">
      {!employeeSsn && !isEdit && (
        <Alert
          variant="danger"
          title="Employee SSN is missing"
          subtitle="In order to add a child support deduction for this employee, please go to the Taxes & Payment method, and add their SSN in the Personal Details section."
          isDeletable={false}
        />
      )}
      <div className="gap-x-small flex flex-col">
        <h4 className="font-F37Bolton-Medium text-content-heading">Garnishment Details</h4>
        <p className="text-content-body-medium text-sm">
          This is the amount to be deducted from employees scheduled payments{' '}
        </p>
      </div>
      <div className="gap-medium sm:gap-x-small flex flex-col sm:flex-row">
        <FormInputDecimalsField
          shouldUnregister={false}
          title="Amount per pay check"
          name="amount"
          isRequired
          key="amount"
          inputClasses="border-gray-300"
          InputAdornment="$"
          decimalsLimit={2}
          placeholder="0.00"
          step={0.01}
          decimalSeparator="."
          groupSeparator=","
          min={0}
        />
        <FormInputDecimalsField
          shouldUnregister={false}
          title="Max percentage of disposable income"
          name="maxPercent"
          isRequired
          key="maxPercent"
          inputClasses="border-gray-300"
          InputAdornment="%"
          decimalsLimit={2}
          placeholder="0.00"
          step={0.01}
          decimalSeparator="."
          groupSeparator=","
          min={0}
          subtitle="Look for this percentage in the “Income Withholding for Support” section of the employee’s official court order."
          labelEndAdornment={
            <>
              <InformationCircleIcon
                className="size-5 cursor-pointer text-gray-500"
                data-tooltip-id="max-percentage-of-child-support"
              />
              <Tooltip
                id="max-percentage-of-child-support"
                className="!bg-navy-500 !text-small z-[1] !w-auto max-w-[240px] !rounded-lg"
                content="This is the maximum percentage that can be withheld from the employee’s disposable income (gross pay minus mandatory taxes like federal, state, Social Security, and Medicare) for this child support deduction. Contact support if you can’t find it."
                place="top-start"
                clickable
              />
            </>
          }
        />
      </div>

      <EffectivePayPeriod />

      <div className="sm:pt-x-large pt-medium sm:gap-x-small gap-medium flex flex-col border-t sm:flex-row">
        <FormSelect
          isRequired
          shouldUnregister={false}
          width="w-full"
          title="Agency"
          name="agency"
          options={formattedStates}
          parentClasses="w-full"
          labelEndAdornment={
            <>
              <InformationCircleIcon
                className="size-5 cursor-pointer text-gray-500"
                data-tooltip-id="effective-payPeriod-info"
              />
              <Tooltip
                id="effective-payPeriod-info"
                className="!bg-navy-500 z-[1] !w-auto max-w-[240px] !rounded-lg !text-sm"
                content="CheckHQ Effective Agencies"
                place="top"
                clickable
              />
            </>
          }
        />
        <FormInputTextField
          name="externalId"
          title="External ID"
          placeholder="Enter External Id"
          width="w-full"
          isRequired
        />
        <Controller
          control={control}
          name="issueDate"
          render={({ field: { onChange, value } }) => (
            <InputDatePicker
              label="Issue Date"
              value={{
                startDate: value,
                endDate: value,
              }}
              isNecessary
              placeholder="mm/dd/yyyy"
              isSingle
              onChange={({ startDate }: { startDate: string }) => onChange(startDate)}
              errorMessage={errors?.issueDate?.message}
              popoverDirection="down"
              inputName="issueDate"
            />
          )}
        />
      </div>
      <div className="gap-x-small flex flex-col">
        <h4 className="font-F37Bolton-Medium text-content-heading">
          Do you want auto-remit payments to state agency?
        </h4>
        <p className="text-content-body-medium text-sm">
          BlinkPayroll will auto-withhold and remit this payment on your behalf each pay period.
          Most employers prefer this option.
        </p>
        <div className="gap-x-small mt-medium flex">
          <label className="flex items-center" htmlFor="radio-yes">
            <Controller
              control={control}
              name="managed"
              render={({ field: { onChange, value } }) => (
                <input
                  className="mr-2 size-[24px] focus:ring-0 focus:ring-offset-0"
                  type="radio"
                  id="radio-yes"
                  value={1}
                  checked={value === 1}
                  onChange={() => onChange(1)}
                />
              )}
            />
            Yes
          </label>
          <label className="ml-4 flex items-center" htmlFor="radio-no">
            <Controller
              control={control}
              name="managed"
              render={({ field: { onChange, value } }) => (
                <input
                  className="mr-2 size-[24px] focus:ring-0 focus:ring-offset-0"
                  type="radio"
                  id="radio-no"
                  value={0}
                  checked={value === 0}
                  onChange={() => onChange(0)}
                />
              )}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default GarnishmentDeductionForm;
