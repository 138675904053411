import { useMemo } from 'react';

import { FEDERAL_TAX_STATE_ABBREVIATION } from '@/utils/constants';

import { useAccountInfo } from '@/hooks';
import useGetCompanyTaxInformation from '../../apis/useGetCompanyTaxInformation/useGetCompanyTaxInformation';
import usePatchCompanyTaxInformation from '../../apis/usePatchCompanyTaxInformation/usePatchCompanyTaxInformation';
import { UseReviewTaxInformationType } from './types';

const useReviewTaxInformation = ({ taxData, onSubmit }: UseReviewTaxInformationType) => {
  const { companyId } = useAccountInfo();

  const {
    data,
    isLoading: isFetchingTaxInformation,
    refetch: refetchTaxData,
  } = useGetCompanyTaxInformation({
    companyId,
    queryParams: {
      queryKey: [companyId, 'confirmEmployerTax', 'reviewEmployerTax'],
    },
  });

  const { mutate: saveReviewTaxInfo, isPending: isSavingReviewTaxInformation } =
    usePatchCompanyTaxInformation({
      companyId,
      mutationParams: {
        onSuccess: () => {
          onSubmit();
        },
      },
    });

  const formattedTaxData = useMemo(() => {
    if (Array.isArray(data)) {
      const stateTaxData = data.filter(
        stateTaxInfo =>
          stateTaxInfo.stateAbbreviation === taxData.stateAbbreviation &&
          stateTaxInfo.stateAbbreviation !== FEDERAL_TAX_STATE_ABBREVIATION,
      );

      const federalTaxData = data.filter(
        stateTaxInfo => stateTaxInfo.stateAbbreviation === FEDERAL_TAX_STATE_ABBREVIATION,
      );
      return [...stateTaxData, ...federalTaxData];
    }
    return [];
  }, [data, taxData]);

  const onSaveReviewData = () => {
    let payload = [];

    if (formattedTaxData?.length) {
      payload = formattedTaxData.flatMap(item => {
        const taxItems = item.taxItems.map(taxItem => {
          const formattedTaxItem = { id: taxItem.id, isExempt: taxItem.isExempt };
          if (!taxItem.isExempt) {
            formattedTaxItem.fields = taxItem.fields.map(({ name, value, defaultValue }) => ({
              name,
              value: value ?? defaultValue,
            }));
          }
          return formattedTaxItem;
        });
        return taxItems;
      });

      const formattedPayload = {
        taxItems: payload,
      };

      saveReviewTaxInfo(formattedPayload);
    }
  };

  return {
    isFetchingTaxInformation,
    formattedTaxData,
    refetchTaxData,
    onSaveReviewData,
    isSavingReviewTaxInformation,
  };
};

export default useReviewTaxInformation;
