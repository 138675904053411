import { isWeekend } from '@internationalized/date';
import moment from 'moment';
import {
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader as AriaCalendarGridHeader,
  CalendarHeaderCell,
  I18nProvider,
  RangeCalendar as AriaRangeCalendar,
  useLocale,
} from 'react-aria-components';

import { isHoliday } from '@/utils/helpers';
import { twMerge } from '@/utils/tailwindMergeConfig';

import CheckHQWrapper from '../CheckHQWrapper/CheckHQWrapper';
import { CalendarPreviewProps } from './types';
import useCalendarPreview from './useCalendarPreview';

// Calendar Grid header for the day
export function CalendarGridHeader() {
  return (
    <AriaCalendarGridHeader>
      {day => (
        <CalendarHeaderCell className="text-x-small text-content-body-light min-w-11 max-w-11 pb-2 pt-3 font-normal uppercase sm:min-w-8 sm:max-w-8 [@media(max-width:374px)]:min-w-8 [@media(max-width:374px)]:max-w-8">
          {day}
        </CalendarHeaderCell>
      )}
    </AriaCalendarGridHeader>
  );
}

const CalendarPreview = (payPeriodData: CalendarPreviewProps) => {
  const {
    isValidForPreview,
    start,
    end,
    payDay,
    payrollDeadline,
    formattedPayrollDeadline,
    formattedPayDate,
    bankHolidays,
    rowsToSkip,
    filterPayDate,
    filterPayPeriodDates,
    isPayPeriodOnSameMonth,
    holidaysInRange,
  } = useCalendarPreview(payPeriodData);
  const { locale } = useLocale();

  if (!isValidForPreview || !start || !end) {
    return null;
  }

  return (
    <div className="flex w-fit flex-col sm:max-w-[250px] [@media(max-width:374px)]:max-w-[250px]">
      <div className="p-small rounded-md border border-gray-100">
        <div className="gap-x-small flex flex-col">
          <p className="text-x-small text-content-heading block">Pay Period</p>
          <h1 className="font-F37Bolton-Medium text-emerald-500">{`${moment(
            start.toString(),
          ).format('MMM DD, YY')} - ${moment(end.toString()).format('MMM DD, YY')}`}</h1>
        </div>
        <I18nProvider locale="en-US">
          <AriaRangeCalendar
            pageBehavior="single"
            visibleDuration={{ months: 2 }}
            value={{ start, end }}
            isReadOnly
          >
            {/* Calendar Grid for first month */}
            <CalendarGrid className="[&_td]:p-0" weekdayStyle="short">
              <CalendarGridHeader />
              <CalendarGridBody>
                {date => (
                  <>
                    {
                      <CalendarCell
                        date={date}
                        className={twMerge(
                          isWeekend(date, locale) ? 'text-gray-300' : 'text-gray-800',
                          `selected:bg-gray-50 selection-start:rounded-s-full selection-end:rounded-e-full text-small font-F37Bolton-Medium group my-1 flex h-8 w-11 cursor-default items-center justify-center gap-3 leading-6 sm:size-8 [@media(max-width:374px)]:min-w-8 [@media(max-width:374px)]:max-w-8`,
                          !filterPayPeriodDates(date) && 'hidden',
                        )}
                      >
                        {({ formattedDate }) => (
                          <>
                            <CheckHQWrapper>
                              <div className="relative">
                                {isHoliday(date.toString(), bankHolidays || {}) && (
                                  <span className="bg-icon-dark absolute left-[35%] top-0 !size-1 rounded-full"></span>
                                )}
                                <span
                                  className={`${
                                    moment(formattedPayDate?.toString()).isSame(date.toString())
                                      ? '!sm:size-6 !size-6 rounded-full bg-emerald-500 text-white'
                                      : moment(formattedPayrollDeadline?.toString()).isSame(
                                          date.toString(),
                                        )
                                      ? 'border-semantic-danger-red-hover text-semantic-danger-red-hover !sm:size-6 !size-6 rounded-full border-2 bg-transparent'
                                      : 'selected:bg-gray-50'
                                  } flex size-full items-center justify-center`}
                                >
                                  {formattedDate}
                                </span>
                              </div>
                            </CheckHQWrapper>
                            <CheckHQWrapper hideItem>
                              <span
                                className={`${
                                  moment(formattedPayDate?.toString()).isSame(date.toString())
                                    ? '!sm:size-6 !size-6 rounded-full bg-emerald-500 text-white'
                                    : 'selected:bg-gray-50'
                                } flex size-full items-center justify-center`}
                              >
                                {formattedDate}
                              </span>
                            </CheckHQWrapper>
                          </>
                        )}
                      </CalendarCell>
                    }
                  </>
                )}
              </CalendarGridBody>
            </CalendarGrid>
            {/* Calendar Grid for second month, show it only if end date or pay date span to second month  */}
            {!isPayPeriodOnSameMonth && (
              <CalendarGrid offset={{ weeks: rowsToSkip ?? 0 }} className="[&_td]:p-0">
                <CalendarGridBody>
                  {date => (
                    <>
                      {filterPayDate(date) && (
                        <CalendarCell
                          date={date}
                          className={` ${
                            isWeekend(date, locale) ? 'text-gray-300' : 'text-gray-800'
                          } selected:bg-gray-50 selection-start:rounded-s-full selection-end:rounded-e-full text-small font-F37Bolton-Medium group my-1 flex h-8 w-11 cursor-default items-center justify-center gap-3 leading-6 sm:size-8 [@media(max-width:374px)]:min-w-8 [@media(max-width:374px)]:max-w-8`}
                        >
                          {({ formattedDate }) => (
                            <>
                              <CheckHQWrapper>
                                <div className="relative">
                                  {isHoliday(date.toString(), bankHolidays || {}) && (
                                    <span className="bg-icon-dark absolute left-[35%] top-0 !size-1 rounded-full"></span>
                                  )}
                                  <span
                                    className={`${
                                      moment(formattedPayDate?.toString()).isSame(date.toString())
                                        ? '!sm:size-6 !size-6 rounded-full bg-emerald-500 text-white'
                                        : moment(formattedPayrollDeadline?.toString()).isSame(
                                            date.toString(),
                                          )
                                        ? 'border-semantic-danger-red-hover text-semantic-danger-red-hover !sm:size-6 !size-6 rounded-full border-2 bg-transparent'
                                        : 'selected:bg-gray-50'
                                    }  flex size-full items-center justify-center`}
                                  >
                                    {formattedDate}
                                  </span>
                                </div>
                              </CheckHQWrapper>
                              <CheckHQWrapper hideItem>
                                <span
                                  className={`${
                                    moment(formattedPayDate?.toString()).isSame(date.toString())
                                      ? '!sm:size-6 !size-6 rounded-full bg-emerald-500 text-white'
                                      : 'selected:bg-gray-50'
                                  }  flex size-full items-center justify-center`}
                                >
                                  {formattedDate}
                                </span>
                              </CheckHQWrapper>
                            </>
                          )}
                        </CalendarCell>
                      )}
                    </>
                  )}
                </CalendarGridBody>
              </CalendarGrid>
            )}
          </AriaRangeCalendar>
        </I18nProvider>
      </div>
      <CheckHQWrapper>
        <div className="mt-medium text-x-small flex flex-col gap-2">
          {payrollDeadline && (
            <span className="text-content-body-strong flex items-center gap-[6px]">
              <div className="border-semantic-danger-red-hover size-2 rounded-full border bg-transparent" />
              {payrollDeadline}: Payroll Deadline
            </span>
          )}
          <span className="text-content-body-strong flex items-center gap-[6px]">
            <div className="size-2 rounded-full bg-emerald-500" />
            {payDay}: Pay day
          </span>
          {holidaysInRange?.map(holiday => (
            <span key={holiday.date} className="text-content-body-strong flex gap-[6px]">
              <div className="bg-icon-dark mt-[5px] size-2 shrink-0 rounded-full" />
              <span className="flex gap-[3px]">
                {holiday.date && moment(holiday.date).format('ddd, MMM DD')}: {'Bank holiday • '}
                {holiday.name}
              </span>
            </span>
          ))}
        </div>
      </CheckHQWrapper>
      <CheckHQWrapper hideItem>
        <div className="mt-[6px] flex items-center gap-4 text-[14px]">
          <span className="flex items-center gap-[6px] text-gray-400">
            <div className="border-navy-100 size-[14px] rounded-full border bg-gray-50" /> Pay
            period
          </span>
          <span className="flex items-center gap-[6px] text-gray-400">
            <div className="border-navy-100 size-[14px] rounded-full border bg-emerald-500" /> Pay
            date
          </span>
        </div>
      </CheckHQWrapper>
    </div>
  );
};

export default CalendarPreview;
