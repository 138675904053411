import { StatusProps } from '@/components/ui/Status/types';
import { EmployeeManualPayHistoryStatusType } from '@/redux/dto/members';
import { BRAND_INFO } from '@/utils/constants';

export const whatToPrepareList = [
  `Ensure you've added all employees who were paid earlier this year before joining ${BRAND_INFO.name}.`,
  'The last pay stub for each employee paid this year, including year-to-date earnings, federal and state taxes, and deductions.',
  'The summary of your last payroll (optional).',
];

export const EMPLOYEE_MANUAL_PAY_HISTORY_STATUS: {
  [key in EmployeeManualPayHistoryStatusType]: EmployeeManualPayHistoryStatusType;
} = {
  completed: 'completed',
  in_progress: 'in_progress',
  not_applicable: 'not_applicable',
  pending: 'pending',
  pending_approval: 'pending_approval',
};

type FormattedManualPayHistoryStatusType = 'in_progress' | 'pending_approval' | 'completed';

export const manualPayHistoryStatusToNameMap: {
  [key in FormattedManualPayHistoryStatusType]: string;
} = {
  completed: 'Completed',
  in_progress: 'In progress',
  pending_approval: 'Pending approval',
};

export const manualPayHistoryStatusToButtonTextMap: {
  [key in FormattedManualPayHistoryStatusType | 'pending']: string;
} = {
  completed: 'Edit',
  in_progress: 'Continue',
  pending_approval: 'Review',
  pending: 'Add',
};

export const manualPayHistoryStatusToStatusMap: {
  [key in FormattedManualPayHistoryStatusType]: StatusProps['variant'];
} = {
  completed: 'completed',
  in_progress: 'inProgress',
  pending_approval: 'inviteSent',
};

export const FEDERAL_MEDICARE_TAX_FIELD_ID = '00-000-0000-MEDI-000';
export const FEDERAL_SOCIAL_SECURITY_TAX_FIELD_ID = '00-000-0000-FICA-000';
