import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { LoaderComponent } from '@/components/ui';

import SubscriptionPaymentContainer from '../SubscriptionPaymentContainer/SubscriptionPaymentContainer';
import SubscriptionPlanGroup from './SubscriptionPlanGroup/SubscriptionPlanGroup';
import useUpgradeSubscriptionPlan from './useUpgradeSubscriptionPlan';

type UpgradeSubscriptionPlanProps = {
  paymentSuccessHandler: (paymentData: { amount: number; planName: string }) => void;
  choosePlanTitle: string;
  subTitle?: ReactNode;
  onCancel: () => void;
  subTitleStyle?: string;
};

const UpgradeSubscriptionPlan = ({
  paymentSuccessHandler,
  choosePlanTitle,
  subTitle,
  onCancel,
  subTitleStyle,
}: UpgradeSubscriptionPlanProps) => {
  const {
    isLoadingSubscriptionPlans,
    plans,
    selectedPlan,
    handlePlanChange,
    recommendedPlan,
    confirmPlanHandler,
    isPaymentModalOpen,
    closePaymentModalHandler,
    isMobileSize,
    hasSubscriptions,
    discount,
    discountCode,
    isDesktop,
  } = useUpgradeSubscriptionPlan();
  return (
    <>
      {isLoadingSubscriptionPlans ? (
        <LoaderComponent />
      ) : (
        <div className="rounded-medium flex gap-2 divide-x border p-6">
          <div className="lg:pr-6">
            <SubscriptionPlanGroup
              plans={plans}
              onSelectSubscriptionPlan={handlePlanChange}
              selectedPlan={selectedPlan}
              recommendedPlan={recommendedPlan}
              confirmPlanHandler={confirmPlanHandler}
              choosePlanTitle={
                !hasSubscriptions ? 'Add your payment method to get started' : choosePlanTitle
              }
              subTitle={subTitle}
              subTitleStyle={subTitleStyle}
              onCancel={onCancel}
              discount={discount}
              shouldShowFreeTrialBadge={!hasSubscriptions}
            />
          </div>
          {!!plans.length && selectedPlan && isDesktop && (
            <>
              <div>
                <SubscriptionPaymentContainer
                  plans={plans}
                  selectedPlan={selectedPlan}
                  paymentSuccessHandler={paymentSuccessHandler}
                  discount={discountCode}
                />
              </div>
            </>
          )}
        </div>
      )}

      {isPaymentModalOpen && isMobileSize && selectedPlan && (
        <DialogComponent
          open={isPaymentModalOpen}
          onCancel={closePaymentModalHandler}
          height="h-auto"
          width="w-[95vw] sm:w-[480px]"
          modalClasses={`sm:p-5 rounded-lg`}
        >
          <div className="sm:px-6">
            <div className="absolute right-2 top-2 flex justify-end pr-[16px] ">
              <Button variant="icon" icon={CloseIcon} onPress={closePaymentModalHandler} />
            </div>
            <div className="mx-auto flex max-w-[420px] flex-col items-center py-12">
              <SubscriptionPaymentContainer
                plans={plans}
                selectedPlan={selectedPlan}
                paymentSuccessHandler={data => {
                  paymentSuccessHandler(data);
                  closePaymentModalHandler();
                }}
                discount={discountCode}
              />
            </div>
          </div>
        </DialogComponent>
      )}
    </>
  );
};

export default UpgradeSubscriptionPlan;
