import { MutationOptions, useMutation } from '@tanstack/react-query';

import { patchCall } from '@/api/axios';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';
import { mutationEndpoints } from './endpoints';

const useEditCompanyLevelDeduction = ({
  companyId,
  deductionId,
  mutationParams,
}: {
  companyId: number;
  deductionId: number;
  mutationParams?: MutationOptions;
}) => {
  const { mutationKey, url, invalidateKeys } = mutationEndpoints.all(companyId, deductionId);
  const { onSuccess } = useQueryHandlers({
    mutationParams,
    invalidateKeys,
  });

  return useMutation({
    mutationKey,
    mutationFn: data => patchCall(url, data),
    ...mutationParams,
    onSuccess,
  });
};

export default useEditCompanyLevelDeduction;
