import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { EditPasswordSchema } from '@/utils/validation';

const useEditPasswordDrawer = ({
  handlePasswordChange: updatePasswordChange,
  onClose,
  isOpen,
}: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFormHasUnsavedChanges, setIsFormHasUnsavedChanges] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const form = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPassword2: '',
    },
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(EditPasswordSchema),
  });

  const { reset } = form;

  const handlePasswordChange = (data: any) => {
    updatePasswordChange(data);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { isDirty } = form.formState;

  useEffect(() => {
    if (isFormHasUnsavedChanges !== isDirty) {
      setIsFormHasUnsavedChanges(isDirty);
    }
  }, [isDirty, isFormHasUnsavedChanges]);

  useEffect(() => {
    reset({
      currentPassword: '',
      newPassword: '',
      newPassword2: '',
    });
  }, [isOpen]);

  const drawerCloseHandler = () => {
    if (isFormHasUnsavedChanges) {
      setIsConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  const confirmUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
    onClose();
  };

  const cancelUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
  };

  return {
    form,
    isDirty,
    handlePasswordChange,
    handleShowPassword,
    showPassword,
    drawerCloseHandler,
    confirmUnsavedChangesHandler,
    cancelUnsavedChangesHandler,
    isConfirmationModalOpen,
  };
};
export default useEditPasswordDrawer;
