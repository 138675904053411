import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button/Button';

import { twMerge } from 'tailwind-merge';
import { UrgentModalType } from './types';

export const UrgentModal = ({
  onClose,
  onConfirm,
  icon,
  title,
  subTitle,
  confirmButtonText,
  isConfirmLoading,
  closeButtonText,
  onCancel,
  buttonWarperClasses,
  isPrimaryButton,
  isWarning,
  contactSupportButton
}: UrgentModalType) => {
  const { t } = useTranslation();
  return (
    <div className="relative flex flex-col items-center justify-center p-6 text-center">
      <div
        className={twMerge(
          'flex size-12 items-center justify-center rounded-[28px] border-8 border-red-50 bg-red-100',
          isWarning ? 'border-yellow-50 bg-yellow-100' : '',
        )}
      >
        {icon ?? (
          <ExclamationTriangleIcon
            className={twMerge('size-6 text-red-500', isWarning ? 'text-yellow-500' : '')}
          />
        )}
      </div>
      <span className="mt-4 font-F37Bolton-Medium text-lg">{t(title)}</span>
      {subTitle && (
        <span className="mt-1 text-sm text-gray-400">
          {typeof subTitle === 'string' ? t(subTitle) : subTitle}
        </span>
      )}
      <div
        className={twMerge(
          'mt-8 flex w-full flex-col-reverse justify-center gap-3 gap-x-3 lg:flex-row',
          buttonWarperClasses ?? '',
        )}
      >
        {contactSupportButton}
        {onCancel && (
          <Button
            variant="tertiary"
            children={closeButtonText ?? 'Cancel'}
            width={`${onConfirm ? 'w-full lg:w-[170px]' : 'w-full'} `}
            parentClasses="lg:mt-0"
            onPress={onCancel}
          />
        )}
        {onConfirm && (
          <Button
            isLoading={isConfirmLoading}
            variant="primary"
            children={confirmButtonText}
            width="w-full lg:w-[170px]"
            parentClasses={twMerge(isPrimaryButton ? '' : 'bg-red-600 hover:bg-red-700')}
            onPress={onConfirm}
          />
        )}
      </div>
      <XMarkIcon
        className="absolute right-4 top-4 size-6 cursor-pointer text-gray-400"
        onClick={onClose}
      />
    </div>
  );
};
