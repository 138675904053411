import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getCall } from '../axios';
import { payrollScheduleQueryEndpoints } from './endpoints';
import { PayrollScheduleType } from './types';

const useGetPayrollSchedule = (
  companyId: number,
  queryParams?: Omit<UseQueryOptions<PayrollScheduleType, Error>, 'queryKey' | 'queryFn'>,
) => {
  const { queryKey, url } = payrollScheduleQueryEndpoints.payrollSchedule(companyId);

  return useQuery<PayrollScheduleType, Error>({
    queryKey,
    queryFn: async () => {
      const response = await getCall(url);
      return response.data;
    },
    retry: 2,
    ...queryParams,
  });
};

export default useGetPayrollSchedule;
