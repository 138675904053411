import { XMarkIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { IconCalendarMonth } from '@tabler/icons-react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { forwardRef, useCallback } from 'react';
import { Label, Text } from 'react-aria-components';
import DatePicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { Tooltip } from 'react-tooltip';
import { tv } from 'tailwind-variants';

import { extractEventName } from '@/utils/helpers';
import { customTv, twMerge } from '@/utils/tailwindMergeConfig';
import { trackEvent } from '@/utils/track';

export interface DateResponse {
  startDate: Date | null;
  endDate: Date | null;
}

export interface InputDatePickerProps {
  /**
   * The title to be displayed above the input field.
   */
  title?: string;

  /**
   * The value of the date picker, which includes startDate and endDate.
   */
  value: DateResponse | undefined;

  /**
   * Event handler for when the date changes.
   */
  onChange: (date: DateResponse) => void;

  /**
   * Marks the field as required. When true, an asterisk (*) will appear next to the title.
   * @default false
   */
  isRequired?: boolean;

  /**
   * Marks the field as optional. When true, the label will show "(optional)" next to the title.
   * @default false
   */
  isOptional?: boolean;

  /**
   * Disables the input field when true.
   * @default false
   */
  isDisabled?: boolean;

  /**
   * Controls the width of the input component. Can be a custom width class (e.g., 'w-1/2').
   */
  width?: string;

  /**
   * Additional classes to apply to the container element of the input.
   */
  className?: string;

  /**
   * An error message to display under the input field. When present, it will style the input with an error state.
   */
  errorMessage?: string;

  /**
   * Description text to display below the title.
   */
  description?: string;

  /**
   * Custom event handler that runs after the default `onChange` behavior.
   * Receives the value as an argument.
   */
  customOnChange?: (value: DateResponse) => void;

  /**
   * Additional classes to apply to the input element itself.
   */
  inputClasses?: string;

  /**
   * Text to display inside a tooltip when hovering over the information icon next to the title.
   */
  tooltipText?: string;

  /**
   * Whether the date picker should allow selecting a single date or a range.
   * @default true
   */
  isSingle?: boolean;

  /**
   * Dates to disable in the date picker.
   */
  disabledDates?: any;

  /**
   * Direction of the date picker's popover.
   */
  popoverDirection?: 'up' | 'down';

  /**
   * Maximum selectable date.
   */
  maxDate?: Date;

  /**
   * Minimum selectable date.
   */
  minDate?: Date;

  /**
   * Placeholder text for the input field.
   */
  placeholder?: string;

  /**
   * Custom class names for the date picker input.
   */
  datePickerInputClasses?: string;

  /**
   * Subtitle or additional information to display below the title.
   */
  subtitle?: string;

  /**
   * Custom class names for the label element.
   */
  labelClassName?: string;

  /**
   * Moment.js compatible value for startOf (e.g. 'day', 'month') to normalize time.
   */
  startOf?: moment.unitOfTime.StartOf;

  /**
   * Moment.js compatible value for endOf (e.g. 'day', 'month') to normalize time.
   */
  endOf?: moment.unitOfTime.StartOf;

  /**
   * Additional props to pass to the DatePicker component.
   */
  [key: string]: any;
}

const InputDatePicker = forwardRef<HTMLDivElement, InputDatePickerProps>(
  (
    {
      title,
      value,
      onChange,
      isRequired = false,
      isOptional = false,
      isDisabled = false,
      width,
      className,
      errorMessage,
      description,
      customOnChange,
      inputClasses,
      tooltipText,
      isSingle = true,
      disabledDates,
      popoverDirection,
      maxDate,
      placeholder,
      subtitle,
      labelClassName,
      datePickerInputClasses,
      ...rest
    },
    ref,
  ) => {
    const handleInputChange = useCallback(
      debounce(() => {
        trackEvent('inputChange', {
          name: rest.name,
          currentPage: extractEventName(window.location.pathname),
        });
      }, 700),
      [],
    );

    const formatDate = (
      dateString: string | Date,
      startOf?: moment.unitOfTime.StartOf,
      endOf?: moment.unitOfTime.StartOf,
    ) => {
      const m = moment(dateString);
      if (!m.isValid()) return null;

      if (startOf) return m.startOf(startOf).format('YYYY-MM-DDTHH:mm:ss');
      if (endOf) return m.endOf(endOf).format('YYYY-MM-DDTHH:mm:ss');

      return m.toISOString();
    };

    const valueChangeHandler = (date: DateValueType) => {
      handleInputChange();

      const formattedDate = {
        startDate: date?.startDate ? formatDate(date.startDate, rest.startOf, rest.endOf) : null,
        endDate: date?.endDate ? formatDate(date.endDate, rest.startOf, rest.endOf) : null,
      };

      onChange?.(formattedDate);
      customOnChange?.(formattedDate);
    };

    const inputVariants = customTv({
      base: `h-11 w-full rounded-x-small placeholder-content-body-light placeholder:text-small focus:outline-none focus:ring-0 focus:border-border-primary focus:ring-offset-0 hover:bg-surface-light-gray`,
      variants: {
        hasError: {
          true: '!border-semantic-danger-red',
          false: 'border-border-dark',
        },
        isDisabled: {
          true: 'border-border-medium text-content-body-light bg-surface-dark-gray hover:bg-surface-dark-gray',
          false: '',
        },
        hasTitle: {
          true: 'mt-x-small',
          false: '',
        },
      },
      defaultVariants: {
        hasError: false,
        isDisabled: false,
        hasTitle: false,
      },
    });

    const inputClassName = inputVariants({
      hasError: !!errorMessage,
      isDisabled,
      hasTitle: !!title,
      class: inputClasses,
    });

    return (
      <div className={twMerge(`flex flex-col ${width ? width : 'w-full'}`, className)}>
        {tooltipText && (
          <Tooltip
            place="top"
            className="z-10 max-w-60 !rounded-lg text-sm"
            id={`informational-tooltip-${rest.name}`}
            content={tooltipText}
            opacity={1}
          />
        )}
        {(title || description) && (
          <div>
            {title && (
              <Label
                className={twMerge(
                  'font-F37Bolton-Medium text-content-heading flex items-center',
                  labelClassName,
                )}
              >
                {title}
                {tooltipText && (
                  <InformationCircleIcon
                    className="text-icon-gray mx-1 size-4 cursor-pointer"
                    data-tooltip-id={`informational-tooltip-${rest.name}`}
                  />
                )}
                {isRequired && (
                  <span
                    className={twMerge(`text-semantic-danger-red ${!tooltipText ? 'ml-1' : ''}`)}
                  >
                    *
                  </span>
                )}
                {isOptional && (
                  <span className={twMerge(`text-gray-300 ${!tooltipText ? 'ml-1' : ''}`)}>
                    (optional)
                  </span>
                )}
              </Label>
            )}
            {description && <p className="text-small text-content-body-medium">{description}</p>}
          </div>
        )}

        <div className="relative h-fit">
          <DatePicker
            startWeekOn="mon"
            asSingle={isSingle}
            useRange={false}
            displayFormat="MM/DD/YYYY"
            showShortcuts={false}
            primaryColor="emerald"
            toggleIcon={e => {
              if (e) {
                return (
                  <IconCalendarMonth
                    className={`ml-2 mr-1 mt-1 size-5 text-gray-400`}
                    aria-hidden="true"
                  />
                );
              } else {
                return null;
              }
            }}
            value={value}
            onChange={valueChangeHandler}
            disabledDates={disabledDates}
            disabled={isDisabled}
            placeholder={
              placeholder ? placeholder : isSingle ? 'mm/dd/yyyy' : 'mm/dd/yyyy to mm/dd/yyyy'
            }
            inputClassName={tv({
              base: inputClassName,
              class: datePickerInputClasses,
            })}
            maxDate={maxDate}
            popoverDirection={popoverDirection}
            ref={ref as any}
            {...rest}
          />

          {value && value.startDate && (
            <XMarkIcon
              onClick={() => valueChangeHandler({ startDate: null, endDate: null })}
              className="absolute bottom-[12px] right-3 size-5 text-gray-400 hover:cursor-pointer"
              aria-hidden="true"
            />
          )}
        </div>

        {(errorMessage || subtitle) && (
          <Text
            slot="description"
            className={twMerge(
              'mt-x-small text-small',
              errorMessage ? 'text-semantic-danger-red' : 'text-content-body-medium',
            )}
          >
            {errorMessage || subtitle}
          </Text>
        )}
      </div>
    );
  },
);

InputDatePicker.displayName = 'InputDatePicker';

export default InputDatePicker;
