import { useCallback, useEffect, useState } from 'react';

import { ErrorPostWorkLocationType } from '@/modules/accountSetup/components/Company/forms/types';

import { addressFormValuesType, stateLocation } from './InputLocationSends';

type EmptyFunction = () => void;

export const useInputLocationSends = ({
  addressError,
  handleFetchCurrentFormValues = (() => {}) as EmptyFunction,
  onPlaceSelect,
  handleCloseSuggestedAddressPopup = (() => {}) as EmptyFunction,
}: {
  addressError?: ErrorPostWorkLocationType;
  handleFetchCurrentFormValues?: () => addressFormValuesType;
  onPlaceSelect: (stateLocation: stateLocation | string) => void;
  handleCloseSuggestedAddressPopup?: VoidFunction;
}) => {
  const [suggestedMsgAddress, setSuggestedMsgAddress] = useState({
    firstLine: '',
    secondLine: '',
  });
  const [currentMsgAddress, setCurrentMsgAddress] = useState({
    firstLine: '',
    secondLine: '',
  });

  useEffect(() => {
    if (addressError?.response?.data?.suggestedAlternative) {
      handleFormateCurrentAddress();
      handleFormateSuggestedAddress(addressError?.response?.data?.suggestedAlternative);
    }
    return () => {
      setSuggestedMsgAddress({
        firstLine: '',
        secondLine: '',
      });
      setCurrentMsgAddress({
        firstLine: '',
        secondLine: '',
      });
    };
  }, [addressError?.response?.data?.suggestedAlternative]);

  const handleFormateCurrentAddress = () => {
    const currentFormValues = handleFetchCurrentFormValues();

    setCurrentMsgAddress({
      firstLine: currentFormValues?.streetAddress + ' ' + currentFormValues?.aptSteNumber || ', ',
      secondLine:
        currentFormValues?.city +
          ', ' +
          currentFormValues?.state +
          ' ' +
          currentFormValues?.zipCode || '',
    });
  };

  const handleFormateSuggestedAddress = useCallback(
    (item: any) => {
      setSuggestedMsgAddress({
        firstLine: item?.line1 + ' ' + (item?.line2 || ' ') || ', ',
        secondLine: item?.city + ', ' + item?.state + ' ' + item?.postal_code || '',
      });
    },
    [addressError],
  );

  const handleSetUpdatedAddress = () => {
    const correctAddress = addressError?.response?.data?.suggestedAlternative;
    onPlaceSelect({
      streetAddress: correctAddress.line1 || '',
      aptSteNumber: correctAddress.line2 || '',
      city: correctAddress.city,
      state: correctAddress.state,
      zipCode: correctAddress.postal_code,
      entries: correctAddress.entries,
      description: `${correctAddress.line1} ${correctAddress.line2} ${correctAddress.city}, ${correctAddress.state} ${correctAddress.postal_code}`,
    });
    handleCloseSuggestedAddressPopup();
  };

  return {
    currentMsgAddress,
    setCurrentMsgAddress,
    suggestedMsgAddress,
    setSuggestedMsgAddress,
    handleFormateCurrentAddress,
    handleSetUpdatedAddress,
  };
};
