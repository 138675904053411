import { PayrollHistoryStatusCard } from '@/components';
import useEmployeePayrollHistory from './useEmployeePayrollHistory';

const EmployeePayrollHistory = () => {
  const { payrollHistoryCardDetail, manualPayHistoryStatus } = useEmployeePayrollHistory();

  return <PayrollHistoryStatusCard {...payrollHistoryCardDetail[manualPayHistoryStatus]} />;
};

export default EmployeePayrollHistory;
