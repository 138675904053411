import { IconX } from '@tabler/icons-react';

import { ReactComponent as Illustration } from '@/assets/illustration/explanationIllustration.svg';
import { Button } from '@/components/ui/designSystem/Button/Button';
import { Separator } from '@/components/ui/Separator/Separator';

type ExplanationModalComponentType = {
  onClose: () => void;
};

export const ExplanationModalComponent = ({ onClose }: ExplanationModalComponentType) => {
  return (
    <div className="p-x-large relative flex flex-col items-center justify-center">
      <Illustration />
      <span className="text-x-large font-F37Bolton-Bold mt-large mb-x-small">
        Your account is being verified
      </span>
      <span className="text-content-body-medium text-small text-center">
        As part of our onboarding process, BlinkPayroll’s team conducts a company assessment to
        verify business details and meet regulatory requirements. In some cases, we may request
        additional documents to confirm your business operations. This process may take up to 3
        business days.
      </span>
      <span className="mt-x-large text-content-body-medium text-small text-center">
        This also helps us determine if your company qualifies for faster payroll processing. If we
        need anything from you, we’ll reach out directly. Thanks for your patience.
      </span>
      <Separator type="light" thickness="1px" className="my-x-large" />
      <span className="text-small text-content-body-medium mb-x-small">
        If you have any questions, please reach out to us
      </span>
      <Button
        variant="tertiary"
        children="Contact support"
        onPress={() => window.fcWidget.open()}
      />
      <IconX
        className="top-x-large right-x-large text-icon-gray size-x-large absolute cursor-pointer"
        onClick={onClose}
      />
    </div>
  );
};
