import { useNavigate } from 'react-router-dom';

import { ReactComponent as DocumentSVG } from '@/assets/illustration/document.svg';
import Button from '@/components/Button/Button';
import { useAccountInfo } from '@/hooks';
import * as routes from '@/routes/routes';

import { EMPLOYEE_MANUAL_PAY_HISTORY_STATUS } from './utils/constants';

const useEmployeePayrollHistory = () => {
  const navigate = useNavigate();

  const { company } = useAccountInfo();

  const { manualPayrollHistoriesStatus } = company ?? {};

  const handleNavigate = () => {
    navigate(routes.PAYROLL_HISTORY_SETUP);
  };

  const pendingDetail = {
    cardImage: <DocumentSVG />,
    actions: (
      <div className="flex  flex-col gap-[14px] pt-5 md:flex-row">
        <Button
          variant="primary"
          parentClasses="!w-full  md:!w-fit  px-[31px]"
          onPress={() => handleNavigate()}
        >
          Set Up Payroll History
        </Button>
      </div>
    ),
    title: "Let's set your employees' pay history",
    subTitle:
      'Tell us how much you’ve paid your employees so far this year so we can accurately calculate their upcoming payroll. ',
  };

  const inProgressDetail = {
    cardImage: <DocumentSVG />,
    title: "Finish setting up your employee's pay history",
    subTitle:
      'You have not finished setting up payroll history for all of your employees, complete it to run your first payroll',
    actions: (
      <div className="flex  flex-col gap-[14px] pt-5 md:flex-row">
        <Button
          variant="primary"
          parentClasses="!w-full  md:!w-fit  px-[31px]"
          onPress={() => handleNavigate()}
        >
          Set up payroll history
        </Button>
      </div>
    ),
  };

  const payrollHistoryCardDetail: any = {
    pending: pendingDetail,
    inProgress: inProgressDetail,
  };

  const formattedPayHistorySetup =
    manualPayrollHistoriesStatus === EMPLOYEE_MANUAL_PAY_HISTORY_STATUS.pending
      ? 'pending'
      : 'inProgress';

  return {
    manualPayHistoryStatus: formattedPayHistorySetup,
    handleNavigate,
    payrollHistoryCardDetail,
  };
};

export default useEmployeePayrollHistory;
