import { CreditCardIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import ContactSupportPopover from '@/components/shared/ContactSupportPopover/ContactSupportPopover';
import { PaymentMethodModal } from '@/components/shared/PaymentMethodModal/PaymentMethodModal';
import { PlanCard } from '@/components/shared/PlanCard/PlanCard';
import { PLANS } from '@/components/shared/SubscriptionBillingCard/utils/constants';
import SubscriptionPlan from '@/components/shared/UpgradeSubscriptionPlan/SubscriptionPlan/SubscriptionPlan';
import { Button } from '@/components/ui/designSystem/Button/Button';
import { useBillingInfo } from '@/hooks';
import usePlans from '@/hooks/usePlans/usePlans';
import { SubscriptionAgreement } from '@/redux/dto/auth';
import { AVAILABLE_SUBSCRIPTIONS } from '@/utils/constants';

import { SubscriptionPlanType } from '../../../UpgradeSubscriptionPlan/types';

type SubscriptionPlanGroupProps = {
  plans: SubscriptionPlanType[];
  selectedPlan: string | null;
  recommendedPlan: string | null;
  onSelectSubscriptionPlan: (plan: string) => void;
  onConfirmPlan: () => void;
  isPending: boolean;
  openCancelModalHandler: () => void;
  activeSubscriptionAgreement: SubscriptionAgreement;
  onCancel: () => void;
};

const SubscriptionPlanGroup = ({
  plans,
  selectedPlan,
  onSelectSubscriptionPlan,
  recommendedPlan,
  onConfirmPlan,
  isPending,
  openCancelModalHandler,
  activeSubscriptionAgreement,
  onCancel,
  discount,
}: SubscriptionPlanGroupProps) => {
  const [isPaymentStepOpen, setIsPaymentStepOpen] = useState<boolean>(false);
  const { availableProducts } = usePlans();
  const { billingInfo, refetchBillingInfo } = useBillingInfo();
  const isOldPlan = Object.values(AVAILABLE_SUBSCRIPTIONS).includes(
    activeSubscriptionAgreement?.plan,
  );

  return (
    <div className="flex h-full flex-col justify-center gap-5">
      <div className="flex flex-col items-center">
        <div className="rounded-full bg-emerald-50 p-2">
          <div className="rounded-[28px] bg-emerald-100 p-2">
            <CreditCardIcon className="size-6 rounded-[28px] text-emerald-500" />
          </div>
        </div>
        <span className="font-F37Bolton-Medium mt-4 text-[24px]">Manage your subscription</span>
      </div>

      <div className="flex items-center justify-center gap-3">
        {isOldPlan && (
          <div className="gap-y-medium flex flex-col items-center">
            <SubscriptionPlan
              key={activeSubscriptionAgreement?.plan}
              isSelected={true}
              amount={activeSubscriptionAgreement?.price.amount}
              planName={activeSubscriptionAgreement?.plan + ' ' + 'Plan'}
              pricingStrategy={'monthly'}
              onSelectSubscriptionPlan={() => null}
              discount={billingInfo?.activeSubscriptionAgreement?.discountCodes[0]?.discount}
              {...activeSubscriptionAgreement?.plan}
            />
            <PlanCard
              plans={plans}
              currentPlan={PLANS.basic}
              onSubscribe={() => setIsPaymentStepOpen(true)}
              discount={discount}
              isUpgrade
            />
            <Button
              variant="link"
              children="Cancel subscription"
              className="text-semantic-danger-red text-small"
              onPress={openCancelModalHandler}
            />
          </div>
        )}
        {activeSubscriptionAgreement?.plan === PLANS['full-service-payroll'] && (
          <PlanCard
            plans={plans}
            currentPlan={activeSubscriptionAgreement.plan}
            discount={activeSubscriptionAgreement?.discountCodes[0]?.discount}
          />
        )}
        {activeSubscriptionAgreement?.plan === PLANS.basic && (
          <PlanCard
            plans={plans}
            currentPlan={activeSubscriptionAgreement.plan}
            onCancel={openCancelModalHandler}
            discount={discount}
          />
        )}
      </div>

      {activeSubscriptionAgreement.plan === PLANS['full-service-payroll'] && (
        <div className="flex items-center justify-center">
          <span className="text-content-body-medium text-small">
            <ContactSupportPopover
              accessibleComponent={
                <Button
                  variant="link"
                  size="x-small"
                  className="inline-block cursor-pointer text-sm text-gray-400 underline underline-offset-2"
                >
                  Contact support
                </Button>
              }
              placement="top"
            />{' '}
            to request account termination
          </span>
        </div>
      )}

      <PaymentMethodModal
        isOpen={isPaymentStepOpen}
        onCancel={() => setIsPaymentStepOpen(false)}
        availableProducts={availableProducts}
        parentOnCancel={() => {
          refetchBillingInfo();
          onCancel();
        }}
      />
    </div>
  );
};

export default SubscriptionPlanGroup;
