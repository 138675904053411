import { forwardRef } from 'react';
import { FormProvider } from 'react-hook-form';

import Button from '@/components/Button/Button';
import { FormInputDecimalsField } from '@/components/ui';
import FormInputCheckboxField from '@/components/ui/FormInputCheckboxField/FormInputCheckboxField';

import ContactSupportButton from '../../ContactSupportButton';
import EmployerTaxInputItemForm from './EmployerTaxInputItemForm';
import { EmployerTaxItemFormProps } from './types';
import useEmployerTaxItemForm from './useEmployerTaxItemForm';

const EmployerTaxItemForm = forwardRef(
  (
    {
      taxItem,
      shouldShowAllFields,
      onSubmit,
      isSavingTaxInformation,
      isLastStep = true,
      isFederalTaxItem,
      shouldShowSupportLink,
      hideSubmitButton = false,
      handleIsDirty,
    }: EmployerTaxItemFormProps,
    ref,
  ) => {
    const {
      formattedTitle,
      taxFormFields,
      rateTaxFieldIndex,
      form,
      isExempt,
      SubmitBtnHandler,
      formattedInfo,
    } = useEmployerTaxItemForm({
      taxItem,
      shouldShowAllFields,
      onSubmit,
      isFederalTaxItem,
      ref,
      handleIsDirty,
    });

    return (
      <div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(SubmitBtnHandler)}>
            <div>
              <h3 className="font-F37Bolton-Medium text-2xl">{formattedTitle}</h3>

              <div className="mt-2 text-sm text-gray-400">{formattedInfo}</div>

              {!!taxFormFields[rateTaxFieldIndex] && (
                <div className="mt-3">
                  <FormInputDecimalsField
                    rules={{
                      required: `Please fill in this ${taxFormFields[rateTaxFieldIndex].description}`,
                      max: {
                        value: 100,
                        message: `${taxFormFields[rateTaxFieldIndex].description} value is invalid`,
                      },
                      ...(!isExempt
                        ? {
                            validate: {
                              min: value => {
                                if (Number(value) <= 0) {
                                  return `If your rate is 0%, please mark that you're exempt from this tax.`;
                                }
                              },
                            },
                          }
                        : {}),
                    }}
                    name={`fields[${rateTaxFieldIndex}].value`}
                    title={isFederalTaxItem ? 'FUTA (Federal Unemployment Tax)' : ''}
                    isRequired
                    InputAdornment={'%'}
                    allowNegativeValue={false}
                    decimalsLimit={4}
                    placeholder="0.00"
                    step={0.01}
                    decimalSeparator="."
                    min={0}
                    max={100}
                    handleValueCheck={value =>
                      Number(value) >= 0 && Number(value) <= 100 ? value : null
                    }
                    isDisabled={isExempt}
                  />
                  {/**
                   * Display Contact Support team message to Exempt form the FUTA (Federal Unemployment tax)
                   */}
                  {isFederalTaxItem ? (
                    <p className="mt-2 text-sm text-gray-400">
                      If you believe you&apos;re exempt from FUTA, please
                      {shouldShowSupportLink ? (
                        <ContactSupportButton className=" inline-block text-sm  lowercase text-[#0075FF] no-underline" />
                      ) : (
                        'contact support'
                      )}{' '}
                      team.
                    </p>
                  ) : (
                    <FormInputCheckboxField
                      name="isExempt"
                      customOnChange={e => {
                        if (e.target.checked) {
                          const fieldName = `fields[${rateTaxFieldIndex}].value`;
                          form.setValue(fieldName, null);
                          form.clearErrors(fieldName);
                        }
                      }}
                      label={<p className="text-sm">I am exempt from this tax</p>}
                      parentClasses="mt-2"
                      inputClasses="mt-0"
                    />
                  )}
                </div>
              )}
              <div className="mt-5 flex flex-col gap-3">
                {!isExempt &&
                  !isFederalTaxItem &&
                  taxFormFields.map((field, index) => {
                    if (field.name !== 'RATE') {
                      return (
                        <EmployerTaxInputItemForm
                          key={field.name}
                          field={field}
                          fieldIndex={index}
                          form={form}
                        />
                      );
                    }
                    return null;
                  })}
              </div>

              {!hideSubmitButton && (
                <div className="mt-5">
                  <Button
                    variant="primary"
                    type="submit"
                    width="w-full"
                    isLoading={isSavingTaxInformation}
                  >
                    {isLastStep ? 'Save changes' : 'Next'}
                  </Button>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    );
  },
);

EmployerTaxItemForm.displayName = 'EmployerTaxItemForm';

export default EmployerTaxItemForm;
