import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountInfo } from '@/hooks';
import { PAYROLL_SETTINGS_STEP_IDS } from '@/modules/PayrollSettings/utils/constants';
import * as routes from '@/routes/routes';

export const usePtoAnnounecement = () => {
  const navigate = useNavigate();

  const { account, patchAccount } = useAccountInfo();

  const metaData = account?.metadata ? account.metadata : {};
  const [componentWidth, setComponentWidth] = useState<number | null>(null);
  const componentRef = useRef(null);

  useEffect(() => {
    if (componentRef.current) {
      const width = componentRef.current.offsetWidth;

      setComponentWidth(width);
    }
  }, []);

  const handleClose = async () => {
    const data = {
      metadata: { ...metaData, isPtoAnnouncementClosed: true },
    };
    try {
      await patchAccount(data);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleRedirect = () => {
    return navigate(routes.PAYROLL_SETTINGS, {
      state: { initialStepId: PAYROLL_SETTINGS_STEP_IDS.payTypes },
    });
  };

  return { componentWidth, handleClose, componentRef, handleRedirect };
};
