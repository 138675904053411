import { SetStateAction, useState } from 'react';
import { PopupModal } from 'react-calendly';
import { tv } from 'tailwind-variants';

import announcementImage from '@/assets/announcement-full-service.png';
import { DialogComponent } from '@/components';
import { LINKS } from '@/utils/constants';

import { Button } from '../designSystem/Button/Button';

const styles = {
  container: tv({ base: 'flex flex-col items-center p-6' }),
  title: tv({ base: 'text-2x-large text-content-heading font-F37Bolton-Bold text-center' }),
  description: tv({ base: 'text-medium mb-6 mt-4 text-center' }),
  offerBadge: tv({
    base: 'text-small bg-primary-emerald-light-bg text-primary-emerald py-2x-small px-x-small border-primary-emerald rounded-2x-small inline-block border border-dashed',
  }),
  button: tv({ base: 'text-large mt-6' }),
  modal: tv({ base: 'w-auto md:w-[480px] mx-5' }),
  closeButton: tv({ base: 'absolute top-4 right-4' }),
};

const AnnouncementCard = ({
  setIsCalendlyOpen,
}: {
  setIsCalendlyOpen: React.Dispatch<SetStateAction<boolean>>;
}) => (
  <div>
    <img src={announcementImage} alt="announcement" />
    <div className={styles.container()}>
      <h1 className={styles.title()}>Introducing Full-Service Payroll 🎉</h1>
      <p className={styles.description()}>
        We handle everything. Payroll and taxes, fully automated. Simplify your payroll process and
        ensure you’re always tax compliant.
      </p>
      <span className={styles.offerBadge()}>50% off for the next 6 months</span>
      <Button variant="primary" className={styles.button()} onPress={() => setIsCalendlyOpen(true)}>
        Schedule a call now
      </Button>
    </div>
  </div>
);

const Announcement = ({
  isAnnouncementModalOpen,
  onCancel,
}: {
  isAnnouncementModalOpen: boolean;
  onCancel: () => void;
}) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  return (
    <>
      <DialogComponent
        height="auto"
        open={isAnnouncementModalOpen}
        onCancel={onCancel}
        showCloseIcon
        closeButtonClass={styles.closeButton()}
        modalClasses={styles.modal()}
      >
        <AnnouncementCard setIsCalendlyOpen={() => setIsCalendlyOpen(true)} />
      </DialogComponent>

      <PopupModal
        url={LINKS.calendlySupport}
        rootElement={document.body}
        open={isCalendlyOpen}
        onModalClose={() => setIsCalendlyOpen(false)}
      />
    </>
  );
};

export default Announcement;
