import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui';
import { isArrayHasData } from '@/utils/helpers';

import EmployeeDeductionRow from './components/EmployeeDeductionRow/EmployeeDeductionRow';
import { EmployeeDeductionTableProps, employeeInfo } from './useEmployeeDeductionTable';

const EmployeeDeductionTable = ({
  employeesData,
  deductionInfo,
  refetchDeductionList,
}: EmployeeDeductionTableProps) => {
  return (
    <div className="rounded-lg border border-gray-100">
      <Table>
        <TableHeader>
          <TableRow className="text-left text-sm text-gray-400">
            <TableHead className="!px-4 py-3">Employee name</TableHead>
            <TableHead className="!px-4 py-3">Employee contribution</TableHead>
            <TableHead className="!px-4 py-3">Company contribution</TableHead>
            <TableHead className="!px-4 py-3">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isArrayHasData(employeesData) &&
            employeesData.map((employee: employeeInfo, idx: number) => (
              <TableRow key={idx} className="text-left font-normal hover:bg-gray-50">
                <EmployeeDeductionRow
                  employeeData={employee}
                  deductionData={deductionInfo}
                  refetchDeduction={refetchDeductionList}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default EmployeeDeductionTable;
