type CodeMessages = {
  [key: string]: {
    title: string;
    subtitle: string;
  };
};

export const codeMessageMap: CodeMessages = {
  BLINK20: {
    title: 'Get 20% off for 12 months!',
    subtitle: 'Limited time offer, don’t miss out.',
  },
  BLINK50: {
    title: 'Get 50% off on Full Service Payroll',
    subtitle: 'Limited time offer, don’t miss out',
  },
  'FULLSERVICE50-6': {
    title: 'Get 50% off for 6 months',
    subtitle: 'Limited time offer, don’t miss out',
  },
  DEFAULT: {
    title: 'Get 50% off on Full Service Payroll',
    subtitle: 'Limited time offer, don’t miss out',
  },
};
