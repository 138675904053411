import { createModel } from '@rematch/core';

import type { RootModel } from '.';

type authState = {
  isPopoverOpen: boolean;
};

export const utility = createModel<RootModel>()({
  state: {
    isPopoverOpen: false,
  } as unknown as authState,
  reducers: {
    SET_IS_OPEN_HELP_POPOVER: (state: authState) => {
      return {
        ...state,
        isPopoverOpen: !state.isPopoverOpen,
      };
    },
    CLOSE_HELP_POPOVER: (state: authState) => {
      return {
        ...state,
        isPopoverOpen: false,
      };
    },
  },
});
