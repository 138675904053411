import * as Yup from 'yup';

import { DEDUCTION_TYPES_NAME } from '@/utils/constants';

const createRequiredSchema = ({
  type,
  message,
  isCheckHqUser,
}: {
  type: string;
  message: string;
  isCheckHqUser?: boolean;
}) => {
  if (isCheckHqUser && type === DEDUCTION_TYPES_NAME.garnishment) {
    return Yup.string().notRequired();
  }

  return Yup.string().when('type', {
    is: (val: string) => [type].includes(val),
    then: schema => schema.required(message),
    otherwise: schema => schema.notRequired(),
  });
};

export const DeductionFormSchema = (isCheckHqUser?: boolean) =>
  Yup.object().shape({
    benefitType: createRequiredSchema({
      type: DEDUCTION_TYPES_NAME.benefit,
      message: 'Benefit Type is Required',
    }),
    garnishmentType: createRequiredSchema({
      type: DEDUCTION_TYPES_NAME.garnishment,
      message: 'Garnishment Type is Required',
    }),
    benefitTitle: createRequiredSchema({
      type: DEDUCTION_TYPES_NAME.benefit,
      message: 'Benefit Title is Required',
    }),
    garnishmentTitle: createRequiredSchema({
      type: DEDUCTION_TYPES_NAME.garnishment,
      message: 'Garnishment Title is Required',
      isCheckHqUser: isCheckHqUser,
    }),
    customPostTaxDeductionTitle: createRequiredSchema({
      type: DEDUCTION_TYPES_NAME.custom,
      message: 'Custom post-tax deductions Title is Required',
    }),
    effectiveStart: isCheckHqUser
      ? createRequiredSchema({
          type: DEDUCTION_TYPES_NAME.benefit,
          message: 'Effective start date is required',
        })
      : Yup.string().notRequired(),
  });
