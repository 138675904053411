import { FC } from 'react';
import { MenuItem as ReactAriaMenuItem } from 'react-aria-components';
import { Tooltip } from 'react-tooltip';

import { AppMenuItemProps } from './types';

const MenuItem: FC<AppMenuItemProps> = ({
  children,
  startIcon,
  tooltipContent,
  tooltipId,
  ...rest
}) => {
  return (
    <ReactAriaMenuItem {...rest} data-tooltip-id={tooltipId}>
      <>
        {tooltipContent && (
          <Tooltip
            place="top"
            className="!bg-navy-500 !text-small z-[3] !w-auto max-w-[225px] !rounded-lg font-normal"
            id="receipt-tooltip"
            content={tooltipContent}
            opacity={1}
          />
        )}
        {startIcon}
        {children}
      </>
    </ReactAriaMenuItem>
  );
};

export default MenuItem;
