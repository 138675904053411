export const ContributionCalculationType = [
  { name: 'Fixed amount per pay period', value: 1 },
  { name: 'Percentage of gross pay', value: 2 },
];

export const CheckHQContributionCalculationType = [
  { name: 'Fixed amount', value: 1 },
  { name: 'Percentage of gross pay', value: 2 },
];

export const effectivePayPeriodType = [
  { name: 'Per pay check', value: 'pay_period' },
  { name: 'Monthly', value: 'monthly' },
];

export const hsaContributionLimitOptions = [
  { name: 'Single', value: 1 },
  { name: 'Family', value: 2 },
];
