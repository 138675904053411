import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';
import EmployerTaxItemModal from '@/components/shared/EmployerTaxItemModal/EmployerTaxItemModal';

import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { TAX_ITEM_VIEW_MODE } from './constants';
import { EmployerTaxContentProps } from './types';
import useEmployerTaxContent from './useEmployerTaxContent';

const EmployerTaxContent = ({
  id,
  title,
  value,
  taxFields,
  currentTab,
  stepperId,
  onSubmit,
}: EmployerTaxContentProps) => {
  const {
    taxItemModalOpen,
    openTaxItemModalHandler,
    onCancelTaxItemModal,
    onSubmitTaxItem,
    onTaxItemTitleClickHandler,
  } = useEmployerTaxContent({ onSubmit, stepperId, currentTab });

  return (
    <div>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="mt-1 size-6 self-start">
            <CheckCircleIcon
              className={twMerge(
                `size-5`,
                currentTab > stepperId ? 'text-emerald-500' : 'text-gray-100',
              )}
            />
          </div>
          <div className="font-F37Bolton-Medium gap-x-small flex break-words">
            <span className={`${currentTab > stepperId ? 'text-content-heading' : ''} `}>
              {title}

              <span
                role="button"
                className="pl-x-small h-fit text-emerald-500"
                onClick={onTaxItemTitleClickHandler}
              >
                Edit
              </span>
            </span>
          </div>
        </div>

        {currentTab === stepperId ? (
          <div>
            <Button
              variant="primary"
              parentClasses="px-7 py-2 h-auto w-auto text-sm"
              onPress={() => {
                openTaxItemModalHandler(TAX_ITEM_VIEW_MODE.add);
              }}
            >
              Add
            </Button>
          </div>
        ) : (
          value !== null && <div className="text-nowrap py-1">{value}</div>
        )}
      </div>

      {!!taxFields.length && (
        <div className="ml-[30px] mt-2 flex flex-col gap-2">
          {taxFields.map(({ title, value, id }) => {
            return (
              <div className="flex w-full items-center justify-between" key={id}>
                <p className="break-words text-gray-400">{title}</p>
                {value !== null && <div className="text-nowrap py-1">{value}</div>}
              </div>
            );
          })}
        </div>
      )}

      {taxItemModalOpen.isOpen && (
        <EmployerTaxItemModal
          isOpen={taxItemModalOpen.isOpen}
          taxItemId={id}
          onCancel={() => onCancelTaxItemModal()}
          shouldShowAllFields={taxItemModalOpen.mode === TAX_ITEM_VIEW_MODE.edit}
          onSubmit={() => onSubmitTaxItem()}
        />
      )}
    </div>
  );
};

export default EmployerTaxContent;
