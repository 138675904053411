import Button from '@/components/Button/Button';
import EmployeeDeductionDrawer from '@/components/shared/EmployeeDeductionDrawer/EmployeeDeductionDrawer';

import useEmployeeDeductionCard, { EmployeeDeductionCardProps } from './useEmployeeDeductionCard';

const EmployeeDeductionCard = ({
  employeeData,
  deductionData,
  isShowingEdit,
}: EmployeeDeductionCardProps) => {
  const { handleDeductionDrawer, isDeductionDrawerOpen } = useEmployeeDeductionCard();
  return (
    <>
      {' '}
      <div className="flex flex-col rounded-lg border border-gray-100 p-4 font-normal">
        <div className="mb-4 flex justify-between border-b border-gray-100 pb-4">
          <div className="font-F37Bolton-Medium text-lg">{employeeData.name}</div>
          {isShowingEdit && (
            <div>
              <Button
                variant="tertiary"
                children="Edit"
                parentClasses="w-fit h-fit text-sm py-2 px-3"
                onPress={handleDeductionDrawer}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="text-gray-400">Employee Contribution</div>
            <div>{employeeData.employeeContribution}</div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-400">Company Contribution</div>
            <div>{employeeData.companyContribution}</div>
          </div>
        </div>
      </div>
      {isDeductionDrawerOpen && (
        <EmployeeDeductionDrawer
          data={deductionData}
          isEditedInPayrollSettings={true}
          isEdit={true}
          isOpen={isDeductionDrawerOpen}
          onClose={handleDeductionDrawer}
          employeeId={employeeData.id}
          employeeName={employeeData?.firstName}
        />
      )}
    </>
  );
};
export default EmployeeDeductionCard;
