import moment from 'moment';
import { NumericFormat } from 'react-number-format';

import { StatusBadge } from '@/components';
import { PAYROLL_TYPE_TO_NAME_MAP } from '@/modules/runPayroll/utils/constants';
import { statusSeverityMap } from '@/utils/statusSeverity';

import PayrollHistoryCardLink from './PayrollHistoryCardLink';

const PayrollHistoryCardListing = ({ payrollHistory, historyStatus, isCheckHqUser }: any) => {
  return (
    <>
      {Array.isArray(payrollHistory) &&
        payrollHistory?.map((item, index) => {
          const statusInfo = historyStatus[item.payments[0]?.status ?? 0];

          const payPeriod =
            item.payPeriodStartDate && item.payPeriodEndDate
              ? moment(item.payPeriodStartDate).format('MMM DD') +
                ' - ' +
                moment(item.payPeriodEndDate).format('MMM DD, YYYY')
              : '--';

          const formattedPayDate =
            item.payDate && moment(item.payDate).isValid()
              ? moment(item.payDate).format('MMM DD,YYYY')
              : '-';

          const cashTipsValue =
            item?.payments?.reduce((sum, payment) => sum + (payment.cashTips || 0), 0) ?? 0;

          const totalPayroll = isCheckHqUser
            ? item?.checkHQCalculatedData?.totals?.liability - cashTipsValue
            : item.totalPayroll;

          return (
            <div key={index} className="rounded-xl border border-gray-100">
              <div>
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <span className="bg-navy-500 font-F37Bolton-Regular text-x-small px-x-small py-2x-small rounded leading-5 text-white">
                      {PAYROLL_TYPE_TO_NAME_MAP[item.type]} Payroll
                    </span>
                    <StatusBadge
                      text={statusInfo.label}
                      severity={statusSeverityMap[statusInfo?.variant]}
                    />
                  </div>
                  <h5 className="mt-3 text-xl text-gray-900">{payPeriod}</h5>
                </div>
                <div className="flex items-center justify-between border-y border-gray-100 p-4">
                  <span className="text-sm text-gray-400">Pay date</span>
                  <span className="font-F37Bolton-Medium">{formattedPayDate}</span>
                </div>
                <div className="flex items-center justify-between p-4">
                  <span className="text-sm text-gray-400">Payroll cost</span>
                  <span>
                    <NumericFormat
                      value={isNaN(totalPayroll) ? 0 : totalPayroll}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={value => (
                        <span className="font-F37Bolton-Medium text-base text-gray-900">
                          ${value}
                        </span>
                      )}
                    />
                  </span>
                </div>
                <PayrollHistoryCardLink
                  payrollId={item?.id}
                  payrollType={item.type}
                  status={item?.status}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PayrollHistoryCardListing;
