import { MutationEndpoint, QueryEndpoint } from '../types';

export const queryEndpoints = {
  runnableActiveEmployee: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'run-payroll', 'runnable-active-employee'],
    url: `/company/${companyId}/pay-employee/get-active`,
  }),

  getViewPayStubDetail: (
    companyId: number,
    employeeId: number,
    payslipId: number,
  ): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'preview-pay-stubs',
      'employee',
      employeeId,
      'payslip',
      payslipId,
    ],
    url: `/company/${companyId}/employee/${employeeId}/preview-pay-stubs/${payslipId}`,
  }),
  getViewPayStubDetailContractor: (
    companyId: number,
    contractorId: number,
    payslipId: number,
  ): QueryEndpoint => ({
    queryKey: [
      'company',
      companyId,
      'preview-pay-stubs',
      'contractor',
      contractorId,
      'payslip',
      payslipId,
    ],
    url: `/company/${companyId}/contractor/${contractorId}/preview-pay-stubs/${payslipId}`,
  }),
};

export const mutationEndpoints = {
  sharePayroll: (companyId: number): MutationEndpoint => ({
    mutationKey: [],
    url: `company/${companyId}/employee-paystubs/share`,
    invalidateKeys: [],
  }),
};
