import { useState } from 'react';

import { DeductionTypes } from '@/redux/dto/companyLevelDeduction';

type employeeInfo = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  employeeContribution: string;
  companyContribution: string;
};

export type EmployeeDeductionRowProps = {
  employeeData: employeeInfo;
  deductionData: DeductionTypes;
  refetchDeduction: () => void;
};
type UseEmployeeDeductionRowType = { refetchDeduction: () => void };

const useEmployeeDeductionTable = ({ refetchDeduction }: UseEmployeeDeductionRowType) => {
  const [isDeductionDrawerOpen, setIsDeductionDrawerOpen] = useState<boolean>(false);
  const handleDeductionDrawer = () => setIsDeductionDrawerOpen(!isDeductionDrawerOpen);
  const onSussesHandler = () => {
    refetchDeduction();
  };
  return { handleDeductionDrawer, isDeductionDrawerOpen, onSussesHandler };
};
export default useEmployeeDeductionTable;
