/* eslint-disable @typescript-eslint/no-unused-vars */

import { PencilIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { LoaderComponent } from '@/components';
import Button from '@/components/Button/Button';
import ConfirmEmployerTax from '@/components/shared/ConfirmEmployerTax/ConfirmEmployerTax';
import { schedules } from '@/utils/constants';

import PayrollSchedule from '../payrollSchedule';
import useRunPayroll from './useRunPayroll';

const schedule = [
  schedules.WEEKLY.label,
  schedules.BI_WEEKLY.label,
  schedules.SEMI_MONTHLY.label,
  schedules.MONTHLY.label,
  schedules.QUARTERLY.label,
];

const RunPayroll: React.FC = () => {
  const {
    isPayrollScheduleOpen,
    setIsPayrollScheduleOpen,
    renderContent,
    isLoading,
    isOpenFillMissedTax,
    setisOpenFillMissedTax,
    refetchWarning,
    scheduleData,
    handlePayrollSchedule,
    isEdit,
    setIsEdit,
    setStep,
    step,
  } = useRunPayroll();

  return (
    <div className="flex w-full max-w-[1130px] flex-col gap-5 px-5 py-8 lg:flex-row lg:gap-0 lg:p-8">
      <div className={`flex w-full bg-white`}>
        <div className="gap-2x-large relative flex w-full flex-col">
          <div className="justify-between md:flex">
            <div>
              <h2 className=" font-F37Bolton-Medium text-[28px] text-gray-900">Employee Payroll</h2>
            </div>
            {scheduleData && (
              <div className="flex items-center justify-between gap-2 text-gray-400">
                Schedule
                <Button
                  variant="text"
                  className="flex items-center gap-2 whitespace-nowrap rounded-full border border-gray-100 px-4 py-2 text-sm leading-5 text-gray-900"
                  onPress={handlePayrollSchedule}
                  width="w-auto"
                >
                  {schedule[scheduleData?.payFrequency - 1]} <PencilIcon className="size-4" />
                </Button>
              </div>
            )}
          </div>

          {isLoading ? (
            <div className=" w-full max-w-[1130px]">
              <LoaderComponent />
            </div>
          ) : (
            <>{renderContent()}</>
          )}
        </div>
        {isOpenFillMissedTax && (
          <ConfirmEmployerTax
            isOpen={isOpenFillMissedTax}
            refetch={refetchWarning}
            toggleModal={() => setisOpenFillMissedTax(prev => !prev)}
          />
        )}
        {isPayrollScheduleOpen && (
          <PayrollSchedule
            isPayrollScheduleOpen={isPayrollScheduleOpen}
            setIsPayrollScheduleOpen={setIsPayrollScheduleOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setStep={setStep}
            step={step}
          />
        )}
      </div>
    </div>
  );
};

export default RunPayroll;
