import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TabType } from '@/components/ui/TabsComponent/types';
import useTabs from '@/components/ui/TabsComponent/useTabs';
import { useBillingInfo } from '@/hooks';
import * as routes from '@/routes/routes';

import { AccountBasicInfo, BillingCenter } from './components';
import { MY_ACCOUNT_TAB_IDS } from './utils/constants';

type useMyAccountType = {
  isBillingCenterOpen: boolean;
  onCloseAccountModal: () => void;
};

const useMyAccount = ({ isBillingCenterOpen, onCloseAccountModal }: useMyAccountType) => {
  const location = useLocation();
  const pathToCheck = routes.MY_ACCOUNT_BILLING_CENTER_MANAGE;

  const { billingInfo: subscriptionInformation, refetchBillingInfo } = useBillingInfo();

  const isManageBillingCenter = location.pathname.includes(pathToCheck);

  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState<TabType[]>([]);

  const tabController = useTabs({
    tabs,
  });

  const { setCurrentTab } = tabController;

  const initialTabSetup: TabType[] = useMemo(
    () => [
      {
        id: MY_ACCOUNT_TAB_IDS.myProfile,
        title: 'My Profile',
        disabled: false,
        component: AccountBasicInfo,
        props: {},
        isCompleted: false,
      },
      {
        id: MY_ACCOUNT_TAB_IDS.accountBilling,
        title: 'Billing center',
        disabled: false,
        component: BillingCenter,
        props: {
          isManageBillingCenter,
          onCloseAccountModal,
        },
        isCompleted: false,
      },
    ],
    [],
  );

  useEffect(() => {
    setTabs(initialTabSetup);
  }, [initialTabSetup]);

  const getSubscriptionStatus = async () => {
    refetchBillingInfo();
    setIsLoading(false);
  };

  useEffect(() => {
    if (subscriptionInformation !== null) {
      getSubscriptionStatus();
    } else {
      setIsLoading(true);
      getSubscriptionStatus();
    }
  }, []);

  useEffect(() => {
    if (isManageBillingCenter || isBillingCenterOpen) {
      setCurrentTab(MY_ACCOUNT_TAB_IDS.accountBilling);
    }
    refetchBillingInfo();
  }, []);

  return {
    subscriptionInformation,
    isLoading,
    tabController,
  };
};

export default useMyAccount;
