import { useFormContext } from 'react-hook-form';

import { useAccountInfo } from '@/hooks';

const useCompanyContribution = () => {
  const { isCheckHqUser } = useAccountInfo();
  const form = useFormContext();
  const { watch } = form || {};
  const period = form.watch('period') || 'pay_period';

  const hasCompanyContribution = watch('hasCompanyContribution') > 0;
  const employeeFields = {
    calculatedAs: watch('calculatedAs'),
    amountPerPayPeriod: watch('amountPerPayPeriod'),
    percentageGrossPay: watch('percentageGrossPay'),
    deductionLimit: watch('deductionLimit'),
  };

  // Track company-specific states
  const companyCalculatedAs = watch('companyCalculatedAs');

  const isCalculatedAsFixedAmount = companyCalculatedAs
    ? parseInt(companyCalculatedAs) === 1
    : hasCompanyContribution && parseInt(employeeFields.calculatedAs) === 1;

  const shouldShowContributionFields = [1, 2].includes(parseInt(companyCalculatedAs));

  return {
    companyCalculatedAs,
    isCalculatedAsFixedAmount,
    shouldShowContributionFields,
    form,
    hasCompanyContribution,
    period,
    isCheckHqUser,
  };
};

export default useCompanyContribution;
