import debounce from 'lodash.debounce';
import { forwardRef, useCallback, useState } from 'react';
import { Input as AriaInput, Label, Text, TextField } from 'react-aria-components';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';

import { extractEventName } from '@/utils/helpers';
import { twMerge } from '@/utils/tailwindMergeConfig';
import { trackEvent } from '@/utils/track';

import { AppInputProps } from './types';

/**
 * @deprecated Use new Input Component from src/components/ui/designSystem. It has various types now which are separate files.
 */

const Input = forwardRef(
  (
    {
      title,
      subtitle,
      placeholder,
      isRequired = false,
      isOptional = false,
      width,
      errorMessage,
      onChange,
      value,
      parentClasses,
      ...rest
    }: AppInputProps,
    ref,
  ) => {
    const [active, setActive] = useState(value ? value : '');

    const handleInputChange = useCallback(
      debounce(() => {
        trackEvent('inputChange', {
          name: rest.name,
          currentPage: extractEventName(location.pathname),
        });
      }, 700),
      [],
    );

    if (rest.type === 'phoneNumber') {
      return (
        <TextField className={`flex flex-col ${parentClasses}`} {...rest}>
          <Label className="font-F37Bolton-Medium text-[16px] text-gray-900">
            {title} {isRequired && <span className="text-red-500">*</span>}
            {isOptional && <span className="text-gray-300">(optional)</span>}
          </Label>
          <InputMask
            mask="(999) 999-9999"
            maskChar="_"
            placeholder={placeholder}
            className={`rounded-x-small mt-x-small h-[44px] placeholder:text-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
              width ? width : 'w-[335px]'
            } ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}
            value={value ?? active}
            onChange={e => {
              handleInputChange();
              onChange && onChange(e);
              setActive(e.target.value);
            }}
            ref={ref}
          />

          {(errorMessage || subtitle) && (
            <Text
              slot="description"
              className={`mt-x-small text-[14px] ${
                errorMessage ? 'text-red-500' : 'text-gray-400'
              }`}
            >
              {errorMessage ? errorMessage : subtitle}
            </Text>
          )}
        </TextField>
      );
    }

    if (rest.type === 'dollar') {
      return (
        <TextField className={`relative flex flex-col ${parentClasses}`} {...rest}>
          <Label className="font-F37Bolton-Medium text-[16px] text-gray-900">
            {title} {isRequired && <span className="text-red-500">*</span>}
            {isOptional && <span className="text-gray-300">(optional)</span>}
          </Label>

          <CurrencyInput
            decimalsLimit={2}
            placeholder={placeholder ?? '$0.00'}
            onValueChange={e => {
              handleInputChange();
              onChange && onChange(e);
              setActive(e?.target?.value);
            }}
            step={0.01}
            onWheel={event => event.currentTarget.blur()}
            min={0}
            decimalSeparator="."
            groupSeparator=","
            value={value}
            className={`input-currency rounded-x-small mt-x-small h-[44px]  placeholder:text-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
              width ? width : 'w-[335px]'
            } ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}
            prefix="$ "
          />

          {(errorMessage || subtitle) && (
            <Text
              slot="description"
              className={`mt-x-small text-[14px] ${
                errorMessage ? 'text-red-500' : 'text-gray-400'
              }`}
            >
              {errorMessage ? errorMessage : subtitle}
            </Text>
          )}
        </TextField>
      );
    }

    if (rest.type === 'ssn') {
      return (
        <TextField className={`flex flex-col ${parentClasses}`} {...rest}>
          <Label className="font-F37Bolton-Medium text-[16px] text-gray-900">
            {title} {isRequired && <span className="text-red-500">*</span>}
            {isOptional && <span className="text-gray-300">(optional)</span>}
          </Label>
          <InputMask
            mask="999-99-9999"
            maskChar=""
            placeholder={placeholder}
            className={`rounded-x-small mt-x-small h-[44px] placeholder:text-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
              width ? width : 'w-[335px]'
            } ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}
            value={value ?? active}
            onChange={e => {
              handleInputChange();
              onChange && onChange(e);
              setActive(e.target.value);
            }}
            ref={ref}
          />

          {(errorMessage || subtitle) && (
            <Text
              slot="description"
              className={`mt-x-small text-[14px] ${
                errorMessage ? 'text-red-500' : 'text-gray-400'
              }`}
            >
              {errorMessage ? errorMessage : subtitle}
            </Text>
          )}
        </TextField>
      );
    }

    return (
      <TextField className={`flex flex-col ${parentClasses}`} {...rest}>
        <Label className="font-F37Bolton-Medium text-[16px] text-gray-900">
          {title} {isRequired && <span className="text-red-500">*</span>}{' '}
          {isOptional && <span className="text-gray-300">(optional)</span>}
        </Label>
        <AriaInput
          placeholder={placeholder}
          className={twMerge(
            'rounded-x-small mt-x-small h-[44px] placeholder:text-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500',
            width ? width : 'w-[335px]',
            errorMessage ? 'border-red-500' : 'border-gray-300',
            rest.isDisabled ? 'border-gray-100 text-gray-100 placeholder:text-gray-100' : '',
          )}
          value={value ?? active}
          onChange={e => {
            handleInputChange();
            onChange && onChange(e);
            setActive(e.target.value);
          }}
          ref={ref}
        />
        {(errorMessage || subtitle) && (
          <Text
            slot="description"
            className={`mt-[8px] text-[14px] ${errorMessage ? 'text-red-500' : 'text-gray-400'}`}
          >
            {errorMessage ? errorMessage : subtitle}
          </Text>
        )}
      </TextField>
    );
  },
);

Input.displayName = 'Input';
export default Input;
