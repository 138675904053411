import React, { lazy, Suspense } from 'react';

import loaderPage from '@/assets/lottie/loader.json';
const Lottie = lazy(() => import('lottie-react'));

interface LoaderProps {
  height?: number;
  classNames?: string;
}

const LoaderComponent: React.FC<LoaderProps> = ({ height = 50, classNames = '' }) => {
  return (
    <div className={`items-center justify-center ${classNames}`}>
      <Suspense fallback={<div />}>
        <Lottie
          animationData={loaderPage}
          style={{ height }}
          loop={true}
          className="text-primary-emerald"
        />
      </Suspense>
    </div>
  );
};

export default LoaderComponent;
