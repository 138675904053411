export const ONBOARDING_STEPPER_IDS = {
  company: 'company',
  payrollSettings: 'payroll-settings',
  taxes: 'taxes',
  employees: 'employees',
  contractors: 'contractors',
  bankAccount: 'bank-account',
  filingAuthorization: 'filing-authorization',
  runPayroll: 'runPayroll',
  finishOnboarding: 'finish-onboarding',
};

export const ONBOARDING_STRUCTURE = {
  both: 'both',
  employee: 'employee',
  contractor: 'contractor',
};
