import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';

import { queryEndpoints } from './endpoints';
import { ActiveEmployeeType } from './types';

type ActiveEmployeeResponseType = AxiosResponse<ActiveEmployeeType>;

const useGetRunnableActiveEmployee = ({
  companyId,
  queryParams,
}: {
  companyId: number;
  queryParams?: Omit<
    UseQueryOptions<ActiveEmployeeResponseType, Error, ActiveEmployeeResponseType>,
    'queryKey'
  >;
}) => {
  const { queryKey, url } = queryEndpoints.runnableActiveEmployee(companyId);

  return useQuery<ActiveEmployeeResponseType, Error>({
    queryKey,
    queryFn: () => getCall(url),
    ...queryParams,
  });
};

export default useGetRunnableActiveEmployee;
