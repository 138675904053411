const GarnishmentIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_31309_109145)">
        <path
          id="Vector"
          d="M13 10L20.383 17.418C21.206 18.238 21.206 19.566 20.383 20.385C19.9876 20.7783 19.4527 20.999 18.895 20.999C18.3373 20.999 17.8024 20.7783 17.407 20.385L10 13"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M6 9L10 13"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M13 10L9 6"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M3 21H10"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M6.79285 15.7931L3.20685 12.2071C3.01938 12.0196 2.91406 11.7653 2.91406 11.5001C2.91406 11.2349 3.01938 10.9806 3.20685 10.7931L5.49985 8.50009L5.99985 9.00009L8.99985 6.00009L8.49985 5.50009L10.7928 3.20709C10.9804 3.01962 11.2347 2.91431 11.4998 2.91431C11.765 2.91431 12.0193 3.01962 12.2068 3.20709L15.7928 6.79309C15.9803 6.98062 16.0856 7.23493 16.0856 7.50009C16.0856 7.76526 15.9803 8.01957 15.7928 8.20709L13.4998 10.5001L12.9998 10.0001L9.99985 13.0001L10.4998 13.5001L8.20685 15.7931C8.01932 15.9806 7.76501 16.0859 7.49985 16.0859C7.23468 16.0859 6.98038 15.9806 6.79285 15.7931Z"
          stroke="#008C70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_31309_109145">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GarnishmentIcon;
