import { useMemo } from 'react';

import { useAccountInfo } from '@/hooks';
import { offCyclePayrollTypes } from '@/modules/runPayroll/utils/constants';
import * as routes from '@/routes/routes';

interface PayrollHistoryLinkBase {
  payrollId: string;
  payrollType: string;
  status: number;
  isAllOffCycleDraftPayroll?: boolean;
  deletePayrollHistory?: any;
}

type PayrollHistoryLinkType = PayrollHistoryLinkBase;
export type PayrollHistoryLinkProps = PayrollHistoryLinkBase;

const usePayrollHistoryLink = ({
  payrollId,
  payrollType,
  status,
  deletePayrollHistory,
}: PayrollHistoryLinkType) => {
  const { isCheckHqUser } = useAccountInfo();

  const payrollLink = useMemo(() => {
    const defaultLink = {
      label: 'View',
      redirectTo:
        status === 0
          ? routes.RUN_PAYROLL_ADD_INFORMATION.replace(':payrollId', payrollId)
          : routes.RUN_PAYROLL_DETAILS.replace(':payrollId', payrollId),
    };

    if (!isCheckHqUser) {
      return defaultLink;
    }

    if ([1, 4, 5].includes(status)) {
      return defaultLink;
    }

    if (offCyclePayrollTypes.includes(payrollType) && status === 0) {
      return {
        label: 'Continue to run',
        redirectTo: routes.RUN_PAYROLL_ADD_INFORMATION?.replace(':payrollId', payrollId),
      };
    }

    return defaultLink;
  }, [isCheckHqUser, payrollId, payrollType]);

  const handleMenuActions = () => {
    deletePayrollHistory(payrollId);
  };

  return { payrollLink, handleMenuActions };
};

export default usePayrollHistoryLink;
