import { IconChevronDown } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { NumericFormat } from 'react-number-format';

import { LoaderComponent, Menu, TableCell } from '@/components';
import { Tag } from '@/components/ui/Tag/Tag';

import { BillingHistoryTableRowProps } from '../../type';

export const statusTextMap: Record<'paid' | 'pending' | 'past_due', string> = {
  paid: 'Paid',
  pending: 'Due',
  past_due: 'Past due',
};

export const statusColorMap: Record<'paid' | 'pending' | 'past_due', string> = {
  paid: 'success',
  pending: 'accent',
  past_due: 'danger',
};

const BillingHistoryTableRow = ({
  billData,
  isLoading,
  handleDownloadInvoice,
  handleDownloadReceipt,
}: BillingHistoryTableRowProps) => {
  const payDate = DateTime.fromISO(billData?.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  const handleMenuActions = (key?: string) => {
    switch (key) {
      case 'download_invoice':
        handleDownloadInvoice();
        break;
      case 'download_receipt':
        if (billData?.status === 'paid') {
          handleDownloadReceipt();
        } else {
          break;
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TableCell className="px-4 py-3 text-start">
        <span className="text-small">{payDate}</span>
      </TableCell>
      <TableCell className="px-4 py-3">
        <span className="text-small capitalize">{billData?.plan}</span>
      </TableCell>
      <TableCell className="px-4 py-3 text-end">
        <NumericFormat
          value={billData?.totalAmount}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
          renderText={value => <span className="text-small">${value}</span>}
        />
      </TableCell>
      <TableCell className="px-4 py-3 text-start">
        <Tag
          tagStyle="default"
          tagType={statusColorMap[billData?.status] ?? 'danger'}
          children={statusTextMap[billData?.status] ?? 'Failed'}
          className="capitalize"
        />
      </TableCell>
      <TableCell className="px-4 py-3 text-end">
        {/* <Button
          variant="tertiary"
          className="text-small h-9 w-[105px]"
          isLoading={isLoading}
          onPress={handleAction}
        >
          {billData?.fsInvoiceUrl ? 'View receipt' : 'Download'}
        </Button> */}
        <div className="flex justify-end">
          {isLoading ? (
            <LoaderComponent height={42} />
          ) : (
            <Menu
              menuButtonVariant="tertiary"
              menuButtonClasses="h-auto text-small !w-auto !text-content-body-strong !py-x-small !px-medium"
              menuButtonLoading={isLoading}
              menuButtonLabel="Download"
              menuButtonIcon={<IconChevronDown className="size-medium text-icon-dark ml-1" />}
              menuItemsList={[
                {
                  key: 'download_invoice',
                  label: 'Download invoice',
                },
                {
                  key: 'download_receipt',
                  label: 'Download receipt',
                  className:
                    billData?.status === 'paid' ? '' : 'text-content-body-light !cursor-default',
                  tooltipContent: 'You can download the receipt after the invoice has been paid',
                  tooltipId: billData?.status === 'paid' ? null : 'receipt-tooltip',
                },
              ]}
              menuWrapperClasses="min-w-[200px]"
              onAction={(actionKey?: Key) => handleMenuActions(actionKey)}
            />
          )}
        </div>
      </TableCell>
    </>
  );
};

export default BillingHistoryTableRow;
